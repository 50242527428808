import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topCategoryList: [],
    categoryList: [],
    categoryToImg: {},
};

const CategoryInfo = createSlice({
    name: 'common/categoryInfo',
    initialState,
    reducers: {
        setTopCategoryList: (state, action) => {
            const categoryList = action.payload
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
            state.topCategoryList = categoryList;
        },
        setCategoryList: (state, action) => {
            state.categoryList = [...action.payload];
        },
        setAllCategoryList: (state, action) => {
            const categoryList = [...action.payload];

            if (categoryList && Array.isArray(categoryList)) {
                categoryList.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
                state.categoryToImg = {};
                categoryList.forEach(category => {
                    state.categoryToImg[category.categoryCode] = category.normalIconURL;
                });
            }

            state.categoryList = categoryList;
            state.topCategoryList = categoryList
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
        },
    },
});

export const { setTopCategoryList, setCategoryList, setAllCategoryList } = CategoryInfo.actions;
export default CategoryInfo.reducer;
