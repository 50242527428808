import { reqGet } from './index';

export const fetchTagList = param => reqGet('/v3/api/common/tag', { ...param });
export const fetchScannerList = param => reqGet('/v3/api/common/scanner', { ...param, isAll: 'Y' });
export const fetchIotItemCount = param => reqGet('/v3/api/common/statistics/category/by-target/count', { ...param });
// 태그 배터리 상태 조회
export const fetchTagBatteryCount = param =>
    reqGet('/v3/api/common/statistics/mapped-tag-battery/by-unit/count', { ...param });

//// 자산 현황
// 카테고리 리스트 호출
export const fetchCategory = param => reqGet('/v3/api/common/target/category/info', { ...param });

// 현재 자산 현황 개수 데이터 호출 (전체 개수, lost signal 개수 etc..)
export const fetchAssetCountStatus = param =>
    reqGet('/v3/api/common/statistics/category/target/tag/by-status/count', { ...param });

// 자산 리스트 호출
export const fetchAssetSummaryList = param =>
    reqGet('/v3/api/common/statistics/category/target/location/info', { ...param });

//// 실시간 위치 현황
export const fetchIotItemList = param => reqGet('/v3/api/common/target/info', { ...param });

// 모든 ioT 항목 수
export const fetchIoTItemCount = param => reqGet('/v3/api/common/statistics/count-by-category/count', { ...param });
