import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { encodeInfo, getLsUserInfo } from '../../../util/common/util';
import { getApiURL } from '../../../api';
import envKeys from '../../../environment';

const { serviceCode } = envKeys;

const LastLinkWrapper = styled.div`
    .metismenu-container.visible & {
        height: calc(2.3em + 4px);
        //    border-bottom: 1px solid var(--blue);
        //    margin-bottom: 3px;
    }
    .metismenu-container.visible &.menu-border-box {
        border-bottom: 1px solid var(--blue);
        margin-bottom: 3px;
    }
    .closed-sidebar .metismenu-container.visible &,
    & {
        height: 0;
        overflow: hidden;
    }
    .closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-container.visible & {
        height: calc(2.3em + 4px);
    }
    @media (max-width: 991.98px) {
        .sidebar-mobile-open .app-sidebar .app-sidebar__inner .metismenu-container.visible & {
            height: calc(2.3em + 4px);
        }
    }
`;

const EditButton = styled(Button)`
    &:active,
    &:focus {
        border: 1px solid transparent;
    }
`;

const isCustomMenu = id => id.toString().indexOf('customDashboard_') >= 0;

const apiInfo = getApiURL();

const createSSOForm = async (serviceCode, oAuthInfo) => {
    const baseUrl = await apiInfo;
    const form = document.createElement('form');
    form.setAttribute('charset', 'UTF-8');
    form.setAttribute('method', 'post');
    form.setAttribute('action', `${baseUrl.wmsUrl}/sso/login`);
    form.setAttribute('target', 'wmsWindow');

    // const formData = new FormData(form);
    // formData.append('serviceCode', serviceCode);
    // formData.append('enOAuthInfo', encodeInfo(oAuthInfo));

    const inputServiceCode = document.createElement('input');
    inputServiceCode.setAttribute('type', 'hidden');
    inputServiceCode.setAttribute('name', 'serviceCode');
    inputServiceCode.setAttribute('value', serviceCode);
    const inputOAuthInfo = document.createElement('input');
    inputOAuthInfo.setAttribute('type', 'hidden');
    inputOAuthInfo.setAttribute('name', 'enOAuthInfo');
    inputOAuthInfo.setAttribute('value', encodeInfo(oAuthInfo));
    form.appendChild(inputServiceCode);
    form.appendChild(inputOAuthInfo);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

const ALink = props => {
    const history = useHistory();
    const { t } = useTranslation();
    // let match = useRouteMatch(['/dashboards/:menuNum([0-9]+)', '/dashboards/edit/:menuNum([0-9]+)']);
    const onClick = e => {
        if (props.id === 'wmsConnection') {
            e.stopPropagation();
            e.preventDefault();
            // const serviceCode = serviceCode;
            // wmsLogin({ serviceCode, enOAuthInfo: encodeInfo(oAuthInfo) });
            const userInfo = getLsUserInfo();
            if (userInfo) {
                createSSOForm(serviceCode, userInfo.oAuthInfo);
            }
        } else {
            if (props.hasSubMenu) {
                props.toggleSubMenu(e);
            } else {
                props.activateMe({
                    newLocation: props.to,
                    selectedMenuLabel: props.label,
                    id: props.id,
                    e,
                });
            }
        }
    };

    return (
        <a
            className={cx(
                'metismenu-link',
                props.id && props.id === 'customDashboard_last' ? 'last-custom-dashboard-link' : '',
                props.active && 'active',
            )}
            href={props.to}
            onClick={onClick}
        >
            {props.children.map((v, i) =>
                i === 1 ? (
                    <React.Fragment key={props.id}>
                        {props.id && props.id.toString().indexOf('home') >= 0 && (
                            <FontAwesomeIcon
                                icon={faHome}
                                style={{ color: 'var(--blue)', verticalAlign: '0.6em', marginRight: '5px' }}
                            />
                        )}
                        <span
                            style={{
                                maxWidth: isCustomMenu(props.id) ? '85%' : '100%',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                display: 'inline-block',
                            }}
                        >
                            {v}
                        </span>
                    </React.Fragment>
                ) : (
                    v
                ),
            )}
            {props.id && isCustomMenu(props.id) && (
                <EditButton
                    outline
                    className="btn-pill"
                    color="primary"
                    size={'sm'}
                    style={{
                        borderColor: '#545cd8',
                        fontSize: '0.7rem',
                        borderRadius: '0.6rem',
                        padding: '0 0.3rem',
                        position: 'absolute',
                        right: '0px',
                        transform: 'translate(-50%, 45%)',
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        history.push({
                            pathname: props.to.replace('#', '').replace('/dashboards/', '/dashboards/edit/'),
                            state: { redirect: true },
                        });
                    }}
                >
                    {t('Dashboard;Edit')}
                </EditButton>
            )}
        </a>
    );
};

const CustomLink = props => {
    return props.id && props.id.toString().indexOf('customDashboard_last') > -1 ? (
        <LastLinkWrapper className={'menu-border-box'}>
            <ALink {...props} />
        </LastLinkWrapper>
    ) : (
        <ALink {...props} />
    );
};

export default CustomLink;
