import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../Common/Button';
import * as column from '../../util/grid/columns';
import { GroupSettingDispatchContext, GroupSettingStateContext } from '../PatientGroupSetting';
import CustomCard from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import {
    createPatientGroup,
    getGroupList,
    getMappingPatientList,
    updatePatientGroup,
} from '../../../../api/groupSetting';
import {
    setGroupList,
    setGroupPatientList,
    setWardInfoList,
    setGroupInfo,
    reset,
    setGroupTitle,
    updateGroupInfo,
    setGroupNameList,
} from '../groupSettingReducer';
import RecordTable from '../../Components/RecordTable';
import ConfirmModal from '../../Components/ConfirmModal';
import cx from 'classnames';
import { validationCheck } from '../../ActiveSchedule/util/Validation';
import CustomGroupModal from './CustomGroupModal';
import useTranslation from '../../../../util/hooks/useTranslation';

const GroupList = () => {
    const t = useTranslation('Group');
    const dispatch = useContext(GroupSettingDispatchContext);
    const state = useContext(GroupSettingStateContext);
    const { groupList, groupInfo } = state;
    const [patientGroupNumber, setPatientGroupNumber] = useState(null);
    const [validation, setValidation] = useState({
        patientGroupName: '그룹명',
        patientGroupId: '그룹 아이디',
        wardNo: '병동',
        teamCd: '병동팀',
        useFlag: '사용유무',
    });

    const groupColumns = [
        column.patientGroupName({
            Header: t('Patient Group Name'),
        }),
        column.patientGroupId({
            Header: t('Patient Group Id'),
        }),
        column.wardName({ Header: t('Ward') }),
        column.useOrNot({ Header: t('Use Flag') }),
        column.wardTeam({ Header: t('Ward Team') }),
    ];

    useEffect(() => {
        if (!groupInfo) {
            setButtonState(true);
        } else {
            setButtonState(false);
        }
    }, [groupInfo]);
    const [buttonState, setButtonState] = useState(true);
    const onClick = e => {
        const { patientGroupId, patientGroupName, patientGroupNum, teamCd, useFlag, wardNo } = e;

        getPatientList({ patientGroupNum });
        setValidation(null);
        dispatch(setGroupInfo({ patientGroupNum, teamCd, wardNo, patientGroupId, patientGroupName, useFlag }));
        dispatch(setGroupTitle({ patientGroupId }));
    };
    const { promise: getGroupNameList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            dispatch(setGroupNameList(res));
        },
    });

    const { promise: getWardInfoList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            dispatch(setWardInfoList(res));
        },
    });
    //환자 그룹 리스트 호출
    const { state: loadingState, promise: getPatientGroupList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            dispatch(setGroupList(res));
        },
    });
    useEffect(() => {
        getPatientGroupList({ order: 'ASC', pageSize: 10 });
        getWardInfoList({ isAll: 'Y' });
    }, []);

    // 환자그룹 등록 API
    const { promise: addPatientGroup } = useAsync({
        promise: createPatientGroup,
        resolve: res => {
            if (res.result === 'success') {
                resultModalToggle();
                getPatientGroupList({ order: 'ASC', pageSize: 10 });
            }
        },
        reject: error => {
            toggleErrorConfirmModal();
        },
    });
    const { promise: editPatientGroup } = useAsync({
        promise: updatePatientGroup,
        resolve: res => {
            if (res.result === 'success') {
                resultEditModalToggle();
                getPatientGroupList({ order: 'ASC', pageSize: 10 });
            }
        },
    });
    const [resultText, setResultText] = useState([]);
    const onAddGroup = () => {
        let result = validationCheck(validation);
        if (!result.length) {
            addPatientGroup({ ...groupInfo });
            getGroupNameList({ order: 'ASC', isAll: 'Y' });
            groupCreateModalToggle();
        } else {
            setResultText(result);
            confirmModalToggle();
        }
    };
    const onEditGroup = () => {
        let result = validationCheck(validation);
        if (!result.length) {
            editPatientGroup({ ...groupInfo });
            groupUpdateModalToggle();
        } else {
            setResultText(result);
            confirmModalToggle();
        }
    };
    const { promise: getPatientList } = useAsync({
        promise: getMappingPatientList,
        resolve: res => {
            dispatch(setGroupPatientList({ res, type: 'mapping' }));
        },
    });

    const onPageChange = pageIndex => {
        getPatientGroupList({ order: 'ASC', pageSize: 10, page: pageIndex });
    };

    //modal 창 관리
    const [modalState, setModalState] = useState(false);
    const confirmModalToggle = () => {
        setModalState(!modalState);
    };

    const [groupCreateModalState, setGroupCreateModalState] = useState(false);
    const groupCreateModalToggle = () => {
        setGroupCreateModalState(!groupCreateModalState);
        setValidation({
            patientGroupName: t('Patient Group Name'),
            patientGroupId: t('Patient Group Id'),
            wardNo: t('Ward'),
            teamCd: t('Ward Team'),
            useFlag: t('Use Flag'),
        });
        dispatch(updateGroupInfo(null));
    };

    const [groupUpdateModalState, setGroupUpdateModalState] = useState(false);
    const groupUpdateModalToggle = () => {
        setGroupUpdateModalState(!groupUpdateModalState);
    };

    const [resultModalState, setResultModalState] = useState(false);
    const resultModalToggle = () => {
        setResultModalState(!resultModalState);
    };

    const [resultEditModalState, setResultEditModalState] = useState(false);
    const resultEditModalToggle = () => {
        setResultEditModalState(!resultEditModalState);
    };

    const [errorConfirmModalState, setErrorConfirmModalState] = useState(false);
    const toggleErrorConfirmModal = () => {
        setErrorConfirmModalState(!errorConfirmModalState);
    };

    return (
        <>
            <CustomCard
                className={'h-100'}
                header={{
                    title: t('Group List'),
                    action: (
                        <>
                            <Button
                                className="pnt-btn btn-orange"
                                onClick={() => {
                                    dispatch(reset());
                                    groupCreateModalToggle();
                                }}
                            >
                                {t('Create Group')}
                            </Button>
                            <Button
                                className={cx(
                                    'pnt-btn btn-blue btn-icon',
                                    (buttonState && 'btn-lightgray') || 'btn-blue',
                                )}
                                iconClassName={'icon-editnote'}
                                disabled={buttonState}
                                onClick={() => {
                                    groupUpdateModalToggle();
                                }}
                            >
                                {t('Edit Group')}
                            </Button>
                        </>
                    ),
                }}
            >
                <RecordTable
                    columns={groupColumns}
                    onPageChange={onPageChange}
                    data={{
                        rows: groupList.rows,
                        pageSize: 10,
                        totalCount: groupList.totalCount,
                        totalPage: groupList.totalPage,
                    }}
                    rowSelect={{
                        selected: groupInfo,
                        rowKey: 'patientGroupNum',
                        selectedRowClassName: 'selected-color',
                    }}
                    onClick={onClick}
                    loading={loadingState.isLoading}
                />
                <ConfirmModal
                    initModal={resultModalState}
                    headerText={t('Alarm')}
                    modalToggle={resultModalToggle}
                    confirmText={t('The group was successfully registered')}
                />
                <ConfirmModal
                    initModal={resultEditModalState}
                    headerText={t('Alarm')}
                    modalToggle={resultEditModalToggle}
                    confirmText={t('The group was modified successfully')}
                />
                <ConfirmModal
                    initModal={modalState}
                    headerText={t('List of prerequisites')}
                    modalToggle={confirmModalToggle}
                    confirmText={TextChange(resultText)}
                />
                <CustomGroupModal
                    headerTitle={t('Alarm')}
                    openModal={groupCreateModalState}
                    onModalClickYes={groupCreateModalToggle}
                    handleClick={onAddGroup}
                    validation={validation}
                    validationCheck={setValidation}
                />
                <CustomGroupModal
                    headerTitle={t('Alarm')}
                    openModal={groupUpdateModalState}
                    onModalClickYes={groupUpdateModalToggle}
                    handleClick={onEditGroup}
                    validation={validation}
                    validationCheck={setValidation}
                />
                <ConfirmModal
                    initModal={errorConfirmModalState}
                    headerText={t('Alarm')}
                    modalToggle={toggleErrorConfirmModal}
                    confirmText={
                        <span style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {t('An already registered group ID')}
                        </span>
                    }
                />
            </CustomCard>
        </>
    );
};

const TextChange = value => {
    const t = useTranslation('Group');
    return (
        <div style={{ alignItems: 'left' }}>
            {value.map((text, i) => (
                <div className={'pnt-label--group'}>
                    <div className={'label-main label-dot font-size-lg'} key={i}>
                        {`${t('Please enter')}${t(text, 'ActTypeCode')} ${t('word')}`}
                    </div>
                    <br />
                </div>
            ))}
        </div>
    );
};

export default GroupList;
