import React from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import PatientGroupSetting from './PatientGroupSetting';
const GroupSetting = ({ match }) => {
    return (
        <>
            <Container fluid>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}`} component={PatientGroupSetting} />
                </ErrorHandleSwitch>
            </Container>
        </>
    );
};

export default GroupSetting;
