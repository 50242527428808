import React, { useState } from 'react';
import Button from '../../../../Common/Button';
import { Col, Row } from 'reactstrap';
import TextInput from '../../../../Common/Input/TextInput';
import ReactSelect from '../../../../Common/Select';
import { searchOptions } from '../../reducer';
import useTranslation from "../../../../../util/hooks/useTranslation";

const Search = ({ selectedOption, handleSearch }) => {
    const t = useTranslation('Search')
    const [searchOption, setSearchOption] = useState(selectedOption);const searchOptions = [
        { label: 'ALL', value: '' },
        { label: t('Classification name'), value: 'clCodeName' },
        { label: t('Code name'), value: 'codeName' },
    ];
    const handleSearchClick = () => {
        if (typeof handleSearch === 'function') {
            handleSearch(searchOption);
        }
    };

    return (
        <div className="filter__now">
            <div className="filter__info">
                <div className="pnt-label--group">
                    <span className="label-main">Search</span>
                </div>
            </div>
            <div className="filter__options">
                <Row className={'w-100'}>
                    <Col md={'2'} className={'pr-0 pb-1'}>
                        <ReactSelect
                            name={'opt'}
                            value={searchOption.opt}
                            options={searchOptions}
                            onChange={selected => {
                                if (selected.value) {
                                    setSearchOption({ ...searchOption, opt: selected });
                                } else {
                                    setSearchOption({ keyword: '', opt: selected });
                                }
                            }}
                            customControlStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </Col>
                    <Col md={'4'} className={'pr-0 d-flex'}>
                        <TextInput
                            type={'text'}
                            size={'100'}
                            disabled={!searchOption.opt.value}
                            handleChange={e => {
                                const { value } = e.target;
                                setSearchOption({ ...searchOption, keyword: value });
                            }}
                            placeholder={t('Select the search option and enter your search term')}
                            name={'keyword'}
                            value={searchOption.keyword}
                            className={'text-ellipsis'}
                        />
                        <Button
                            className={'pnt-btn btn-blue btn-icon-only none-under-770 ml-2'}
                            iconClassName={'icon-search'}
                            onClick={handleSearchClick}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Search;
