import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';
import { useTranslation } from 'react-i18next';
import { setBookmarkMenu } from '../../../reducers/CustomMenu';
import CustomLink from './CustomLink';
import useAsync from '../../../util/hooks/useAsync';
import { fetchDashboardList } from '../../../api/dashboard';
import _ from 'lodash';

const chgMenuLabelByLanguage = (menus, t) => {
    return menus.map(v => {
        const copyV = { ...v };
        if (copyV.content) {
            copyV.content = chgMenuLabelByLanguage(copyV.content, t);
        }
        if (!copyV.id || (copyV.id && copyV.id.indexOf('customDashboard_') < 0)) {
            copyV.label = t(`Menu;${copyV.label}`);
        }
        return copyV;
    });
};

const Nav = ({ location, match }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { promise: getCustomMenu } = useAsync({
        promise: fetchDashboardList,
        param: { isIndividual: 'Y', show: 'Y', isAll: 'Y' },
        resolve: response => {
            let bookmarkedMenu = response.rows || [];
            dispatch(setBookmarkMenu(bookmarkedMenu));
        },
    });

    const { enableMobileMenu } = useSelector(state => state.ThemeOptions);

    const navRef = useRef();
    const { menus: menuSet } = useSelector(state => state.CustomMenu);
    const [menus, setMenus] = useState(menuSet);

    const { dashboardListInfo } = useSelector(state => state.Dashboards);
    const { bookmarkedMenu } = useSelector(state => state.CustomMenu);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    // const isPathActive = path => {
    //     return location.pathname.startsWith(path);
    // };

    const forcedActiveUpdate = () => {
        //window.location.hash === # + location.pathname
        const activePath = window.location.hash || `#${location.pathname}`;
        if (activePath.indexOf('dashboards/edit') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/edit', ''));
            // navRef.current.changeActiveLinkTo('#/dashboards/list');
        }
        navRef.current.changeActiveLinkTo(activePath);
    };

    useEffect(() => {
        setMenus(menuSet);
    }, [menuSet]);

    useEffect(() => {
        // 대시보드 생성 수정 시, 북마크 메뉴 다시 API 호출로 수정
        getCustomMenu();
        // const bookmarked = dashboardListInfo.rows.filter(dashboard => dashboard.show === 'Y');
        // dispatch(setBookmarkMenu(bookmarked));
    }, [dashboardListInfo]);

    // useEffect(() => {
    //     setMenus(chgMenuLabelByLanguage(CustomNav, t));
    // }, [lang]);

    useEffect(() => {
        const customMenus = bookmarkedMenu.map((dashboard, i) => {
            const { menuName, menuNum, home } = dashboard;
            const menu = { label: menuName, to: `#/dashboards/${menuNum}` };
            menu.id = 'customDashboard_' + (i + 1);
            if (i === bookmarkedMenu.length - 1) {
                menu.id = 'customDashboard_last';
            }
            if (home === 'Y') {
                menu.id += '_home';
            }
            return menu;
        });

        const dashboardMenu = _.cloneDeep(menuSet).find(menu => menu.id === 'dashboard');
        if (dashboardMenu) {
            const dashboardSubMenu = dashboardMenu.content;
            const dashboardSubMenuLen = dashboardSubMenu.length;
            let checkCnt = 0;
            while (dashboardSubMenuLen > checkCnt++) {
                if (dashboardSubMenu[0].id && dashboardSubMenu[0].id.indexOf('customDashboard_') >= 0) {
                    dashboardSubMenu.shift();
                }
            }
            dashboardMenu.content = [...customMenus, ...dashboardSubMenu];
        }

        // setMenus(
        //     menus.map(menu => {
        //         if (menu.id && menu.id === 'dashboard') {
        //             return dashboardMenu;
        //         }
        //         return menu;
        //     }),
        // );
        setMenus(
            chgMenuLabelByLanguage(
                menuSet.map(menu => {
                    if (menu.id && menu.id === 'dashboard') {
                        return dashboardMenu;
                    }
                    return menu;
                }),
                t,
            ),
        );
    }, [menuSet, bookmarkedMenu, t]);

    useEffect(() => {
        forcedActiveUpdate(location);
    }, [location, menus]);

    return (
        <MetisMenu
            content={menus}
            onSelected={toggleMobileSidebar}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            ref={navRef}
            LinkComponent={CustomLink}
        />
    );
};

export default withRouter(Nav);
