import { createSlice } from '@reduxjs/toolkit';

import { onHandleAge, onHandlePartAge } from '../../Components/MainPages/util/dateChange';

const initialState = {
    targetId: null,
    partInfo: null,
    patientInfo: null,
    patientDetailInfo: null,
    patientInfoList: { rows: [], pageSize: 20 },
};

const PatientInfo = createSlice({
    name: 'common/patientInfo',
    initialState,
    reducers: {
        setTargetId: (state, action) => {
            state.targetId = action.payload;
        },
        setPartInfo: (state, action) => {
            const data = action.payload;
            if (data) {
                const age = onHandlePartAge(data.properties.birthDay);
                const status = data.properties.status;
                state.partInfo = { age: Number(age), status };
            } else {
                state.partInfo = null;
            }
        },
        setPatientInfo: (state, action) => {
            const data = action.payload;
            state.patientInfo = data;
        },
        setPatientDetailInfo: (state, action) => {
            // 환자 카드 데이터
            const data = action.payload;
            if (data) {
                let patientInfo = {
                    ...data,
                    ...data.properties,
                };
                delete patientInfo.properties;
                patientInfo = {
                    ...patientInfo,
                    age: onHandleAge(patientInfo.birthDay),
                    birthDay: data.birthDay,
                    targetId: data.targetId,
                    targetName: data.targetName,
                    targetNum: data.targetNum,
                };

                state.patientDetailInfo = patientInfo;
            } else {
                state.patientDetailInfo = null;
            }
        },

        setPatientInfoList: (state, action) => {
            let educationInfo = [];
            const data = action.payload;
            if (data) {
                educationInfo = data.rows.reduce((acc, curr, i) => {
                    acc.push({
                        targetNum: curr.targetNum,
                        targetName: curr.targetName,
                        targetId: curr.targetId,
                        age: onHandleAge(curr.properties.birthDay),
                        ...curr.properties,
                    });
                    return acc;
                }, []);

                state.patientInfoList = {
                    pageSize: 20,
                    page: action.payload.page,
                    totalCount: action.payload.totalCount,
                    rows: educationInfo,
                };
            } else {
                state.patientInfoList = { rows: [] };
            }
        },
        resetPatientDetailInfo: (state, action) => {
            state.patientDetailInfo = null;
        },
    },
});

export const {
    setTargetId,
    setPartInfo,
    setPatientInfo,
    setPatientDetailInfo,
    setPatientInfoList,
    resetPatientDetailInfo,
} = PatientInfo.actions;
export default PatientInfo.reducer;
