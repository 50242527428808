import React, { Fragment } from 'react';

import CustomCard from '../../Common/Card';
import Button from '../../Common/Button';
import cx from 'classnames';

const ActiveRecordCard = ({ headerTitle, headerTitleSub, buttonOption, spanIcon, url, children, style }) => {
    return (
        <CustomCard
            style={style}
            header={{
                title: headerTitle,
                subTitle: headerTitleSub,
                action: (
                    <>
                        {buttonOption &&
                            buttonOption.length > 0 &&
                            buttonOption.map((item, i) => (
                                <Fragment key={i}>
                                    <Button
                                        className={cx(
                                            (item.color && item.color) || 'btn-blue',
                                            'pnt-btn',
                                            (item.iconBtnClass && item.iconBtnClass) || null,
                                        )}
                                        onClick={item.onClick}
                                    >
                                        {item.className && <span className={`${item.className}`}></span>}
                                        {item.label}
                                    </Button>
                                    {item.borderOption && <div className="pnt-border border-vert"></div>}
                                </Fragment>
                            ))}

                        {spanIcon &&
                            spanIcon.length > 0 &&
                            spanIcon.map((icon, i) => (
                                <Button
                                    key={i}
                                    onClick={() => window.open(url, '_blank')}
                                    className=" btn-icon-only btn-blue"
                                    iconClassName={icon}
                                />
                            ))}
                    </>
                ),
            }}
        >
            {children}
        </CustomCard>
    );
};

export default ActiveRecordCard;
