import React, { useState } from 'react';
import { CardProps } from '../type';
import { Card as RSCard, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';
import Button from '../Button';

const Card = ({
    className,
    header = { title: '' },
    footerTitle,
    overlayOn: on = false,
    overlay = { title: '' },
    children,
    innerRef,
    style,
}: CardProps): JSX.Element => {
    const { title, subTitle, action, titleIcon } = header;
    const { title: overlayTitle, action: overlayAction, content } = overlay;
    const [overlayOn, setOverlayOn] = useState(on);
    return (
        <>
            <RSCard className={className} style={style} innerRef={innerRef}>
                {!!title && (
                    <CardHeader>
                        <div className={'card-header__title'}>
                            <CardTitle title={title} tag={'h3'} className={'title__main text-ellipsis'}>
                                {titleIcon && (
                                    <span style={{ transform: 'scale(0.8)' }} className={`mr-1 ${titleIcon}`} />
                                )}
                                {title}
                            </CardTitle>
                            <p className={'title__sub'}>{subTitle}</p>
                        </div>

                        {(!!action || !!overlayTitle) && (
                            <div className={'card-header__function'}>
                                {action}
                                {!!overlayTitle && (
                                    <Button
                                        className={'btn-icon-only card-overwrap-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconClassName={`icon-info`}
                                    />
                                )}
                            </div>
                        )}
                    </CardHeader>
                )}

                <CardBody
                // className={'overflow-visible'}
                >
                    {children}
                </CardBody>

                {footerTitle && <CardFooter>{footerTitle}</CardFooter>}

                {overlayOn && (
                    <div className={`card-overlay on`}>
                        <RSCard>
                            <CardHeader>
                                <div className="card-header__title">
                                    <CardTitle tag={'h3'} className={'title__main'}>
                                        {overlayTitle}
                                    </CardTitle>
                                </div>
                                <div className="card-header__function">
                                    {overlayAction}
                                    <Button
                                        className={'btn-icon-only btn-small p-0 card-overwrap-close-btn'}
                                        onClick={e => setOverlayOn(!overlayOn)}
                                        iconClassName={'icon-close'}
                                    />
                                </div>
                            </CardHeader>

                            <CardBody>{content}</CardBody>
                        </RSCard>
                    </div>
                )}
            </RSCard>
        </>
    );
};

export default Card;
