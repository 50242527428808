import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';

import { layout } from '../../Common/style';
import { CustomNav, SettingsNav } from '../AppNav/NavItems';
import { setMenus } from '../../../reducers/CustomMenu';
import cloneDeep from 'lodash/cloneDeep';
import useTranslation from "../../../util/hooks/useTranslation";

const AppSidebar = props => {
    const t = useTranslation('Menu');
    const dispatch = useDispatch();
    const { enableSidebarShadow, enableMobileMenu, backgroundColor } = useSelector(state => state.ThemeOptions);
    const { profile } = useSelector(state => state.UserInfo);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const [mainMenu, setMainMenu] = useState(true);
    const toggleMenu = () => {
        setMainMenu(!mainMenu);
    };

    useEffect(() => {
        if (mainMenu) {
            const HandleCustomNav = cloneDeep(CustomNav);
            if (profile && profile.drst === 'nr') {
                HandleCustomNav.map(v => {
                    if (v.id === 'activityHistory') {
                        v.content.splice(2, 1);
                    }
                    return v;
                });
                //HandleCustomNav[2].content.splice(2, 1);
            }
            dispatch(setMenus(HandleCustomNav));
        } else {
            dispatch(setMenus(SettingsNav));
        }
    }, [mainMenu, profile]);

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <ReactCSSTransitionGroup
                component="div"
                className={cx('app-sidebar', 'pnt-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
                transitionName="SidebarAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div>
                    <HeaderLogo />

                    <PerfectScrollbar>
                        <div
                            className="app-sidebar__inner"
                            style={{ padding: `1rem ${layout.layoutSpacerX} ${layout.layoutSpacerX}` }}
                        >
                            <Nav />
                        </div>
                    </PerfectScrollbar>
                </div>
                {profile && profile.drst === 'nr' && (
                    <div className={'pnt-sidebar-bottom'}>
                        <div className="metismenu vertical-nav-menu">
                            <ul className="metismenu-container">
                                <li className="metismenu-item">
                                    <div className={'menu-border-box'}>
                                        <a
                                            className="metismenu-link"
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                toggleMenu();
                                            }}
                                        >
                                            <i className="metismenu-icon pe-7s-settings" />
                                            <span
                                                style={{
                                                    maxWidth: '100%',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    display: 'inline-block',
                                                }}
                                            >
                                                {mainMenu ? t('Settings') : t('Home')}
                                            </span>
                                            {/*<i className="metismenu-state-icon pe-7s-angle-down caret-left rotate-minus-90"></i>*/}
                                        </a>
                                    </div>

                                    {/*<ul className="metismenu-container visible">*/}
                                    {/*    <li className="metismenu-item">*/}
                                    {/*        <a className="metismenu-link active" href="#/dashboards/list">*/}
                                    {/*            <i className="metismenu-icon"></i>*/}
                                    {/*            <span style="max-width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; display: inline-block;">*/}
                                    {/*                대시보드 목록*/}
                                    {/*            </span>*/}
                                    {/*        </a>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </ReactCSSTransitionGroup>
        </>
    );
};
export default AppSidebar;
