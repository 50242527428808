import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import Filter from '../../../Components/Filter/Filter';
import CustomCard from '../../../../Common/Card';
import * as column from '../../../util/grid/columns';
import useAsync from '../../../../../util/hooks/useAsync';
import PatientInfoCard from '../../../Components/PatientInfoCard';
import { useHistory, useParams } from 'react-router-dom';
import { getActiveSchedulePatientHistoryList } from '../../../../../api/activeScheduleHistory';

import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../Common/Table';
import WardTeam from '../../../Components/Filter/Components/WardTeam';
import Activity from '../../../Components/Filter/Components/Activity';
import Risk from '../../../Components/Filter/Components/Risk';
import { useFilterParam } from '../../../Components/Filter/hooks/useFilterParam';
import { setFilter, setSearch, setSearchFilter } from '../../../../../reducers/Common/SearchFilter';
import useTranslation from '../../../../../util/hooks/useTranslation';
import useColumns from '../../../../../util/hooks/useColumns';

const PatientInfoDetail = () => {
    const t = useTranslation('Common Columns');
    const param = useParams();
    const history = useHistory();
    const storeDispatch = useDispatch();
    const { targetNum } = param;
    const { profile } = useSelector(state => state.UserInfo);

    const [list, setList] = useState({ rows: [], pageSize: 10 });
    const columns = useColumns(
        [
            column.ward(),
            column.patientName(),
            column.department(),
            column.decubitusDanger(),
            column.activityExpected(),
            column.activityName(),
            column.performanceDate(),
        ],
        t,
    );
    const { promise: getPrevFilterList } = useAsync({
        promise: getActiveSchedulePatientHistoryList,
        resolve: res => {
            setList(res);
        },
    });

    const handlePage = () => {
        history.goBack();
    };

    const filterParam = useFilterParam();
    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...filterParam,
                    targetNum,
                    page: 1,
                    pageSize: 10,
                });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    targetNum,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT',
                    actTypeCodesName: '욕창치료관리',
                });
            }
        }
    }, [filterParam, profile]);
    const onPageChange = pageIndex => {
        if (profile && profile.metaData.drst === 'nr') {
            getPrevFilterList({ ...filterParam, targetNum, page: pageIndex, pageSize: 10 });
        }
        if (profile && profile.metaData.drst === 'ts') {
            getPrevFilterList({
                ...filterParam,
                targetNum,
                page: pageIndex,
                pageSize: 10,
                actTypeCodes: 'BS_REPORT',
                actTypeCodesName: '욕창치료관리',
            });
        }
    };
    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearch(null));
        storeDispatch(setSearchFilter(null));
    };
    const onTrClick = data => {
        const { psNum } = data;
        history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
    };

    return (
        <div className="grid-main-container">
            <div>
                <PatientInfoCard
                    headerTitle={t('Patient Information', 'Active Dashboard')}
                    headerTitleSub={t('Function to display selected patient information', 'Active Dashboard')}
                    targetNum={targetNum}
                    buttonOption={[{ label: t('Back', 'Active Dashboard'), onClick: handlePage }]}
                />

                <Filter reset={reset}>
                    <WardTeam />
                    <Activity />
                    <Risk />
                </Filter>
            </div>
            <CustomCard
                header={{
                    title: t('History by Activity - List', 'Active History'),
                    subTitle: t('Ability to view activities performed on a specific patient ', 'Active History'),
                }}
            >
                <Table
                    // key={JSON.stringify(list)}
                    columns={columns}
                    data={{
                        rows: list.rows,
                        pageSize: 10,
                        totalCount: list.totalCount,
                        totalPage: list.totalPage,
                    }}
                    onPageChange={onPageChange}
                    onTrClick={onTrClick}
                    textAlign={'center'}
                />
            </CustomCard>
        </div>
    );
};

export default PatientInfoDetail;
