import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import useAsync from '../../../util/hooks/useAsync';
import { fetchAssetCountStatus, fetchCategory } from '../../../api/asset';
import ReactSelect from '../../Common/Select';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import Button from '../../Common/Button';
import Label from '../../Common/Label';
import GridLayoutCard from '../Components/GridLayoutCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const AssetCount = ({ start = 0, end = 0, label }) => {
    return (
        <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <CountUp
                className={'font-weight-bold'}
                style={{ fontSize: '2.5rem' }}
                start={start}
                end={end}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                useEasing={false}
                suffix=""
                duration="1"
            />
            <h6
                className={'mt-2'}
                style={{ fontWeight: 500, fontSize: '0.88rem', textAlign: 'center', wordBreak: 'keep-all' }}
            >
                {label}
            </h6>
        </div>
    );
};

const AssetCountStatus = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();

    const { mode } = useSelector(state => state.DashboardFrame);
    const monitorTO = useRef();
    const { config } = widgetInfo;
    const settings = useSettings(config);

    // 카테고리 Select
    const match = useRouteMatch();
    const [categoryOption, setCategoryOption] = useState([{ value: '', label: t('AssetCountStatus;All Asset') }]);
    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('AssetCountStatus;All Asset') });
    useAsync({
        immediate: true,
        promise: fetchCategory,
        param: { parentCode: 'ASSET' },
        resolve: response => {
            const optionList = response.rows.map(category => {
                return { value: category.categoryCode, label: category.categoryName };
            });
            setCategoryOption([{ value: '', label: t('AssetCountStatus;All Asset') }, ...optionList]);
        },
        deps: [match.params.menuNum],
    });
    const handleSelectValueChange = value => {
        setSelectedOption(value);
        setShowSearchArea(!showSearchArea);
    };

    // 현재 자산 현황 데이터 호출
    const defaultData = {
        totalCount: 0,
        restrictedCount: 0,
        lostSignalCount: 0,
        lowBatteryCount: 0,
        neverDetectedCount: 0,
    };
    const [data, setData] = useState(defaultData);
    const { state: assetCountStatusInfo, promise: getAssetCountStatus } = useAsync({
        promise: fetchAssetCountStatus,
    });
    const monitor = () => {
        getAssetCountStatus(
            selectedOption && selectedOption.value !== ''
                ? { categoryCode: selectedOption.value }
                : { categoryCode: 'ASSET' },
        );
        monitorTO.current = setTimeout(monitor, settings.intervalTime);
    };
    const monitorStop = () => {
        clearTimeout(monitorTO.current);
    };
    useEffect(() => {
        monitorStop();
        if (mode === 'monitoring' || selectedOption) {
            monitor();
        }
        return () => {
            monitorStop();
        };
    }, [mode, config, selectedOption]);

    useEffect(() => {
        if (assetCountStatusInfo.response) {
            setData(assetCountStatusInfo.response);
        }
    }, [assetCountStatusInfo]);

    // 현재 자산 현황 데이터 호출 후 UI 데이터 설정
    const [countData, setCountData] = useState({ prev: data, curr: data });
    useEffect(() => {
        setCountData(prev => ({
            prev: prev.curr,
            curr: data,
        }));
    }, [data]);

    const widgetRef = useRef();
    const [showSearchArea, setShowSearchArea] = useState(false);
    const handleSearchAreaClick = e => {
        setShowSearchArea(!showSearchArea);
    };

    return (
        <>
            <GridLayoutCard
                ref={widgetRef}
                widgetInfo={widgetInfo}
                subTitle={selectedOption.label}
                headerAction={
                    <Button className={'btn-blue'} onClick={handleSearchAreaClick}>
                        {t('Button;Search')}
                    </Button>
                }
                searchFilter={
                    showSearchArea && (
                        <div className={'card filter_ver3 absolute-filter'}>
                            <div className={'filter__now'}>
                                <div className="filter__info">
                                    <Label name={t('AssetCountStatus;Search')} />
                                </div>
                                <div className="filter__options">
                                    <div className={'select-container'}>
                                        <ReactSelect
                                            name={'selectInput'}
                                            value={selectedOption}
                                            customControlStyles={{ width: '100%' }}
                                            customMenuStyles={{ width: '100%' }}
                                            customOptionStyles={{ width: '100%' }}
                                            onChange={handleSelectValueChange}
                                            options={categoryOption}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {...restProps}
            >
                <div className={'scrollbar-container'}>
                    <PerfectScrollbar>
                        <Row className={'flex-center'} style={{ height: '100%' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                                <CountWrapper>
                                    <AssetCount
                                        start={countData.prev.totalCount}
                                        end={countData.curr.totalCount}
                                        label={t('AssetCountStatus;Registered Asset')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <AssetCount
                                        start={countData.prev.restrictedCount}
                                        end={countData.curr.restrictedCount}
                                        label={t('AssetCountStatus;Out of Authorized Zone')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <AssetCount
                                        start={countData.prev.lostSignalCount}
                                        end={countData.curr.lostSignalCount}
                                        label={t('AssetCountStatus;Lost Signal')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <AssetCount
                                        start={countData.prev.lowBatteryCount}
                                        end={countData.curr.lowBatteryCount}
                                        label={t('AssetCountStatus;Low Battery')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <AssetCount
                                        start={countData.prev.neverDetectedCount}
                                        end={countData.curr.neverDetectedCount}
                                        label={t('AssetCountStatus;No Response')}
                                    />
                                </CountWrapper>
                            </div>
                        </Row>
                    </PerfectScrollbar>
                </div>

                {children}
            </GridLayoutCard>
        </>
    );
};

export default AssetCountStatus;
