import ThemeOptions from './ThemeOptions';
import DashboardFrame from './Dashboards/DashboardFrame';
import Dashboards from './Dashboards/Dashboards';
import DashboardEdit from './Dashboards/DashboardEdit';
import CustomMenu from './CustomMenu';
import UserInfo from './UserInfo';
import TagInfo from './TagInfo';
import FloorInfo from './Common/FloorInfo';
import PerformanceRecordOption from './Common/PerformanceRecordOption';
import PatientInfo from './Common/PatientInfo';
import SearchFilter from './Common/SearchFilter';
import CategoryInfo from './Common/CategoryInfo';
import AppConfig from './Common/AppConfig';
import SocketInfo from './SocketInfo';
import CommonInfo from './Common/CommonInfo';
import ErrorHandler from './ErrorHandler';

export default {
    ThemeOptions,
    DashboardFrame,
    Dashboards,
    DashboardEdit,
    CustomMenu,
    UserInfo,
    TagInfo,
    FloorInfo,
    PatientInfo,
    SearchFilter,
    PerformanceRecordOption,
    CategoryInfo,
    AppConfig,
    SocketInfo,
    CommonInfo,
    ErrorHandler,
};
