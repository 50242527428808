import React from 'react';
import Table from '../../Common/Table';

const RecordTable = ({
    columns,
    noDataPlaceholder,
    textAlign,
    data,
    onClick,
    loading,
    onPageChange,
    paging,
    renderRowSubComponent,
    rowSelect,
}) => {
    return (
        <Table
            columns={columns}
            paging={paging}
            data={data}
            loading={loading}
            onPageChange={onPageChange}
            onTrClick={onClick}
            textAlign={textAlign}
            renderRowSubComponent={renderRowSubComponent}
            rowSelect={rowSelect}
            noDataPlaceholder={noDataPlaceholder}
        />
    );
};

export default RecordTable;
