import React, { createContext, useEffect, useReducer, useState } from 'react';
import PatientInfoCard from '../../../Components/PatientInfoCard';
import { useHistory, useParams } from 'react-router-dom';
import SkinConditionInfo from './SkinConditionInfo';
import SkinConditionRecordList from './SkinConditionRecordList';
import skinConditionRecordManagementReducer, {
    initialState,
    setRequest,
} from '../skinConditionRecordManagementReducer';
import { useDispatch, useSelector } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSkinInfoApi } from '../../../../../api/activeScheduleHistory';
import { setPatientDetailInfo } from '../../../../../reducers/Common/PatientInfo';
import CustomCard from '../../../../Common/Card';
import { dateChange } from '../../../util/dateChange';
import useTranslation from '../../../../../util/hooks/useTranslation';

export const SkinConditionRecordDispatchContext = createContext();
export const SkinConditionRecordStateContext = createContext();
const SkinConditionRecord = () => {
    const t = useTranslation('Active Dashboard');
    const param = useParams();
    const { reportNum } = param;
    const history = useHistory();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(skinConditionRecordManagementReducer, initialState);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { request } = state;

    const { promise: getSkinInfo } = useAsync({
        promise: getSkinInfoApi,
        resolve: res => {
            dispatch(setRequest(res));
            storeDispatch(setPatientDetailInfo(res));
        },
    });

    useEffect(() => {
        getSkinInfo({ reportNum });
    }, []);

    const handlePage = () => {
        history.goBack();
    };

    const [tableHeight, setTableHeight] = useState(null);
    return (
        <>
            <SkinConditionRecordDispatchContext.Provider value={dispatch}>
                <SkinConditionRecordStateContext.Provider value={state}>
                    <div>
                        <ResizeDetector
                            handleWidth
                            handleHeight
                            onResize={(width, height) => {
                                setTableHeight(`calc(100% - ${height}px )`);
                            }}
                        >
                            <PatientInfoCard
                                headerTitle={t('Patient Information')}
                                headerTitleSub={t('Function to display selected patient information')}
                                targetNum={patientDetailInfo && patientDetailInfo.targetNum}
                                buttonOption={[{ label: t('Back'), onClick: handlePage }]}
                            />
                            <CustomCard>
                                <div className="p-0 pnt-label-7 row">
                                    <div className="col-xl-6 col-lg-12 mb-2">
                                        <div className="pnt-label--group">
                                            <span className="label-main label-dot ">의뢰인</span>
                                            <span className="label-options">
                                                <span>{request.regName}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mb-2">
                                        <div className="pnt-label--group">
                                            <span className="label-main label-dot ">의뢰시 욕창단계</span>
                                            <span className="label-options">
                                                <span>{request.bsReportGradeCodeName}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mb-2">
                                        <div className="pnt-label--group">
                                            <span className="label-main label-dot ">진행상황</span>
                                            <span className="label-options">
                                                <span>{request.bsStatusCodeName}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mb-2">
                                        <div className="pnt-label--group">
                                            <span className="label-main label-dot ">의뢰일시</span>
                                            <span className="label-options">
                                                <span>{dateChange(request.happDate)}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </CustomCard>
                        </ResizeDetector>
                    </div>
                    <div style={{ height: tableHeight }} className="p-0 row">
                        <div className="col-xl-6 col-lg-12 mb-2">
                            <SkinConditionRecordList />
                        </div>
                        <div className="col-xl-6 col-lg-12 mb-2">
                            <SkinConditionInfo />
                        </div>
                    </div>
                </SkinConditionRecordStateContext.Provider>
            </SkinConditionRecordDispatchContext.Provider>
        </>
    );
};

export default SkinConditionRecord;
