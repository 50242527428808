import React, { useContext, useEffect, useState } from 'react';
import {
    DescriptionRow,
    HandleCustomInput,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import { setBsEducationInfo, setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import {
    updateActivityEducationScheduleInfo,
    setActivityEducationScheduleInfo,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { createEducationRecord, getEducationRecord, updateEducationRecord } from '../../../../../../api/activeSchedule';
import CustomSearchModal from '../../../util/CustomSearchModal';
import { TextChange, validationCheck, validationTest } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { ScheduleByActivityDispatchContext, ScheduleByActivityStateContext } from '../../CarryingOutActivities';
import { useHistory } from 'react-router-dom';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import ResizeDetector from 'react-resize-detector';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const Education = () => {
    const t = useTranslation('Evaluation');
    const storeDispatch = useDispatch();
    const history = useHistory();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { bsEducationInfo, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { actItemCodeName, actTypeCodeName, targetNum, psNum, exeDate } = patientDetailInfo;
    const { activityEducationScheduleInfo } = state;
    const [tableHeight, setTableHeight] = useState(null);
    const [pageState, setPageState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    useEffect(() => {
        if (exeDate) {
            getEducationRecordInfo({ psNum });
            setPageState(true);
            setNurseRecordState(true);
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, [exeDate]);
    //옵션값 호출
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BS_EDU', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setBsEducationInfo(res));
        },
    });
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    //수행안함 API
    const { promise: noActivityEducationRecord } = useAsync({
        promise: createEducationRecord,
        resolve: res => {
            history.push('/activity-schedule');
        },
    });
    const onHandleNotDone = () => {
        noActivityEducationRecord({
            bsEduCode1: '',
            bsEduCode2: '',
            bsEduCode3: '',
            exeStatusCode: 'N',
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            remark: '',
            wardCode: '',
            wardCodeName: '',
            targetNum,
            psNum,
        });
    };

    //교육 등록 API
    const { promise: addEducationRecord } = useAsync({
        promise: createEducationRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getEducationRecordInfo({ psNum });
            }
        },
    });

    //교육정보 수정 API
    const { promise: editEducationRecord } = useAsync({
        promise: updateEducationRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getEducationRecordInfo({ psNum });
            }
        },
    });
    // const validation = () => {
    //     let item = [];
    //     if (!nurseRecordState) {
    //         item = validationCheck(validationItem);
    //         !nurseInfo.hasOwnProperty('exeUserName') && item.push('간호사');
    //         !nurseInfo.hasOwnProperty('exeDate') && item.push('수행일시');
    //     }
    //     return item;
    // };
    const onAddEducationRecord = () => {
        const validationResult = validationTest(validationItem, nurseRecordState, nurseInfo);
        if (!validationResult.length) {
            setPageState(!pageState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editEducationRecord({
                      ...activityEducationScheduleInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addEducationRecord({
                      ...activityEducationScheduleInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      exeStatusCode: 'C',
                      ip: profile.clientIp,
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    //수정 로직 추가 예정
    const onEditEducationRecord = () => {
        setPageState(!pageState);
    };

    //교육 정보 조회 API
    const { promise: getEducationRecordInfo } = useAsync({
        promise: getEducationRecord,
        resolve: res => {
            const { wardCode, wardCodeName } = res;
            setZoneState({ label: wardCodeName, value: wardCode });
            dispatch(setActivityEducationScheduleInfo(res));
            storeDispatch(setNurseInfo(res));
        },
    });

    //체크 박스 체크 시 상태변경 로직
    const [validationResultState, setValidationResultState] = useState([]);
    const [validationItem, setValidationItem] = useState({ BS_EDU: '교육항목' });
    const [checkState, setCheckState] = useState({});
    const onHandleCheckBox = e => {
        const { value, name, checked } = e.currentTarget;

        setValidationItem({ ...validationItem, [name]: null });
        let temp = [];
        if (checkState[name]) {
            temp = checkState[name];
        }
        if (checked) {
            temp.push(value);
            setCheckState({
                ...checkState,
                [name]: temp,
            });
            dispatch(updateActivityEducationScheduleInfo({ [name]: value }));
        } else {
            setCheckState({
                ...checkState,
                [name]: temp.filter(v => v !== value),
            });
            dispatch(updateActivityEducationScheduleInfo({ [name]: null }));
        }
    };
    useEffect(() => {
        for (let name in checkState) {
            if (checkState[name].length) {
                setValidationItem({ [name]: null });
            } else if (checkState[name].length === 0) {
                setValidationItem({ [name]: '교육항목' });
            }
        }
    }, [checkState]);

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };

    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityEducationScheduleInfo({ wardCode: value }));
        setZoneState({ label, value });
    };
    const handleInputChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityEducationScheduleInfo({ [name]: value }));
    };

    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onAddEducationRecord,

                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onEditEducationRecord,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };

    return (
        <div>
            <ResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => {
                    setTableHeight(height);
                }}
            >
                <ActiveRecordCard
                    style={{ height: tableHeight }}
                    headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                        actItemCodeName,
                        'ActTypeCode',
                    )}`}
                    buttonOption={buttonOptionChange(pageState)}
                    spanIcon={['icon-info']}
                    url={`${url.wmsUrl}/v2/html/aum/guide/2_1.html`}
                >
                    <div className="p-0 pnt-label-7 row">
                        <DescriptionRow
                            label={t('Nurse')}
                            component={
                                <HandleNurseTextInput
                                    name={t('Nurse')}
                                    readOnly={nurseRecordState}
                                    onClick={toggleModal}
                                    placeholder={`${t('Nurse')}||${t('Number')}`}
                                    value={
                                        nurseInfo.hasOwnProperty('exeUserName') &&
                                        nurseInfo.exeUserName &&
                                        `${nurseInfo.exeUserName} ||${nurseInfo.exeUserNum}`
                                    }
                                    className={'d-flex '}
                                    text={
                                        activityEducationScheduleInfo &&
                                        activityEducationScheduleInfo.hasOwnProperty('exeUserName') &&
                                        activityEducationScheduleInfo.exeUserName &&
                                        `${activityEducationScheduleInfo.exeUserName} | ${activityEducationScheduleInfo.exeUserNum}`
                                    }
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('When performed')}
                            component={
                                <HandleDateInput
                                    readOnly={nurseRecordState}
                                    value={startDate}
                                    handleDateValueChange={handleStartDateValueChange}
                                    valueType={'ms'}
                                    text={activityEducationScheduleInfo && activityEducationScheduleInfo.exeDate}
                                    minDate={moment().valueOf()}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Zones')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    options={zoneOptionRows}
                                    readOnly={pageState}
                                    isMulti={false}
                                    onChange={handleZoneSelect}
                                    value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                    text={
                                        (activityEducationScheduleInfo &&
                                            t(activityEducationScheduleInfo.wardCodeName, 'ActTypeCode')) ||
                                        '-'
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Education Topics')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                pageState
                                    ? activityEducationScheduleInfo &&
                                      activityEducationScheduleInfo.bsEduCodes &&
                                      activityEducationScheduleInfo.bsEduCodes.length > 0 &&
                                      activityEducationScheduleInfo.bsEduCodes.map(
                                          (activityEducationScheduleInfo, i) => (
                                              <HandleCustomInput
                                                  key={i}
                                                  readOnly={pageState}
                                                  id={activityEducationScheduleInfo}
                                                  type="checkbox"
                                                  value={activityEducationScheduleInfo}
                                                  label={t(activityEducationScheduleInfo, 'ActTypeCode')}
                                                  checkedState={true}
                                              />
                                          ),
                                      )
                                    : bsEducationInfo.BS_EDU &&
                                      bsEducationInfo.BS_EDU.length > 0 &&
                                      bsEducationInfo.BS_EDU.map((educationInfo, i) => (
                                          <HandleCustomInput
                                              key={i}
                                              readOnly={pageState}
                                              type="checkbox"
                                              id={educationInfo.id}
                                              value={educationInfo.value}
                                              label={t(educationInfo.label, 'ActTypeCode')}
                                              name={educationInfo.name}
                                              onChange={onHandleCheckBox}
                                              checkState={
                                                  (nurseRecordState && activityEducationScheduleInfo) || checkState
                                              }
                                          />
                                      ))
                            }
                        />

                        <DescriptionRow
                            label={t('Remarks')}
                            className="col-xl-12 col-lg-12 mb-3"
                            spanClassName={'w-50'}
                            component={
                                <HandleTextInput
                                    size={200}
                                    name={'remark'}
                                    placeholder={t('Enter any remarks.', 'Evaluation')}
                                    readOnly={pageState}
                                    onChange={handleInputChange}
                                    value={activityEducationScheduleInfo && activityEducationScheduleInfo.remark}
                                />
                            }
                        />
                    </div>
                    <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                    <ConfirmModal
                        initModal={confirmModalState}
                        headerText={t('Required Items')}
                        modalToggle={toggleConfirmModal}
                        confirmText={TextChange(validationResultState)}
                    />
                </ActiveRecordCard>
            </ResizeDetector>
        </div>
    );
};

export default Education;
