import { reqGet, reqPost, reqDelete, reqPut } from './index';
import { queryStr } from '../util/common/util';
//활동별 일정 목록
export const getActiveScheduleList = param => reqGet(`/v3/api/ps01/act`, param);

// 활동별 일정 수정
export const updateActivityAssessment = param => reqPut(`/v3/api/ps01/act/${param.psNum}`, param);

// 활동별 일정 삭제
export const deleteActivityAssessment = param => reqDelete(`/v3/api/ps01/act/${param.psNum}`, param);

// 활동별 일정 목록 조회
export const getActivityAssessmentList = param => reqGet(`/v3/api/ps01/act/patient/${param.targetNum}`);
//활동별 조회
export const getActivityAssessment = param => reqGet(`/v3/api/ps01/act/${param.psNum}`);

//욕창 교육
export const createEducationRecord = param => reqPost(`/v3/api/ps01/execution/bs/edu/${param.psNum}`, param);
export const getEducationRecord = param => reqGet(`/v3/api/ps01/execution/bs/edu/${param.psNum}`);
export const updateEducationRecord = param => reqPut(`/v3/api/ps01/execution/bs/edu/${param.psNum}`, param);
// 욕창 누운자세변경
export const createLyingPosition = param => reqPost(`/v3/api/ps01/execution/bs/iv2/${param.psNum}`, param);
export const updateLyingPosition = param => reqPut(`/v3/api/ps01/execution/bs/iv2/${param.psNum}`, param);
export const getLyingPosition = param => reqGet(`/v3/api/ps01/execution/bs/iv2/${param.psNum}`);
// 활동병 일정 추가
export const createActivityAssessment = param => reqPost(`/v3/api/ps01/act`, param);
// 활동별 일정 조회
export const getActivityAssessmentInfo = param => reqGet(`/v3/api/ps01/act/${param.psNum}`, param);

//환자별 일정조회
export const getPatientAssessment = param => reqGet(`/v3/api/ps01/act/patient`, param);
export const getPatientSubRowsAssessment = param => reqGet(`/v3/api/ps01/act/patient/list/${param.targetNum}`, param);

// 위험도 평가 API
export const createRiskAssessment = param => reqPost(`/v3/api/ps01/execution/bs/rating/${param.psNum}`, param);
export const getRiskAssessment = param => reqGet(`/v3/api/ps01/execution/bs/rating/${param.psNum}`);
export const updateRiskAssessment = param => reqPut(`/v3/api/ps01/execution/bs/rating/${param.psNum}`, param);

//전신 피부 사정(상단수행)
export const createMainWholeSkinAssessmentApi = param => reqPost(`/v3/api/ps01/execution/bs/iv1/${param.psNum}`, param);
export const getMainWholeSkinAssessmentApi = param => reqGet(`/v3/api/ps01/execution/bs/iv1/${param.psNum}`, param);
export const updateMainWholeSkinAssessmentApi = param => reqPut(`/v3/api/ps01/execution/bs/iv1/${param.psNum}`, param);

//전신 피부 사정
export const createWholeSkinAssessmentApi = param => reqPost(`/v3/api/ps01/execution/bs/iv1/sub/${param.psNum}`, param);
export const getWholeSkinAssessmentApi = param => reqGet(`/v3/api/ps01/execution/bs/iv1/sub/${param.skinNum}`, param);
export const updateWholeSkinAssessmentApi = param =>
    reqPut(`/v3/api/ps01/execution/bs/iv1/sub/${param.skinNum}`, param);
export const getWholeSkinStateCheckApi = param => {
    const formData = new FormData();
    formData.append('imageName', param.files);
    return reqPost(`/v3/api/ps01/execution/bs/iv1/img`, formData);
};
export const getWholeSkinSubAssessmentApi = param =>
    reqGet(`/v3/api/ps01/execution/bs/iv1/sub/list/${param.psNum}`, param);

//치료기록(상단수행)
export const createCureRecord = param => reqPost(`/v3/api/ps01/execution/bs/record/${param.psNum}`, param);
export const getCureRecord = param => reqGet(`/v3/api/ps01/execution/bs/iv/${param.psNum}`);
export const updateRecord = param => reqPut(`/v3/api/ps01/execution/bs/iv/${param.psNum}`, param);
//치료기록
export const createCureAssessment = param => reqPost(`/v3/api/ps01/execution/bs/report`, param);
export const getCureAssessment = param => reqGet(`/v3/api/ps01/execution/bs/report`, param);
export const updateAssessment = param => reqPut(`/v3/api/ps01/execution/bs/report`, param);
export const getCureAssessmentListApi = param =>
    reqGet(`/v3/api/ps01/execution/bs/record/sub/list/${param.psNum}`, param);
export const getAntecedentRequestOptionApi = param =>
    reqGet(`/v3/api/ps01/execution/bs/record/report/${param.targetNum}`);
export const getCureAssessmentApi = param => reqGet(`/v3/api/ps01/execution/bs/record/sub/${param.recordNum}`, param);
//욕창 치료기록 수행 및 수정
export const updateCureRecord = param => reqPut(`/v3/api/ps01/execution/bs/record/sub/${param.recordNum}`, param);
export const updateCureRecordApi = param =>
    reqPut(`/v3/api/ps01/execution/bs/record/sub/exe/${param.recordNum}`, param);
////////////낙상 //////////
// 낙상 교육
export const createFallDownEducation = param => reqPost(`/v3/api/ps01/execution/bf/eduiv1/${param.psNum}`, param);
export const getFallDownEducation = param => reqGet(`/v3/api/ps01/execution/bf/eduiv1/${param.psNum}`, param);
export const updateFallDownEducation = param => reqPut(`/v3/api/ps01/execution/bf/eduiv1/${param.psNum}`, param);
//낙상 직접 중재
export const createFallDownPreventionActivities = param =>
    reqPost(`/v3/api/ps01/execution/bf/eduiv2/${param.psNum}`, param);
export const getFallDownPreventionActivities = param =>
    reqGet(`/v3/api/ps01/execution/bf/eduiv2/${param.psNum}`, param);
export const updateFallDownPreventionActivities = param =>
    reqPut(`/v3/api/ps01/execution/bf/eduiv2/${param.psNum}`, param);
//낙상 배뇨라운딩
export const createFallDownUrinationRound = param => reqPost(`/v3/api/ps01/execution/bf/eduiv3/${param.psNum}`, param);
export const getFallDownUrinationRoundApi = param => reqGet(`/v3/api/ps01/execution/bf/eduiv3/${param.psNum}`, param);
export const updateFallDownUrinationRound = param => reqPut(`/v3/api/ps01/execution/bf/eduiv3/${param.psNum}`, param);
//낙상 수행기록 7세 미만
export const createChildFallDownRecord = param =>
    reqPost(`/v3/api/ps01/execution/bf/rating1/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);
export const getChildFallDownRecord = param => reqGet(`/v3/api/ps01/execution/bf/rating1/${param.psNum}`);
export const updateChildFallDownRecord = param =>
    reqPut(`/v3/api/ps01/execution/bf/rating1/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);
//낙상 수행기록 7세 이상 ,성인
export const createPersonFallDownRecord = param =>
    reqPost(`/v3/api/ps01/execution/bf/rating2/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);

export const getPersonFallDownRecord = param => reqGet(`/v3/api/ps01/execution/bf/rating2/${param.psNum}`);
export const updatePersonFallDownRecord = param =>
    reqPut(`/v3/api/ps01/execution/bf/rating2/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);

//낙상 수행기록 응급
export const createEmergencyFallDownRecord = param =>
    reqPost(`/v3/api/ps01/execution/bf/rating3/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);

export const getEmergencyFallDownRecord = param => reqGet(`/v3/api/ps01/execution/bf/rating3/${param.psNum}`);
export const updateEmergencyFallDownRecord = param =>
    reqPut(`/v3/api/ps01/execution/bf/rating3/${param.psNum}?${queryStr(param.bfHighRiskCodes)}`, param);

//낙상예방현황
export const getFallStatusPatientInfoList = param =>
    reqGet(
        `
/v3/api/ps01-aum/fall/dashboard/list`,
        param,
    );

//환자 정보 목록 조회
export const getPatientInfoList = param =>
    reqGet(
        `
/v3/api/ps01/patient/info`,
        param,
    );

export const getPatientInfo = param =>
    reqGet(
        `
/v3/api/ps01/patient/info/${param.targetKey}`,
        param,
    );

// 의사/ 간호사 목록 조회
export const getHospitalStaffInfoList = param => reqGet(`/v3/api/ps01-app/user/reporter`, param);

// export const getHospitalStaffInfoListApi = param =>
//     reqGet(
//         `/v3/api/ps01-app/user/reporter
// `,
//         param,
//     );

//선행의뢰 목록
export const getAntecedentRequestListApi = param =>
    reqGet(
        `
/v3/api/ps01/execution/bs/iv1/report/${param.targetNum}`,
    );
// 관심 지정
export const updatePatientStatusAppointedApi = param => reqPut(`/v3/api/ps01/patient/info/${param.targetNum}`, param);
// 관심 해제
export const updatePatientStatusReleaseApi = param =>
    reqPut(`/v3/api/ps01/patient/info/release/${param.targetNum}`, param);
