import React from 'react';
import widgetList from '../../../Widgets';

export const getUsableWidgetInfo = type => widgetList[type] || widgetList.temp;

export const widgetDiscriminator = widgetInfo => {
    const { id, type } = widgetInfo;
    const props = { key: id, widgetInfo: widgetInfo };
    const { Component } = getUsableWidgetInfo(type);
    return Component ? <Component {...props} /> : null;
    // switch (config.type) {
    //     case 'tagStatus':
    //         return <TagStatus {...props} />;
    //     case 'temp':
    //     default:
    //         return <TemporaryWidget {...props} />;
    // }
};

export const makeLayoutsData = layout => {
    return {
        lg: layout,
        md: layout,
        sm: layout,
        xs: layout,
        xxs: layout,
    };
};

export const getMaxY = layouts => {
    let max = 0;
    layouts.forEach(v => {
        max = Math.max(max, v.y + v.x);
    });
    return max;
};
