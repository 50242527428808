import React from 'react';
import CustomCard from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import Table from '../../../../Common/Table';
import { resetPatientDetailInfo } from '../../../../../reducers/Common/PatientInfo';
import { useDispatch } from 'react-redux';
import * as column from '../../../util/grid/columns';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../../../../util/hooks/useTranslation';
import useColumns from '../../../../../util/hooks/useColumns';

const ActivityScheduleList = ({ handlePageChange, activeScheduleListState, list }) => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const columns = useColumns(
        [
            column.ward(),
            column.patientName({ history }),
            column.department(),
            column.decubitusDanger(),
            column.activityExpected(),
            column.activityName(),
            column.classificationOfPerformance(),
            column.timeRemaining(),
            column.expectedDate(),
            column.activity({
                history,
                width: 250,
                className: 'flex-center',
                Cell: ({ row }) => {
                    const { targetNum, psNum } = row.original;
                    return (
                        <div className={'table-btn-container'}>
                            <button
                                className="pnt-txt txt-border  txt-btn-info mr-2"
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/activity-schedule/schedule-by-activity-detail/${targetNum}`);
                                }}
                            >
                                {t('Patient')}
                            </button>
                            <button
                                className="pnt-txt txt-border txt-btn-secondary mr-2"
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/activity-schedule/add-activity/${targetNum}/${psNum}`);
                                }}
                            >
                                {t('Activities')}
                            </button>
                            <button
                                type={'button'}
                                className="pnt-txt txt-border  txt-btn-org"
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
                                }}
                            >
                                {t('Perform')}
                            </button>
                        </div>
                    );
                },
            }),
        ],
        t,
    );

    const onHandlePage = () => {
        storeDispatch(resetPatientDetailInfo(null));
        history.push(`/activity-schedule/add-activity`);
    };
    const onHandleTable = () => {
        history.push('/activity-schedule/schedule-by-patient');
    };
    const onTrClick = e => {
        const { targetNum } = e;
        history.push(`/activity-schedule/schedule-by-activity-detail/${targetNum}`);
    };

    return (
        <CustomCard
            header={{
                title: t('Schedule list by activity', 'Activity ScheduleList'),
                subTitle: t(
                    'List of pressure ulcer/fall activities scheduled to be performed',
                    'Activity ScheduleList',
                ),
                action: (
                    <>
                        <Button className="pnt-btn btn-orange" onClick={onHandleTable}>
                            {t('View by patient', 'Activity ScheduleList')}
                        </Button>
                        <Button
                            className="pnt-btn btn-blue btn-icon"
                            iconClassName={'icon-plus'}
                            onClick={onHandlePage}
                        >
                            {t('Add an activity', 'Activity ScheduleList')}
                        </Button>
                    </>
                ),
            }}
        >
            <Table
                key={JSON.stringify(list)}
                columns={columns}
                data={{
                    page: list.page,
                    rows: list.rows,
                    totalCount: list.totalCount,
                    totalPage: list.totalPage,
                    pageSize: 10,
                }}
                loading={activeScheduleListState.isLoading}
                onPageChange={handlePageChange}
                onTrClick={onTrClick}
                textAlign={'center'}
                noDataPlaceholder={t('Data does not exist.')}
            />
        </CustomCard>
    );
};

export default ActivityScheduleList;
