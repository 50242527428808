import React, { useEffect, useState } from 'react';
import RecordCard from '../../Components/RecordCard';
import Button from '../../../Common/Button';
import CustomCard from '../../../Common/Card';
import { useHistory, useParams } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import * as column from '../../util/grid/columns';
import {
    getActivityAssessmentList,
    getPatientInfo,
    updatePatientStatusAppointedApi,
    updatePatientStatusReleaseApi,
} from '../../../../api/activeSchedule';
import { setPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getRiskStatus, RiskStatus } from '../../util/riskNamePlate';
import ConfirmModal from '../../Components/ConfirmModal';
import { onHandleTextChange } from '../../util/TextChange';
import Table from '../../../Common/Table';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';

const ScheduleByActivityDetail = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const param = useParams();
    const storeDispatch = useDispatch();
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);

    const [filterList, setFilterList] = useState({ rows: [] });
    const columns = useColumns(
        [
            column.ward(),
            column.patientName(),
            column.department(),
            column.activityExpected(),
            column.decubitusDanger(),
            column.activityName(),
            column.classificationOfPerformance(),
            column.timeRemaining(),
            column.expectedDate(),
            column.activity({
                history,
                Cell: ({ row }) => {
                    const targetNum = row.original.targetNum;
                    const psNum = row.original.psNum;
                    return (
                        <div className={'table-btn-container'}>
                            <button
                                className="pnt-txt txt-border  txt-btn-secondary mr-2"
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/activity-schedule/add-activity/${targetNum}/${psNum}`);
                                }}
                            >
                                {t('Activities')}
                            </button>
                            <button
                                type={'button'}
                                className="pnt-txt txt-border  txt-btn-org"
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
                                }}
                            >
                                {t('Perform')}
                            </button>
                        </div>
                    );
                },
            }),
        ],
        t,
    );

    const { promise: getPrevFilterList } = useAsync({
        promise: getActivityAssessmentList,
        resolve: res => {
            setFilterList(res);
        },
    });

    //환자 정보 조회 API
    const { promise: getPatientDetailInfo } = useAsync({
        promise: getPatientInfo,
        resolve: res => {
            storeDispatch(setPatientDetailInfo(res));
        },
    });
    useEffect(() => {
        getPrevFilterList({ page: 1, targetNum: param.targetNum });
        getPatientDetailInfo({ keyType: 'targetNum', targetKey: param.targetNum });
    }, []);
    const onHandlePage = () => {
        history.push(`/activity-schedule/add-activity`);
    };
    const onClick = e => {
        const { psNum, targetNum } = e;
        history.push(`/activity-schedule/add-activity/${targetNum}/${psNum}`);
    };

    const onHandleBackPage = () => {
        history.goBack();
    };
    const [errorText, setErrorText] = useState(
        t('Not possible because no fall risk assessment has been performed.', 'Activity ScheduleList'),
    );
    const { promise: updatePatientStatusRelease } = useAsync({
        promise: updatePatientStatusReleaseApi,
        resolve: res => {
            const { pkValue: targetNum } = res;
            getPatientDetailInfo({
                keyType: 'targetNum',
                targetKey: targetNum,
            });
        },
        reject: error => {
            setErrorText(
                t(
                    'It is not possible to specify a state of risk assessment that has not been conducted, or a state of high risk or interest.',
                    'Activity ScheduleList',
                ),
            );
            toggleModal();
        },
    });
    const { promise: updatePatientStatusAppointed } = useAsync({
        promise: updatePatientStatusAppointedApi,
        resolve: res => {
            const { pkValue: targetNum } = res;
            getPatientDetailInfo({
                keyType: 'targetNum',
                targetKey: targetNum,
            });
        },
        reject: err => {
            setErrorText(
                t(
                    'It is not possible to specify a state of risk assessment that has not been conducted, or a state of high risk or interest.',
                    'Activity ScheduleList',
                ),
            );
            toggleModal();
        },
    });

    const onHandleFallDownRelease = () => {
        updatePatientStatusRelease({
            categoryCode: 'PATIENT',
            releaseType: 'BF',
            targetNum: patientDetailInfo.targetNum,
        });
    };
    const onHandleDecubitusRelease = () => {
        updatePatientStatusRelease({
            categoryCode: 'PATIENT',
            releaseType: 'BS',
            targetNum: patientDetailInfo.targetNum,
        });
    };
    const onHandleFallDown = () => {
        const { targetNum, targetId, targetName, bfRisk } = patientDetailInfo;
        if (bfRisk) {
            updatePatientStatusAppointed({
                targetNum,
                targetId,
                targetName,
                categoryCode: 'PATIENT',
                properties: {
                    bfRisk: 'BF04',
                },
            });
        } else {
            setErrorText(
                t('Not possible because no fall risk assessment has been performed.', 'Activity ScheduleList'),
            );
            toggleModal();
        }
    };
    const onHandleDecubitus = () => {
        const { targetNum, targetId, targetName, bsRisk } = patientDetailInfo;
        if (bsRisk) {
            updatePatientStatusAppointed({
                targetNum,
                targetId,
                targetName,
                categoryCode: 'PATIENT',
                properties: {
                    bsRisk: 'BS03',
                },
            });
        } else {
            setErrorText(t('No pressure ulcer risk assessment has been performed.', 'Activity ScheduleList'));
            toggleModal();
        }
    };
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const getSexInKorean = value => {
        let word;
        switch (value) {
            case 'male':
                word = t('Male', 'Active Dashboard');
                break;
            case 'female':
                word = t('Female', 'Active Dashboard');
                break;
        }
        return word;
    };

    return (
        <div className="grid-main-container">
            {patientDetailInfo && (
                <RecordCard
                    headerTitle={t('Patient Information', 'Active Dashboard')}
                    subTitle={t('Function to display selected patient information', 'Active Dashboard')}
                    buttonOption={[
                        { label: t('Back', 'Active Dashboard'), onClick: onHandleBackPage, className: 'btn-gray' },

                        {
                            label:
                                patientDetailInfo && patientDetailInfo.bfRisk === 'BF04'
                                    ? t('Disable Fall Attention', 'Activity ScheduleList')
                                    : t('Assigning Fall Attention', 'Activity ScheduleList'),
                            onClick:
                                patientDetailInfo && patientDetailInfo.bfRisk === 'BF04'
                                    ? onHandleFallDownRelease
                                    : onHandleFallDown,
                            className: 'btn-gray',
                        },
                        {
                            label:
                                patientDetailInfo && patientDetailInfo.bsRisk === 'BS03'
                                    ? t('Pressure Ulcers Unattended', 'Activity ScheduleList')
                                    : t('Assign Pressure Ulcer Alerts', 'Activity ScheduleList'),
                            onClick:
                                patientDetailInfo && patientDetailInfo.bsRisk === 'BS03'
                                    ? onHandleDecubitusRelease
                                    : onHandleDecubitus,
                            className: 'btn-blue',
                        },
                    ]}
                >
                    <div className="p-0  pnt-label-7 row">
                        <div className="col-xl-6 col-lg-12 mb-2">
                            <div className={'pnt-label--group'}>
                                <span className="label-main label-dot">
                                    {t('Patient Information', 'Active Dashboard')}
                                </span>
                                <span className="label-options">
                                    {patientDetailInfo &&
                                        `${patientDetailInfo.targetName}/${patientDetailInfo.patid} [${getSexInKorean(
                                            patientDetailInfo.sex,
                                        )}] [${patientDetailInfo.age}] [${patientDetailInfo.status} - KTAS${
                                            (patientDetailInfo.ktas && patientDetailInfo.ktas) || ''
                                        }]
                                 `}
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 mb-2">
                            <div className={'pnt-label--group'}>
                                <span className="label-main label-dot">
                                    {t('Department/Primary Care Physician', 'Active Dashboard')}
                                </span>
                                <span className="label-options">
                                    {patientDetailInfo &&
                                        onHandleTextChange([patientDetailInfo.medDept, patientDetailInfo.chadrname])}
                                </span>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-12 mb-2">
                            <div className={'pnt-label--group'}>
                                <span className="label-main label-dot">{t('Ward/Room/Bed', 'Active Dashboard')}</span>
                                <span className="label-options">
                                    {patientDetailInfo &&
                                        onHandleTextChange([
                                            patientDetailInfo.wardNo,
                                            patientDetailInfo.roomNo,
                                            patientDetailInfo.bedNo,
                                        ])}
                                </span>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-12 mb-2">
                            <div className={'pnt-label--group'}>
                                <span className="label-main label-dot">
                                    {t('Risk categorization', 'Active Dashboard')}
                                </span>
                                <span className="label-options">
                                    {patientDetailInfo && patientDetailInfo.bsRisk && (
                                        <RiskStatus value={patientDetailInfo.bsRisk} />
                                    )}
                                    {patientDetailInfo && patientDetailInfo.bfRisk && (
                                        <RiskStatus value={patientDetailInfo.bsRisk} />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </RecordCard>
            )}
            <CustomCard
                header={{
                    title: t('Patient-specific appointment list', 'Activity ScheduleList'),
                    subTitle: t(
                        'List of pressure ulcer/fall activities scheduled to be performed',
                        'Activity ScheduleList',
                    ),
                    action: (
                        <>
                            <Button className="btn-blue  btn-icon" iconClassName={'icon-plus'} onClick={onHandlePage}>
                                {t('Add an activity', 'Activity ScheduleList')}
                            </Button>
                        </>
                    ),
                }}
            >
                <div style={{ height: '700px' }}>
                    <div style={{ height: '100%' }}>
                        <Table
                            key={JSON.stringify(filterList)}
                            textAlign={'center'}
                            onTrClick={onClick}
                            columns={columns}
                            paging={false}
                            data={filterList}
                        />
                    </div>
                </div>
                <ConfirmModal
                    initModal={modalState}
                    headerText={t('Warning')}
                    modalToggle={toggleModal}
                    confirmText={errorText}
                />
            </CustomCard>
        </div>
    );
};

export default ScheduleByActivityDetail;
