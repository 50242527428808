import React, { useEffect, useState } from 'react';
import { Col, Row, Input } from 'reactstrap';
import Select from 'react-select';
import { defaultStyle } from '../../Common/Select/selectStyle';
import { useParams, useHistory } from 'react-router-dom';
import CustomCard from '../../Common/Card';
import Button from '../../Common/Button';
import TextInput from '../../Common/Input/TextInput';
import useAsync from '../../../util/hooks/useAsync';
import {
    createCommonCodeApi,
    getCommonCodeApi,
    getCommonCodeListApi,
    deleteCommonCodeApi,
    updateCommonCodeApi,
} from '../../../api/commonCode';
import cx from 'classnames';
import styled from 'styled-components';
import useConfirmModal from '../../Common/ConfirmModal/useConfirmModal';
import { TextChange, validationCheck } from '../ActiveSchedule/util/Validation';
import ConfirmModal from '../Components/ConfirmModal';
import useTranslation from "../../../util/hooks/useTranslation";

const StyledTextInput = styled(TextInput)`
    &:disabled {
        cursor: not-allowed;
    }
`;

const DescriptionRow = ({ label, value, name, disabled, handleChange, LabelComponent }) => {
    return (
        <Row>
            <Col className={'mb-3'}>
                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot mr-3">{label}</span>
                    <span className="label-options">
                        {LabelComponent ? (
                            LabelComponent
                        ) : (
                            <StyledTextInput
                                name={name}
                                value={value || ''}
                                maxlength={30}
                                handleChange={handleChange}
                                disabled={disabled}
                            />
                        )}
                    </span>
                </div>
            </Col>
        </Row>
    );
};

const codeDepthOption = [{ value: 0, label: '0' }];
const topClCode = { codeNum: -1, code: 'ROOT_CODE_CL', codeName: '최상위 코드분류' };

const CodeDetail = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const { codeNum } = useParams();
    const [codeDepth, setCodeDepth] = useState(codeDepthOption[0]);
    const [codeDepthList, setCodeDepthList] = useState(codeDepthOption);
    const [clCodeList, setClCodeList] = useState([]);
    const [codeInfo, setCodeInfo] = useState({
        clCodeNum: topClCode.codeNum,
        clCode: topClCode.code,
        clCodeName: topClCode.codeName,
    });
    const [validationResultState, setValidationResultState] = useState([]);
    const [validation, setValidation] = useState({
        codeDepth: '코드타입',
        clCode: '코드분류',
        code: '코드',
        codeName: '코드명',
        useFlag: '사용여부',
    });
    const { toggleModal: toggleSaveModal, Modal: SaveModal } = useConfirmModal({
        header: { title: '알림' },
        confirmText: '저장이 완료되었습니다.',
        okCallback: () => {
            history.push('/common-code');
        },
    });
    const { toggleModal: toggleDeleteCompleteModal, Modal: DeleteCompleteModal } = useConfirmModal({
        header: { title: '알림' },
        confirmText: '삭제가 완료되었습니다.',
        okCallback: () => {
            history.push('/common-code');
        },
    });
    const { toggleModal: toggleDeleteFailureModal, Modal: DeleteFailureModal } = useConfirmModal({
        header: { title: '알림' },
        confirmText: '코드 삭제에 실패했습니다. 잠시 후에 다시 시도해 주세요.',
        okCallback: () => {
            history.push('/common-code');
        },
    });
    const { toggleModal: toggleDeleteModal, Modal: DeleteModal } = useConfirmModal({
        header: { title: '알림' },
        confirmText: '해당 코드를 삭제하시겠습니까?',
        okCallback: () => {
            deleteCommonCode({ codeNum });
        },
    });

    const { promise: getCodeInfo } = useAsync({
        promise: getCommonCodeApi,
        resolve: res => {
            setCodeInfo(res);
        },
    });
    const { promise: createCommonCode } = useAsync({
        promise: createCommonCodeApi,
        resolve: res => {
            toggleSaveModal();
        },
    });
    const { promise: updateCommonCode } = useAsync({
        promise: updateCommonCodeApi,
        resolve: res => {
            toggleSaveModal();
        },
    });
    const { promise: deleteCommonCode } = useAsync({
        promise: deleteCommonCodeApi,
        resolve: res => {
            toggleDeleteCompleteModal();
        },
        reject: err => {
            toggleDeleteFailureModal();
        },
    });
    const {
        promise: getCodeListInfo,
        state: { response: codeListInfo },
    } = useAsync({
        promise: getCommonCodeListApi,
        resolve: res => {
            setCodeDepthList(
                res.rows
                    .reduce(
                        (acc, curr) => {
                            if (!acc.find(v => v.value === curr.codeDepth)) {
                                acc.push({ value: curr.codeDepth, label: curr.codeDepth });
                            }
                            return acc;
                        },
                        [...codeDepthOption],
                    )
                    .sort((a, b) => a.value - b.value),
            );
        },
    });

    const handleInputChange = e => {
        const { name, value } = e.currentTarget;

        setCodeInfo({ ...codeInfo, [name]: value });
        setValidation({ ...validation, [name]: null });
    };
    const handleInputCheckChange = e => {
        const { name, checked } = e.currentTarget;
        setCodeInfo({ ...codeInfo, [name]: checked ? 'Y' : 'N' });
        setValidation({ ...validation, [name]: null });
    };

    useEffect(() => {
        getCodeListInfo({ isAll: 'Y' });
        if (codeNum) {
            getCodeInfo({ codeNum });
        }
    }, [codeNum]);

    useEffect(() => {
        if (codeListInfo && codeListInfo.rows && codeListInfo.rows.length) {
            const filteredClCodeList = codeListInfo.rows.filter(v => v.codeDepth === codeDepth.value);

            if (codeDepth.value === 0) {
                filteredClCodeList.push(topClCode);
            }
            setClCodeList(filteredClCodeList);

            const clCodeInfo = {};

            if (filteredClCodeList[0]) {
                const { code, codeNum, codeName } = filteredClCodeList[0];
                clCodeInfo.clCode = code;
                clCodeInfo.clCodeNum = codeNum;
                clCodeInfo.clCodeName = codeName;
            } else {
                clCodeInfo.clCode = null;
                clCodeInfo.clCodeNum = null;
                clCodeInfo.clCodeName = null;
            }

            setCodeInfo({
                ...codeInfo,
                ...clCodeInfo,
                codeDepth: (codeDepth.value || 0) + 1,
            });
        }
    }, [codeDepth, codeListInfo]);

    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };

    return (
        <>
            <Row className={'custom-row'}>
                <Col>
                    <CustomCard
                        // className={'search-detail-height'}
                        header={{
                            title: t('Common Code List','Menu'),
                            action: (
                                <>
                                    <Button
                                        className="pnt-btn btn-gray"
                                        onClick={() => {
                                            history.goBack();
                                        }}
                                    >
                                        {t('Back','Active Dashboard')}
                                    </Button>
                                    <div className="pnt-border border-vert" />
                                    {codeNum && (
                                        <Button
                                            className={cx('btn-danger', 'pnt-btn', 'btn-icon')}
                                            onClick={e => {
                                                toggleDeleteModal();
                                            }}
                                        >
                                            <span className={'icon-delete'} />
                                            {t('Delete','Active Dashboard')}
                                        </Button>
                                    )}
                                    <Button
                                        className={cx('btn-blue', 'pnt-btn', 'btn-icon')}
                                        onClick={e => {
                                            const { clCodeNum, useFlag, ...restCodeInfo } = codeInfo;
                                            const reqParam = { ...restCodeInfo, useFlag: useFlag ? useFlag : 'N' };
                                            // for (let key in reqParam) {
                                            //     if (!reqParam[key]) {
                                            //         delete reqParam[key];
                                            //     }
                                            // }
                                            if (!codeNum) {
                                                let validationResult = [];
                                                validationResult = validationCheck(validation);
                                                if (!validationResult.length) {
                                                    createCommonCode(reqParam);
                                                } else {
                                                    setValidationResultState(validationResult);
                                                    toggleConfirmModal();
                                                }
                                            } else {
                                                updateCommonCode(reqParam);
                                            }
                                        }}
                                    >
                                        <span className={'icon-editnote'} />
                                        {t('Save','Active Dashboard')}
                                    </Button>
                                </>
                            ),
                        }}
                    >
                        {codeNum ? (
                            <>
                                <DescriptionRow
                                    label={t('Classification code')}
                                    name={'clCode'}
                                    value={codeInfo.clCode}
                                    handleChange={handleInputChange}
                                    disabled
                                />
                                <DescriptionRow
                                    label={t('Classification code name')}
                                    name={'clCodeName'}
                                    value={codeInfo.clCodeName}
                                    handleChange={handleInputChange}
                                    disabled
                                />
                            </>
                        ) : (
                            <DescriptionRow
                                label={t('Classification code')}
                                LabelComponent={
                                    <>
                                        <Select
                                            styles={{
                                                ...defaultStyle,
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    width: '150px',
                                                }),
                                                option: provided => ({
                                                    ...provided,
                                                }),
                                                // control: provided => ({
                                                //     ...provided,
                                                //     width: '190px',
                                                // }),
                                            }}
                                            isSearchable={false}
                                            value={codeDepth}
                                            name={'codeDepth'}
                                            options={codeDepthList}
                                            onChange={selected => {
                                                setCodeDepth(selected);
                                                setValidation({ ...validation, codeDepth: null });
                                            }}
                                        />
                                        <Select
                                            menuPortalTarget={document.getElementById('selectContainer')}
                                            styles={{
                                                ...defaultStyle,
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    width: '190px',
                                                }),
                                                option: provided => ({
                                                    ...provided,
                                                }),
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                // control: provided => ({
                                                //     ...provided,
                                                //     width: '190px',
                                                // }),
                                            }}
                                            value={{ codeNum: codeInfo.clCodeNum, codeName: codeInfo.clCodeName }}
                                            name={'clCode'}
                                            options={clCodeList}
                                            getOptionValue={option => option.codeNum}
                                            getOptionLabel={option => option.codeName}
                                            onChange={selected => {
                                                setValidation({ ...validation, clCode: null });
                                                setCodeInfo({
                                                    ...codeInfo,
                                                    // codeDepth: selected.codeDepth + 1,
                                                    clCode: selected.code,
                                                    clCodeNum: selected.codeNum,
                                                    clCodeName: selected.codeName,
                                                });
                                            }}
                                        />
                                    </>
                                }
                            />
                        )}
                        <DescriptionRow
                            label={t('code')}
                            name={'code'}
                            value={codeInfo.code}
                            handleChange={handleInputChange}
                        />
                        <DescriptionRow
                            label={t('code name')}
                            name={'codeName'}
                            value={codeInfo.codeName}
                            handleChange={handleInputChange}
                        />
                        <DescriptionRow
                            label={t('Enabled or not')}
                            LabelComponent={
                                <Input
                                    style={{ marginLeft: 0 }}
                                    type="checkbox"
                                    name={'useFlag'}
                                    value={'Y'}
                                    checked={codeInfo.useFlag === 'Y'}
                                    onChange={handleInputCheckChange}
                                />
                            }
                        />
                        {codeNum && (
                            <DescriptionRow
                                label={t('Sort Order')}
                                name={'orderNum'}
                                value={codeInfo.orderNum}
                                handleChange={handleInputChange}
                                disabled
                            />
                        )}
                        <DescriptionRow
                            label={t('Code Level')}
                            name={'codeDepth'}
                            value={codeInfo.codeDepth}
                            handleChange={handleInputChange}
                            disabled
                        />
                    </CustomCard>
                </Col>
            </Row>
            <SaveModal />
            <DeleteModal />
            <DeleteCompleteModal />
            <DeleteFailureModal />
            <ConfirmModal
                initModal={confirmModalState}
                headerText={t('Required fields Item','Evaluation')}
                modalToggle={toggleConfirmModal}
                confirmText={TextChange(validationResultState)}
            />
        </>
    );
};

export default CodeDetail;
