import React, { useEffect, useState } from 'react';
import CustomCard from '../../Common/Card';
import Button from '../../Common/Button';
import useAsync from '../../../util/hooks/useAsync';
import { getPatientInfo } from '../../../api/activeSchedule';
import { getRiskStatus, RiskStatus } from '../util/riskNamePlate';
import { onHandleTextChange } from '../util/TextChange';
import { onHandleAge } from '../util/dateChange';
import useTranslation from '../../../util/hooks/useTranslation';

const PatientInfoCard = ({ headerTitle, flag, headerTitleSub, buttonOption, spanIcon, targetNum }) => {
    const t = useTranslation('Active Dashboard');
    const [patientInfo, setPatientInfo] = useState({});
    const { promise: getPatientInfoApi } = useAsync({
        promise: getPatientInfo,
        resolve: res => {
            setPatientInfo(res);
        },
    });
    useEffect(() => {
        if (targetNum) {
            getPatientInfoApi({ targetKey: targetNum });
        }
    }, [flag, targetNum]);

    const getSexInKorean = value => {
        let word;
        switch (value) {
            case 'male':
                word = t('Male');
                break;
            case 'female':
                word = t('Female');
                break;
        }
        return word;
    };
    return (
        <CustomCard
            header={{
                title: headerTitle,
                subTitle: headerTitleSub,
                action: (
                    <>
                        {buttonOption &&
                            buttonOption.length > 0 &&
                            buttonOption.map((buttonOption, i) => (
                                <Button key={i} className={`pnt-btn btn-gray`} onClick={buttonOption.onClick}>
                                    {buttonOption.className && <span className={`${buttonOption.className}`}></span>}
                                    {buttonOption.label}
                                </Button>
                            ))}
                        {spanIcon &&
                            spanIcon.length > 0 &&
                            spanIcon.map(icon => (
                                <Button className="pnt-btn btn-blue btn-icon-only" iconClassName={icon} />
                            ))}
                    </>
                ),
            }}
        >
            <div className="p-0 pnt-label-7 row">
                <div className="col-xl-6 col-lg-12 mb-2">
                    <div className="pnt-label--group">
                        <span className="label-main label-dot ">{t('Patient Information')}</span>
                        <span className="label-options">
                            <span>
                                {patientInfo &&
                                    patientInfo.properties &&
                                    `${patientInfo.targetName} / ${patientInfo.properties.patid} [${getSexInKorean(
                                        patientInfo.properties.sex,
                                    )}] [${onHandleAge(patientInfo.properties.birthDay)}]
                                [${patientInfo.properties.status} - KTAS${
                                        (patientInfo.properties.ktas && patientInfo.properties.ktas) || ''
                                    }]`}
                            </span>
                        </span>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-12 mb-2">
                    <div className="pnt-label--group">
                        <div className="label-main label-dot ">{t('Department/Primary Care Physician')}</div>
                        <div className="label-options">
                            {patientInfo &&
                                patientInfo.properties &&
                                onHandleTextChange([patientInfo.properties.medDept, patientInfo.properties.chadrname])}
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-12 ">
                    <div className="pnt-label--group">
                        <div className="label-main label-dot ">{t('Ward/Room/Bed')}</div>
                        <div className="label-options">
                            {patientInfo &&
                                patientInfo.properties &&
                                onHandleTextChange([
                                    patientInfo.properties.wardNo,
                                    patientInfo.properties.roomNo,
                                    patientInfo.properties.bedNo,
                                ])}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 ">
                    <div className="pnt-label--group">
                        <div className="label-main label-dot ">{t('Risk categorization')}</div>
                        <div className="label-options color-secondary">
                            {patientInfo && patientInfo.properties && (
                                <RiskStatus value={patientInfo.properties.bsRisk} />
                            )}
                            {patientInfo && patientInfo.properties && (
                                <RiskStatus value={patientInfo.properties.bfRisk} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </CustomCard>
    );
};

export default PatientInfoCard;
