import { CustomInput, Input } from 'reactstrap';
import React from 'react';
import TextInput from '../../../Common/Input/TextInput';
import ReactSelect from '../../../Common/Select';
import Button from '../../../Common/Button';
import DatePicker from '../../../Common/DatePicker';
import SearchableSelect from '../../../Common/Select/SearchableSelect';
import styled from 'styled-components';
import { dateChange } from '../dateChange';
import imageNotFound from '../../../../assets/images/image-not-found.svg';
import { includes } from 'react-table/src/filterTypes';
import useTranslation from '../../../../util/hooks/useTranslation';

const CustomText = styled.div`
    position: static;
    width: 184px;
    line-height: 37px;
    height: 38px;
    left: 310px;
    top: 0px;
    background: #f7f7f7;
    border-radius: 3px;
    border: 1px solid hsl(0, 0%, 80%);
    border-color: hsl(0deg 0% 80%);
    padding: 0px 0px 0px 10px;
`;

export const HandleCustomInput = ({
    className,
    readOnly,
    label,
    type,
    name,
    id,
    value,
    onChange,
    checkedState,
    checkState,
}) => {
    return readOnly ? (
        value && (
            <CustomInput
                className={className}
                type={type}
                id={id}
                label={label}
                value={value}
                onChange={onChange}
                checked={checkedState}
            />
        )
    ) : (
        <CustomInput
            className={className}
            type={type}
            id={id}
            value={value}
            label={label}
            onChange={onChange}
            checked={!(!checkState[name] || !checkState[name].length)}
            name={name}
        />
    );
};

export const HandleDateInput = ({
    readOnly,
    handleDateValueChange,
    valueType,
    text,
    value,
    minDate,
    disabled,
    title = '수행일시',
}) => {
    const t = useTranslation('Common Columns');
    return readOnly ? (
        <div>{dateChange(text)}</div>
    ) : (
        <DatePicker
            value={value}
            title={t(title)}
            disabled={disabled}
            handleChange={handleDateValueChange}
            valueType={valueType}
            showTimeInput
            minDate={minDate}
        />
    );
};
export const HandleCheckbox = ({ readOnly, checked, onChange, value, name }) => {
    return readOnly ? (
        <div>{value}</div>
    ) : (
        <Input
            style={{ marginLeft: 0 }}
            type={'checkbox'}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
        />
    );
};
export const HandleTextInput = ({
    type = 'text',
    min,
    max,
    size,
    readOnly = false,
    onChange,
    name,
    disabled = false,
    className,
    placeholder,
    value,
}) => {
    return readOnly ? (
        <div className={'text-ellipsis'}>
            <span title={value}>{value}</span>
        </div>
    ) : (
        <TextInput
            type={type}
            min={min}
            max={max}
            size={size}
            name={name}
            disabled={disabled}
            handleChange={onChange}
            className={className}
            placeholder={placeholder}
            value={value}
        />
    );
};
export const HandleNurseTextInput = ({ readOnly, name, className, placeholder, value, text, onClick }) => {
    const t = useTranslation('Evaluation');
    return readOnly ? (
        <div>{text === null ? '-' : text}</div>
    ) : (
        <>
            <div className={className}>
                <TextInput
                    name={name}
                    className={'border-color'}
                    value={value ? value : `${t('Nurse')}||${t('Number')}`}
                    disabled
                    placeholder={placeholder}
                />
                <Button
                    className={'pnt-btn btn-blue btn-icon-only ml-2'}
                    onClick={onClick}
                    iconClassName={'icon-search'}
                />
            </div>
        </>
    );
};
export const HandleTextInputAndButton = ({ readOnly = false, name, placeholder, value, text, onClick }) => {
    const t = useTranslation('Evaluation');
    return readOnly ? (
        <div>{text}</div>
    ) : (
        <>
            <div className={'d-flex'}>
                <TextInput
                    name={name}
                    className={'border-color'}
                    value={value ? value : `${t('Doctor name')}||${t('Number')}`}
                    disabled
                    placeholder={placeholder}
                />
                <Button
                    className={'pnt-btn btn-blue btn-icon-only ml-2'}
                    onClick={onClick}
                    iconClassName={'icon-search'}
                />
            </div>
        </>
    );
};

export const HandleImage = ({ readOnly, attachNum, value, src }) => {
    return readOnly ? (
        <>
            <img src={attachNum ? src : imageNotFound} width={200} height={200} alt={'전신피부사정 이미지'} />
        </>
    ) : (
        <span>{value}</span>
    );
};
export const HandleText = ({ value }) => {
    return (
        <>
            <span className="label-options">{value}</span>
        </>
    );
};
export const HandleFileInput = ({ readOnly, onChange }) => {
    const t = useTranslation('Common Columns');
    return readOnly ? null : (
        <>
            <label
                className="input-file-button"
                htmlFor="input-file"
                style={{
                    padding: '6px 25px',
                    backgroundColor: '#FF6600',
                    borderRadius: '4px',
                    color: 'white',
                    cursor: 'pointer',
                }}
            >
                {t('Select File')}
            </label>
            <input
                type="file"
                id="input-file"
                onChange={onChange}
                style={{ display: 'none' }}
                accept="image/jpg, image/jpeg"
            />
        </>
    );
};
export const HandleSearchableSelect = ({
    customControlStyles,
    customOptionStyles,
    readOnly = false,
    options,
    selected,
    onChange,
    text: { code: codes, rows },
}) => {
    const t = useTranslation('Common Columns');
    const solution = ({ codes, rows }) => {
        let label = [];
        const code = codes ? codes.split(',') : [];
        if (code.length) {
            label = rows.reduce((acc, curr) => {
                code.filter(code => code === curr.value && acc.push(curr.label));
                return acc;
            }, []);
        }
        return label.join(',');
    };
    return readOnly ? (
        <div>{solution({ codes, rows })}</div>
    ) : (
        <SearchableSelect
            title={t('Select')}
            valueContainerStyle={{ width: '235px', height: '30', borderColor: '#3E66FB' }}
            menuStyle={{ width: '235px' }}
            defaultLabel={false}
            data={options}
            onChange={onChange}
            selected={selected}
            customControlStyles={customControlStyles}
            customOptionStyles={customOptionStyles}
        />
    );
};
export const HandleReactSelect = ({
    customControlStyles,
    customOptionStyles,
    isDisabled,
    readOnly = false,
    options,
    value,
    isMulti,
    onChange,
    name,
    text,
}) => {
    return readOnly ? (
        <div>{text}</div>
    ) : (
        <div>
            <ReactSelect
                options={options}
                isDisabled={isDisabled}
                isMulti={isMulti}
                onChange={onChange}
                value={value}
                name={name}
                customControlStyles={customControlStyles}
                customOptionStyles={customOptionStyles}
            />
        </div>
    );
};

export const DataGroup = ({ children, label, className }) => {
    return (
        <>
            <div className={className}>
                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{label}</span>
                    <span className="label-options">{children}</span>
                </div>
            </div>
        </>
    );
};
export const FilterChange = ({ title, text, data, selected, onChange, handleData }) => {
    return handleData === 'ts' ? (
        <CustomText>
            <strong style={{ fontSize: '0.8rem' }}>{text}</strong>
        </CustomText>
    ) : (
        <>
            <SearchableSelect title={title} data={data} selected={selected} onChange={onChange} />
        </>
    );
};
export const DescriptionRow = ({ className = 'col-xl-6 col-lg-12 mb-3', label, component, spanClassName = '' }) => {
    return (
        <div className={className}>
            <div className={'pnt-label--group'}>
                <span className="label-main label-dot">{label}</span>
                <span className={`label-options ${spanClassName}`}>{component}</span>
            </div>
        </div>
    );
};
