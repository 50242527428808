import React, { createContext, useEffect, useReducer } from 'react';
import PatientInfoCard from '../../Components/PatientInfoCard';
import { useHistory, useParams } from 'react-router-dom';
import activeScheduleReducer, { activeScheduleInitialState, setCardHeight } from '../activeScheduleReducer';
import scheduleByActivityReducer, { initialState } from '../ScheduleByActivity/scheduleByActivityReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import useAsync from '../../../../util/hooks/useAsync';
import { getActivityAssessment } from '../../../../api/activeSchedule';
import ResizeDetector from 'react-resize-detector';
import PerformanceRecord from './PerformanceRecord/PerformanceRecord';
import useTranslation from '../../../../util/hooks/useTranslation';
export const CommonDispatchContext = createContext();
export const CommonStateContext = createContext();
export const ScheduleByActivityDispatchContext = createContext();
export const ScheduleByActivityStateContext = createContext();

const CarryingOutActivities = props => {
    const t = useTranslation('Active Dashboard');
    const param = useParams();
    const history = useHistory();
    const storeDispatch = useDispatch();
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const [commonState, commonDispatch] = useReducer(activeScheduleReducer, activeScheduleInitialState);
    const [state, dispatch] = useReducer(scheduleByActivityReducer, initialState);
    const { flag } = state;
    const { pageState, psNum } = param;

    const { promise: getPatient } = useAsync({
        promise: getActivityAssessment,
        fixedParam: { psNum },
        resolve: res => {
            storeDispatch(setPatientDetailInfo(res));
        },
    });
    useEffect(() => {
        getPatient();
    }, []);

    const handlePage = () => {
        const { actItemCode } = patientDetailInfo;
        if (!['BS_IV_1', 'BS_REPORT_1'].includes(actItemCode)) {
            storeDispatch(setPatientDetailInfo(null));
        }
        history.goBack();
    };
    return (
        <>
            <CommonDispatchContext.Provider value={commonDispatch}>
                <CommonStateContext.Provider value={commonState}>
                    <ScheduleByActivityDispatchContext.Provider value={dispatch}>
                        <ScheduleByActivityStateContext.Provider value={state}>
                            <div>
                                <ResizeDetector
                                    handleWidth
                                    handleHeight
                                    onResize={(width, height) => {
                                        commonDispatch(setCardHeight(`calc(100% - ${height}px )`));
                                    }}
                                >
                                    <PatientInfoCard
                                        targetNum={patientDetailInfo && patientDetailInfo.targetNum}
                                        headerTitle={t('Patient Information')}
                                        keyValue={'patientInfo'}
                                        headerTitleSub={t('Function to display selected patient information')}
                                        buttonOption={[{ label: t('Back'), onClick: handlePage }]}
                                        flag={flag}
                                    />
                                </ResizeDetector>
                            </div>
                            <PerformanceRecord pageState={pageState} />
                        </ScheduleByActivityStateContext.Provider>
                    </ScheduleByActivityDispatchContext.Provider>
                </CommonStateContext.Provider>
            </CommonDispatchContext.Provider>
        </>
    );
};

export default CarryingOutActivities;
