//AssetSummaryList
export const textInputForSearch = {
    width: 'max-content',
    paddingRight: '50px',
};

//SelectedAsset
export const borderFullHeight = {
    height: '100%',
};

export const textInputFullWidth = {
    width: '100%',
};
