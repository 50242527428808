import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import {
    createFallDownUrinationRound,
    getFallDownUrinationRoundApi,
    updateFallDownUrinationRound,
} from '../../../../../../api/activeSchedule';
import { useHistory } from 'react-router-dom';
import { CustomInput } from 'reactstrap';
import { ScheduleByActivityDispatchContext, ScheduleByActivityStateContext } from '../../CarryingOutActivities';
import { setUrinationRoundInfo, updateUrinationRoundInfo } from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { setBfUrinationRoundOption, setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import { useDispatch, useSelector } from 'react-redux';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import { TextChange, validationCheck } from '../../../util/Validation';
import CustomSearchModal from '../../../util/CustomSearchModal';
import ConfirmModal from '../../../../Components/ConfirmModal';
import ResizeDetector from 'react-resize-detector';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const UrinationRound = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { bfUrinationRoundOption, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);

    const { actItemCodeName, actTypeCodeName, targetNum, psNum, exeDate } = patientDetailInfo;
    const { urinationRoundInfo } = state;
    const [validation, setValidation] = useState({});
    const [bfUrinationCheck, setBfUrinationCheck] = useState(true);
    const [tableHeight, setTableHeight] = useState(null);
    const checkValidation = urinationRoundInfo && urinationRoundInfo.exeFlag === 'Y' ? 'enforcement' : 'notEnforced';
    const [check, setCheck] = useState(checkValidation);
    //For Select
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BF_EDU_IV_3', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setBfUrinationRoundOption(res));
        },
    });
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });

    const { promise: getFallDownUrinationRound } = useAsync({
        promise: getFallDownUrinationRoundApi,
        resolve: res => {
            const { wardCodeName, exeFlag, bfUrinationCode, wardCode } = res;
            setCheck(exeFlag === 'Y' ? 'enforcement' : 'notEnforced');

            setBfUrinationCheck(bfUrinationCode ? true : false);

            setRecordState(true);
            setNurseRecordState(true);
            dispatch(setUrinationRoundInfo(res));
            storeDispatch(setNurseInfo(res));
            setZoneState({ label: wardCodeName, value: wardCode });
        },
    });
    useEffect(() => {
        if (exeDate) {
            getFallDownUrinationRound({ psNum });
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, [exeDate]);

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const [validationResultState, setValidationResultState] = useState([]);
    const { promise: addFallDownUrinationRound } = useAsync({
        promise: createFallDownUrinationRound,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownUrinationRound({ psNum });
            }
        },
    });
    const { promise: editFallDownUrinationRound } = useAsync({
        promise: updateFallDownUrinationRound,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownUrinationRound({ psNum });
            }
        },
    });
    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editFallDownUrinationRound({
                      ...nurseInfo,
                      ...urinationRoundInfo,
                      psNum,
                      targetNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addFallDownUrinationRound({
                      ...nurseInfo,
                      ...urinationRoundInfo,
                      psNum,
                      targetNum,
                      exeStatusCode: 'C',
                      ip: profile.clientIp,
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    const onHandleEdit = () => {
        setRecordState(!recordState);
    };
    const { promise: noFallDownUrinationRound } = useAsync({
        promise: createFallDownUrinationRound,
        resolve: () => {
            history.push('/activity-schedule');
        },
    });

    const onHandleNotDone = () => {
        noFallDownUrinationRound({
            bfEduIv3Code: '',
            exeFlag: '',
            exeStatusCode: 'N',
            bfUrinationCode: null,
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateUrinationRoundInfo({ wardCode: value }));
        setZoneState({ label, value });
    };
    const handleSelectValueChange = e => {
        const { name, value } = e;
        dispatch(updateUrinationRoundInfo({ [name]: value }));
    };
    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateUrinationRoundInfo({ [name]: value }));
    };

    const onChangeCheck = e => {
        const { id, name, value } = e.currentTarget;
        dispatch(updateUrinationRoundInfo({ [name]: value }));
        setCheck(id);
    };

    //모달창
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };

    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };

    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onHandleSave,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onHandleEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };
    const bfUrinationRoundOptionRows =
        bfUrinationRoundOption.length > 0 &&
        bfUrinationRoundOption.map(ele => {
            return {
                ...ele,
                label: t(ele.label, 'ActTypeCode'),
            };
        });

    return (
        <div>
            <ResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => {
                    setTableHeight(height);
                }}
            >
                <ActiveRecordCard
                    style={{ height: tableHeight }}
                    headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                        actItemCodeName,
                        'ActTypeCode',
                    )}`}
                    buttonOption={buttonOptionChange(recordState)}
                    spanIcon={['icon-info']}
                    url={`${url.wmsUrl}/v2/html/aum/guide/5_3.html`}
                >
                    <div className="p-0 pnt-label-7 row">
                        <DescriptionRow
                            label={t('Nurse')}
                            component={
                                <HandleNurseTextInput
                                    name={'nurse'}
                                    onClick={toggleModal}
                                    placeholder={`${t('Nurse')}||${t('Number')}`}
                                    readOnly={nurseRecordState}
                                    className={'d-flex'}
                                    text={
                                        urinationRoundInfo &&
                                        urinationRoundInfo.hasOwnProperty('exeUserName') &&
                                        urinationRoundInfo.exeUserName &&
                                        `${urinationRoundInfo.exeUserName} || ${urinationRoundInfo.exeUserNum}`
                                    }
                                    value={
                                        nurseInfo.hasOwnProperty('exeUserName') &&
                                        `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                    }
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('When performed')}
                            component={
                                <HandleDateInput
                                    value={startDate}
                                    handleDateValueChange={handleStartDateValueChange}
                                    readOnly={nurseRecordState}
                                    valueType={'ms'}
                                    text={urinationRoundInfo && `${urinationRoundInfo.exeDate} `}
                                    minDate={moment().valueOf()}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Zones')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    options={zoneOptionRows}
                                    readOnly={recordState}
                                    isMulti={false}
                                    onChange={handleZoneSelect}
                                    value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                    text={
                                        (urinationRoundInfo && t(urinationRoundInfo.wardCodeName, 'ActTypeCode')) || '-'
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Enforced or not')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <>
                                    <CustomInput
                                        id={'enforcement'}
                                        type={'checkbox'}
                                        label={t('Enforcement')}
                                        name={'exeFlag'}
                                        className={'col-12 mb-2'}
                                        value={'Y'}
                                        onChange={onChangeCheck}
                                        disabled={recordState}
                                        checked={check === 'enforcement'}
                                    />

                                    <CustomInput
                                        id={'bfUrinationCode'}
                                        type={'checkbox'}
                                        disabled={recordState}
                                        label={t(
                                            'Educating and reducing bed height to the lowest level to prevent falls',
                                        )}
                                        className={'col-12 mb-3'}
                                        name={'bfUrinationCode'}
                                        value={t(
                                            'Educating and reducing bed height to the lowest level to prevent falls',
                                        )}
                                        checked={bfUrinationCheck}
                                        onChange={e => {
                                            const { name, checked, value } = e.currentTarget;
                                            setBfUrinationCheck(checked);
                                            dispatch(
                                                updateUrinationRoundInfo({
                                                    [name]: checked ? 1 : null,
                                                    remark: checked ? value : '',
                                                }),
                                            );
                                        }}
                                    />
                                </>
                            }
                        />
                        <DescriptionRow
                            label={t('N/A')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <>
                                    <CustomInput
                                        id={'notEnforced'}
                                        type={'checkbox'}
                                        label={t('Not applicable')}
                                        name={'exeFlag'}
                                        value={'N'}
                                        className={'mr-3'}
                                        onChange={onChangeCheck}
                                        disabled={recordState}
                                        checked={check === 'notEnforced'}
                                    />
                                    {bfUrinationRoundOption.length > 0 && (
                                        <HandleReactSelect
                                            readOnly={recordState}
                                            options={bfUrinationRoundOptionRows}
                                            isMulti={false}
                                            closeMenuOnSelect={false}
                                            onChange={handleSelectValueChange}
                                            isDisabled={check === 'enforcement'}
                                            customControlStyles={{ width: '235px' }}
                                            customOptionStyles={{ width: '235px' }}
                                            text={
                                                (urinationRoundInfo &&
                                                    t(urinationRoundInfo.bfEduIv3CodeName, 'ActTypeCode')) ||
                                                t('Not selected')
                                            }
                                        />
                                    )}
                                </>
                            }
                        />
                        <DescriptionRow
                            label={t('Remarks')}
                            className="col-xl-12 col-lg-12 mb-3"
                            spanClassName={'w-50'}
                            component={
                                <HandleTextInput
                                    size={200}
                                    onChange={onHandleTextChange}
                                    readOnly={recordState}
                                    name={'remark'}
                                    disabled={bfUrinationCheck}
                                    placeholder={t('Enter any remarks.')}
                                    value={urinationRoundInfo && t(urinationRoundInfo.remark, 'ActTypeCode')}
                                />
                            }
                        />
                    </div>
                </ActiveRecordCard>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ResizeDetector>
        </div>
    );
};

export default UrinationRound;
