import React, { useContext, useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { CustomInput } from 'reactstrap';
import Button from '../../../Common/Button';
import { useTranslation } from 'react-i18next';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchIotItemList } from '../../../../api/asset';
import {
    setFloorInfo,
    setGeofenceInfo,
    setRealTimeLog,
    setSelectedFloor,
    setSelectedItem,
} from '../realTimeLocationReducer';
import { RealTimeLocationStatusContext, RealTimeLocationStatusDispatchContext } from '../index';
import GeofenceLayer from '../../../Common/Map/Layers/GeofenceLayer';
import { useSelector } from 'react-redux';

const SingleTarget = ({ mapRef }) => {
    const { t } = useTranslation();

    const { floorInfo, geofenceInfo, selectedItem, realTimeLog } = useContext(RealTimeLocationStatusContext);
    const dispatch = useContext(RealTimeLocationStatusDispatchContext);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);

    const [positionTracking, setPositionTracking] = useState(false);

    const { promise: getIotItem } = useAsync({
        promise: fetchIotItemList,
        resolve: response => {
            const { rows } = response;
            const data = rows[0];
            dispatch(setSelectedFloor(data.floorId));
            dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === data.floorId)));

            // const map = mapRef.current.leafletElement;
            // map.flyTo([data.lat, data.lng]);
        },
    });

    useEffect(() => {
        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].latLng);
        }
    }, []);

    useEffect(() => {
        if (selectedItem.hasOwnProperty('value')) {
            getIotItem({ keyType: 'targetId', targetId: selectedItem.value });
        }
    }, [selectedItem]);

    useEffect(() => {
        if (positionTracking) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].latLng);
        }
    }, [realTimeLog]);

    const handleRefreshClick = () => {
        dispatch(setSelectedItem({}));
        dispatch(setSelectedFloor(''));
        dispatch(setFloorInfo({}));
        dispatch(setGeofenceInfo([]));
        dispatch(setRealTimeLog({ count: 0, data: [] }));
        setPositionTracking(false);
    };

    const handleItemPositionCheck = () => {
        setPositionTracking(!positionTracking);

        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].latLng);
        }
    };

    return (
        <>
            <Control position={'topleft'}>
                <div className={'item-container'}>
                    <CustomInput
                        type={'checkbox'}
                        id={'itemPosition'}
                        label={`${t('RealTimeLocationStatus;Always Show Item Position In Center')}`}
                        checked={positionTracking}
                        onChange={handleItemPositionCheck}
                    />
                </div>
            </Control>
            {Object.keys(selectedItem).length > 0 && (
                <Control position={'topleft'}>
                    <Button
                        iconClassName={'icon-refresh'}
                        onClick={handleRefreshClick}
                        className={'btn-icon-only btn-sm btn-gray'}
                    />
                </Control>
            )}

            <Control position="topright">
                <div className={'item-container'}>
                    <span>{`${t('RealTimeLocationStatus;Current Floor')} : `}</span>
                    <span>{floorInfo && floorInfo.floorId}</span>
                </div>
            </Control>

            {geofenceInfo && geofenceInfo.length > 0 && <GeofenceLayer geofenceList={geofenceInfo} />}
        </>
    );
};

export default SingleTarget;
