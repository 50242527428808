import React from 'react';

const AppFooter = () => {
    return (
        <>
            <footer className="app-footer">
                <div className="app-footer__inner">
                    <div className="app-footer-left" />
                    <div className="app-footer-right">
                        <address style={{ marginBottom: 0, color: '#ccc', fontWeight: 'bold', fontSize: '12px' }}>
                            copyright &copy; 2021. People and technology all right reserved
                        </address>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default AppFooter;
