import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ActiveAlarmItem from './ActiveAlarmItem';

const ActiveCardList = ({ listInfo, handleRedirectClick = () => {} }) => {
    return (
        <PerfectScrollbar>
            {listInfo && listInfo.rows.length > 0
                ? listInfo.rows.map((item, i) => (
                      <ActiveAlarmItem key={i} alarmInfo={item} handleRedirectClick={handleRedirectClick} />
                  ))
                : []}
        </PerfectScrollbar>
    );
};

export default ActiveCardList;
