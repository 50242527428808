import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // 설정 된 값
    markerConfig: {
        markerTransitionActive: 'Y',
        markerPulseActive: 'Y',
        markerBorderRadius: 'C',
        markerPulseAnimation: 'U',
        markerPulseRssi: -50.0,
        markerPulseColor: '#009313',
    },

    // UI에 실제 적용될 값
    markerConfigValue: {
        moveTransitionTime: 250,
        className: 'circle pulse-U',
        defaultSize: 10,
        minSize: 2,
        maxSize: 23,
    },
};

const AppConfig = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        setMarkerConfig: (state, action) => {
            const defaultConfig = initialState.markerConfig;
            const config = action.payload;
            for (let propId in config) {
                if (config.hasOwnProperty(propId)) {
                    if (config[propId] === null || config[propId] === undefined || config[propId] === '') {
                        config[propId] = defaultConfig[propId];
                    }
                }
            }
            state.markerConfig = config;

            const className = [];
            let moveTransitionTime = 0;
            if (config.markerTransitionActive === 'Y') {
                moveTransitionTime = 250;
            }
            state.markerConfigValue.moveTransitionTime = moveTransitionTime;

            if (config.markerPulseActive === 'Y') {
                if (config.markerBorderRadius === 'C') {
                    className.push('circle');
                } else {
                    className.push('square');
                }

                className.push(`pulse-${config.markerPulseAnimation}`);
            }
            // className.push(config.markerPulseColor);

            state.markerConfigValue.className = className.join(' ');
        },
    },
});

export const { setMarkerConfig } = AppConfig.actions;
export default AppConfig.reducer;
