import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    DescriptionRow,
    HandleCheckbox,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../util/componentChange/componentChange';
import ActiveRecordCard from '../../Components/ActiveRecordCard';
import CustomSearchModal from '../util/CustomSearchModal';
import { ScheduleByActivityDispatchContext, ScheduleByActivityStateContext } from './CarryingOutActivities';
import { setNurseInfo, updateNurseInfo } from '../activeScheduleReducer';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getAntecedentRequestOptionApi,
    getCureAssessmentApi,
    updateCureRecord,
    updateCureRecordApi,
} from '../../../../api/activeSchedule';
import { setAntecedentRequestOption, setBsRecordDressing } from '../../../../reducers/Common/PerformanceRecordOption';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectOptions } from '../../../../api/performanceRecord';
import {
    setTreatmentSkinRecordInfo,
    updateTreatmentSkinRecordInfo,
} from '../ScheduleByActivity/scheduleByActivityReducer';
import { dateChange } from '../../util/dateChange';
import { setPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../util/hooks/useTranslation';

const EditTreatmentRecord = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    const param = useParams();
    const { psNum: recordNum } = param;
    const storeDispatch = useDispatch();

    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { antecedentRequestOption, bsRecordDressing } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { actItemCodeName, actTypeCodeName, targetNum, psNum } = patientDetailInfo;
    const { treatmentSkinRecordInfo } = state;

    useEffect(() => {
        return () => {
            dispatch(setPatientDetailInfo(null));
            dispatch(updateTreatmentSkinRecordInfo(null));
            storeDispatch(updateNurseInfo(null));
        };
    }, []);
    const [recordState, setRecordState] = useState(false);
    const [nurseState, setNurseState] = useState(false);

    const { promise: getCureAssessment } = useAsync({
        promise: getCureAssessmentApi,
        resolve: res => {
            dispatch(setTreatmentSkinRecordInfo({ ...res }));
            storeDispatch(setNurseInfo(res));
            if (res.exeUserName) {
                setRecordState(true);
                setNurseState(true);
            }
        },
    });
    const { promise: getAntecedentRequestList } = useAsync({
        promise: getAntecedentRequestOptionApi,
        resolve: res => {
            storeDispatch(setAntecedentRequestOption(res));
        },
    });
    const { promise: editCureRecord } = useAsync({
        promise: updateCureRecord,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                onPageHandle();
            }
        },
    });
    const { promise: activityCureRecord } = useAsync({
        promise: updateCureRecordApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                onPageHandle();
            }
        },
    });

    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BS_RECORD_DRESSING', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setBsRecordDressing(res));
        },
    });

    useEffect(() => {
        getCureAssessment({ recordNum });
        getAntecedentRequestList({ targetNum });
        return () => {
            storeDispatch(setNurseInfo(null));
        };
    }, []);

    const onPageHandle = () => {
        history.goBack();
    };

    const handleSave = () => {
        //성공여부 확인후 페이지 이동
        const {
            cm,
            jam,
            trtcts,
            reportNum,
            bsRecordDressingCode,
            recordNum,
            bsRecordDressingCodeName,
            exeDate,
        } = treatmentSkinRecordInfo;
        if (exeDate) {
            editCureRecord({
                targetNum,
                psNum,
                reportNum,
                recordNum,
                exeStatusCode: 'C',
                trtDate: nurseInfo.exeDate,
                cm,
                jam,
                trtcts,
                bsRecordDressingCode,
                bsRecordDressingCodeName,
                regId: nurseInfo.exeUserNum,
                regName: nurseInfo.exeUserName,
                ...nurseInfo,
            });
        } else {
            activityCureRecord({
                targetNum,
                psNum,
                reportNum,
                recordNum,
                exeStatusCode: 'C',
                trtDate: nurseInfo.exeDate,
                cm,
                jam,
                trtcts,
                bsRecordDressingCode,
                bsRecordDressingCodeName,
                regId: nurseInfo.exeUserNum,
                regName: nurseInfo.exeUserName,
                ...nurseInfo,
            });
        }
    };
    const handleEdit = () => {
        setRecordState(!recordState);
    };
    const [startDate, setStartDate] = useState();
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    const [dressingState, setDressingState] = useState({ label: t('Select'), value: 0 });
    const handleSelectValueChange = selected => {
        const { value } = selected;
        dispatch(updateTreatmentSkinRecordInfo({ bsRecordDressingCode: value }));
        setDressingState(selected);
    };
    const [requestState, setRequestState] = useState({ label: t('Select'), value: 0 });
    const handleSelectRequestValueChange = selected => {
        const { label, recordNum } = selected;
        setRequestState({ label, value: recordNum });
        getCureAssessment({ recordNum });
    };

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const onHandleTextInput = e => {
        const { value, name } = e.target;
        dispatch(updateTreatmentSkinRecordInfo({ [name]: value }));
    };

    const handleInputCheckChange = e => {
        const { checked, name } = e.currentTarget;
        dispatch(updateTreatmentSkinRecordInfo({ [name]: checked ? 'Y' : 'N' }));
    };

    return (
        <>
            <ActiveRecordCard
                headerTitle={`${t('Edit History')} : ${t(actTypeCodeName, 'ActTypeCode')}>${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={[
                    recordState
                        ? {
                              onClick: handleEdit,
                              borderOption: true,
                              label: t('Edit'),
                              className: 'icon-editnote',
                              iconBtnClass: 'btn-icon',
                          }
                        : {
                              onClick: handleSave,
                              borderOption: true,
                              label: t('Save'),
                              className: 'icon-save',
                              iconBtnClass: 'btn-icon',
                          },
                ]}
                url={`${url.wmsUrl}/v2/html/aum/guide/3.html`}
                spanIcon={['icon-info']}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse')}
                        component={
                            <HandleNurseTextInput
                                name={t('Nurse')}
                                className={'d-flex'}
                                readOnly={nurseState}
                                onClick={toggleModal}
                                placeholder={`${t('Nurse')} | ${t('Number')}`}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    treatmentSkinRecordInfo &&
                                    treatmentSkinRecordInfo.hasOwnProperty('exeUserName') &&
                                    treatmentSkinRecordInfo.exeUserName &&
                                    `${treatmentSkinRecordInfo.exeUserName} ||${treatmentSkinRecordInfo.exeUserNum}`
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('When performed')}
                        component={
                            <HandleDateInput
                                value={startDate}
                                readOnly={nurseState}
                                text={nurseInfo && nurseInfo.exeDate}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Preceding request')}
                        className="col-xl-12 col-lg-12 mb-4"
                        component={
                            <HandleReactSelect
                                readOnly={nurseState}
                                options={antecedentRequestOption.rows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                value={requestState}
                                text={
                                    treatmentSkinRecordInfo &&
                                    `${treatmentSkinRecordInfo.bsHappPartCodeName}-${dateChange(
                                        treatmentSkinRecordInfo.happDate,
                                    )}-${dateChange(treatmentSkinRecordInfo.reqDate)}`
                                }
                                onChange={handleSelectRequestValueChange}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Occurrence site')}
                        className="col-xl-12 col-lg-12 mb-4"
                        component={<span>{treatmentSkinRecordInfo && treatmentSkinRecordInfo.bsHappPartCodeName}</span>}
                    />
                    <DescriptionRow
                        label={t('Categorizing Requests')}
                        component={
                            <span>{treatmentSkinRecordInfo && treatmentSkinRecordInfo.bsReportDivisionCodeName}</span>
                        }
                    />

                    <DescriptionRow
                        label={t('Pressure Ulcer Stage')}
                        component={
                            <span>{treatmentSkinRecordInfo && treatmentSkinRecordInfo.bsRecordGradeCodeName}</span>
                        }
                    />
                    <DescriptionRow
                        label={t('Request Date')}
                        component={
                            <span>{treatmentSkinRecordInfo && dateChange(treatmentSkinRecordInfo.reqDate)}</span>
                        }
                    />

                    <DescriptionRow
                        label={t('Occurrence Date')}
                        component={
                            <span> {treatmentSkinRecordInfo && dateChange(treatmentSkinRecordInfo.happDate)}</span>
                        }
                    />
                    <DescriptionRow
                        label={t('Occurred in')}
                        component={
                            <span> {treatmentSkinRecordInfo && treatmentSkinRecordInfo.bsHappPlaceCodeName}</span>
                        }
                    />
                    <DescriptionRow
                        label={t('LengthXWidth')}
                        component={
                            <HandleTextInput
                                name={'cm'}
                                placeholder={t('Please enter length x width')}
                                onChange={onHandleTextInput}
                                readOnly={recordState}
                                value={treatmentSkinRecordInfo && treatmentSkinRecordInfo.cm}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Undermining Fistula Yes/No')}
                        component={
                            <HandleCheckbox
                                name={'jam'}
                                value={'Y'}
                                checked={treatmentSkinRecordInfo && treatmentSkinRecordInfo.jam === 'Y'}
                                onChange={handleInputCheckChange}
                                readOnly={nurseState}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Dressing Type')}
                        component={
                            <HandleReactSelect
                                options={bsRecordDressing.rows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleSelectValueChange}
                                value={dressingState}
                                text={treatmentSkinRecordInfo && treatmentSkinRecordInfo.bsRecordDressingCodeName}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Treatment Details')}
                        component={
                            <HandleTextInput
                                name={'trtcts'}
                                readOnly={recordState}
                                placeholder={t('Please enter the treatment.')}
                                onChange={onHandleTextInput}
                                value={treatmentSkinRecordInfo && treatmentSkinRecordInfo.trtcts}
                            />
                        }
                    />
                </div>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
            </ActiveRecordCard>
        </>
    );
};

export default EditTreatmentRecord;
