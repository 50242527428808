import React, { Fragment } from 'react';
import cx from 'classnames';

function SubRows({ row, rowProps, data }) {
    return (
        <>
            {data.map((x, i) => {
                return (
                    <Fragment key={i}>
                        <div
                            {...rowProps}
                            style={{
                                left: '0px',
                                top: '0px',
                                width: '100%',
                                display: 'flex',

                                backgroundColor: '#d8e0fe',
                            }}
                            className={cx('tr')}
                        >
                            {row.cells.map(cell => {
                                const { style: cellStyle, ...cellProps } = cell.getCellProps({
                                    style: cell.column.style,
                                });
                                return (
                                    <div
                                        {...cellProps}
                                        className="td"
                                        style={{
                                            ...cellStyle,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                            flex: `${cellStyle.width.replace('px', '').replace('%', '')} 0 auto`,
                                        }}
                                    >
                                        {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                                            value: cell.column.accessor && cell.column.accessor(x, i),
                                            row: { ...row, original: x },
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </Fragment>
                );
            })}
        </>
    );
}

export function SubRowAsync({ row, rowProps, visibleColumns }) {
    const subRows = row.original.list || [];
    return <SubRows row={row} rowProps={rowProps} visibleColumns={visibleColumns} data={subRows} />;
}
