import React, { useEffect, useReducer, useState } from 'react';
import ActiveRecordCard from '../../../Components/ActiveRecordCard';
import ReactSelect from '../../../../Common/Select';
import TextInput from '../../../../Common/Input/TextInput';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import {
    setActivityOption,
    setActivitySecondOption,
    setExecutionCycleOption,
    setExecutionCycleTypeOption,
} from '../../../../../reducers/Common/PerformanceRecordOption';
import { useDispatch, useSelector } from 'react-redux';
import settingReducer, { commonInitialState, setExecutionCycleInfo, setExecutionCycleType } from '../../settingReducer';
import { HandleReactSelect } from '../../../util/componentChange/componentChange';
import activitySettingReducer, {
    initialState,
    setActivityTypeOption,
    setActivityTypeSecondOption,
    setRiskGradeInfoOption,
    updateActivityScheduleInfo,
} from '../activitySettingReducer';
import { createActivitySettingApi } from '../../../../../api/activitySetting';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../../../Components/ConfirmModal';
import { TextChange, validationCheck } from '../../../ActiveSchedule/util/Validation';
import useTranslation from '../../../../../util/hooks/useTranslation';

const ActivitySettingAdd = () => {
    //리덕스
    const t = useTranslation('Activity Setting');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const { executionCycleOption, activityOption, activitySecondOption, executionCycleTypeOption } = useSelector(
        state => state.PerformanceRecordOption,
    );
    const { profile } = useSelector(state => state.UserInfo);
    const { single, repeat } = executionCycleOption;

    //리듀서
    const [commonState, commonDispatch] = useReducer(settingReducer, commonInitialState);
    const [state, dispatch] = useReducer(activitySettingReducer, initialState);

    const { activityScheduleInfo, riskGradeInfoOption, activityTypeOption, activityTypeSecondOption } = state;
    const { executionCycleInfo, executionCycleType } = commonState;
    const { type } = executionCycleType;

    const { promise: getExecutionCycleOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleInfo(data));
        },
    });

    const { promise: getExecutionCycleTypeOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleTypeOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleType({ type: data, executionCycleOption }));
        },
    });
    const { promise: getActivityTypeOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            dispatch(setActivityTypeOption(res));
        },
    });

    const { promise: getActivityTypeSecondOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            dispatch(setActivityTypeSecondOption(res));
        },
    });
    const { promise: getRiskGrade } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            dispatch(setRiskGradeInfoOption(res));
        },
    });
    const { promise: getActivityOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivityOption({ res, profile }));
        },
    });

    const { promise: getActivitySecondOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivitySecondOption(res));
        },
    });
    useEffect(() => {
        getActivityOption({ clCode: 'ACT_TYPE', codeDepth: '2' });
        getActivitySecondOption({ clCode: 'BS_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_EDU', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_REPORT', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_EDU_IV', codeDepth: '3' });
        getExecutionCycleOption({ clCode: 'EXE_CYCLE', codeDepth: '2' });
        getExecutionCycleTypeOption({ clCode: 'EXE_CYCLE_TYPE', codeDepth: '2' });
        getActivityTypeOption({ clCode: 'EXE_TYPE', codeDepth: '2' });
        getActivityTypeSecondOption({ clCode: 'REPEAT', codeDepth: '3' });
        getActivityTypeSecondOption({ clCode: 'RANDOM', codeDepth: '3' });
        getRiskGrade({ clCode: 'BS_RISK', codeDepth: '3' });
        getRiskGrade({ clCode: 'BF_RISK', codeDepth: '3' });
    }, []);

    const [day, setDay] = useState({ value: '일요일', label: t('Sunday', 'Common Columns') });
    const onChange = selected => {
        const { value, label } = selected;
        setDay({ value, label });
        dispatch(updateActivityScheduleInfo({ exeCycleVal: value }));
    };

    const initOption = { label: t('Select an activity classification', 'Active Dashboard'), value: 0 };
    const [option, setOption] = useState(initOption);
    const [secondOption, setSecondOption] = useState(initOption);
    const [activityTypeState, setActivityTypeState] = useState(initOption);
    const [secondActivityState, setSecondActivityState] = useState(initOption);
    const [riskGrade, setRiskGrade] = useState({ label: t('Select', 'Common Columns'), value: 0 });
    const [wardSettingState, setWardSettingState] = useState({ label: t('Select', 'Common Columns'), value: 0 });
    const [activityCycle, setActivityCycle] = useState({ label: t('Select', 'Common Columns'), value: 0 });
    const [activityCycleSecond, setActivityCycleSecond] = useState({ label: t('Select', 'Common Columns'), value: 0 });
    const [validation, setValidation] = useState({
        actTypeCode: ' 활동',
        actItemCode: '활동수행',
        exeTypeCode: '수행조건',
        exeTypeDetailCode: '수행상세조건',
        riskGradeCodes: '위험분류',
        exeCycleTypeCode: '수행주기',
        exeCycleCode: '수행상세주기',
        patientGroupFlag: '병동별 설정가능 여부',
        readyMin: '미리알림설정',
        delayMin: '지연알림설정',
    });
    const [validationResultState, setValidationResultState] = useState([]);
    const weekOption = [
        { value: '일요일', label: t('Sunday', 'Common Columns') },
        { value: '월요일', label: t('Monday', 'Common Columns') },
        { value: '화요일', label: t('Tuesday', 'Common Columns') },
        { value: '수요일', label: t('Wednesday', 'Common Columns') },
        { value: '목요일', label: t('Thursday', 'Common Columns') },
        { value: '금요일', label: t('Friday', 'Common Columns') },
        { value: '토요일', label: t('Saturday', 'Common Columns') },
    ];
    const handleSelectValueChange = e => {
        const { name, label, value } = e;
        dispatch(updateActivityScheduleInfo({ [name]: value }));
        setOption({ label, value });
        setValidation({ ...validation, actTypeCode: null });
        setSecondOption({ label: t('Select an activity'), value: 0 });
    };

    //select value Change
    const handleSelectSecondValueChange = e => {
        const { label, value } = e;
        dispatch(updateActivityScheduleInfo({ actItemCode: value }));
        setValidation({ ...validation, actItemCode: null });
        setSecondOption({ label, value });
    };

    const optionChange = optionData => {
        if (activityScheduleInfo && activityScheduleInfo.actTypeCode) {
            return optionData[activityScheduleInfo.actTypeCode].map(ele => {
                return {
                    ...ele,
                    label: t(ele.label, 'ActTypeCode'),
                };
            });
        } else {
            return optionData.row;
        }
    };
    const activityOptionChange = optionData => {
        if (activityTypeState && activityTypeState.value === 'REPEAT') {
            return optionData.REPEAT.map(ele => {
                return {
                    ...ele,
                    label: t(ele.label, 'ActTypeCode'),
                };
            });
        } else if (activityTypeState && activityTypeState.value === 'RANDOM') {
            return optionData.RANDOM.map(ele => {
                return {
                    ...ele,
                    label: t(ele.label, 'ActTypeCode'),
                };
            });
            // } else if (activityTypeState && activityTypeState.value === 'HIGHRISK') {
            //     return optionData.HIGHRISK;
        } else if (activityTypeState && activityTypeState.value === 'NORMAL') {
            return optionData.NORMAL.map(ele => {
                return {
                    ...ele,
                    label: t(ele.label, 'ActTypeCode'),
                };
            });
        } else {
            return [{ label: t('Select an activity classification', 'Active Dashboard'), value: 0 }];
        }
    };

    const { promise: addActivitySetting } = useAsync({
        promise: createActivitySettingApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                handleBackPage();
            }
        },
    });
    const handleSave = () => {
        let validationResult = [];
        validationResult = validationCheck(validation);
        if (!validationResult.length) {
            addActivitySetting({ ...activityScheduleInfo });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };
    const handleBackPage = () => {
        history.goBack();
    };
    const [placeholder, setPlaceholder] = useState(null);
    const placeholderChange = selected => {
        const { value } = selected;
        if (value === 601) {
            setPlaceholder(t('Please enter a time'));
        }
        if (value === 602) {
            setPlaceholder(t('Please enter a time'));
        }
        if (value === 603) {
            setPlaceholder(t('Please enter a day'));
        }
        if (value === 604) {
            setPlaceholder(t('Please enter a week'));
        }
    };

    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };

    const activityOptionRows = activityOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const activityTypeOptionRows = activityTypeOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const riskGradeInfoOptionBS_RISK = riskGradeInfoOption.BS_RISK.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const riskGradeInfoOptionBF_RISK = riskGradeInfoOption.BF_RISK.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const executionCycleTypeOptionRows = executionCycleTypeOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const singleRows = single.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const repeatRows = repeat.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        <ActiveRecordCard
            headerTitle={t('Set Periodicity for Activities')}
            buttonOption={[
                { label: t('Back', 'Active Dashboard'), onClick: handleBackPage, color: 'btn-gray' },
                {
                    label: t('Save', 'Active Dashboard'),
                    onClick: handleSave,
                    iconBtnClass: 'btn-icon',
                    color: 'btn-blue',
                    className: 'icon-save',
                },
            ]}
            headerTitleSub={t(
                'Ability to set separate pressure ulcer/fall activity conditions and activity frequency.',
            )}
        >
            <div className={'flx-col gap-2 pnt-label-8 '}>
                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Activities', 'Common Columns')}</span>
                    <span className="label-options">
                        <HandleReactSelect
                            options={activityOptionRows}
                            isMulti={false}
                            closeMenuOnSelect={false}
                            onChange={handleSelectValueChange}
                            value={option}
                            customControlStyles={{ width: '200px' }}
                        />
                        <HandleReactSelect
                            options={optionChange(activitySecondOption)}
                            isMulti={false}
                            closeMenuOnSelect={false}
                            onChange={handleSelectSecondValueChange}
                            value={secondOption}
                            customControlStyles={{ width: '200px' }}
                        />
                    </span>
                </div>

                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Performance Conditions', 'Active Dashboard')}</span>
                    <span className="label-options">
                        <HandleReactSelect
                            value={activityTypeState}
                            options={activityTypeOptionRows}
                            onChange={selected => {
                                const { label, id, value } = selected;
                                setActivityTypeState({ label, value });
                                setSecondActivityState({ label: t('Select an activity'), value: 0 });
                                dispatch(updateActivityScheduleInfo({ exeTypeCode: id }));
                                setValidation({ ...validation, exeTypeCode: null });
                            }}
                            isMulti={false}
                            closeMenuOnSelect={false}
                            customControlStyles={{ width: '200px' }}
                        />

                        <HandleReactSelect
                            options={activityOptionChange(activityTypeSecondOption)}
                            value={secondActivityState}
                            isMulti={false}
                            closeMenuOnSelect={false}
                            onChange={selected => {
                                const { label, id, value } = selected;
                                setSecondActivityState({ label, value });
                                dispatch(updateActivityScheduleInfo({ exeTypeDetailCode: id }));
                                setValidation({ ...validation, exeTypeDetailCode: null });
                            }}
                            customControlStyles={{ width: '200px' }}
                        />
                    </span>
                </div>
                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Risk classification', 'Common Columns')}</span>
                    <span className="label-options">
                        <ReactSelect
                            name={1}
                            value={riskGrade}
                            options={
                                (activityTypeState.value === 0 && [
                                    { label: t('Select', 'Common Columns'), value: 0 },
                                ]) ||
                                (activityTypeState.value === 'REPEAT' && riskGradeInfoOptionBS_RISK) ||
                                (activityTypeState.value === 'RANDOM' && riskGradeInfoOptionBS_RISK) ||
                                riskGradeInfoOptionBF_RISK
                            }
                            onChange={selected => {
                                const { value, label } = selected;
                                dispatch(updateActivityScheduleInfo({ riskGradeCodes: value }));
                                setRiskGrade({ label, value });
                                setValidation({ ...validation, riskGradeCodes: null });
                            }}
                            customControlStyles={{ width: '200px' }}
                        />
                    </span>
                </div>

                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Performance cycle', 'Common Columns')}</span>
                    <span className="label-options">
                        {executionCycleTypeOption.rows.length > 0 && executionCycleOption.single.length > 0 && type && (
                            <>
                                <ReactSelect
                                    options={executionCycleTypeOptionRows}
                                    value={activityCycle}
                                    onChange={selected => {
                                        const { label, codeName, value } = selected;
                                        dispatch(updateActivityScheduleInfo({ exeCycleTypeCode: codeName }));
                                        commonDispatch(setExecutionCycleType({ type: selected, executionCycleOption }));
                                        setActivityCycle({ label, value });
                                        setActivityCycleSecond({ label: t('Select', 'Common Columns'), value: 0 });
                                        setValidation({ ...validation, exeCycleTypeCode: null });
                                        setPlaceholder(null);
                                    }}
                                    customControlStyles={{ width: '200px' }}
                                />
                                <ReactSelect
                                    options={executionCycleType && type.label === '단일' ? singleRows : repeatRows}
                                    value={activityCycleSecond}
                                    onChange={selected => {
                                        const { label, codeValue, value } = selected;
                                        placeholderChange(selected);
                                        dispatch(updateActivityScheduleInfo({ exeCycleCode: codeValue }));
                                        commonDispatch(setExecutionCycleInfo(selected));
                                        setActivityCycleSecond({ label, value });
                                        setValidation({ ...validation, exeCycleCode: null });
                                    }}
                                    customControlStyles={{ width: '150px' }}
                                />
                            </>
                        )}
                        {executionCycleInfo.optionData &&
                            ['EXE_CYCLE_1', 'EXE_CYCLE_2', 'EXE_CYCLE_3', 'EXE_CYCLE_4', 'EXE_CYCLE_7'].includes(
                                executionCycleInfo.optionData.codeValue,
                            ) && (
                                <TextInput
                                    name={'exeCycleVal'}
                                    type={'number'}
                                    placeholder={placeholder}
                                    handleChange={e => {
                                        const { name, value } = e.target;
                                        dispatch(updateActivityScheduleInfo({ [name]: value }));
                                    }}
                                    min={0}
                                    max={99999}
                                />
                            )}

                        {executionCycleInfo.optionData &&
                            ['EXE_CYCLE_5'].includes(executionCycleInfo.optionData.codeValue) && (
                                <>
                                    <ReactSelect
                                        options={hourOption}
                                        onChange={selected => {
                                            const { value } = selected;
                                            dispatch(updateActivityScheduleInfo({ exeCycleVal: value }));
                                        }}
                                    />
                                    <ReactSelect
                                        options={hourOption}
                                        onChange={selected => {
                                            const { value } = selected;
                                            dispatch(updateActivityScheduleInfo({ exeCycleVal2: value }));
                                        }}
                                    />
                                    <ReactSelect
                                        options={hourOption}
                                        onChange={selected => {
                                            const { value } = selected;
                                            dispatch(updateActivityScheduleInfo({ exeCycleVal3: value }));
                                        }}
                                    />
                                </>
                            )}
                        {executionCycleInfo.optionData &&
                            ['EXE_CYCLE_6'].includes(executionCycleInfo.optionData.codeValue) && (
                                <ReactSelect value={day} options={weekOption} onChange={onChange} />
                            )}
                    </span>
                </div>

                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Can be set per ward')}</span>
                    <span className="label-options">
                        <ReactSelect
                            value={wardSettingState}
                            options={wardSettingOption}
                            onChange={selected => {
                                const { value, label } = selected;
                                dispatch(updateActivityScheduleInfo({ patientGroupFlag: value }));
                                setWardSettingState({ label, value });
                                setValidation({ ...validation, patientGroupFlag: null });
                            }}
                            customControlStyles={{ width: '200px' }}
                        />
                    </span>
                </div>

                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Setting up reminders')}</span>
                    <span className="label-options">
                        <TextInput
                            name={'readyMin'}
                            type={'number'}
                            placeholder={t('minute ago')}
                            handleChange={e => {
                                const { name, value } = e.target;
                                dispatch(updateActivityScheduleInfo({ [name]: value }));
                                setValidation({ ...validation, [name]: null });
                            }}
                            min={0}
                            max={99999}
                        />
                    </span>
                </div>

                <div className={'pnt-label--group'}>
                    <span className="label-main label-dot">{t('Setting up delayed notifications')}</span>
                    <span className="label-options">
                        <TextInput
                            name={'delayMin'}
                            type={'number'}
                            placeholder={t('minutes after')}
                            min={0}
                            max={99999}
                            handleChange={e => {
                                const { name, value } = e.target;
                                dispatch(updateActivityScheduleInfo({ [name]: value }));
                                setValidation({ ...validation, [name]: null });
                            }}
                        />
                    </span>
                </div>
            </div>
            <ConfirmModal
                initModal={confirmModalState}
                headerText={t('Required fields Item', 'Evaluation')}
                modalToggle={toggleConfirmModal}
                confirmText={TextChange(validationResultState)}
            />
        </ActiveRecordCard>
    );
};

const hourOption = [
    { value: '00:00', label: '00:00' },
    { value: '00:30', label: '00:30' },
    { value: '01:00', label: '01:00' },
    { value: '01:30', label: '01:30' },
    { value: '02:00', label: '02:00' },
    { value: '02:30', label: '02:30' },
    { value: '03:00', label: '03:00' },
    { value: '03:30', label: '03:30' },
    { value: '04:00', label: '04:00' },
    { value: '04:30', label: '04:30' },
    { value: '05:00', label: '05:00' },
    { value: '05:30', label: '05:30' },
    { value: '06:00', label: '06:00' },
    { value: '06:30', label: '06:30' },
    { value: '07:00', label: '07:00' },
    { value: '07:30', label: '07:30' },
    { value: '08:00', label: '08:00' },
    { value: '08:30', label: '08:30' },
    { value: '09:00', label: '09:00' },
    { value: '09:30', label: '09:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
    { value: '21:00', label: '21:00' },
    { value: '21:30', label: '21:30' },
    { value: '22:00', label: '22:00' },
    { value: '22:30', label: '22:30' },
    { value: '23:00', label: '23:00' },
    { value: '23:30', label: '23:30' },
];

const wardSettingOption = [
    { value: 'Y', label: 'Y', name: 'patientGroupFlag' },
    { value: 'N', label: 'N', name: 'patientGroupFlag' },
];
export default ActivitySettingAdd;
