import React, { useState, useMemo, createContext, useReducer, useEffect } from 'react';
import Select from 'react-select';
import { defaultStyle } from '../selectStyle';
import { Dropdown, ValueContainer, DropdownIndicator, TreeMenuList } from './Parts';

const selectStyles = {
    ...defaultStyle,
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
    }),
    control: provided => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
        margin: 8,
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
    menuList: provided => ({
        ...provided,
        maxWidth: '17rem',
    }),
};

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_FLATTEN_DATA':
            const { valueKey, labelKey, parentKey } = state;
            const flattenData = action.payload.map(v => ({
                ...v,
                value: v[valueKey],
                label: v[labelKey],
            }));
            return {
                ...state,
                flattenData: flattenData,
                treeData: makeTreeData(
                    flattenData.filter(v => !v[parentKey]),
                    flattenData.filter(v => !!v[parentKey]),
                    valueKey,
                    parentKey,
                ),
            };
        case 'UPDATE_CHECKED_NODE':
            return { ...state, checked: action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

function addLeafNode(treeData, dataToAdd, valueKey, parentKey) {
    const parent = dataToAdd[parentKey];
    let find = false;

    // 자식 탐색
    treeData.every(v => {
        if (v[valueKey] === parent) {
            if (v.children) {
                v.children.push(dataToAdd);
            } else {
                v.children = [dataToAdd];
            }
            find = true;
            return false;
        }
        return true;
    });

    // 자식 탐색 실패시 자식의 자식 탐색
    if (!find) {
        treeData.every(v => {
            if (v.children) {
                find = addLeafNode(v.children, dataToAdd, valueKey, parentKey);
                if (find) {
                    return false;
                }
            }
            return true;
        });
    }
    return find;
}
function makeTreeData(treeData = [], flattenData, valueKey, parentKey) {
    const dataToAdd = [...flattenData];
    const restData = [];

    if (treeData.length) {
        while (dataToAdd.length > 0) {
            const curr = dataToAdd.shift();
            const found = addLeafNode(treeData, curr, valueKey, parentKey);
            if (!found) {
                restData.push(curr);
            }
        }
    }

    if (restData.length > 0) {
        makeTreeData(treeData, restData, valueKey, parentKey);
    }
    return treeData;
}

export const TreeSelectContext = createContext();

const TreeSelect = ({
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    parentKey = 'parent',
    onChange,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        title,
        labelKey,
        valueKey,
        parentKey,
        flattenData: data,
        treeData: [],
    });
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];
    const filterOption = ({ label }, string) => ~label.toLowerCase().indexOf(string.toLowerCase());
    const selectedOptions = useMemo(() => {
        const selectedKeyArr = selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        });
        return data.reduce((acc, curr) => {
            if ((selectedKeyArr || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FLATTEN_DATA', payload: data });
    }, [data]);

    return (
        <TreeSelectContext.Provider value={state}>
            <Dropdown
                isOpen={isOpen}
                onClose={toggleOpen}
                target={
                    <ValueContainer title={title} isOpen={isOpen} selected={selectedOptions} onClick={toggleOpen} />
                }
            >
                <Select
                    isMulti
                    // autoFocus
                    backspaceRemovesValue={false}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        MenuList: TreeMenuList,
                        Control: () => null,
                    }}
                    controlShouldRenderValue={false}
                    // hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={onChange}
                    options={state.treeData}
                    styles={selectStyles}
                    tabSelectsValue={false}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    value={selectedOptions}
                    filterOption={filterOption}
                />
            </Dropdown>
        </TreeSelectContext.Provider>
    );
};

export default TreeSelect;
