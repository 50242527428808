import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import UserBox from './Components/UserBox';
import HeaderDots from './Components/HeaderDots';

import { CustomNav } from '../AppNav/NavItems';
import useTranslation from '../../../util/hooks/useTranslation';
import FallPreventionActivities from '../../MainPages/ActiveSchedule/Components/PerformanceRecord/Fall/FallPreventionActivities';
import FallPreventionAlarm from './Components/FallPreventionAlarm';

const initialGnbMenu = { menuName: '', subMenuName: '' };

const Header = () => {
    const t = useTranslation('Menu');
    const location = useLocation();
    const match = useRouteMatch(['/dashboards/:menuNum([0-9]+)', '/dashboards/edit/:menuNum([0-9]+)']);
    const [gnbMenu, setGnbMenu] = useState(initialGnbMenu);
    const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
        state => state.ThemeOptions,
    );
    const { bookmarkedMenu } = useSelector(state => state.CustomMenu);
    useEffect(() => {
        let pathname = location.pathname;
        if (pathname.includes('/dashboards/edit/')) {
            pathname = pathname.replace('/edit', '');
        }
        let foundGnbMenu = null;
        if (match && match.params.menuNum) {
            const menuNum = Number(match.params.menuNum);
            bookmarkedMenu.forEach(menu => {
                if (menu.menuNum === menuNum) {
                    foundGnbMenu = menu;
                    setGnbMenu({
                        menuName: 'Dashboards',
                        subMenuName: menu.menuName,
                        isCustom: true,
                    });
                    return false;
                }
            });
        } else {
            CustomNav.forEach(menu => {
                if (menu.content) {
                    const subMenu = menu.content;
                    const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
                    if (foundMenu) {
                        foundGnbMenu = foundMenu;
                        setGnbMenu({
                            menuName: menu.label,
                            subMenuName: foundMenu.label,
                            isCustom: foundMenu.id && foundMenu.id.includes('customDashboard_'),
                        });
                        return false;
                    }
                }
            });
        }

        if (!foundGnbMenu) {
            setGnbMenu(initialGnbMenu);
        }
    }, [location, CustomNav, bookmarkedMenu]);
    // useEffect(() => {
    //
    //     let pathname = location.pathname;
    //
    //     if (pathname.includes('/dashboards/edit/')) {
    //         pathname = pathname.replace('/edit', '');
    //     }
    //     const pathnameData = pathname.split("/")
    //     if(pathnameData.length >2) {
    //         console.log(pathnameData)
    //         setGnbMenu({
    //             menuName: pathnameData[1],
    //             subMenuName: pathnameData[2],
    //             isCustom: true,
    //         });
    //
    //     }else{
    //         if(pathname){
    //
    //         }else{
    //             let foundGnbMenu = null;
    //             if (match && match.params.menuNum) {
    //                 const menuNum = Number(match.params.menuNum);
    //                 bookmarkedMenu.forEach(menu => {
    //                     if (menu.menuNum === menuNum) {
    //                         foundGnbMenu = menu;
    //                         setGnbMenu({
    //                             menuName: 'Dashboards',
    //                             subMenuName: menu.menuName,
    //                             isCustom: true,
    //                         });
    //                         return false;
    //                     }
    //                 });
    //             } else {
    //                 CustomNav.forEach(menu => {
    //                     if (menu.content) {
    //                         const subMenu = menu.content;
    //                         const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
    //                         if (foundMenu) {
    //                             foundGnbMenu = foundMenu;
    //                             setGnbMenu({
    //                                 menuName: menu.label,
    //                                 subMenuName: foundMenu.label,
    //                                 isCustom: foundMenu.id && foundMenu.id.includes('customDashboard_'),
    //                             });
    //                             return false;
    //                         }
    //                     }
    //                 });
    //             }
    //
    //             if (!foundGnbMenu) {
    //                 setGnbMenu(initialGnbMenu);
    //             }
    //         }
    //     }
    //
    //
    //
    //
    //
    //
    // }, [location, CustomNav, bookmarkedMenu]);

    return (
        <ReactCSSTransitionGroup
            component="div"
            className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
        >
            <HeaderLogo />

            <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
                <div className="app-header-left">
                    {/*<SearchBox />*/}
                    {/*<MegaMenu />*/}
                    <span className={'menu-title'}>{gnbMenu.menuName ? `${t(gnbMenu.menuName)} >` : ''}</span>
                    <span className={'sub-menu-title'}>
                        {gnbMenu.subMenuName && (gnbMenu.isCustom ? gnbMenu.subMenuName : t(gnbMenu.subMenuName))}
                    </span>
                </div>
                <div className="app-header-right">
                    <FallPreventionAlarm />
                    <HeaderDots />
                    <UserBox />
                    {/*<HeaderRightDrawer />*/}
                </div>
            </div>
        </ReactCSSTransitionGroup>
    );
};

export default Header;
