import React from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
export const validation = values => {
    let resultText = [];
    if (values) {
        for (let key in values) {
            const value = values[key];

            if (key['actItemCode']) {
                resultText.push('활동');
            } else if (key['actTypeCode']) {
                resultText.push('활동');
            } else if (key['sdate']) {
                resultText.push('수행시작 일정');
            } else if (key['edate']) {
                resultText.push('수행마감 일정');
            }
        }
        return resultText;
    } else {
        resultText.push('활동', '수행시작 일정', '수행마감 일정');
        return resultText;
    }
};
export const validationCheck = values => {
    let resultText = [];
    for (let key in values) {
        const value = values[key];
        if (value) {
            resultText.push(value);
        }
    }
    return resultText;
};

export const TextChange = value => {
    const t = useTranslation('Group');
    return (
        <div style={{ alignItems: 'left' }}>
            {value.map((text, i) => (
                <div className={'pnt-label--group'}>
                    <div className={'label-main label-dot font-size-lg'} key={i}>
                        {`${t('Please enter')}${t(text, 'ActTypeCode')}${t('word')}`}
                    </div>
                    <br />
                </div>
            ))}
        </div>
    );
};
export const validationTest = (validationItem, nurseRecordState, nurseInfo) => {
    let item = [];
    if (!nurseRecordState) {
        item = validationCheck(validationItem);
        !nurseInfo.hasOwnProperty('exeUserName') && item.push('간호사');
        !nurseInfo.hasOwnProperty('exeDate') && item.push('수행일시');
    }
    return item;
};
