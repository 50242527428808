import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '../type';

const Button = ({
    className,
    disabled,
    onClick,
    reverseIconClassName,
    iconClassName,
    children,
}: ButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <button disabled={disabled} className={`pnt-btn ${className}`} onClick={onClick}>
            {iconClassName && <span className={iconClassName} />}
            {children}
            {reverseIconClassName && <span className={reverseIconClassName} />}
        </button>
    );
};

export default Button;
