import React from 'react';
import ReactSelect from '../../../../Common/Select';
import { Col } from 'reactstrap';
import useTranslation from "../../../../../util/hooks/useTranslation";

export const SingleSelect = ({ value, handleOptionChange }) => {
    const t = useTranslation('Search')
    const option = [
        { name: 'opt', label: t('Patient ID'), value: 'targetId' },
        { name: 'opt', label: t('Patient name'), value: 'targetName' },
    ];

    return (
        <ReactSelect
            name={0}
            placeholder={t('Patient ID or Name')}
            value={value}
            options={option}
            defaultValue={option}
            onChange={handleOptionChange}
            customControlStyles={{ width: '235px' }}
            customOptionStyles={{ width: '235px' }}
        />
    );
};

