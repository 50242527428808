import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_WARD_SETTING_INFO = 'wardSetting/SET_WARD_SETTING_INFO';
const SET_WARD_SETTING_LIST = 'wardSetting/SET_WARD_SETTING_LIST';
const UPDATE_WARD_SETTING_INFO = 'wardSetting/UPDATE_WARD_SETTING_INFO';
const SET_ACTIVITY_CYCLE_INFO_LIST = 'wardSetting/SET_ACTIVITY_CYCLE_INFO_LIST';
export const {
    setWardSettingInfo,
    setWardSettingList,
    updateWardSettingInfo,
    setActivityCycleInfoList,
} = createActions(
    {
        SET_WARD_SETTING_INFO: wardSettingInfo => wardSettingInfo,
        SET_WARD_SETTING_LIST: wardSettingList => wardSettingList,
        UPDATE_WARD_SETTING_INFO: wardSettingInfo => wardSettingInfo,
        SET_ACTIVITY_CYCLE_INFO_LIST: activityCycleInfoList => activityCycleInfoList,
    },
    { prefix: 'wardSetting' },
);

export const wardSettingInitialState = {
    wardSettingInfo: null,
    wardSettingList: { rows: [] },
    activityCycleInfoList: { rows: [] },
};

const wardSettingReducer = handleActions(
    {
        [SET_WARD_SETTING_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                // const { actTypeCodeName, actItemCodeName, exeTypeDetailCodeName, riskGradeCodesName } = data;
                // const actExeText = `${actTypeCodeName}|${actItemCodeName}|${exeTypeDetailCodeName}|${riskGradeCodesName}`;
                draft.wardSettingInfo = data;
            });
        },
        [UPDATE_WARD_SETTING_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.wardSettingInfo = { ...state.wardSettingInfo, ...data };
            });
        },
        [SET_WARD_SETTING_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.wardSettingList = action.payload;
            });
        },

        [SET_ACTIVITY_CYCLE_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                let filterData = [];
                filterData = data.rows.reduce((acc, curr, i) => {
                    acc.push({
                        label: curr.actExeText,
                        value: curr.configNum,
                    });
                    return acc;
                }, []);
                draft.activityCycleInfoList.rows = filterData;
            });
        },
    },
    wardSettingInitialState,
);
export default wardSettingReducer;
