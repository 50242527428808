import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropdownToggle, DropdownMenu, Col, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logOut } from '../../../../reducers/UserInfo';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchFpUnreadAlertCntApi, fetchNumOfUnreadAlert, fetchPsUnreadAlertCnt } from '../../../../api/alert';
import styled from 'styled-components';
import { fetchUserInfo } from '../../../../api/login';
import userImg from '../../../../assets/images/unknown_user.png';
import { useTranslation } from 'react-i18next';
import Button from '../../../Common/Button';
import background from '../../../../assets/images/background.jpg';
import ProfileModal from './UserBoxPopup/ProfileModal';
import AppSettingModal from './UserBoxPopup/AppSettingModal';
import LanguageSelectModal from './UserBoxPopup/LanguageSelectModal';

const WrappedProfile = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserName = styled.span`
    color: white;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.3rem;
`;
const UnreadMessage = styled.span`
    color: white;
    font-size: 0.8rem;
`;

const ProfileCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 10px;
    &:hover {
        background-color: #f5f5f5;
    }
`;

const SettingIconSpan = styled.span`
    background-size: 270px;
    transform: scale(2.5);
    background-color: white;
    border-radius: 0.2rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 20%);
`;

const SettingSpan = styled.span`
    font-size: 0.7rem;
    font-weight: bold;
    color: grey;
    margin-top: 1rem;
    text-align: center;
`;

export const ModalTitle = styled.span`
    font-weight: bold;
    font-size: 1rem;
`;

const UserBox = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [unreadAlert, setUnreadAlert] = useState(0);
    const [unreadAlertSecond, setUnreadAlertSecond] = useState(0);
    const [userInfo, setUserInfo] = useState({});

    const [profileModal, setProfileModal] = useState(false);
    const toggleProfileModal = () => {
        setProfileModal(!profileModal);
    };

    const [appSettingModal, setAppSettingModal] = useState(false);
    const toggleAppSettingModal = () => {
        setAppSettingModal(!appSettingModal);
    };

    const [languageSelectModal, setLanguageSelectModal] = useState(false);
    const toggleLanguageSelectModal = () => {
        setLanguageSelectModal(!languageSelectModal);
    };

    const handleLogout = useCallback(() => {
        dispatch(logOut());
    }, []);

    useEffect(() => {
        getUserInfo();
    }, []);
    // 욕창/낙상 지연 알마 카운트
    const { promise: getPsUnreadCnt } = useAsync({
        promise: fetchPsUnreadAlertCnt,
        resolve: res => {
            if (res.count) {
                setUnreadAlert(res.count);
            }
        },
        reject: error => {
            console.log(error);
        },
    });
    //낙상예방알람 카운트
    const { promise: getUnreadCnt } = useAsync({
        promise: fetchFpUnreadAlertCntApi,
        resolve: res => {
            if (res.count) {
                setUnreadAlertSecond(res.count);
            }
        },
        reject: error => {
            console.log(error);
        },
    });
    const { promise: getUserInfo } = useAsync({
        promise: fetchUserInfo,
        resolve: ({ userID, userName, userEmail }) => {
            setUserInfo({ userID, userName, userEmail });
        },
        reject: error => {
            console.log(error);
        },
    });

    const handleClick = () => {
        getPsUnreadCnt();
        getUnreadCnt();
    };

    return (
        <>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0 border-0" onClick={handleClick}>
                                    <img width={42} className="rounded-circle" src={userImg} alt="" />
                                    <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg" style={{ width: '400px' }}>
                                    <div
                                        className="dropdown-menu-header"
                                        style={{
                                            backgroundColor: 'rgba(30, 34, 90, 0.6)',
                                            borderBottom: '1px solid #9eade7',
                                        }}
                                    >
                                        <div className="dropdown-menu-header-inner p-0">
                                            <div
                                                className="menu-header-image opacity-2"
                                                style={{
                                                    backgroundImage: 'url(' + background + ')',
                                                }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div
                                                        className="widget-content-wrapper"
                                                        style={{ padding: '1rem 1.2rem 1rem 1.4rem' }}
                                                    >
                                                        {/*<div className="widget-content-left mr-3">*/}
                                                        {/*    <img*/}
                                                        {/*        width={42}*/}
                                                        {/*        className="rounded-circle"*/}
                                                        {/*        src={userImg}*/}
                                                        {/*        alt=""*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                        <div className="widget-content-left">
                                                            <WrappedProfile>
                                                                <UserName>{userInfo.userName}</UserName>
                                                                <UnreadMessage>
                                                                    {t('UserBox;There are')}
                                                                    <b>&nbsp;{unreadAlert + unreadAlertSecond}</b>
                                                                    &nbsp;
                                                                    {t('UserBox;unread messages')}
                                                                </UnreadMessage>
                                                            </WrappedProfile>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button
                                                                className={'btn-orange btn-shadow'}
                                                                onClick={handleLogout}
                                                            >
                                                                {t('UserBox;Logout')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-setting">
                                        <Row className="no-gutters">
                                            <ProfileCol sm onClick={toggleProfileModal}>
                                                <SettingIconSpan className="icon-profile-setting" />
                                                <SettingSpan>{t('UserBox;Profile setting')}</SettingSpan>
                                            </ProfileCol>
                                            <ProfileCol sm onClick={toggleAppSettingModal}>
                                                <SettingIconSpan className="icon-app-setting" />
                                                <SettingSpan>{t('UserBox;Application setting')}</SettingSpan>
                                                </ProfileCol>
                                            <ProfileCol sm onClick={toggleLanguageSelectModal}>
                                                <SettingIconSpan className="icon-language-setting" />
                                                <SettingSpan>{t('UserBox;Language setting')}</SettingSpan>
                                            </ProfileCol>
                                        </Row>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ml-3 header-user-info">
                            <div className="widget-heading">{userInfo.userName}</div>
                            {/*<div className="widget-subheading">VP People Manager</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <ProfileModal initModal={profileModal} toggleModal={toggleProfileModal} userInfo={userInfo} />
            <AppSettingModal initModal={appSettingModal} toggleModal={toggleAppSettingModal} />
            <LanguageSelectModal initModal={languageSelectModal} toggleModal={toggleLanguageSelectModal} />
        </>
    );
};

export default UserBox;
