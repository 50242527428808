import produce from 'immer';
import { defaultActionCreator } from '../../../util/common/util';

const SET_SEARCH_OPTION = 'SET_SEARCH_OPTION';
const SET_PREV_SEARCH_OPTION = 'SET_PREV_SEARCH_OPTION';
const SET_CODE_LIST = 'SET_CODE_LIST';

export const setSearchOption = param => defaultActionCreator(SET_SEARCH_OPTION, param);
export const setPrevSearchOption = param => defaultActionCreator(SET_PREV_SEARCH_OPTION, param);
export const setCodeList = param => defaultActionCreator(SET_CODE_LIST, param);

export const PAGE_SIZE = 10;

export const searchOptions = [
    { label: 'ALL', value: '' },
    { label: '분류 명', value: 'clCodeName' },
    { label: '코드 명', value: 'codeName' },
];

export const initialState = {
    searchOption: { opt: searchOptions[0], keyword: '' },
    prevSearchOption: {},
    codeListInfo: {
        rows: [],
        pageSize: PAGE_SIZE,
    },
};

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_SEARCH_OPTION:
            return produce(state, draft => {
                draft.searchOption = action.payload;
            });
        case SET_PREV_SEARCH_OPTION:
            return produce(state, draft => {
                draft.prevSearchOption = action.payload;
            });
        case SET_CODE_LIST:
            return produce(state, draft => {
                draft.codeListInfo = action.payload;
            });
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
