import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const StyledModalTitle = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.color || 'black'};
`;

const StyledFooter = styled.div`
    height: 5rem;
    background-color: #f8f9fa;
    border-top: 1px solid #d1d1d1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
`;
const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;
const CustomModal = ({
    handleClick = () => {},
    openModal,
    subTitle,
    onModalClickYes,
    content,
    headerTitle,
    headerClassName = 'black',
    btnClassName = 'btn-orange',
    titleClassName,
}) => {
    const { t } = useTranslation('Modal');

    return (
        <Modal isOpen={openModal}>
            <ModalHeader className={headerClassName} toggle={onModalClickYes}>
                <StyledModalTitle className={titleClassName}>{t(headerTitle)}</StyledModalTitle>
                {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
            <StyledFooter>
                <button className="pnt-btn color-orange btn-back mr-1 " onClick={onModalClickYes}>
                    {/*{t('Button;Close')}*/}
                    {t('Close')}
                </button>
                <button
                    className={`pnt-btn   border w-30 ${btnClassName}`}
                    onClick={() => {
                        handleClick();
                        onModalClickYes();
                    }}
                >
                    <span />
                    {/*{t('Button;Select')}*/}
                    {t('Confirm')}
                </button>
            </StyledFooter>
        </Modal>
    );
};

export default CustomModal;
