import React from 'react';
import Table from '../../../../Common/Table';
import useTranslation from '../../../../../util/hooks/useTranslation';

const FallPatientList = ({ data, handlePageChange, onTrClick, loadingState, columns }) => {
    const t = useTranslation('Common Columns');
    return (
        <>
            <Table
                onPageChange={handlePageChange}
                columns={columns}
                data={{
                    pageSize: 10,
                    totalCount: data.totalCount,
                    rows: data.rows,
                    totalPage: data.totalPage,
                }}
                loading={loadingState}
                onTrClick={onTrClick}
                noDataPlaceholder={t('Data does not exist.')}
            />
        </>
    );
};

export default FallPatientList;
