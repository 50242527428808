import React from 'react';
import styled from 'styled-components';
import { Portal } from 'react-overlays';

export const CalendarContainer = ({ children }) => {
    const el = document.getElementsByClassName('app-main__inner')[0];

    return <Portal container={el}>{children}</Portal>;
};

export const DatePickerContainer = styled('div')`
    & .react-datepicker__input-container .form-control {
        z-index: 10;
    }
    & .react-datepicker-wrapper {
        z-index: 0;
    }
    & .react-datepicker-popper {
        z-index: 500;
    }

    & .react-datepicker__navigation,
    & .react-datepicker__navigation:focus,
    & .react-datepicker__navigation:active {
        border: 0.45rem solid transparent;
    }
    & .react-datepicker__navigation--previous,
    & .react-datepicker__navigation--previous:focus,
    & .react-datepicker__navigation--previous:active {
        border-right-color: #ccc;
    }
    & .react-datepicker__navigation--next,
    & .react-datepicker__navigation--next:focus,
    & .react-datepicker__navigation--next:active {
        border-left-color: #ccc;
        right: 125px;
    }

    & .react-datepicker__input-time-container {
        width: initial;
        clear: initial;
        float: left;
        margin: initial;
        .react-datepicker-time__caption {
            display: none;
        }
        .react-datepicker-time__input-container .react-datepicker-time__input {
            margin: 0;
            border-left: #dee2e6 solid 1px;
            .react-datepicker__header.react-datepicker__header--time {
                border-bottom: #dee2e6 solid 1px;
                padding: 9px 5px;
            }
        }
        .time-label-box {
            background-color: #f8f9fa;
            display: flex;
            border-bottom: 1px solid #aeaeae;
            div {
                width: 50%;
                line-height: 1.7rem;
                text-align: center;
                padding: 0.166rem;
            }
            div:last-child {
                border-left: #dee2e6 solid 1px;
            }
        }
    }
    & .list-group {
        height: 210px;
        .list-wrap {
            height: 100%;
            overflow: auto;
            ::-webkit-scrollbar {
                width: 10px;
                background-color: #f5f5f5;
            }
            ::-webkit-scrollbar-track {
                background-color: #f5f5f5;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #686868;
                border: 1px solid #e9ecef;
                border-radius: 4px;
            }
        }
    }

    & .list-group-item {
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 2px;
        padding: 0.5rem 1rem;
    }
    & .list-group-item.active {
        background-color: #216ba5;
    }
`;
