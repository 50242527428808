import React, { useState } from 'react';
import Button from '../../../Common/Button';
import styled from 'styled-components';
import Card from '../../../Common/Card';
import { Row, Collapse } from 'reactstrap';
import ResizeDetector from 'react-resize-detector';
import useTranslation from "../../../../util/hooks/useTranslation";

const FilterCard = styled(Card)`
    z-index: 1;
    .card-body {
        padding: 0;
        overflow: visible;
        // background: linear-gradient(90deg, rgba(240, 240, 245, 1) 0%, rgba(240, 240, 245, 0.5) 100%);
    }
`;

const FilterRow = styled(Row)`
    margin: 0;
    padding: 0.3rem 1rem;
    justify-content: space-between;
    flex-wrap: nowrap;
    background: linear-gradient(90deg, rgba(240, 240, 245, 1) 0%, rgba(240, 240, 245, 0.5) 100%);
`;

const MobileFilterRow = styled(Row)`
    margin: 0.3rem 0 0;
    padding-top: 0.8rem;
    border-top: 1px solid #e6dede;
    justify-content: space-evenly;
`;

const FilterTitle = styled.div`
    // display: flex;
    // flex: 0 0 auto;
    // justify-content: center;
    // align-items: center;
    // margin-right: 0.5rem;
    // text-transform: capitalize;
    // line-height: 1.3;
    // font-size: 0.8rem;
    // font-weight: 700;
`;
const FilterBox = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
`;
const FilterAction = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
`;

export const RESPONSIVE_FILTER_WIDTH = 690;
const Filter = ({ reset, children, Search }) => {
    const t = useTranslation('Filter');
    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                return (
                    <>
                        <FilterCard>
                            <FilterRow>
                                <FilterTitle className="filter-title">{t('Filter')}</FilterTitle>
                                {!isSmall && <FilterBox>{children}</FilterBox>}

                                <FilterAction>
                                    <Button
                                        className="pnt-btn mr-1 btn-danger btn-icon-only"
                                        onClick={reset}
                                        iconClassName={'icon-refresh'}
                                    />
                                    {isSmall && (
                                        <Button
                                            className="pnt-btn btn-gray btn-icon-only btn-filter-open"
                                            onClick={toggleFilter}
                                            iconClassName={'icon-down'}
                                        />
                                    )}
                                </FilterAction>
                            </FilterRow>
                            {isSmall && (
                                <Collapse isOpen={filterOpen}>
                                    <MobileFilterRow>{children}</MobileFilterRow>
                                </Collapse>
                            )}
                            {!!Search && Search}
                        </FilterCard>
                    </>
                );
            }}
        />
    );
};

export default Filter;
