import { createSlice } from '@reduxjs/toolkit';
import { CustomNav } from '../Components/Layout/AppNav/NavItems';

const initialState = {
    bookmarkedMenu: [],
    menus: CustomNav,
};

const CustomMenu = createSlice({
    name: 'customMenu',
    initialState,
    reducers: {
        setBookmarkMenu: (state, action) => {
            state.bookmarkedMenu = [...action.payload].sort((a, b) => a.order - b.order);
        },
        setMenus: (state, action) => {
            state.menus = action.payload;
        },
    },
});

export const { setBookmarkMenu, setMenus } = CustomMenu.actions;
export default CustomMenu.reducer;
