import React, { useState, useMemo, createContext, useReducer } from 'react';
import Select from 'react-select';
import { defaultStyle } from '../selectStyle';
import { Dropdown, ValueContainer, DropdownIndicator, Control, Option, MenuList } from '../Components/Parts';
import { useTranslation } from 'react-i18next';

const selectStyles = menuStyle => {
    return {
        ...defaultStyle,
        container: (provided, state) => ({
            ...provided,
            minWidth: '11.5rem',
            maxWidth: '17rem',
            ...menuStyle,
        }),
        control: provided => ({
            ...provided,
            minWidth: '11.5rem',
            maxWidth: '14rem',
            margin: 8,
        }),
        menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
        menuList: provided => ({
            ...provided,
            maxWidth: '17rem',
        }),
    };
};

export const SearchableSelectContext = createContext();

const SearchableSelect = ({
    className,
    defaultLabel,
    valueContainerStyle,
    menuStyle,
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    onChange,
    name,
}) => {
    const [state] = useReducer(null, { title, labelKey, valueKey });
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];
    //const filterOption = ({ label }, string) => ~label.toLowerCase().indexOf(string.toLowerCase());
    const selectedOptions = useMemo(() => {
        return data.reduce((acc, curr) => {
            if ((selected || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    return (
        <SearchableSelectContext.Provider value={state}>
            <Dropdown
                className={className}
                isOpen={isOpen}
                onClose={toggleOpen}
                target={
                    <ValueContainer
                        title={title}
                        defaultLabel={defaultLabel}
                        style={valueContainerStyle}
                        labelKey={labelKey}
                        isOpen={isOpen}
                        selected={selectedOptions}
                        onClick={toggleOpen}
                    />
                }
            >
                <Select
                    isMulti
                    // autoFocus
                    backspaceRemovesValue={false}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        MenuList,
                        Option,
                        Control,
                    }}
                    controlShouldRenderValue={false}
                    // hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={onChange}
                    options={data}
                    placeholder={t('Search;Search')}
                    noOptionsMessage={() => t('No options')}
                    styles={selectStyles(menuStyle)}
                    tabSelectsValue={false}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    value={selectedOptions}
                    //filterOption={filterOption}
                    name={name}
                />
            </Dropdown>
        </SearchableSelectContext.Provider>
    );
};

export default SearchableSelect;
