import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import { setActivityRecordInfo, updateActivityRecordInfo } from '../../../ScheduleByActivity/scheduleByActivityReducer';
import useAsync from '../../../../../../util/hooks/useAsync';
import { createCureRecord, getCureRecord, updateRecord } from '../../../../../../api/activeSchedule';
import { useHistory } from 'react-router-dom';
import CustomSearchModal from '../../../util/CustomSearchModal';
import { TextChange, validationCheck } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { ScheduleByActivityDispatchContext, ScheduleByActivityStateContext } from '../../CarryingOutActivities';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import ResizeDetector from 'react-resize-detector';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const Record = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const { zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { actItemCodeName, actTypeCodeName, actItemCode, targetNum, psNum, exeDate } = patientDetailInfo;
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { activityRecordInfo } = state;
    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const [validation, setValidation] = useState({});
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    useEffect(() => {
        if (exeDate) {
            getCureRecordInfo({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, [exeDate]);

    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const onHandleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };

    const handleZoneSelect = e => {
        const { value, name, label } = e;
        dispatch(updateActivityRecordInfo({ wardCode: value }));
        setZoneState({ label, value });
        setValidation({ [name]: null });
    };

    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityRecordInfo({ [name]: value }));
    };

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const { promise: addCureRecordInfo } = useAsync({
        promise: createCureRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getCureRecordInfo({ psNum });
            }
        },
    });

    const { promise: getCureRecordInfo } = useAsync({
        promise: getCureRecord,
        resolve: res => {
            const { wardCodeName, wardCode } = res;
            dispatch(setActivityRecordInfo(res));
            storeDispatch(setNurseInfo(res));
            setZoneState({ label: wardCodeName, value: wardCode });
        },
    });

    const { promise: editCureRecordInfo } = useAsync({
        promise: updateRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getCureRecordInfo({ psNum });
            }
        },
    });

    const [validationResultState, setValidationResultState] = useState([]);
    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            nurseRecordState || setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editCureRecordInfo({
                      ...activityRecordInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addCureRecordInfo({
                      ip: profile.clientIp,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      ...activityRecordInfo,
                      exeStatusCode: 'C',
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    //수정 로직 추가 예정
    const onHandleEdit = () => {
        setRecordState(!recordState);
        nurseRecordState || setNurseRecordState(!nurseRecordState);
    };

    //  모달 창
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: noCureRecordInfo } = useAsync({
        promise: createCureRecord,
        resolve: res => {
            history.push('/activity-schedule');
        },
    });
    const onHandleNotDone = ({ exeStatusCode }) => {
        noCureRecordInfo({
            ip: profile.clientIp,
            exeStatusCode,
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onHandleSave,

                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: () => onHandleNotDone({ exeStatusCode: 'N' }),
                },
                {
                    label: t('Not applicable'),
                    onClick: () => onHandleNotDone({ exeStatusCode: 'A' }),
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onHandleEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };
    const urlChange = value => {
        let resultUrl = null;
        if (value === 'BS_IV_4') {
            resultUrl = `${url.wmsUrl}/v2/html/aum/guide/2_5.html`;
            return resultUrl;
        }
        if (value === 'BS_IV_5') {
            resultUrl = `${url.wmsUrl}/v2/html/aum/guide/2_6.html`;
            return resultUrl;
        }
        if (value === 'BS_IV_6') {
            resultUrl = `${url.wmsUrl}/v2/html/aum/guide/2_7.html`;
            return resultUrl;
        }
        if (value === 'BS_IV_7') {
            resultUrl = `${url.wmsUrl}/v2/html/aum/guide/2_8.html`;
            return resultUrl;
        }
        if (value === 'BS_IV_8') {
            resultUrl = `${url.wmsUrl}/v2/html/aum/guide/2_9.html`;
            return resultUrl;
        }
    };

    const [tableHeight, setTableHeight] = useState(null);
    return (
        <div>
            <ResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => {
                    setTableHeight(height);
                }}
            >
                <ActiveRecordCard
                    style={{ height: tableHeight }}
                    headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                        actItemCodeName,
                        'ActTypeCode',
                    )}`}
                    buttonOption={buttonOptionChange(recordState)}
                    spanIcon={['icon-info']}
                    url={urlChange(actItemCode)}
                >
                    <div className="p-0 pnt-label-7 row">
                        <DescriptionRow
                            label={t('Nurse')}
                            component={
                                <HandleNurseTextInput
                                    name={'nurse'}
                                    readOnly={nurseRecordState}
                                    onClick={toggleModal}
                                    placeholder={`${t('Nurse')}||${t('Number')}`}
                                    className={'d-flex '}
                                    value={
                                        nurseInfo.hasOwnProperty('exeUserName') &&
                                        nurseInfo.exeUserName &&
                                        `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                    }
                                    text={
                                        activityRecordInfo &&
                                        activityRecordInfo.hasOwnProperty('exeUserName') &&
                                        activityRecordInfo.exeUserName &&
                                        `${activityRecordInfo.exeUserName} ||${activityRecordInfo.exeUserNum}`
                                    }
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('When performed')}
                            component={
                                <HandleDateInput
                                    value={startDate}
                                    handleDateValueChange={onHandleStartDateValueChange}
                                    valueType={'ms'}
                                    readOnly={nurseRecordState}
                                    text={activityRecordInfo && activityRecordInfo.exeDate}
                                    minDate={moment().valueOf()}
                                />
                            }
                        />

                        <DescriptionRow
                            label={t('Zones')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    options={zoneOptionRows}
                                    readOnly={recordState}
                                    isMulti={false}
                                    onChange={handleZoneSelect}
                                    value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                    text={
                                        (activityRecordInfo && t(activityRecordInfo.wardCodeName, 'ActTypeCode')) || '-'
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Remarks')}
                            className="col-xl-12 col-lg-12 mb-3"
                            spanClassName={'w-50'}
                            component={
                                <HandleTextInput
                                    size={200}
                                    readOnly={recordState}
                                    name={'remark'}
                                    placeholder={t('Enter any remarks.', 'Evaluation')}
                                    onChange={onHandleTextChange}
                                    value={activityRecordInfo && activityRecordInfo.remark}
                                />
                            }
                        />
                    </div>
                </ActiveRecordCard>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required Items')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ResizeDetector>
        </div>
    );
};

export default Record;
