import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchFilterInfo: {
        patientGroupNames: [],
        patientGroupNums: [],
        actTypeCodesName: [],
        actTypeCodes: [],
        actItemCodesName: [],
        actItemCodes: [],
        riskGradeCodesName: [],
        riskGradeCodes: [],
    },
    wardInfoList: { rows: [] },
    bsRiskInfoList: { rows: [] },
    bfRiskInfoList: { rows: [] },
    search: {},
    filter: {},
};

const SearchFilter = createSlice({
    name: 'common/searchFilter',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            const filterData = action.payload;
            if (filterData) {
                for (let key in filterData) {
                    if (filterData.hasOwnProperty(key)) {
                        state.filter[key] = filterData[key];
                    }
                }
            } else {
                state.filter = {};
            }
        },
        setSearchFilter: (state, action) => {
            const data = action.payload;
            if (data) {
                state.searchFilter = data;
            } else {
                state.search = {};
                state.searchFilter = {
                    patientGroupNames: [],
                    patientGroupNums: [],
                    actTypeCodesName: [],
                    actTypeCodes: [],
                    actItemCodesName: [],
                    actItemCodes: [],
                    riskGradeCodesName: [],
                    riskGradeCodes: [],
                };
            }
        },
        updateSearchFilter: (state, action) => {
            const data = action.payload;
            state.searchFilter = { ...state.searchFilter, ...data };
        },
        setSearch: (state, action) => {
            const data = action.payload;
            state.search = { ...state.searchFilter, ...data };
        },
        updateSearch: (state, action) => {
            const data = action.payload;
        },

        setWardInfoList: (state, action) => {
            let filterData = [];
            const data = action.payload;

            filterData = data.rows.reduce((acc, curr) => {
                acc.push({
                    label: curr.patientGroupName,
                    value: curr.patientGroupNum,
                    filterName: 'patientGroupNames',
                    filterValueName: 'patientGroupNums',
                });
                return acc;
            }, []);
            state.wardInfoList.rows = filterData;
        },
        setBsRiskInfoList: (state, action) => {
            let filterData = [];
            const data = action.payload;
            if (data) {
                // 데이터 가공
                filterData = data.rows.reduce((acc, curr) => {
                    acc.push({
                        label: curr.codeName,
                        value: curr.code,
                        filterName: 'riskGradeCodesName',
                        filterValueName: 'riskGradeCodes',
                    });
                    return acc;
                }, []);
                //데이터 가공 후 처리
                state.bsRiskInfoList.rows = [...filterData];
            } else {
                //데이터 null 일 경우 처리
                state.bsRiskInfoList.rows = [];
            }
        },
        setBfRiskInfoList: (state, action) => {
            let filterData = [];
            const data = action.payload;
            if (data) {
                // 데이터 가공
                filterData = data.rows.reduce((acc, curr) => {
                    acc.push({
                        label: curr.codeName,
                        value: curr.code,
                        filterName: 'riskGradeCodesName',
                        filterValueName: 'riskGradeCodes',
                    });
                    return acc;
                }, []);
                //데이터 가공 후 처리
                state.bfRiskInfoList.rows = [...filterData];
            } else {
                //데이터 null 일 경우 처리
                state.bfRiskInfoList.rows = [];
            }
        },
    },
});

export const {
    setFilter,
    setSearchFilter,
    updateSearchFilter,
    setSearch,
    updateSearch,
    setWardInfoList,
    setBsRiskInfoList,
    setBfRiskInfoList,
} = SearchFilter.actions;
export default SearchFilter.reducer;
