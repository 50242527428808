import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_EXECUTION_CYCLE_INFO = 'setting/SET_EXECUTION_CYCLE_INFO';
const SET_EXECUTION_CYCLE_TYPE = 'setting/SET_EXECUTION_CYCLE_TYPE';
const SET_WARD_INFO_LIST = 'setting/SET_WARD_INFO_LIST';
export const { setExecutionCycleInfo, setExecutionCycleType, setWardInfoList } = createActions(
    {
        SET_EXECUTION_CYCLE_INFO: executionCycleInfo => executionCycleInfo,
        SET_EXECUTION_CYCLE_TYPE: executionCycleType => executionCycleType,
        SET_WARD_INFO_LIST: wardInfoList => wardInfoList,
    },
    { prefix: 'setting' },
);

export const commonInitialState = {
    executionCycleInfo: { optionData: null },
    executionCycleType: { type: null },
    wardInfoList: null,
};

const settingReducer = handleActions(
    {
        [SET_EXECUTION_CYCLE_INFO]: (state, action) => {
            return produce(state, draft => {
                const optionData = action.payload;
                draft.executionCycleInfo.optionData = { ...optionData };
            });
        },
        [SET_EXECUTION_CYCLE_TYPE]: (state, action) => {
            return produce(state, draft => {
                const type = action.payload.type;
                let optionData = null;
                optionData = action.payload.executionCycleOption;
                if (type.label === '단일') {
                    draft.executionCycleInfo.optionData = { ...optionData.single[0] };
                } else {
                    draft.executionCycleInfo.optionData = { ...optionData.repeat[0] };
                }
                draft.executionCycleType = { type };
            });
        },
        [SET_WARD_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const optionData = action.payload;
                let filterData = [];
                filterData = optionData.rows.reduce((acc, curr, i) => {
                    acc.push({ label: curr.patientGroupId, value: curr.patientGroupNum });
                    return acc;
                }, []);
                draft.wardInfoList = [...filterData];
            });
        },
    },
    commonInitialState,
);
export default settingReducer;
