import React, { useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import FloorTreeSelect from './FloorTreeSelect';
import FloorSelect from './FloorSelect';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchCategory } from '../../../api/asset';
import useAsync from '../../../util/hooks/useAsync';

export const CustomSelect = ({ label, name, id, innerRef, defaultValue, options, multiple }) => {
    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input
                type="select"
                name={name}
                id={id}
                innerRef={innerRef}
                defaultValue={defaultValue}
                multiple={multiple}
            >
                {options.map(v => (
                    <option key={v.value} value={v.value}>
                        {v.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export const WidgetName = ({ defaultValue, innerRef }) => {
    const { t } = useTranslation();
    return (
        <FormGroup>
            <Label for="widgetName">{t('Widget;Widget Name')}</Label>
            <Input
                type="text"
                name="name"
                id="widgetName"
                placeholder=""
                innerRef={innerRef}
                maxLength={40}
                defaultValue={defaultValue}
            />
        </FormGroup>
    );
};

export const IntervalTime = ({ defaultValue, innerRef, options = [10, 15, 20, 30] }) => {
    const { t } = useTranslation();
    return (
        <FormGroup>
            <Label for="intervalTime">{t('Widget;Interval Time')}</Label>
            <Input type="select" name="intervalTime" id="intervalTime" innerRef={innerRef} defaultValue={defaultValue}>
                {options.map(v => (
                    <option key={v} value={v * 1000}>
                        {v}초
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export const HiddenHeader = ({ defaultValue, innerRef }) => {
    const { t } = useTranslation();
    return (
        <FormGroup>
            <Label check for="peopleStatus">
                {t('Widget;Hide Header')}
            </Label>
            <Input
                style={{ marginLeft: '1.25rem' }}
                type={'checkbox'}
                id={'hiddenHeader'}
                name={'hiddenHeader'}
                innerRef={innerRef}
                defaultChecked={defaultValue}
            />
        </FormGroup>
    );
};

export const ChartType = ({ defaultValue, innerRef }) => {
    const { t } = useTranslation();
    const options = [
        { label: t('CategoryChart;Pie'), value: 'pie' },
        { label: t('CategoryChart;Doughnut'), value: 'doughnut' },
    ];
    return (
        <FormGroup>
            <Label for="chartType">{t('CategoryChart;Type of Graph')}</Label>
            <Input type="select" name="chartType" id="chartType" innerRef={innerRef} defaultValue={defaultValue}>
                {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export const CategoryType = ({ innerRef, defaultValue }) => {
    const { t } = useTranslation();
    const [selectOptions, setSelectOptions] = useState([]);
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            const { rows } = res;
            const categoryList = rows
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
            setSelectOptions([
                {
                    value: undefined,
                    label: t('CategoryChart;All Category Type'),
                },
                ...categoryList,
            ]);
        },
    });

    return (
        <CustomSelect
            label={t('CategoryChart;Type of Category')}
            name="categoryType"
            id="categoryType"
            innerRef={innerRef}
            defaultValue={defaultValue}
            options={selectOptions}
            multiple={true}
        />
    );
};

export const CategoryCode = ({
    defaultValue,
    innerRef,
    options = [
        { value: 'ASSET', label: 'Asset' },
        { value: 'PEOPLE', label: 'People' },
        { value: 'SENSOR', label: 'Sensor' },
    ],
}) => {
    const { t } = useTranslation();
    return (
        <FormGroup>
            <Label for="categoryCode">{t('CategoryChart;Type of Category')}</Label>
            <Input type="select" name="categoryCode" id="categoryCode" innerRef={innerRef} defaultValue={defaultValue}>
                {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export const TagState = ({
    innerRef,
    defaultValue,
    options = [
        { value: 'sos', label: 'SOS' },
        // { value: 'lostSignal', label: '신호 상실' },
        { value: 'battery', label: 'Low Battery' },
    ],
}) => {
    const { t } = useTranslation();
    return (
        <CustomSelect
            label={t('TagSateAlarmList;Tag State')}
            name="tagState"
            id="tagState"
            innerRef={innerRef}
            defaultValue={defaultValue}
            options={options}
            multiple={true}
        />
    );
};

export const Floor = ({ defaultValue = '', innerRef, options }) => {
    const { floorList } = useSelector(state => state.FloorInfo);
    return (
        <FormGroup>
            <Label for="floor">층</Label>
            <FloorTreeSelect floorList={floorList} selectedFloorStr={defaultValue} innerRef={innerRef} />
        </FormGroup>
    );
};

export const SingleFloor = ({ defaultValue = '', innerRef }) => {
    const [selectedFloor, setSelectedFloor] = useState(defaultValue);
    const { t } = useTranslation();
    const handleChange = selects => {
        if (selects[0]) {
            setSelectedFloor(selects[0].floorId);
        }
    };
    return (
        <FormGroup>
            <Label for="floor">{t('GeofenceStatus;Floor')}</Label>
            <Input type={'hidden'} id={'floor'} name={'floor'} innerRef={innerRef} defaultValue={selectedFloor} />
            <FloorSelect selectedFloorId={selectedFloor} handleChange={handleChange} />
        </FormGroup>
    );
};

export const CustomSelectWithValue = ({
    label,
    name,
    id,
    multiple,
    defaultValue = '',
    innerRef,
    options,
    onChange,
}) => {
    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input
                type="select"
                name={name}
                id={id}
                innerRef={innerRef}
                defaultValue={defaultValue}
                multiple={multiple}
                onChange={onChange}
            >
                {options.map(v => (
                    <option key={v.value} value={v.value}>
                        {v.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};
