import React from 'react';
import { DateInputProps } from '../type';

const DateInput = ({ className, value, name, handleDateChange, min, max }: DateInputProps): JSX.Element => {
    return (
        <input
            onChange={handleDateChange}
            className={`pnt-input ${className}`}
            type={'datetime-local'}
            value={value}
            name={name}
            min={min}
            max={max}
        />
    );
};

export default DateInput;
