import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Button from '../../../../Common/Button';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { MinToDhm, minToDhm } from '../../../util/dateChange';
import { onHandleTextChange } from '../../../util/TextChange';
import useTranslation from '../../../../../util/hooks/useTranslation';

const ActiveAlarmItem = ({ alarmInfo, handleRedirectClick }) => {
    const t = useTranslation('Active Dashboard');
    const history = useHistory();
    const textChange = (no, name) => (no && `[${no}]${name}`) || name;
    const handlePage = targetNum => {
        history.push(`/activity-schedule/schedule-by-activity-detail/${targetNum}`);
    };
    return (
        <>
            <Card>
                <CardBody className={cx({ 'custom-card-color': alarmInfo.rdate && alarmInfo.rdate.includes('-') })}>
                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        <div className={'text-ellipsis'}>
                            <span
                                style={{ cursor: 'pointer' }}
                                className={'mr-1 font-weight-bold'}
                                onClick={() => handlePage(alarmInfo.targetNum)}
                                title={textChange(alarmInfo.patid, alarmInfo.targetName)}
                            >
                                {textChange(alarmInfo.patid, alarmInfo.targetName) ? t('Sir') : ''}
                            </span>
                            <span
                                className={'text-ellipsis'}
                                title={onHandleTextChange([alarmInfo.wardNo, alarmInfo.roomNo, alarmInfo.bedNo])}
                            >
                                {onHandleTextChange([alarmInfo.wardNo, alarmInfo.roomNo, alarmInfo.bedNo])}
                            </span>
                            <p
                                className={'text-ellipsis mb-0'}
                                title={`${t(alarmInfo.actTypeCodeName, 'ActTypeCode')}>${alarmInfo.actItemCodeName}`}
                            >{`${t(alarmInfo.actTypeCodeName, 'ActTypeCode')}>${t(
                                alarmInfo.actItemCodeName,
                                'ActTypeCode',
                            )}`}</p>
                        </div>
                        <div className={'d-flex align-items-center w-35'}>
                            <div className={'text-ellipsis w-100'}>
                                <span
                                    className={cx(
                                        alarmInfo.rdate && alarmInfo.rdate.includes('-')
                                            ? 'color-danger'
                                            : 'color-secondary',
                                        'text-ellipsis',
                                    )}
                                >
                                    {alarmInfo.rdate && alarmInfo.rdate.includes('-') ? t('Delayed') : t('Scheduled')}
                                </span>

                                <p
                                    className={cx(
                                        alarmInfo.rdate && alarmInfo.rdate.includes('-')
                                            ? 'color-danger'
                                            : 'color-secondary',
                                        'mb-0',
                                        'text-ellipsis',
                                    )}
                                    title={minToDhm(alarmInfo.rdate)}
                                >
                                    {/*{minToDhm(alarmInfo.rdate)}*/}
                                    <MinToDhm value={alarmInfo.rdate} />
                                </p>
                            </div>
                            <Button
                                className={cx(
                                    'pnt-btn btn-icon-only pl-1 pr-3',
                                    alarmInfo.rdate && alarmInfo.rdate.includes('-') ? 'btn-danger' : 'btn-blue',
                                )}
                                iconClassName={'icon-next'}
                                onClick={() => {
                                    const { psNum } = alarmInfo;
                                    handleRedirectClick(psNum);
                                }}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default ActiveAlarmItem;
