import React, { useContext, useEffect, useState } from 'react';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import { setBsSelectOption, setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import {
    setActivityInitialEvaluationScheduleInfo,
    setFlag,
    updateActivityInitialEvaluationScheduleInfo,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { createRiskAssessment, getRiskAssessment, updateRiskAssessment } from '../../../../../../api/activeSchedule';
import { TextChange, validationCheck } from '../../../util/Validation';
import CustomSearchModal from '../../../util/CustomSearchModal';
import ConfirmModal from '../../../../Components/ConfirmModal';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from '../../CarryingOutActivities';
import { useHistory } from 'react-router-dom';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const InitialEvaluation = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    // 리덕스 ,리듀서
    const storeDispatch = useDispatch();
    const { bsSelectOption, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);

    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const commonState = useContext(CommonStateContext);
    const { cardHeight } = commonState;

    // 리덕스 ,리듀서 상태값
    const { actItemCodeName, riskGradeCode, actTypeCodeName, targetNum, psNum, exeDate } = patientDetailInfo;
    const { bsFrictionstress, bsSense, bsActivity, bsHumidity, bsMobility, bsNutrition } = bsSelectOption;
    const { activityInitialEvaluationScheduleInfo } = state;

    const [bradenScale, setBradenScale] = useState(0);
    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const [selectOption, setSelectOption] = useState({
        BS_SENSE: [{ label: t('Select'), id: 0 }],
        BS_HUMIDITY: [{ label: t('Select'), id: 0 }],
        BS_ACTIVITY: [{ label: t('Select'), id: 0 }],
        BS_MOBILITY: [{ label: t('Select'), id: 0 }],
        BS_NUTRITION: [{ label: t('Select'), id: 0 }],
        BS_FRICTIONSTRESS: [{ label: t('Select'), id: 0 }],
    });
    const [validation, setValidation] = useState({
        BS_SENSE: t('Sensory Cognition', 'Validation'),
        BS_HUMIDITY: t('Humidity', 'Validation'),
        BS_ACTIVITY: t('Activity level', 'Validation'),
        BS_MOBILITY: t('Mobility', 'Validation'),
        BS_NUTRITION: t('Nutrition', 'Validation'),
        BS_FRICTIONSTRESS: t('Friction and stress', 'Validation'),
    });

    const [validationResultState, setValidationResultState] = useState([]);
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const { promise: getCheckOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBsSelectOption(res));
        },
    });

    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    useEffect(() => {
        getCheckOption({ clCode: 'BS_SENSE', codeDepth: '2' });
        getCheckOption({ clCode: 'BS_HUMIDITY', codeDepth: '2' });
        getCheckOption({ clCode: 'BS_ACTIVITY', codeDepth: '2' });
        getCheckOption({ clCode: 'BS_MOBILITY', codeDepth: '2' });
        getCheckOption({ clCode: 'BS_NUTRITION', codeDepth: '2' });
        getCheckOption({ clCode: 'BS_FRICTIONSTRESS', codeDepth: '2' });
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, []);

    useEffect(() => {
        if (exeDate) {
            getRiskAssessmentRecord({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
    }, [exeDate]);

    const handleSelectValueChange = e => {
        const { value, name, id, label, codeName } = e;
        const beforeScore = selectOption[id][0].value || 0;
        const gap = beforeScore - Number(value);
        setBradenScale(bradenScale - Number(gap));
        setSelectOption({ ...selectOption, [id]: [{ label: label, value: value, name: name, id: id }] });
        const braden = bradenScale - Number(gap);
        dispatch(
            updateActivityInitialEvaluationScheduleInfo({
                [name]: value,
                [`${name}Name`]: codeName,
                braden,
                bradenStatus: braden > 16 ? 'Normal' : 'Danger',
            }),
        );
        setValidation({ ...validation, [id]: null });
    };

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityInitialEvaluationScheduleInfo({ [name]: value }));
    };
    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityInitialEvaluationScheduleInfo({ wardCode: value, wardCodeName: label }));
        setZoneState({ label, value });
    };

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const { promise: addRiskAssessment } = useAsync({
        promise: createRiskAssessment,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getRiskAssessmentRecord({ psNum });
            }
        },
    });

    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            nurseRecordState || setNurseRecordState(!nurseRecordState);
            !nurseRecordState
                ? addRiskAssessment({
                      ...activityInitialEvaluationScheduleInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      exeStatusCode: 'C',
                      ip: profile.clientIp,
                  })
                : editRiskAssessmentRecord({
                      ...activityInitialEvaluationScheduleInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };
    const onHandleEdit = () => {
        setRecordState(!recordState);
        nurseRecordState || setNurseRecordState(!nurseRecordState);
    };

    const { promise: getRiskAssessmentRecord } = useAsync({
        promise: getRiskAssessment,
        resolve: res => {
            const { wardCodeName, wardCode } = res;
            dispatch(setActivityInitialEvaluationScheduleInfo(res));
            dispatch(setFlag());
            storeDispatch(setNurseInfo(res));
            setZoneState({ label: wardCodeName, value: wardCode });
        },
    });

    const { promise: editRiskAssessmentRecord } = useAsync({
        promise: updateRiskAssessment,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getRiskAssessmentRecord({ psNum });
            }
        },
    });

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };

    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: noActivityRiskAssessment } = useAsync({
        promise: createRiskAssessment,
        resolve: res => {
            history.push('/activity-schedule');
        },
    });
    const onHandleNotDone = () => {
        noActivityRiskAssessment({
            braden: '',
            bradenStatus: '',
            bsActivityCode: '',
            bsFrictionStressCode: '',
            bsHumidityCode: '',
            bsMobilityCode: '',
            bsNutritionCode: '',
            bsSenseCode: '',
            exeDate: nurseInfo.exeDate,
            ip: profile.clientIp,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            exeStatusCode: 'N',
            remark: '',
            wardCode: '',
            wardCodeName: '',
            psNum,
            targetNum,
        });
    };
    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onHandleSave,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onHandleEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };

    const urlChange = (value, riskValue) => {
        if (value === '초기평가') {
            return `${url.wmsUrl}/v2/html/aum/guide/1_1.html`;
        } else if (['BS01', 'BS03'].includes(riskValue)) {
            return `${url.wmsUrl}/v2/html/aum/guide/1_2.html`;
        } else {
            return `${url.wmsUrl}/v2/html/aum/guide/1_3.html`;
        }
    };

    const bsSenseRows = bsSense.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bsHumidityRows = bsHumidity.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bsActivityRows = bsActivity.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bsMobilityRows = bsMobility.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bsNutritionRows = bsNutrition.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bsFrictionstressRows = bsFrictionstress.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        cardHeight && (
            <>
                <ActiveRecordCard
                    style={{ height: cardHeight }}
                    headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                        actItemCodeName,
                        'ActTypeCode',
                    )}`}
                    buttonOption={buttonOptionChange(recordState)}
                    spanIcon={['icon-info']}
                    url={urlChange(actItemCodeName, riskGradeCode)}
                >
                    <div className="p-0 pnt-label-7 row">
                        <DescriptionRow
                            label={t('Nurse')}
                            component={
                                <HandleNurseTextInput
                                    name={'nurse'}
                                    readOnly={nurseRecordState}
                                    onClick={toggleModal}
                                    placeholder={`${t('Nurse')}||${t('Number')}`}
                                    className={'d-flex'}
                                    value={
                                        nurseInfo.hasOwnProperty('exeUserName') &&
                                        nurseInfo.exeUserName &&
                                        `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                    }
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        activityInitialEvaluationScheduleInfo.hasOwnProperty('exeUserName') &&
                                        activityInitialEvaluationScheduleInfo.exeUserName &&
                                        `${activityInitialEvaluationScheduleInfo.exeUserName} | ${activityInitialEvaluationScheduleInfo.exeUserNum}`
                                    }
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('When performed')}
                            component={
                                <HandleDateInput
                                    value={startDate}
                                    readOnly={nurseRecordState}
                                    handleDateValueChange={handleStartDateValueChange}
                                    valueType={'ms'}
                                    text={nurseInfo && nurseInfo.exeDate}
                                    minDate={moment().valueOf()}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Zones')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    options={zoneOptionRows}
                                    readOnly={recordState}
                                    isMulti={false}
                                    onChange={handleZoneSelect}
                                    value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                    text={
                                        (activityInitialEvaluationScheduleInfo &&
                                            t(activityInitialEvaluationScheduleInfo.wardCodeName, 'ActTypeCode')) ||
                                        '-'
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />

                        <DescriptionRow
                            label={t('Sensory Cognition', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsSenseRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsSense'}
                                    value={selectOption['BS_SENSE']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsSenseCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsSenseCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Humidity', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsHumidityRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsHumidity'}
                                    value={selectOption['BS_HUMIDITY']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsHumidityCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsHumidityCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Activity level', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsActivityRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsActivity'}
                                    value={selectOption['BS_ACTIVITY']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsActivityCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsActivityCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Mobility', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsMobilityRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsMobility'}
                                    value={selectOption['BS_MOBILITY']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsMobilityCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsMobilityCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Nutrition', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsNutritionRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsNutrition'}
                                    value={selectOption['BS_NUTRITION']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsNutritionCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsNutritionCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Friction and stress', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsFrictionstressRows}
                                    isMulti={false}
                                    onChange={handleSelectValueChange}
                                    name={'bsFrictionStress'}
                                    value={selectOption['BS_FRICTIONSTRESS']}
                                    text={
                                        activityInitialEvaluationScheduleInfo &&
                                        `${activityInitialEvaluationScheduleInfo.bsFrictionStressCode}-${t(
                                            activityInitialEvaluationScheduleInfo.bsFrictionStressCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    customControlStyles={{ width: '235px' }}
                                    customOptionStyles={{ width: '235px' }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Braden Scale', 'Validation')}
                            className="col-xl-12 col-lg-12 mb-3"
                            component={
                                (activityInitialEvaluationScheduleInfo &&
                                    activityInitialEvaluationScheduleInfo['bradenStatus'] &&
                                    `${activityInitialEvaluationScheduleInfo.braden}${t('score')}/${t(
                                        activityInitialEvaluationScheduleInfo.bradenStatus,
                                    )}`) ||
                                `${bradenScale}${t('Score')}/${bradenScale >= 16 ? t('Normal') : t('Danger')}`
                            }
                        />
                        <DescriptionRow
                            label={t('Remarks')}
                            className="col-xl-12 col-lg-12 mb-3 "
                            spanClassName={'w-50'}
                            component={
                                <HandleTextInput
                                    size={200}
                                    name={'remark'}
                                    onChange={onHandleTextChange}
                                    placeholder={t('Enter any remarks.', 'Evaluation')}
                                    readOnly={recordState}
                                    value={
                                        activityInitialEvaluationScheduleInfo &&
                                        activityInitialEvaluationScheduleInfo.remark
                                    }
                                />
                            }
                        />
                    </div>
                </ActiveRecordCard>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </>
        )
    );
};

export default InitialEvaluation;
