import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_PRESSURE_SORE_REQUEST_LIST = 'skinConditionRecordManagement/SET_PRESSURE_SORE_REQUEST_LIST';
const SET_SKIN_CONDITION_RECORD_LIST = 'skinConditionRecordManagement/SET_SKIN_CONDITION_RECORD_LIST';
const SET_SKIN_INFO = 'skinConditionRecordManagement/SET_SKIN_INFO';
const SET_GRADE_OPTION = 'skinConditionRecordManagement/SET_GRADE_OPTION';
const UPDATE_SKIN_INFO = 'skinConditionRecordManagement/UPDATE_SKIN_INFO';
const SET_REQUEST = 'skinConditionRecordManagement/SET_REQUEST';
export const {
    setPressureSoreRequestList,
    setSkinConditionRecordList,
    setSkinInfo,
    setGradeOption,
    updateSkinInfo,
    setRequest,
} = createActions(
    {
        SET_PRESSURE_SORE_REQUEST_LIST: pressureSoreRequestList => pressureSoreRequestList,
        SET_SKIN_CONDITION_RECORD_LIST: skinConditionRecordList => skinConditionRecordList,
        SET_SKIN_INFO: skinInfo => skinInfo,
        SET_GRADE_OPTION: gradeOption => gradeOption,
        UPDATE_SKIN_INFO: skinInfo => skinInfo,
        SET_REQUEST: request => request,
    },
    {
        prefix: 'skinConditionRecordManagement',
    },
);
export const initialState = {
    pressureSoreRequestList: { rows: [] },
    skinConditionRecordList: { rows: [] },
    skinInfo: {},
    gradeOption: { label: '선택해주세요', value: '0' },
    expanded: {},
    request: { regName: '', bsStatusCodeName: '', happDate: 0, bsReportGradeCodeName: '' },
};
const skinConditionRecordManagementReducer = handleActions(
    {
        [SET_PRESSURE_SORE_REQUEST_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.pressureSoreRequestList = data;
            });
        },
        [SET_SKIN_CONDITION_RECORD_LIST]: (state, action) => {
            return produce(state, draft => {
                //reduce 함수를 사용하여 데이터 순서를 가공하여 넣어준다.
                const data = action.payload;
                draft.skinConditionRecordList = data;
            });
        },

        [SET_SKIN_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.skinInfo = data;
            });
        },
        [SET_GRADE_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.gradeOption = { label: data.bsReportGradeCodeName, value: data.bsReportGradeCode };
            });
        },

        [UPDATE_SKIN_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.skinInfo = { ...state.skinInfo, ...data };
            });
        },
        [SET_REQUEST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.request = data;
            });
        },
    },
    initialState,
);
export default skinConditionRecordManagementReducer;
