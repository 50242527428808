import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_SCHEDULE_PATIENT_LIST = 'scheduleByPatient/SET_SCHEDULE_PATIENT_LIST';
const SET_SCHEDULE_PATIENT_SUB_ROWS = 'scheduleByPatient/SET_SCHEDULE_PATIENT_SUB_ROWS';
const SET_EXPANDED = 'scheduleByPatient/SET_EXPANDED';
export const { setSchedulePatientList, setSchedulePatientSubRows, setExpanded } = createActions(
    {
        SET_SCHEDULE_PATIENT_LIST: schedulePatientList => schedulePatientList,
        SET_SCHEDULE_PATIENT_SUB_ROWS: schedulePatientSubRows => schedulePatientSubRows,
        SET_EXPANDED: expanded => expanded,
    },
    {
        prefix: 'scheduleByPatient',
    },
);
export const initialState = {
    schedulePatientList: { rows: [] },
    schedulePatientSubRows: { rows: [] },
    expanded: {},
};

const scheduleByActivityReducer = handleActions(
    {
        [SET_SCHEDULE_PATIENT_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.schedulePatientList = data;
            });
        },
        [SET_SCHEDULE_PATIENT_SUB_ROWS]: (state, action) => {
            return produce(state, draft => {
                const { targetNum, rows } = action.payload;
                draft.schedulePatientList.rows = draft.schedulePatientList.rows.map(row => {
                    if (row.targetNum === targetNum) {
                        row.list = rows;
                        row.isExpanded = true;
                    }
                    return row;
                });
            });
        },
        [SET_EXPANDED]: (state, action) => {
            return produce(state, draft => {
                const targetNum = action.payload;
                if (targetNum) {
                    draft.expanded[targetNum] = !draft.expanded[targetNum];
                } else {
                    draft.expanded = {};
                }
            });
        },
    },
    initialState,
);
export default scheduleByActivityReducer;
