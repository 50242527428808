import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { temp } from '../../Components/Widgets';

const initialState = {
    origin: {},
    editable: {},
    tempWidgetLayout: null,
};

const DashboardEdit = createSlice({
    name: 'dashboardEdit',
    initialState,
    reducers: {
        setEditDashboard: (state, action) => {
            const dashboard = action.payload;
            if (dashboard) {
                dashboard.widgetInfo = JSON.parse(dashboard.widgetInfo);
                if (!dashboard.widgetInfo || dashboard.widgetInfo.length === 0) {
                    const id = moment().valueOf().toString(36);
                    dashboard.widgetInfo = [
                        {
                            menuNum: dashboard.menuNum,
                            id,
                            type: temp.type,
                            config: temp.config,
                            layout: {
                                ...temp.layout,
                                i: id,
                                x: 0,
                                y: 0,
                            },
                            edited: false,
                        },
                    ];
                }
                state.origin = dashboard;
                state.editable = dashboard;
            } else {
                state.origin = {};
                state.editable = {};
            }
        },
        setDashboardWidgets: (state, action) => {
            const widgetInfo = action.payload;
            state.origin.widgetInfo = widgetInfo;
            state.editable.widgetInfo = widgetInfo;
        },
        setTempWidgetLayout: (state, action) => {
            state.tempWidgetLayout = action.payload;
        },
        editWidget: (state, action) => {
            state.editable.widgetInfo = state.editable.widgetInfo.map(widget => {
                if (widget.id === action.payload.id) {
                    return action.payload;
                }
                return widget;
            });
        },
        editWidgetSettings: (state, action) => {
            const newId = `d${state.editable.menuNum}_${moment().valueOf().toString(36)}`;
            state.editable.widgetInfo = state.editable.widgetInfo.map(widget => {
                if (widget.id === action.payload.id) {
                    widget.config.settings = action.payload.settings;
                    widget.id = newId;
                    widget.layout.i = newId;
                }
                return widget;
            });
        },
        editWidgets: (state, action) => {
            state.editable.widgetInfo = action.payload;
        },
        editLayout: (state, action) => {
            const layout = action.payload;
            state.editable.widgetInfo = state.editable.widgetInfo.map(widgetInfo => {
                const layoutInfo = widgetInfo.layout;
                widgetInfo.layout = layout.find(v => v.i === layoutInfo.i);
                return widgetInfo;
            });
        },
        deleteWidget: (state, action) => {
            const widgetId = action.payload;
            state.editable.widgetInfo = state.editable.widgetInfo.reduce((acc, widgetInfo) => {
                if (widgetInfo.id !== widgetId) {
                    acc.push(widgetInfo);
                }
                return acc;
            }, []);
        },
    },
});

export const {
    setEditDashboard,
    setDashboardWidgets,
    setTempWidgetLayout,
    editWidget,
    editWidgetSettings,
    editWidgets,
    editLayout,
    deleteWidget,
} = DashboardEdit.actions;
export default DashboardEdit.reducer;
