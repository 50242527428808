import React, { useEffect } from 'react';
import { FilterChange } from '../../../util/componentChange/componentChange';
import { setFilter, updateSearchFilter } from '../../../../../reducers/Common/SearchFilter';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import { setActivityOption, setActivitySecondOption } from '../../../../../reducers/Common/PerformanceRecordOption';
import FilterOptionBox from './FilterOptionBox';
import useTranslation from '../../../../../util/hooks/useTranslation';

const Activity = () => {
    const t = useTranslation('Filter');
    const storeDispatch = useDispatch();
    const { profile } = useSelector(state => state.UserInfo);
    const {
        activityOption,
        activitySecondOption = {
            row: [{ label: '', value: 0 }],
        },
    } = useSelector(state => state.PerformanceRecordOption);
    const { actTypeCodes, actItemCodes } = useSelector(state => state.SearchFilter.filter);
    const { promise: getActivityOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivityOption({ res, profile }));
        },
    });
    const { promise: getActivitySecondOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivitySecondOption(res));
        },
    });
    useEffect(() => {
        getActivityOption({ clCode: 'ACT_TYPE', codeDepth: '2' });
        getActivitySecondOption({ clCode: 'BS_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_EDU', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_REPORT', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_EDU_IV', codeDepth: '3' });
    }, []);

    const handleActivityFilterChange = selected => {
        let arrLabel = [];
        let arrValue = [];
        if (selected.length > 0) {
            arrLabel = selected.map(v => v.label);
            arrValue = selected.map(v => v.value);
            storeDispatch(setFilter({ actTypeCodesName: arrLabel, actTypeCodes: arrValue }));
            storeDispatch(updateSearchFilter({ actTypeCodesName: arrLabel, actTypeCodes: arrValue }));
        } else {
            storeDispatch(setFilter({ actTypeCodesName: null, actTypeCodes: null }));
            storeDispatch(updateSearchFilter({ actTypeCodesName: null, actTypeCodes: null }));
            storeDispatch(updateSearchFilter({ actItemCodesName: null, actItemCodes: null }));
        }
    };

    const handleActivityDetailFilterChange = selected => {
        let arrLabel = [];
        let arrValue = [];
        if (selected.length > 0) {
            arrLabel = selected.map(v => v.label);
            arrValue = selected.map(v => v.value);
            storeDispatch(setFilter({ actItemCodesName: arrLabel, actItemCodes: arrValue }));
            storeDispatch(updateSearchFilter({ actItemCodesName: arrLabel, actItemCodes: arrValue }));
        } else {
            storeDispatch(setFilter({ actItemCodesName: null, actItemCodes: null }));
            storeDispatch(updateSearchFilter({ actItemCodesName: arrLabel, actItemCodes: arrValue }));
        }
    };

    const activityOptionChange = optionData => {
        if (actTypeCodes) {
            if (optionData && actTypeCodes.length) {
                let option = [];
                let optionRows = [];
                option = actTypeCodes.reduce((acc, curr, i) => {
                    let data = optionData[curr].map(v => v);
                    acc = [...acc, ...data];
                    return acc;
                }, []);
                if (option.length > 0) {
                    optionRows = option.map(ele => {
                        return {
                            ...ele,
                            label: t(ele.label, 'ActTypeCode'),
                        };
                    });
                }
                return optionRows;
            } else {
                return [];
            }
        }
    };
    const activityOptionRows = activityOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        <>
            <FilterOptionBox>
                <FilterChange
                    title={t('Activity classification')}
                    data={activityOptionRows}
                    selected={actTypeCodes}
                    onChange={handleActivityFilterChange}
                    text={t('Pressure Ulcer Activity Record, Pressure Ulcer Prevention Activities')}
                    handleData={profile && profile.metaData.drst}
                />
            </FilterOptionBox>
            <FilterOptionBox>
                <FilterChange
                    title={t('Detail Activity')}
                    data={activityOptionChange(activitySecondOption)}
                    selected={actItemCodes}
                    onChange={handleActivityDetailFilterChange}
                    text={t('Treatment records, full body skin condition check')}
                    handleData={profile && profile.metaData.drst}
                />
            </FilterOptionBox>
        </>
    );
};

export default Activity;
