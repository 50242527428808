import React, { useContext, useEffect, useState, useRef } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import LoginTitle from './LoginTitle';
import { loginErrorMsg } from '../../../Common/style';
import TextInput from '../../../Common/Input/TextInput';
import Button from '../../../Common/Button';
import cx from 'classnames';
import { LoginDispatchContext } from '../index';
import { setNextStep } from '../reducer';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchCheckOtp, fetchSendOtp } from '../../../../api/login';
import { secToHms } from '../../../../util/common/util';
import { useDispatch } from 'react-redux';
import { setAdditionalInfo } from '../../../../reducers/UserInfo';

const INPUT_TIME_LIMIT = 300;
const secToStr = sec => {
    const hms = secToHms(sec);
    return hms.m.toString().padStart(2, '0') + ':' + hms.s.toString().padStart(2, '0');
};

const FindForm = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const timeoutRef = useRef();
    const dispatch = useContext(LoginDispatchContext);
    const [formData, setFormData] = useState({ email: '', verificationCode: '' });
    const [checkVerificationCode, setCheckVerificationCode] = useState(false);
    const [showVerificationErrorMsg, setShowVerificationErrorMsg] = useState({
        err: false,
        msg: 'Please check the verification code',
    });
    const { promise: getOtpNumber, state: getOtpNumberState } = useAsync({
        promise: fetchSendOtp,
        resolve: res => {
            if (res.code === '0') {
                setRestTime(INPUT_TIME_LIMIT);
                startTimeout();
                setShowVerificationErrorMsg({ err: false, msg: '' });
            } else {
                setShowVerificationErrorMsg({ err: true, msg: 'Issuing the verification code failed.' });
            }
        },
        reject: err => {
            setShowVerificationErrorMsg({ err: true, msg: 'Issuing the verification code failed.' });
            console.log(err);
        },
    });
    const { promise: checkOtpNumber, state: checkOtpNumberState } = useAsync({
        promise: fetchCheckOtp,
        resolve: res => {
            if (res.authResult) {
                const {
                    userCompany: { uuid, secret },
                } = res;
                storeDispatch(setAdditionalInfo({ companyInfo: { uuid, secret } }));
                dispatch(setNextStep({ email: checkOtpNumberState.request.userID }));
            } else {
                setShowVerificationErrorMsg({ err: true, msg: 'Please check the verification code' });
            }
        },
        reject: err => {
            console.log(err);
        },
    });

    const [restTime, setRestTime] = useState(INPUT_TIME_LIMIT);
    const stopTimeout = () => {
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
            timeoutRef.current = null;
        }
    };
    const startTimeout = () => {
        stopTimeout();
        timeoutRef.current = setInterval(() => {
            let prevRestTime = INPUT_TIME_LIMIT;
            setRestTime(prev => {
                prevRestTime = prev;
                return prev ? prev - 1 : prev;
            });
            if (!prevRestTime) {
                stopTimeout();
            }
        }, 1000);
    };

    const handleFormChange = e => {
        const target = e.currentTarget;
        setFormData({ ...formData, [target.name]: target.value });
    };

    const handleRequestCode = () => {
        if (!formData.email) {
            setShowVerificationErrorMsg({ err: true, msg: 'Please enter your e-mail' });
            return false;
        }
        getOtpNumber({ userID: formData.email });
    };

    const handleCheckCode = () => {
        checkOtpNumber({
            userID: formData.email,
            otpNum: formData.verificationCode,
        });
    };

    useEffect(() => {
        if (formData.verificationCode.length === 8) {
            setCheckVerificationCode(true);
        } else {
            setCheckVerificationCode(false);
        }
    }, [formData.verificationCode]);

    useEffect(() => {
        return () => {
            stopTimeout();
        };
    }, []);

    return (
        <>
            <LoginTitle
                canGoBack
                title={t('Forget Password')}
                subTitle={
                    <>
                        <div>{t('Please enter your email address when registering.')}</div>
                        <div>{t('We will send you a verification code to reset your password.')}</div>
                    </>
                }
            />
            <div className="cont-box">
                <div className="cont-box__wrap flex-col">
                    <div className={'cont--error'} style={!showVerificationErrorMsg.err ? loginErrorMsg : {}}>
                        <p>* {t(showVerificationErrorMsg.msg)}</p>
                    </div>

                    <div className="cont--input flx-col">
                        <div className={'flx-row gap-2'}>
                            <TextInput
                                className={'w-100'}
                                type={'email'}
                                name={'email'}
                                placeholder={t('Please enter your id')}
                                value={formData.email}
                                handleChange={handleFormChange}
                            />
                            <Button className="w-50 btn-blue color-white" onClick={handleRequestCode}>
                                {t('Send verification code')}
                            </Button>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <TextInput
                                className={'w-100'}
                                type={'text'}
                                maxlength={6}
                                name={'verificationCode'}
                                placeholder={t('Please enter the verification code')}
                                value={formData.verificationCode}
                                handleChange={handleFormChange}
                            />
                            <div
                                className={cx({ 'd-none': !getOtpNumberState.response })}
                                // className={cx({ 'd-none': !getOtpNumberState.response || getOtpNumberState.isLoading })}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 8,
                                    height: '38px',
                                    color: '#000',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {secToStr(restTime)}
                            </div>
                        </div>
                    </div>
                    <div className={cx('cont--btn', !checkVerificationCode && 'disabled')}>
                        <Button
                            className={cx('btn-orange btn-full', checkVerificationCode ? 'active' : 'non-active')}
                            onClick={handleCheckCode}
                        >
                            {t('Check Verification Code')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FindForm;
