import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { Col, CustomInput, Row, Toast, ToastBody, ToastHeader } from 'reactstrap';
import Label from '../../../Common/Label';
import { useSelector } from 'react-redux';
import TreeSelect from '../../../Common/Select/TreeSelect';
import { setBookmark, setSelectedCategory } from '../realTimeLocationReducer';
import { RealTimeLocationStatusContext, RealTimeLocationStatusDispatchContext } from '../index';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretSquareDown,
    faCaretSquareRight,
    faCheckSquare,
    faFile,
    faFolder,
    faFolderOpen,
    faMinusSquare,
    faPlusSquare,
    faSquare,
} from '@fortawesome/free-regular-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

const StyledDiv = styled.div`
    background: transparent;
    border-radius: 3px;
    border: 1px solid #0f2027;
    margin: 0 0.1em;
    padding: 0.25em 0.5em;
    width: 12rem;
    height: 11rem;
`;

const findTree = (tree, categoryCodePathArr, level = 0) => {
    const findNode = tree.find(v => v.categoryCode === categoryCodePathArr[level]);
    if (findNode && !findNode.children) {
        findNode.children = [];
    }
    return findNode ? findTree(findNode.children, categoryCodePathArr, ++level) : tree;
};

const makeTreeData = options => {
    const tempOptions = options;
    const treeData = [];
    if (tempOptions.length) {
        const categoryInfo = tempOptions[0];
        treeData.push({ ...categoryInfo, value: categoryInfo.categoryCodePath, label: categoryInfo.categoryName });
    }
    for (let i = 1, len = tempOptions.length; i < len; i++) {
        const option = tempOptions[i];
        const categoryCodePathArr = option.categoryCodePath.split('>');
        const parent = findTree(treeData, categoryCodePathArr);
        parent.push({ ...option, value: option.categoryCodePath, label: option.categoryName });
    }
    return treeData;
};

const FilteringPopup = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    defaultValue,
    widgetRef,
    mapRef,
    ...restProps
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const draggableRef = useRef();
    const { selectedCategory, categoryList, bookmark } = useContext(RealTimeLocationStatusContext);
    const dispatch = useContext(RealTimeLocationStatusDispatchContext);

    const [expanded, setExpanded] = useState([]);
    const [checked, setChecked] = useState([]);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupDragging, setPopupDragging] = useState(false);
    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        // setPosition({
        //     x: widgetPosition.x + widgetPosition.width / 2 - 100 + window.scrollX,
        //     // y: widgetPosition.y + 30 + window.scrollY,
        //     y: 100,
        // });
        const x = (widgetPosition.width - 221) / 2 - 20;
        // widgetPosition.width / 2 - widgetPosition.width / 4;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: x,
            y: y,
        });
    }, []);

    // const getPosition = (el, position = { top: 0, left: 0 }) => {
    //     if (el) {
    //         position.top += el.offsetTop;
    //         position.left += el.offsetLeft;
    //         getPosition(el.offsetParent, position);
    //     }
    //     return position;
    // };
    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            // const { top: clientTop, left: clientLeft } = widget.getBoundingClientRect();
            // const { top, left } = getPosition(widget);
            //
            // widgetPosition.x = clientLeft - left;
            // widgetPosition.y = clientTop - top;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const handleCheckboxChange = () => {
        dispatch(setBookmark());
    };
    const test = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds=".leaflet-container"
            defaultPosition={position}
            onStop={() => mapRef.current.leafletElement.dragging.enable()}
            onStart={() => mapRef.current.leafletElement.dragging.disable()}
        >
            <Toast isOpen={modal} className={'popup-open filtering handle'}>
                <ToastHeader toggle={toggleModal}>{t('RealTimeLocationStatus;IoT Filtering')}</ToastHeader>
                <ToastBody>
                    <Row>
                        <form className="flx-col" ref={formRef}>
                            <Col>
                                <Label name={t('RealTimeLocationStatus;Bookmark Filtering')} />
                                <CustomInput
                                    type={'checkbox'}
                                    name={'isBookmark'}
                                    id={'isBookmark'}
                                    label={t('RealTimeLocationStatus;Bookmark')}
                                    selected={bookmark}
                                    onChange={handleCheckboxChange}
                                />
                            </Col>

                            <Col>
                                <Label name={t('RealTimeLocationStatus;Category Filtering')} />
                                <StyledDiv className="scroll-area-sm">
                                    <PerfectScrollbar>
                                        <CheckboxTree
                                            nodes={makeTreeData(categoryList)}
                                            showNodeIcon={false}
                                            checked={checked}
                                            expanded={expanded}
                                            onCheck={(checkedNode, targetNode) => {
                                                setChecked(checkedNode);
                                                const checkedCategory = checkedNode.map(node => {
                                                    if (node.includes('>')) {
                                                        const leaf = node.split('>');
                                                        return leaf[leaf.length - 1];
                                                    } else {
                                                        return node;
                                                    }
                                                });
                                                dispatch(setSelectedCategory(checkedCategory));
                                            }}
                                            onExpand={expanded => {
                                                setExpanded(expanded);
                                            }}
                                            icons={{
                                                check: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-check"
                                                        icon={faCheckSquare}
                                                        color={'#fc7210'}
                                                    />
                                                ),
                                                uncheck: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-uncheck"
                                                        icon={faSquare}
                                                        color={'#000'}
                                                    />
                                                ),
                                                halfCheck: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-uncheck"
                                                        icon={faSquare}
                                                        color={'#000'}
                                                    />
                                                ),
                                                expandClose: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-close"
                                                        icon={faCaretSquareRight}
                                                        color={'#000'}
                                                    />
                                                ),
                                                expandOpen: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-open"
                                                        icon={faCaretSquareDown}
                                                        color={'#000'}
                                                    />
                                                ),
                                                expandAll: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-expand-all"
                                                        icon={faPlusSquare}
                                                        color={'#000'}
                                                    />
                                                ),
                                                collapseAll: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-collapse-all"
                                                        icon={faMinusSquare}
                                                        color={'#000'}
                                                    />
                                                ),
                                                parentClose: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-parent-close"
                                                        icon={faFolder}
                                                    />
                                                ),
                                                parentOpen: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-parent-open"
                                                        icon={faFolderOpen}
                                                    />
                                                ),
                                                leaf: (
                                                    <FontAwesomeIcon
                                                        className="rct-icon rct-icon-leaf-close"
                                                        icon={faFile}
                                                    />
                                                ),
                                            }}
                                        />
                                    </PerfectScrollbar>
                                </StyledDiv>
                            </Col>
                        </form>
                    </Row>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default FilteringPopup;
