import React, { useEffect, useReducer } from 'react';
import useAsync from '../../../../util/hooks/useAsync';
import Filter from '../../Components/Filter/Filter';
import CustomCard from '../../../Common/Card';
import Button from '../../../Common/Button';
import RecordTable from '../../Components/RecordTable';
import * as column from '../../util/grid/columns';
import { useHistory } from 'react-router-dom';
import { fetchWardSettingListApi } from '../../../../api/activitySetting';
import wardSettingReducer, { setWardSettingList, wardSettingInitialState } from './wardSettingReducer';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import { useDispatch } from 'react-redux';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';
const WardSetting = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const [state, dispatch] = useReducer(wardSettingReducer, wardSettingInitialState);
    const storeDispatch = useDispatch();
    const { wardSettingList } = state;
    const columns = useColumns(
        [
            column.wardSettingPatientGroupName(),
            column.classificationOfActivities(),
            column.detailActivity(),
            column.detailCondition(),
            column.lastDecubitusDivision(),
            column.executionCycle(),
        ],
        t,
    );
    const { promise: getPrevFilterList } = useAsync({
        promise: fetchWardSettingListApi,
        resolve: res => {
            dispatch(setWardSettingList(res));
        },
    });
    const filterParam = useFilterParam();
    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
    };
    useEffect(() => {
        if (filterParam) {
            getPrevFilterList({ ...filterParam });
        } else {
            getPrevFilterList({ isAll: 'Y' });
        }
    }, [filterParam]);

    const onClick = e => {
        const { pgConfigNum } = e;
        history.push(`/active/ward-setting/edit/${pgConfigNum}`);
    };
    const onHandleAddPage = () => {
        history.push('/active/ward-setting/add');
    };
    return (
        <div className="grid-main-container">
            <div>
                <Filter reset={reset}>
                    <WardTeam />
                    <Activity />
                    <Risk />
                </Filter>
            </div>
            <CustomCard
                className={'search-list-height'}
                header={{
                    title: t('List of Periodicity Settings by Ward', 'Activity Setting'),
                    subTitle: t('Show list with performance interval set per ward', 'Activity Setting'),
                    action: (
                        <Button
                            className="pnt-btn btn-blue btn-icon"
                            onClick={onHandleAddPage}
                            iconClassName={'icon-plus'}
                        >
                            {t('Add personalization', 'Activity Setting')}
                        </Button>
                    ),
                }}
            >
                <RecordTable
                    columns={columns}
                    data={{
                        rows: wardSettingList.rows,
                        totalCount: wardSettingList.totalCount,
                        totalPage: wardSettingList.totalPage,
                        pageSize: 10,
                    }}
                    onClick={onClick}
                    noDataPlaceholder={t('Data does not exist.')}
                />
            </CustomCard>
        </div>
    );
};

export default WardSetting;
