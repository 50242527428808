import React, {useContext, useEffect} from 'react';
import {Card} from 'reactstrap';
import CustomCard from '../../Common/Card';
import Button from '../../Common/Button';
import Search from './Components/Search';
import useAsync from '../../../util/hooks/useAsync';
import {getCommonCodeListApi} from '../../../api/commonCode';
import Table from '../../Common/Table';
import * as column from '../util/grid/columns';
import {useHistory} from 'react-router-dom';
import {CodeManagementContext, CodeManagementDispatchContext} from './index';
import {PAGE_SIZE, setCodeList, setPrevSearchOption, setSearchOption} from './reducer';
import useTranslation from "../../../util/hooks/useTranslation";
import useColumns from "../../../util/hooks/useColumns";

const makeSearchParam = ({ opt, keyword }) => {
    const searchParam = { pageSize: PAGE_SIZE };
    if (keyword && opt.value) {
        searchParam[opt.value] = keyword;
    }
    return searchParam;
};

const CodeList = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const dispatch = useContext(CodeManagementDispatchContext);
    const { searchOption, prevSearchOption, codeListInfo } = useContext(CodeManagementContext);
    const { promise: getCommonCodeList } = useAsync({
        keepState: true,
        fixedParam: { pageSize: PAGE_SIZE },
        promise: getCommonCodeListApi,
        resolve: response => {
            if (response && response.rows) {
                dispatch(setCodeList({ ...response, pageSize: PAGE_SIZE }));
            } else {
                dispatch(setCodeList({ rows: [], pageSize: PAGE_SIZE }));
            }
        },
    });

    const columns = useColumns([
            column.codeNum(),
            column.clCode(),
            column.clCodeName(),
            column.code(),
            column.codeName(),
            column.orderNum(),
            column.useFlag(),
        ],t
    );

    useEffect(() => {
        if (searchOption) {
            // 검색어 있거나 전체 검색일 경우
            if (searchOption.keyword || !searchOption.opt.value) {
                // 이전 검색 옵션과 다를 경우
                if (
                    searchOption.keyword !== prevSearchOption.keyword ||
                    searchOption.opt.value !== prevSearchOption.opt.value
                ) {
                    getCommonCodeList({ ...makeSearchParam(searchOption) });
                }
            }
        }
        return () => {
            dispatch(setPrevSearchOption(searchOption));
        };
    }, [searchOption]);

    return (
        <div className="grid-main-container">
            <Card className={'filter_ver3'}>
                <>
                    <Search
                        selectedOption={searchOption}
                        handleSearch={selected => {
                            dispatch(setSearchOption(selected));
                        }}
                    />
                </>
            </Card>
            <CustomCard
                //className={'search-list-height'}
                header={{
                    title: t('Common Code List','Menu'),
                    action: (
                        <Button
                            className="pnt-btn btn-orange"
                            onClick={() => {
                                history.push('/common-code/create');
                            }}
                        >
                            {t('Generate code')}
                        </Button>
                    ),
                }}
            >
                <Table
                    data={codeListInfo}
                    columns={columns}
                    onPageChange={pageIdx => {
                        const param = makeSearchParam(searchOption);
                        getCommonCodeList({ ...param, page: pageIdx });
                    }}
                    onTrClick={data => {
                        if (data.codeNum) {
                            history.push(`/common-code/${data.codeNum}`);
                        }
                    }}
                />
            </CustomCard>
        </div>
    );
};

export default CodeList;
