import React, { useEffect, useReducer, useState } from 'react';
import Filter from '../../Components/Filter/Filter';
import CustomCard from '../../../Common/Card';
import useAsync from '../../../../util/hooks/useAsync';
import * as column from '../../util/grid/columns';
import { useHistory } from 'react-router-dom';
import Table from '../../Components/Table/Table';
import { getPatientScheduleHistoryList, getPatientScheduleHistorySubRows } from '../../../../api/activeScheduleHistory';
import historyByPatientReducer, {
    initialState,
    setHistoryPatientList,
    setHistoryPatientSubRows,
    setExpanded,
} from './historyByPatientReducer';
import { SubRowAsync } from '../../Components/Table/Components/SubRows';
import { useDispatch, useSelector } from 'react-redux';
import { dateChange } from '../../util/dateChange';
import Search from '../../Components/Search/Search';
import { SingleSelect } from '../../Components/Search/Components/SingleSelect';
import SearchInput from '../../Components/Search/Components/SearchInput';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import { resetPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';

const HistoryByPatient = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(historyByPatientReducer, initialState);
    const { expanded, historyPatientList } = state;
    const { profile } = useSelector(state => state.UserInfo);

    const { promise: getSubRows, state: patientSubRowsState } = useAsync({
        promise: getPatientScheduleHistorySubRows,
        resolve: (res, req) => {
            dispatch(setHistoryPatientSubRows({ targetNum: req.targetNum, rows: res.rows }));
        },
    });

    const columns = useColumns(
        [
            column.headerGroupWard(),
            column.headerGroupPatientName({ history, path: `/activity-history/history-by-patient/detail` }),
            column.headerGroupDepartment(),
            column.headerGroupActivityDate(),
            column.headerGroupDanger(),
            column.headerDangerEvaluation(),
            column.headerEducation(),
            column.headerGroupDirectInterventionAndNurse(),
            column.headerGroupTreatmentAndPerformanceDateAndTime({
                columns: [
                    {
                        Header: t('When performed'),
                        id: 'exeDate',
                        accessor: (d, idx) => {
                            if (!d.actItemCode) {
                                return (
                                    <span key={d.recordCount}>
                                        {d.recordCount}
                                        {t('times')}
                                    </span>
                                );
                            } else {
                                return (
                                    <span key={idx} title={dateChange(d.exeDate)}>
                                        {dateChange(d.exeDate)}
                                    </span>
                                );
                            }
                        },
                    },
                    {
                        Header: () => null,
                        id: 'expander ',
                        width: 120,
                        Cell: ({ row }) => {
                            const props = { ...row.getToggleRowExpandedProps() };
                            const targetNum = row.original.targetNum;
                            return (
                                <span
                                    {...props}
                                    onClick={e => {
                                        e.stopPropagation();
                                        props.onClick(e);
                                        dispatch(setExpanded(targetNum));
                                        getSubRows({ targetNum, ...filterParam, ...optionData });
                                    }}
                                >
                                    {expanded[targetNum] ? (
                                        <button className={'pnt-btn btn-blue btn-icon btn-icon-only btn-extra-small'}>
                                            <span className={'icon-up'} />
                                        </button>
                                    ) : (
                                        <button className={'pnt-btn btn-gray btn-icon btn-icon-only btn-extra-small'}>
                                            <span className={'icon-down'} />
                                        </button>
                                    )}
                                </span>
                            );
                        },
                        SubCell: () => null,
                    },
                ],
            }),
        ],
        t,
    );

    const { promise: getPrevFilterList, state: patientAssessmentState } = useAsync({
        promise: getPatientScheduleHistoryList,
        resolve: res => {
            dispatch(
                setHistoryPatientList({
                    pageSize: 10,
                    totalCount: res.totalCount,
                    totalPage: res.totalPage,
                    rows: res.rows.map(v => ({
                        ...v,
                        //actTypeCodeName: '2021-07-08', //v.exeDate
                        actItemCodeName: [v.bfRisk, v.bsRisk],
                        exeUserName: v.ivCount,
                        exeDate: v.recordCount,
                    })),
                }),
            );
        },
    });

    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
        ),
        [],
    );

    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
        setOptionData({ keyword: '', opt: null });
        dispatch(setExpanded(null));
        setSearchOption(null);
    };
    const filterParam = useFilterParam();
    const [optionData, setOptionData] = useState({ keyword: '', opt: null });
    const handleValueChange = e => {
        const { value } = e.target;
        setOptionData({ ...optionData, keyword: value });
    };

    const [searchOption, setSearchOption] = useState(null);
    const handleOptionChange = e => {
        const { value, label, name } = e;
        setOptionData({ ...optionData, opt: value });
        setSearchOption({ name, value, label });
    };

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearchClick();
        }
    };

    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({ ...optionData, ...filterParam, page: 1, pageSize: 10 });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT',
                    actTypeCodesName: t('Managing pressure ulcer care', 'Pressure ulcers'),
                });
            }
        }
        storeDispatch(resetPatientDetailInfo());
    }, [filterParam, profile]);

    const handlePageChange = pageIndex => {
        if (profile && profile.metaData.drst === 'nr') {
            getPrevFilterList({ ...filterParam, ...optionData, page: pageIndex, pageSize: 10 });
        }
        if (profile && profile.metaData.drst === 'ts') {
            getPrevFilterList({
                ...filterParam,
                ...optionData,
                page: pageIndex,
                pageSize: 10,
                actTypeCodes: 'BS_REPORT',
                actTypeCodesName: t('Managing pressure ulcer care', 'Pressure ulcers'),
            });
        }
    };

    const handleSearchClick = () => {
        if (searchOption) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT',
                    actTypeCodesName: t('Managing pressure ulcer care', 'Pressure ulcers'),
                });
            }
        }
    };

    const [tableHeight, setTableHeight] = useState(null);
    const onTrClick = e => {
        const { targetNum } = e;
        history.push(`/activity-history/detail/${targetNum}`);
    };

    return (
        <div className="grid-main-container">
            <Filter
                reset={reset}
                Search={
                    <Search handleSearchClick={handleSearchClick}>
                        <SingleSelect value={searchOption} handleOptionChange={handleOptionChange} />
                        <SearchInput
                            value={optionData}
                            handleKeyDown={handleKeyDown}
                            handleValueChange={handleValueChange}
                        />
                    </Search>
                }
            >
                <WardTeam />
                <Activity />
                <Risk />
            </Filter>
            <CustomCard
                header={{
                    title: t('History by Patient - List', 'Patient History'),
                    subTitle: t('Function to view activities performed on a patient', 'Patient History'),
                }}
                style={{ height: tableHeight }}
            >
                <Table
                    textAlign={'center'}
                    onPageChange={handlePageChange}
                    columns={columns}
                    data={historyPatientList}
                    expanded={expanded}
                    loading={patientAssessmentState.isLoading || patientSubRowsState.isLoading}
                    renderRowSubComponent={renderRowSubComponent}
                    noDataPlaceholder={t('Data does not exist.')}
                    onTrClick={onTrClick}
                />
            </CustomCard>
        </div>
    );
};

export default HistoryByPatient;
