import React, { useState, useEffect } from 'react';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import ResponsiveBarChart from '../../Common/Charts/BarChart';
import Card from '../Components/WidgetCard';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const initData = [
    {
        key: 'PNT',
        name: 'PNT',
        authorized: 12,
        unAuthorized: 2,
    },
];

const FloorPermissionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { t } = useTranslation();
    const settings = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { floorInOutState } = useSelector(state => state.TagInfo);
    const [floorPermissionStatus, setFloorPermissionStatus] = useState(initData);

    const chartMeta = {
        dataSet: [
            { key: 'authorized', name: t('FloorPermissionStatus;Authorized'), color: '#1f77b4' },
            { key: 'unAuthorized', name: t('FloorPermissionStatus;Unauthorized'), color: '#ff7f0e' },
        ],
    };

    useEffect(() => {
        setFloorPermissionStatus(
            //selectableFloorList
            floorList.map(v => {
                return {
                    key: v.floorId,
                    name: v.floorName,
                    authorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].authorized.length
                            : 0,
                    unAuthorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].unAuthorized.length
                            : 0,
                };
            }),
        );
    }, [floorList, floorInOutState]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <ResponsiveBarChart settings={settings} meta={chartMeta} data={floorPermissionStatus} />
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default FloorPermissionStatus;
