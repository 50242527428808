import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from './useSettings';
import useAsync from '../../../util/hooks/useAsync';

const useMonitor = ({
    config,
    paramInfo = [],
    dynamicParam,
    defaultData,
    fetchData,
    makeData = ({ newData }) => {
        return newData;
    },
}) => {
    const { mode } = useSelector(state => state.DashboardFrame);
    const monitorTO = useRef();
    //초기 상탯값을 최초 렌더싱시에만 계산하기위해서 initialState(지연 초기 state, Lazy initial state)를 사용함
    const [data, setData] = useState(() => makeData({ newData: defaultData }));
    const settings = useSettings(config);
    const { state, promise } = useAsync({ promise: fetchData });

    const monitor = () => {
        const param = {};
        paramInfo.forEach(paramKey => {
            if (settings[paramKey]) {
                param[paramKey] = settings[paramKey];
            }
        });
        promise({ ...param, ...dynamicParam });
        monitorTO.current = setTimeout(monitor, settings.intervalTime);
    };

    const stop = () => {
        clearTimeout(monitorTO.current);
    };
    // useEffect(() => {
    //     if (mode === 'monitoring') {
    //         monitor();
    //     }
    //     return () => {
    //         stop();
    //     };
    // }, []);
    useEffect(() => {
        stop();
        if (mode === 'monitoring') {
            monitor();
        } else {
            setData(makeData({ newData: defaultData }));
        }
        return () => {
            stop();
        };
    }, [mode, config, dynamicParam]);

    useEffect(() => {
        if (state.response) {
            setData(makeData({ newData: state.response }));
        }
    }, [state]);

    return { monitor, stop, data };
};

export default useMonitor;
