import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { FeatureGroup, Marker, Popup } from 'react-leaflet';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import useAsync from '../../../util/hooks/useAsync';
import { fetchBookmarkList, fetchGeofenceList } from '../../../api/common';
import Map from '../../Common/Map/Components/Map';
import RotatedImageOverlay from '../../Common/Map/Components/RotatedImageOverlay';
import Button from '../../Common/Button';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import Label from '../../Common/Label';
import { fetchCategory, fetchIotItemList } from '../../../api/asset';
import GridLayoutCard from '../Components/GridLayoutCard';
import SingleTarget from './Components/SingleTarget';
import AllTarget from './Components/AllTarget';
import realTimeLocationReducer, {
    initialState,
    setBookmarkList,
    setCategoryGroup,
    setCategoryImg,
    setCategoryList,
    setGeofenceInfo,
    setIotItemList,
    setRealTimeLog,
    setSelectedFloor,
    setSelectedItem,
} from './realTimeLocationReducer';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Col, Container, Row } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import ConfirmModal from '../../Common/ConfirmModal';
import AniMarker from '../../Common/Map/Components/AniMarker';

export const RealTimeLocationStatusContext = React.createContext();
export const RealTimeLocationStatusDispatchContext = React.createContext();

const SEARCH_PAGE_SIZE = 20;

const RealTimeLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const { geofenceInOutState, tagListByFloor } = useSelector(state => state.TagInfo);
    const [state, dispatch] = useReducer(realTimeLocationReducer, initialState);
    const {
        floorInfo,
        selectedFloor,
        iotItemList,
        selectedItem,
        categoryForImg,
        realTimeLog,
        bookmark,
        bookmarkList,
        selectedCategory,
    } = state;

    const [search, setSearch] = useState(false);
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [searchTargetName, setSearchTargetName] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [zoom, setZoom] = useState(null);

    const mapRef = useRef();
    const widgetRef = useRef();
    const match = useRouteMatch();

    const { promise: getCategoryList, state: assetCategoryInfo } = useAsync({
        promise: fetchCategory,
        fixedParam: { isAll: 'Y' },
        resolve: response => {
            const { rows } = response;

            const tempCategoryList = [{ value: '', label: t('RealTimeLocationStatus;All Asset') }];
            const tempCategoryForImg = {};
            if (rows && Array.isArray(rows)) {
                rows.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
                const parentCodes = rows.map(category => category.parentCode);
                rows.forEach(category => {
                    tempCategoryForImg[category.categoryCode] = category.normalIconURL;
                    if (!parentCodes.includes(category.categoryCode)) {
                        tempCategoryList.push(category);
                    }
                });
                tempCategoryList[0].value = tempCategoryList
                    .slice(1)
                    .map(category => category.categoryCode)
                    .join(',');
            }
            dispatch(setCategoryList(rows));
            dispatch(setCategoryGroup(rows));
            dispatch(setCategoryImg(tempCategoryForImg));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });
    const { promise: getBookmarkList, state: bookmarkInfo } = useAsync({
        promise: fetchBookmarkList,
        immediate: true,
        resolve: response => {
            const { rows } = response;

            dispatch(setBookmarkList(rows));
        },
    });
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const { promise: getIotItemList, state: iotItemInfo } = useAsync({
        promise: fetchIotItemList,
        keepState: true,
        fixedParam: { pageSize: SEARCH_PAGE_SIZE },
        resolve: response => {
            setSearch(true);
            const { rows } = response;

            if (response.page === 1) {
                dispatch(setIotItemList(rows, t));
            } else {
                // console.log(iotItemList.concat(rows));
                dispatch(setIotItemList(iotItemList.concat(rows), t));
            }
        },
    });

    useEffect(() => {
        dispatch(setSelectedFloor(settings.floor));
    }, []);

    useEffect(() => {
        if (selectedFloor) {
            const floor = Object.keys(tagListByFloor).filter(key => key === selectedFloor);
            const tagList = tagListByFloor[floor];
            let resultTagList = [];

            if (tagList && tagList.length > 0 && settings.targetOption === 'ALL TARGET') {
                if (bookmark && selectedCategory.length > 0) {
                    let bookmarkedTagList = [];
                    for (let i = 0; i < tagList.length; i++) {
                        const tag = bookmarkList.find(v => v.targetNum === tagList[i].target.targetNum);
                        tag && bookmarkedTagList.push(tagList[i]);
                    }

                    let bookmarkedCategoryList = [];
                    for (let i = 0; i < bookmarkedTagList.length; i++) {
                        const tag = selectedCategory.find(v => v === bookmarkedTagList[i].target.categoryCode);
                        tag && bookmarkedCategoryList.push(bookmarkedTagList[i]);
                    }

                    resultTagList = bookmarkedCategoryList;
                } else if (bookmark) {
                    let bookmarkedTagList = [];
                    for (let i = 0; i < tagList.length; i++) {
                        const tag = bookmarkList.find(v => v.targetNum === tagList[i].target.targetNum);
                        tag && bookmarkedTagList.push(tagList[i]);
                    }

                    resultTagList = bookmarkedTagList;
                } else if (selectedCategory.length > 0) {
                    let categoryList = [];
                    for (let i = 0; i < tagList.length; i++) {
                        const tag = selectedCategory.find(v => v === tagList[i].target.categoryCode);
                        tag && categoryList.push(tagList[i]);
                    }

                    resultTagList = categoryList;
                } else {
                    resultTagList = tagList;
                }
            } else if (tagList && tagList.length > 0 && settings.targetOption === 'SINGLE TARGET' && selectedItem) {
                resultTagList = tagList.filter(tag => tag.target.targetId === selectedItem.value);
            }

            dispatch(
                setRealTimeLog({
                    count: resultTagList.length,
                    data: resultTagList,
                }),
            );
        }
    }, [selectedFloor, tagListByFloor, bookmark, selectedCategory]);
    //
    useEffect(() => {
        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            const map = mapRef.current.leafletElement;
            if (map && settings.targetOption === 'ALL TARGET') {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            dispatch(setGeofenceInfo(response.rows, geofenceInOutState));
        }
    }, [getGeofenceInfo]);

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleModalToggle = () => {
        setStatusModal(!statusModal);
    };

    return (
        <RealTimeLocationStatusDispatchContext.Provider value={dispatch}>
            <RealTimeLocationStatusContext.Provider value={state}>
                <GridLayoutCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    ref={widgetRef}
                    subTitle={
                        settings.targetOption === 'ALL TARGET'
                            ? t('RealTimeLocationStatus;All Target')
                            : settings.targetOption === 'SINGLE TARGET' && !selectedItem.hasOwnProperty('label')
                            ? t('RealTimeLocationStatus;Please Search For An Item Under The Search Area') +
                              t('RealTimeLocationStatus;(Single Target)')
                            : selectedItem.label
                    }
                    headerAction={
                        settings.targetOption === 'SINGLE TARGET' && (
                            <Button className={'btn-blue'} onClick={handleSearchAreaClick}>
                                {t('Button;Search')}
                            </Button>
                        )
                    }
                    searchFilter={
                        settings.targetOption === 'SINGLE TARGET' &&
                        showSearchArea && (
                            <div className={'card filter_ver3 absolute-filter'}>
                                <div className={'filter__now'}>
                                    <div className="filter__info">
                                        <Label name={t('AssetCountStatus;Search')} />
                                    </div>
                                    <div className="filter__options">
                                        <div className={'select-container'}>
                                            <AsyncTypeahead
                                                isLoading={iotItemInfo.isLoading}
                                                placeholder={t('RealTimeLocationStatus;Search Item Name or Item ID')}
                                                paginationText={t('Search;Display additional results...')}
                                                emptyLabel={
                                                    !search
                                                        ? t('RealTimeLocationStatus;Please Search for the Item.')
                                                        : t('Search;There are no search results')
                                                }
                                                id="targetId"
                                                multiple={false}
                                                allowNew={false}
                                                options={iotItemList}
                                                maxResults={SEARCH_PAGE_SIZE - 1}
                                                onChange={selected => {
                                                    const item = selected[0];
                                                    if (
                                                        item &&
                                                        item.label &&
                                                        item.label.split('> ')[1] ===
                                                            t('RealTimeLocationStatus;Signal Not Detected')
                                                    ) {
                                                        dispatch(setSelectedItem({}));
                                                        setStatusModal(true);
                                                    } else {
                                                        dispatch(setSelectedItem(item));
                                                    }
                                                }}
                                                onInputChange={query => {
                                                    setSearchTargetName(query);
                                                }}
                                                paginate
                                                clearButton={true}
                                                onPaginate={(e, shownResults) => {
                                                    const page = Math.ceil(shownResults / SEARCH_PAGE_SIZE);
                                                    getIotItemList({
                                                        page,
                                                        opt: 'targetSearch',
                                                        keyword: searchTargetName,
                                                    });
                                                }}
                                                onSearch={query => {
                                                    getIotItemList({
                                                        opt: 'targetSearch',
                                                        keyword: searchTargetName,
                                                    });
                                                }}
                                                useCache={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                >
                    <Map
                        ref={mapRef}
                        onZoomend={e => {
                            setZoom(e.target.getZoom());
                        }}
                    >
                        {settings.targetOption === 'ALL TARGET' ? (
                            <AllTarget widgetRef={widgetRef} mapRef={mapRef} />
                        ) : (
                            <SingleTarget mapRef={mapRef} />
                        )}

                        {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                                onLoad={() => {
                                    const map = mapRef.current.leafletElement;
                                    if (map && realTimeLog.data[0] && settings.targetOption === 'SINGLE TARGET') {
                                        map.setView(realTimeLog.data[0].location.latLng);
                                    }
                                }}
                            />
                        )}

                        <FeatureGroup>
                            {realTimeLog.data.map(log => {
                                const { target } = log;
                                return (
                                    <AniMarker key={target.targetNum} targetInfo={log} zoom={zoom}>
                                        <Popup attribution={{ autoClose: false }}>
                                            <Label
                                                name={target.targetNum}
                                                value={target.targetName}
                                                labelValueClassName={'label-dot label-must'}
                                            />
                                        </Popup>
                                    </AniMarker>
                                );
                            })}
                        </FeatureGroup>
                    </Map>

                    <ConfirmModal
                        initModal={statusModal}
                        header={{ title: t('Search;Search Result') }}
                        toggleModal={handleModalToggle}
                        confirmText={
                            <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                                <Row className={'mb-1'}>
                                    {t(
                                        'RealTimeLocationStatus;The item you selected can not be tracked because there is no signal.',
                                    )}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t(
                                        'RealTimeLocationStatus;The item may be out of range or there might be a problem with the tag.',
                                    )}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t(
                                        'RealTimeLocationStatus;If there is an issue, please contact your system administrator.',
                                    )}
                                </Row>
                            </Container>
                        }
                        removeCancel={true}
                    />
                    {children}
                </GridLayoutCard>
            </RealTimeLocationStatusContext.Provider>
        </RealTimeLocationStatusDispatchContext.Provider>
    );
};

export default RealTimeLocationStatus;
