import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Label from '../../../Common/Label';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';
import GeofenceLayer from '../../../Common/Map/Layers/GeofenceLayer';
import Map from '../../../Common/Map/Components/Map';
import { useSelector } from 'react-redux';
import { borderFullHeight } from '../../style';
import { isMobile } from 'react-device-detect';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchGeofenceList } from '../../../../api/common';
import { AssetSummaryListContext } from '../index';

const SelectedAsset = () => {
    const { selectedAsset, floorInfo } = useContext(AssetSummaryListContext);
    const mapRef = useRef();

    const [markerPosition, setMarkerPosition] = useState([]);

    useEffect(() => {
        setMarkerPosition(selectedAsset.latlng);
    }, []);

    useEffect(() => {
        const map = mapRef.current.leafletElement;
        if (map) {
            map.setView(selectedAsset.latlng);
        }
    }, [selectedAsset]);

    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const { geofenceInOutState } = useSelector(state => state.TagInfo);
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    useEffect(() => {
        if (selectedAsset) {
            getGeofenceList({ floor: selectedAsset.floor });
        }
    }, [selectedAsset]);
    useEffect(() => {
        const { response } = getGeofenceInfo;

        if (response) {
            setGeofenceInfo(
                response.rows.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                }),
            );
        }
    }, [getGeofenceInfo, geofenceInOutState]);

    return (
        <>
            <Row style={{ height: '100%' }}>
                <Col md={'3'}>
                    <div className={'col-xl-12 mb-2'}>
                        <Label
                            name={'Id'}
                            value={selectedAsset.targetId || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>

                    <div className={'col-xl-12 mb-2'} style={{ lineBreak: 'anywhere' }}>
                        <Label
                            name={'Name'}
                            value={selectedAsset.targetName || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>
                    <div className={'col-xl-12 mb-2'}>
                        <Label
                            name={'Category'}
                            value={selectedAsset.categoryCodePath || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>
                </Col>

                <Col md={'1'}>
                    {isMobile ? (
                        <div className="pnt-border border-horiz border-bold mb-4" />
                    ) : (
                        <div className="pnt-border border-vert border-bold mb-4" style={borderFullHeight} />
                    )}
                </Col>

                <Col md="8">
                    <Map ref={mapRef}>
                        {floorInfo && floorInfo.imgURL && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                                onLoad={e => {
                                    const map = mapRef.current.leafletElement;
                                    if (map) {
                                        map.setView(selectedAsset.latlng);
                                    }
                                }}
                            />
                        )}
                        {selectedAsset.floor && geofenceInfo.length > 0 && (
                            <GeofenceLayer geofenceList={geofenceInfo} />
                        )}

                        <Marker
                            key={selectedAsset.targetId}
                            position={markerPosition}
                            icon={L.divIcon({
                                className: 'custom-asset-marker',
                                html: `<div></div>`,
                            })}
                        />
                    </Map>
                </Col>
            </Row>
        </>
    );
};

export default SelectedAsset;
