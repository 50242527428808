import { reqGet, reqPut } from './index';

//활동별 이력
export const getActiveScheduleHistoryList = param => reqGet(`/v3/api/ps01/record`, param);
export const getActiveSchedulePatientHistoryList = param =>
    reqGet(`/v3/api/ps01/record/patient/${param.targetNum}`, param);

//환자별 이력
export const getPatientScheduleHistoryList = param => reqGet(`/v3/api/ps01/record/patient`, param);
export const getPatientScheduleHistorySubRows = param =>
    reqGet(`/v3/api/ps01/record/patient/${param.targetNum}`, param);

//상세페이지 환자별 이력
export const getPatientHistoryList = param => reqGet(`/v3/api/ps01/record/patient/list/${param.targetNum}`, param);

//피부사정 기록
export const getSkinInfoList = param => reqGet(`/v3/api/ps01/skin`, param);
export const getSkinDetailInfoList = param => reqGet(`/v3/api/ps01/skin/list/${param.reportNum}`, param);
export const getSkinInfoApi = param => reqGet(`/v3/api/ps01/skin/${param.reportNum}`, param);
export const getSkinDetailInfo = param => reqGet(`/v3/api/ps01/skin/view/${param.skinNum}`, param);
export const updateSkinInfoApi = param => reqPut(`/v3/api/ps01/skin/view/${param.skinNum}`, param);
