import React, { useEffect, useReducer, useState } from 'react';
import Search from '../../Components/Search/Search';
import Filter from '../../Components/Filter/Filter';
import CustomCard from '../../../Common/Card';
import Button from '../../../Common/Button';
import Table from '../../Components/Table/Table';
import * as column from '../../util/grid/columns';
import useAsync from '../../../../util/hooks/useAsync';
import { useHistory } from 'react-router-dom';
import { getPatientAssessment, getPatientSubRowsAssessment } from '../../../../api/activeSchedule';
import scheduleByPatientReducer, {
    initialState,
    setExpanded,
    setSchedulePatientList,
    setSchedulePatientSubRows,
} from './scheduleByPatientReducer';
import { SubRowAsync } from '../../Components/Table/Components/SubRows';
import { resetPatientDetailInfo, setPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import { useDispatch, useSelector } from 'react-redux';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { SingleSelect } from '../../Components/Search/Components/SingleSelect';
import SearchInput from '../../Components/Search/Components/SearchInput';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';
const ScheduleByPatient = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(scheduleByPatientReducer, initialState);
    const { profile } = useSelector(state => state.UserInfo);
    const { schedulePatientList, expanded } = state;

    const { promise: getPatientSubRows, state: patientSubRowsState } = useAsync({
        promise: getPatientSubRowsAssessment,
        resolve: (res, req) => {
            dispatch(
                setSchedulePatientSubRows({
                    targetNum: req.targetNum,
                    rows: res.rows,
                }),
            );
        },
    });
    const columns = useColumns(
        [
            column.headerGroupWard(),
            column.headerGroupPatientName({ history, path: `/activity-schedule/schedule-by-activity-detail` }),
            column.headerGroupDepartment(),
            column.headerGroupDecubitusDanger(),
            column.headerGroupClassificationOfPerformance(),
            column.headerGroupTimeRemaining(),
            column.headerGroupExpectedDate(),
            column.headerGroupDelay({
                columns: [
                    {
                        Header: () => null,
                        width: 50,
                        id: 'delayCount',
                        accessor: (d, idx) => {
                            const { delayCount } = d;
                            return !d.actItemCode ? (
                                <span key={idx} title={delayCount}>
                                    {delayCount}{t('case')}
                                </span>
                            ) : null;
                        },
                    },
                    {
                        Header: () => null,
                        id: 'expander',
                        width: 120,
                        Cell: ({ row, idx }) => {
                            const props = { ...row.getToggleRowExpandedProps() };
                            const { targetNum } = row.original;
                            return (
                                <span
                                    key={idx}
                                    {...props}
                                    onClick={e => {
                                        e.stopPropagation();
                                        props.onClick(e);
                                        dispatch(setExpanded(targetNum));
                                        getPatientSubRows({ targetNum, ...filterParam, ...optionData });
                                    }}
                                >
                                    {expanded[targetNum] ? (
                                        <button
                                            title={'Detailed list of patients'}
                                            className={'pnt-btn btn-blue btn-icon btn-icon-only btn-extra-small'}
                                        >
                                            <span className={'icon-up'} />
                                        </button>
                                    ) : (
                                        <button
                                            title={'Detailed list of patients'}
                                            className={'pnt-btn btn-gray btn-icon btn-icon-only btn-extra-small'}
                                        >
                                            <span className={'icon-down'} />
                                        </button>
                                    )}
                                </span>
                            );
                        },
                        SubCell: ({ row }) => {
                            const { targetNum, psNum } = row.original;
                            return (
                                <div className={'table-btn-container'}>
                                    <button
                                        className="pnt-txt txt-border txt-btn-secondary mr-2"
                                        onClick={() => {
                                            storeDispatch(setPatientDetailInfo(row.original));

                                            history.push(`/activity-schedule/add-activity/${targetNum}/${psNum}`);
                                        }}
                                    >
                                        {t('Activities')}
                                    </button>
                                    <button
                                        type={'button'}
                                        className="pnt-txt txt-border txt-btn-org"
                                        onClick={() => {
                                            storeDispatch(setPatientDetailInfo(row.original));
                                            history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
                                        }}
                                    >
                                        {t('Perform')}
                                    </button>
                                </div>
                            );
                        },
                    },
                ],
            }),
        ],
        t,
    );
    const { promise: getPrevFilterList, state: patientAssessmentState } = useAsync({
        promise: getPatientAssessment,
        resolve: res => {
            dispatch(
                setSchedulePatientList({
                    rows: res.rows.map(v => ({
                        ...v,
                        exeDate: v.hourCount,
                        exeStatusCodeName: v.targetId,
                        ddate: v.todayCount,
                    })),
                    pageSize: 10,
                    totalCount: res.totalCount,
                    totalPage: res.totalPage,
                }),
            );
        },
    });

    const onHandleTable = () => {
        history.push('/activity-schedule');
    };
    const onHandlePage = () => {
        storeDispatch(resetPatientDetailInfo(null));
        history.push(`/activity-schedule/add-activity`);
    };

    const renderRowSubComponent = React.useCallback(
        ({ row, rowProps, visibleColumns }) => (
            <SubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
        ),
        [],
    );

    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
        setOptionData({ keyword: '', opt: null });
        dispatch(setExpanded(null));
        setSearchOption(null);
    };
    const filterParam = useFilterParam();
    const [optionData, setOptionData] = useState({ keyword: '', opt: null });
    const handleValueChange = e => {
        const { value } = e.target;
        setOptionData({ ...optionData, keyword: value });
    };

    const [searchOption, setSearchOption] = useState(null);
    const handleOptionChange = e => {
        const { value, label, name } = e;
        setOptionData({ ...optionData, opt: value });
        setSearchOption({ name, value, label });
    };

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearchClick();
        }
    };

    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({ ...optionData, ...filterParam, page: 1, pageSize: 10 });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
        storeDispatch(resetPatientDetailInfo());
    }, [filterParam, profile]);

    const handlePageChange = pageIndex => {
        if (profile && profile.metaData.drst === 'nr') {
            getPrevFilterList({ ...filterParam, ...optionData, page: pageIndex, pageSize: 10 });
        }
        if (profile && profile.metaData.drst === 'ts') {
            getPrevFilterList({
                ...filterParam,
                ...optionData,
                page: pageIndex,
                pageSize: 10,
                actTypeCodes: 'BS_REPORT,BS_EDU',
                actItemCodes: 'BS_REPORT_1,BS_IV_1',
                actTypeCodesName: '욕창치료관리,욕창예방활동',
                actItemCodesName: '욕창치료관리,전신피부상태확인',
            });
        }
    };
    const handleSearchClick = () => {
        if (searchOption) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
    };
    const onTrClick = e => {
        const { targetNum } = e;
        history.push(`/activity-schedule/schedule-by-activity-detail/${targetNum}`);
    };

    return (
        <div className="grid-main-container">
            <Filter
                reset={reset}
                Search={
                    <Search handleSearchClick={handleSearchClick}>
                        <SingleSelect value={searchOption} handleOptionChange={handleOptionChange} />
                        <SearchInput
                            value={optionData}
                            handleKeyDown={handleKeyDown}
                            handleValueChange={handleValueChange}
                        />
                    </Search>
                }
            >
                <WardTeam />
                <Activity />
                <Risk />
            </Filter>

            <CustomCard
                header={{
                    title: t('Patient-specific appointment list','Activity ScheduleList'),
                    subTitle: t('List of pressure ulcer activities scheduled to be performed','Activity ScheduleList'),
                    action: (
                        <>
                            <Button className="pnt-btn btn-orange" onClick={onHandleTable}>
                                {t('View by activity','Activity ScheduleList')}
                            </Button>
                            <Button
                                className="pnt-btn btn-blue btn-icon"
                                iconClassName={'icon-plus'}
                                onClick={onHandlePage}
                            >
                                {t('Add an activity','Activity ScheduleList')}
                            </Button>
                        </>
                    ),
                }}
            >
                <Table
                    textAlign={'center'}
                    columns={columns}
                    data={{
                        page: schedulePatientList.page,
                        rows: schedulePatientList.rows,
                        totalCount: schedulePatientList.totalCount,
                        totalPage: schedulePatientList.totalPage,
                        pageSize: 10,
                    }}
                    onTrClick={onTrClick}
                    noDataPlaceholder={t('Data does not exist.')}
                    renderRowSubComponent={renderRowSubComponent}
                    loading={patientAssessmentState.isLoading || patientSubRowsState.isLoading}
                    onPageChange={handlePageChange}
                    expanded={expanded}
                />
            </CustomCard>
        </div>
    );
};

export default ScheduleByPatient;
