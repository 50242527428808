import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_FLOOR_INFO = 'realTimeLocation/SET_FLOOR_INFO';
const SET_SELECTED_FLOOR = 'realTimeLocation/SET_SELECTED_FLOOR';
const SET_GEOFENCE_INFO = 'realTimeLocation/SET_GEOFENCE_INFO';
const SET_CATEGORY_LIST = 'realTimeLocation/SET_CATEGORY_LIST';
const SET_CATEGORY_GROUP = 'realTimeLocation/SET_CATEGORY_GROUP';
const SET_CATEGORY_IMG = 'realTimeLocation/SET_CATEGORY_IMG';
const SET_IOT_ITEM_LIST = 'realTimeLocation/SET_IOT_ITEM_LIST';
const SET_SELECTED_ITEM = 'realTimeLocation/SET_SELECTED_ITEM';
const SET_SELECTED_ITEM_INFO = 'realTimeLocation/SET_SELECTED_ITEM_INFO';
const SET_REAL_TIME_LOG = 'realTimeLocation/SET_REAL_TIME_LOG';
const SET_BOOKMARK = 'realTimeLocation/SET_BOOKMARK';
const SET_BOOKMARK_LIST = 'realTimeLocation/SET_BOOKMARK_LIST';
const SET_SELECTED_CATEGORY = 'realTimeLocation/SET_SELECTED_CATEGORY';
const SET_POPUP = 'realTimeLocation/SET_POPUP';

export const {
    setFloorInfo,
    setSelectedFloor,
    setGeofenceInfo,
    setCategoryList,
    setCategoryGroup,
    setCategoryImg,
    setIotItemList,
    setSelectedItem,
    setRealTimeLog,
    setBookmark,
    setBookmarkList,
    setSelectedCategory,
    setPopup,
} = createActions(
    {
        SET_FLOOR_INFO: floorInfo => floorInfo,
        SET_SELECTED_FLOOR: selectedFloor => selectedFloor,
        SET_GEOFENCE_INFO: (geofenceInfo, geofenceInOutState) => {
            return { geofenceInfo, geofenceInOutState };
        },
        SET_CATEGORY_LIST: categoryList => categoryList,
        SET_CATEGORY_GROUP: categoryGroup => categoryGroup,
        SET_CATEGORY_IMG: categoryImg => categoryImg,
        SET_IOT_ITEM_LIST: (iotItemList, t) => {
            return { iotItemList, t };
        },
        SET_SELECTED_ITEM: selectedItem => selectedItem,
        SET_REAL_TIME_LOG: realTimeLog => realTimeLog,
        SET_BOOKMARK: bookmark => bookmark,
        SET_BOOKMARK_LIST: bookmarkList => bookmarkList,
        SET_SELECTED_CATEGORY: category => category,
        SET_POPUP: popup => popup,
    },
    {
        prefix: 'realTimeLocation',
    },
);

export const initialState = {
    floorInfo: {},
    selectedFloor: '',
    realTimeLog: { count: 0, data: [] },
    iotItemList: [],
    geofenceInfo: [],
    selectedItem: {},
    categoryList: [],
    categoryGroup: [],
    categoryForImg: {},
    bookmark: false,
    bookmarkList: [],
    selectedCategory: [],
    popup: {
        floorDetailPopup: false,
        filteringPopup: false,
    },
};

const realTimeLocationReducer = handleActions(
    {
        [SET_FLOOR_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.floorInfo = { ...action.payload };
            });
        },
        [SET_SELECTED_FLOOR]: (state, action) => {
            return produce(state, draft => {
                draft.selectedFloor = action.payload;
            });
        },
        [SET_GEOFENCE_INFO]: (state, action) => {
            return produce(state, draft => {
                const { geofenceInfo, geofenceInOutState } = action.payload;
                draft.geofenceInfo = geofenceInfo.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                });
            });
        },

        [SET_CATEGORY_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.categoryList = [...action.payload];
            });
        },
        [SET_CATEGORY_GROUP]: (state, action) => {
            return produce(state, draft => {
                draft.categoryGroup = [...action.payload].filter(v => v.depth === 0).map(v => v.categoryCodePath);
            });
        },
        [SET_CATEGORY_IMG]: (state, action) => {
            return produce(state, draft => {
                draft.categoryForImg = { ...action.payload };
            });
        },
        [SET_REAL_TIME_LOG]: (state, action) => {
            return produce(state, draft => {
                draft.realTimeLog = action.payload;
            });
        },
        [SET_IOT_ITEM_LIST]: (state, action) => {
            return produce(state, draft => {
                const { iotItemList, t } = action.payload;

                draft.iotItemList = iotItemList.map(item => {
                    return {
                        value: item.targetId ? item.targetId : item.value,
                        label: item.targetName
                            ? `(name) ${item.targetName} | (ID) ${item.targetId} > ${
                                  !item.lastDate || item.lastDate === 0 || item.lostSignalStatus === 'LOSTSIGNAL_ON'
                                      ? t('RealTimeLocationStatus;Signal Not Detected')
                                      : t('RealTimeLocationStatus;Signal Detected')
                              }`
                            : item.label,
                    };
                });
            });
        },
        [SET_SELECTED_ITEM]: (state, action) => {
            return produce(state, draft => {
                draft.selectedItem = { ...action.payload };
            });
        },
        [SET_SELECTED_CATEGORY]: (state, action) => {
            return produce(state, draft => {
                draft.selectedCategory = action.payload;
            });
        },
        [SET_BOOKMARK]: (state, action) => {
            return produce(state, draft => {
                draft.bookmark = !state.bookmark;
            });
        },
        [SET_BOOKMARK_LIST]: (state, action) => {
            return produce(state, draft => {
                // const bookmarkedTargetNum = action.payload.map(bookmark => {
                //     return bookmark.targetNum;
                // });
                draft.bookmarkList = action.payload;
            });
        },
        [SET_POPUP]: (state, action) => {
            return produce(state, draft => {
                draft.popup = { ...state.popup, ...action.payload };
            });
        },
    },
    initialState,
);

export default realTimeLocationReducer;
