import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Col } from 'reactstrap';
import { ModalTitle } from '../UserBox';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { setColorScheme } from '../../../../../reducers/ThemeOptions';
import { useDispatch, useSelector } from 'react-redux';

const ThemeSwatch = ({ className }) => {
    const dispatch = useDispatch();
    const { colorScheme } = useSelector(state => state.ThemeOptions);
    return (
        <div
            className={cx('swatch-holder cursor-pointer', className, { active: colorScheme === className })}
            onClick={() => {
                dispatch(setColorScheme(className));
            }}
        />
    );
};

const AppSettingModal = ({ initModal, toggleModal }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={initModal}>
            <ModalHeader>
                <ModalTitle>{t('UserBox;Application setting')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Col>
                    <h6 className="pb-2">{t('UserBox;Please choose color scheme')}</h6>
                    <div className="theme-settings-swatches">
                        <ThemeSwatch className={'pnt-light'} />
                        {/*<ThemeSwatch className={'pnt-dark'} />*/}
                        <ThemeSwatch className={'pnt-navy'} />
                    </div>
                </Col>
            </ModalBody>
            <ModalFooter>
                <button className="pnt-btn btn-lightgray w-20" onClick={toggleModal}>
                    {t('UserBox;Close')}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default AppSettingModal;
