import React, { useContext, useEffect } from 'react';
import CustomCard from '../../../../Common/Card';
import * as column from '../../../util/grid/columns';
import { SkinConditionRecordDispatchContext, SkinConditionRecordStateContext } from './SkinConditionRecord';
import { setGradeOption, setSkinConditionRecordList, setSkinInfo } from '../skinConditionRecordManagementReducer';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSkinDetailInfo, getSkinDetailInfoList } from '../../../../../api/activeScheduleHistory';
import { useParams } from 'react-router-dom';
import Table from '../../../../Common/Table';

const SkinConditionRecordList = () => {
    const param = useParams();
    const { reportNum } = param;
    const state = useContext(SkinConditionRecordStateContext);
    const dispatch = useContext(SkinConditionRecordDispatchContext);
    const { skinConditionRecordList, skinInfo } = state;

    const { promise: getSkinAssmangementList } = useAsync({
        promise: getSkinDetailInfoList,
        resolve: res => {
            dispatch(setSkinConditionRecordList(res));
        },
    });

    const { promise: getSkinAssmangementInfo } = useAsync({
        promise: getSkinDetailInfo,
        resolve: res => {
            dispatch(setGradeOption(res));
            dispatch(setSkinInfo(res));
        },
    });
    useEffect(() => {
        getSkinAssmangementList({ reportNum });
    }, []);

    const onTrClick = selected => {
        const { skinNum } = selected;
        getSkinAssmangementInfo({ skinNum });
    };
    const handlePageChange = pageIndex => {
        getSkinAssmangementList({ reportNum, page: pageIndex, pageSize: 10 });
    };
    return (
        <CustomCard
            className={'custom-card'}
            header={{
                title: '피부사정기록',
                subTitle: '환자에게 수행될 활동 일정을 수정하는 기능입니다.',
            }}
        >
            <Table
                onPageChange={handlePageChange}
                columns={columns}
                data={skinConditionRecordList}
                onTrClick={onTrClick}
                rowSelect={{
                    selected: skinInfo,
                    rowKey: 'skinNum',
                    selectedRowClassName: 'selected-color',
                }}
            />
        </CustomCard>
    );
};
const columns = [column.recordDate(), column.aiJudgment(), column.expertJudgment(), column.siteOfOccurrence()];
export default SkinConditionRecordList;
