import { reqPost, reqGet, reqPut, reqBodyDelete, reqBodyPut } from './index';

export const getGroupList = param => reqGet('/v3/api/ps01/patient/group/info', param);

export const createPatientGroup = param => reqPost(`/v3/api/ps01/patient/group/info`, param);
export const updatePatientGroup = param => reqPut(`/v3/api/ps01/patient/group/info/${param.patientGroupNum}`, param);
export const getMappingPatientList = param => reqGet('/v3/api/ps01/patient/group/info/mapping', param);
export const updateMappingPatient = param => reqPut('/v3/api/ps01/patient/group/info/mapping', param);

export const createGroupMapping = param =>
    reqPost(
        `/v3/api/ps01/patient/group/info/mapping?patientGroupNum=${param.patientGroupNum}&targetNum=${param.targetNum}`,
        param,
    );
export const updateGroupMapping = param => reqBodyPut(`/v3/api/ps01/patient/group/info/mapping`, param);

export const deleteGroupMapping = param => reqBodyDelete(`/v3/api/ps01/patient/group/info/mapping`, param);

export const getUnMappingPatientList = param =>
    reqGet(
        `/v3/api/ps01/patient/group/info/un-mapping`, { ...param },
    );
export const fetchPatientListApi = param => reqGet(`/v3/api/ps01/patient/info`, param);
export const createPatientInfo = param => reqPost(`/v3/api/ps01/patient/group/info/mapping`, param);
