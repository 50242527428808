import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_SENSOR_TYPE = 'realTimeSensorMonitoring/SET_SENSOR_TYPE';
const SET_FLOOR_INFO = 'realTimeSensorMonitoring/SET_FLOOR_INFO';
const SET_SELECTED_FLOOR = 'realTimeSensorMonitoring/SET_SELECTED_FLOOR';
const SET_SENSOR_LIST = 'realTimeSensorMonitoring/SET_SENSOR_LIST';
const SET_SELECTED_SENSOR = 'realTimeSensorMonitoring/SET_SELECTED_SENSOR';
const SET_SELECTED_SENSOR_TYPE = 'realTimeSensorMonitoring/SET_SELECTED_SENSOR_TYPE';
const SET_SELECTED_SENSING_TYPES = 'realTimeSensorMonitoring/SET_SELECTED_SENSING_TYPES';
const CLEAR_SENSING_TYPES = 'realTimeSensorMonitoring/CLEAR_SENSING_TYPES';

export const {
    setSensorType,
    setFloorInfo,
    setSelectedFloor,
    setSensorList,
    setSelectedSensor,
    setSelectedSensorType,
    setSelectedSensingTypes,
    clearSensingTypes,
} = createActions(
    {
        SET_SENSOR_TYPE: sensorType => sensorType,
        SET_FLOOR_INFO: floorInfo => floorInfo,
        SET_SELECTED_FLOOR: selectedFloor => selectedFloor,
        SET_SENSOR_LIST: sensorList => sensorList,
        SET_SELECTED_SENSOR: selectedSensor => selectedSensor,
        SET_SELECTED_SENSOR_TYPE: selectedSensorType => selectedSensorType,
        SET_SELECTED_SENSING_TYPES: (name, value) => {
            return {
                name,
                value,
            };
        },
        CLEAR_SENSING_TYPES: sensingTypes => sensingTypes,
    },
    {
        prefix: 'realTimeSensorMonitoring',
    },
);

export const initialState = {
    sensorType: '',
    floorInfo: {},
    selectedFloor: [],
    selectedSensor: {},
    sensorList: [],
    selectedSensorType: [],
    selectedSensingTypes: {
        Environment: [],
        Health: [],
        Movement: [],
        Chemistry: [],
        Other: [],
    },
};

const realTimeSensorMonitoringReducer = handleActions(
    {
        [SET_SENSOR_TYPE]: (state, action) => {
            return produce(state, draft => {
                draft.sensorType = action.payload;
            });
        },
        [SET_FLOOR_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.floorInfo = { ...action.payload };
            });
        },
        [SET_SELECTED_FLOOR]: (state, action) => {
            return produce(state, draft => {
                draft.selectedFloor = action.payload;
            });
        },
        [SET_SENSOR_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                const filterNullValue = data.filter(sensor => sensor.targetId);
                const tempData = [];
                for (let i = 0; i < filterNullValue.length; i++) {
                    for (let j = 0; j < filterNullValue[i].sensorItems.length; j++) {
                        const sensorItem = filterNullValue[i].sensorItems[j];
                        tempData.push({ ...sensorItem, ...filterNullValue[i] });
                    }
                }

                draft.sensorList = tempData;
            });
        },
        [SET_SELECTED_SENSOR]: (state, action) => {
            return produce(state, draft => {
                draft.selectedSensor = { ...action.payload };
            });
        },
        [SET_SELECTED_SENSOR_TYPE]: (state, action) => {
            return produce(state, draft => {
                draft.selectedSensorType = action.payload;
            });
        },
        [SET_SELECTED_SENSING_TYPES]: (state, action) => {
            return produce(state, draft => {
                const { name, value } = action.payload;

                draft.selectedSensingTypes = { ...state.selectedSensingTypes, [name]: [...value] };
            });
        },
        [CLEAR_SENSING_TYPES]: (state, action) => {
            return produce(state, draft => {
                draft.selectedSensingTypes = {
                    Environment: [],
                    Health: [],
                    Movement: [],
                    Chemistry: [],
                    Other: [],
                };
            });
        },
    },
    initialState,
);

export default realTimeSensorMonitoringReducer;
