import { reqGet } from './index';

export const fetchFloorList = param => reqGet('/v3/api/common/floor', { ...param, isAll: 'Y' });
export const fetchGeofenceList = param => reqGet('/v3/api/common/geofence', { ...param, isAll: 'Y' });

//북마크 여부
export const fetchBookmarkList = param => reqGet('v3/api/common/target/bookmark', { ...param, isAll: 'Y' });

// 알람 정보 목록
export const fetchAlramList = param => reqGet('v3/api/ps01/alert', { ...param });
// 낙상예방 알림조회
export const fetchFallPreventionAlarmListApi = param => reqGet('/v3/api/ps01/alert/wms', { ...param });
