import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    url: {},
    nurseInfo: {},
    nurseInfoList: { rows: [], pageSize: 10 },
};

const CommonInfo = createSlice({
    name: 'common/CommonInfo',
    initialState,
    reducers: {
        setUrl: (state, action) => {
            const data = action.payload;
            state.url = data;
        },
        setNurseInfo: (state, action) => {
            const data = action.payload;
            const resultData = {
                exeDate: data.exeDate,
                exeUserName: data.exeUserName,
                exeUserNum: data.exeUserNum,
            };
            state.nurseInfo = { ...resultData };
        },
        updateNurseInfo: (state, action) => {
            const data = action.payload;
            if (data) {
                state.nurseInfo = { ...state.nurseInfo, ...data };
            } else {
                state.nurseInfo = {};
            }
        },
        setNurseInfoList: (state, action) => {
            const data = action.payload;
            if (data) {
                let resultData = [];

                resultData = data.rows.reduce((acc, curr, i) => {
                    acc.push({ ...curr.metaData, ...curr });
                    return acc;
                }, []);
                state.nurseInfoList = { ...action.payload, rows: resultData };
            } else {
                state.nurseInfoList = { rows: [], pageSize: 10 };
            }
        },
    },
});

export const { setUrl, setNurseInfo, updateNurseInfo, setNurseInfoList } = CommonInfo.actions;
export default CommonInfo.reducer;
