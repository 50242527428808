import { reqDelete, reqGet, reqPost, reqPut } from './index';

export const fetchActivitySettingListApi = param => reqGet(`/v3/api/ps01/act/config`, param);
export const createActivitySettingApi = param => reqPost(`/v3/api/ps01/act/config`, param);
export const fetchActivitySettingApi = param => reqGet(`/v3/api/ps01/act/config/${param.configNum}`);
export const updateActivitySettingApi = param => reqPut(`/v3/api/ps01/act/config/${param.configNum}`, param);
export const deleteActivitySettingApi = param => reqDelete(`/v3/api/ps01/act/config/${param.configNum}`, param);

export const fetchWardSettingListApi = param => reqGet(`/v3/api/ps01/act/config/pg`, param);
export const createWardSettingApi = param => reqPost(`/v3/api/ps01/act/config/pg`, param);
export const fetchWardSettingApi = param => reqGet(`/v3/api/ps01/act/config/pg/${param.pgConfigNum}`, param);
export const updateWardSettingApi = param => reqPut(`/v3/api/ps01/act/config/pg/${param.pgConfigNum}`, param);
export const deleteWardSettingApi = param => reqDelete(`/v3/api/ps01/act/config/pg/${param.pgConfigNum}`, param);

export const fetchActivitySettingSelectListApi = param => reqGet(`/v3/api/ps01/act/config/pg/exe`, param);
