import React from 'react';

export const onHandleTextChange = value => {
    let text = null;
    let initString = '';
    let resultText = null;

    text = value.reduce((acc, curr, i) => {
        if (curr !== '' && curr) {
            if (!i) {
                resultText = curr;
            } else if (i >= 1) {
                resultText += '/' + curr;
            }
        } else {
            if (!i) {
                resultText = ' - ';
            } else if (i >= 1) {
                resultText += '/' + ' - ';
            }
        }
        return resultText;
    }, initString);
    return text;
};
