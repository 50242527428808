import React, { useEffect, useReducer, useState } from 'react';
import ActiveRecordCard from '../../../Components/ActiveRecordCard';
import ReactSelect from '../../../../Common/Select';
import { useHistory, useParams } from 'react-router-dom';
import {
    initialState,
    setExecutionCycleInfo,
    setExecutionCycleType,
} from '../../ActivitySetting/activitySettingReducer';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import {
    setExecutionCycleOption,
    setExecutionCycleTypeOption,
} from '../../../../../reducers/Common/PerformanceRecordOption';
import TextInput from '../../../../Common/Input/TextInput';
import settingReducer, { setWardInfoList } from '../../settingReducer';
import wardSettingReducer, {
    setActivityCycleInfoList,
    setWardSettingInfo,
    updateWardSettingInfo,
    wardSettingInitialState,
} from '../wardSettingReducer';
import { getGroupList } from '../../../../../api/groupSetting';
import {
    deleteWardSettingApi,
    fetchActivitySettingSelectListApi,
    fetchWardSettingApi,
    updateWardSettingApi,
} from '../../../../../api/activitySetting';
import { DescriptionRow, HandleReactSelect } from '../../../util/componentChange/componentChange';
import useTranslation from '../../../../../util/hooks/useTranslation';

const WardSettingEdit = () => {
    const t = useTranslation('Activity Setting');
    const history = useHistory();
    const param = useParams();
    const { pgConfigNum } = param;
    const storeDispatch = useDispatch();
    const [commonState, commonDispatch] = useReducer(settingReducer, initialState);
    const [state, dispatch] = useReducer(wardSettingReducer, wardSettingInitialState);
    const { executionCycleOption, executionCycleTypeOption } = useSelector(state => state.PerformanceRecordOption);
    const { single, repeat } = executionCycleOption;
    const { executionCycleType } = commonState;
    const { type } = executionCycleType;
    const { activityCycleInfoList, wardSettingInfo } = state;

    //제어 컴포넌트
    const [activityCycle, setActivityCycle] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [activityCycleSecond, setActivityCycleSecond] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [exeCycleValue, setExeCycleValue] = useState(null);
    const [exeCycleValueSecond, setExeCycleValueSecond] = useState(null);
    const [exeCycleValueThird, setExeCycleValueThird] = useState(null);

    const { promise: updateWardSetting } = useAsync({
        promise: updateWardSettingApi,
        resolve: () => {
            onHandleBackPage();
        },
    });
    const { promise: getWardSettingInfo } = useAsync({
        promise: fetchWardSettingApi,
        resolve: res => {
            const {
                exeCycleCode,
                exeCycleCodeName,
                exeCycleTypeCode,
                exeCycleTypeCodeName,
                exeCycleVal,
                exeCycleVal2,
                exeCycleVal3,
            } = res;
            setActivityCycle({ label: exeCycleTypeCodeName, value: exeCycleTypeCode });
            setActivityCycleSecond({ label: exeCycleCodeName, value: exeCycleCode });
            setExeCycleValue({ label: exeCycleVal, value: exeCycleVal });
            if (exeCycleCode === 'EXE_CYCLE_5') {
                setExeCycleValueSecond({ label: exeCycleVal2, value: exeCycleVal2 });
                setExeCycleValueThird({ label: exeCycleVal3, value: exeCycleVal3 });
            }
            dispatch(setWardSettingInfo(res));
        },
    });

    const { promise: getWardInfoList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            commonDispatch(setWardInfoList(res));
        },
    });

    const { promise: getActivitySettingSelectList } = useAsync({
        promise: fetchActivitySettingSelectListApi,
        resolve: res => {
            dispatch(setActivityCycleInfoList(res));
        },
    });
    const { promise: getExecutionCycleOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            dispatch(setExecutionCycleInfo(data));
        },
    });
    const { promise: getExecutionCycleTypeOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleTypeOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleType({ type: data, executionCycleOption }));
        },
    });
    useEffect(() => {
        getWardSettingInfo({ pgConfigNum });
        getWardInfoList({});
        getActivitySettingSelectList({});
        getExecutionCycleOption({ clCode: 'EXE_CYCLE', codeDepth: '2' });
        getExecutionCycleTypeOption({ clCode: 'EXE_CYCLE_TYPE', codeDepth: '2' });
    }, []);

    const { promise: removeWardSettingInfo } = useAsync({
        promise: deleteWardSettingApi,
        resolve: () => {
            onHandleBackPage();
        },
    });

    const onHandleBackPage = () => {
        history.push('/active/ward-setting');
    };
    const onHandleDelete = () => {
        removeWardSettingInfo({ pgConfigNum });
    };
    const onHandleSave = () => {
        const {
            exeCycleCode,
            exeCycleTypeCode,
            exeCycleVal,
            exeCycleVal2,
            exeCycleVal3,
            patientGroupNum,
            configNum,
        } = wardSettingInfo;

        updateWardSetting({
            pgConfigNum,
            configNum,
            exeCycleCode,
            exeCycleTypeCode,
            exeCycleVal,
            exeCycleVal2,
            exeCycleVal3,
            patientGroupNum,
        });
    };
    const onChange = selected => {
        const { value, label } = selected;
        dispatch(updateWardSettingInfo({ exeCycleVal: value }));
        setExeCycleValue({ label, value });
    };

    const selectStyle = {
        control: provided => {
            return {
                ...provided,
                width: '40vw',
            };
        },
        option: provided => {
            return {
                ...provided,
                width: '40vw',
            };
        },
    };
    const optionSetting = value => {
        if (wardSettingInfo) {
            let data = null;
            data = value.map(v => {
                if (v.value === wardSettingInfo.configNum) {
                    return v;
                }
            });
            return data;
        }
    };
    const exeCycleChange = selected => {
        const { label, codeName } = selected;
        setExeCycleValue(null);
        setExeCycleValueSecond(null);
        setExeCycleValueThird(null);
        dispatch(updateWardSettingInfo({ exeCycleVal: '' }));
        dispatch(updateWardSettingInfo({ exeCycleVal2: '' }));
        dispatch(updateWardSettingInfo({ exeCycleVal3: '' }));
        dispatch(updateWardSettingInfo({ exeCycleCodeName: '' }));
        dispatch(updateWardSettingInfo({ exeCycleTypeCode: codeName }));
        dispatch(updateWardSettingInfo({ exeCycleTypeCodeName: label }));
        commonDispatch(setExecutionCycleType({ type: selected, executionCycleOption }));
        setActivityCycle({ label, value: codeName });
        setActivityCycleSecond({ label: t('Select', 'Evaluation'), value: 0 });
    };

    const activityCycleInfoListRows = activityCycleInfoList.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const executionCycleTypeOptionRows = executionCycleTypeOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const singleRows = single.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const repeatRows = repeat.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        <>
            <ActiveRecordCard
                headerTitle={t('Set Per-Ward Performance Period - Edit')}
                buttonOption={[
                    {
                        label: t('Back', 'Active Dashboard'),
                        color: 'btn-gray',
                        borderOption: true,
                        onClick: onHandleBackPage,
                    },
                    { label: t('Delete', 'Active Dashboard'), color: 'btn-danger', onClick: onHandleDelete },
                    {
                        label: t('Save', 'Active Dashboard'),
                        iconBtnClass: 'btn-icon',
                        className: 'icon-save',
                        onClick: onHandleSave,
                    },
                ]}
                headerTitleSub={t('Ability to set separate pressure ulcer activity conditions and activity frequency.')}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Select cycles by activity')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <ReactSelect
                                styles={selectStyle}
                                options={activityCycleInfoListRows}
                                value={optionSetting(activityCycleInfoListRows)}
                                isMulti={false}
                                isSearchable={false}
                                onChange={selected => {
                                    const { value } = selected;
                                    dispatch(updateWardSettingInfo({ configNum: value }));
                                }}
                                customControlStyles={{ width: '1350px' }}
                                customOptionStyles={{ width: '1350px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Select a ward')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <span className="label-options">{wardSettingInfo && wardSettingInfo.patientGroupName}</span>
                        }
                    />
                    <DescriptionRow
                        label={t('Pressure Ulcer Activity')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <span className="label-options">
                                {t(wardSettingInfo && wardSettingInfo.actTypeCodeName, 'ActTypeCode')}/
                                {t(wardSettingInfo && wardSettingInfo.actItemCodeName, 'ActTypeCode')}
                            </span>
                        }
                    />
                    <DescriptionRow
                        label={t('Performance Conditions')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <>
                                {wardSettingInfo &&
                                    `${t(wardSettingInfo.actItemCodeName, 'ActTypeCode')} / ${t(
                                        wardSettingInfo.exeTypeDetailCodeName,
                                        'ActTypeCode',
                                    )}`}
                            </>
                        }
                    />
                    <DescriptionRow
                        label={t('Pressure Ulcer Risk Classification')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<>{wardSettingInfo && t(wardSettingInfo.riskGradeCodesName, 'ActTypeCode')}</>}
                    />
                    <DescriptionRow
                        label={t('Performance cycle', 'Common Columns')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <>
                                {executionCycleTypeOption.rows.length > 0 &&
                                    executionCycleOption.single.length > 0 &&
                                    type && (
                                        <>
                                            <ReactSelect
                                                options={executionCycleTypeOptionRows}
                                                value={{
                                                    value: activityCycle.value,
                                                    label: t(activityCycle.label, 'ActTypeCode'),
                                                }}
                                                onChange={exeCycleChange}
                                            />
                                            <ReactSelect
                                                options={
                                                    activityCycle.value === 'EXE_CYCLE_TYPE_2' ? repeatRows : singleRows
                                                }
                                                value={{
                                                    label: t(activityCycleSecond.label, 'ActTypeCode'),
                                                    value: activityCycleSecond.value,
                                                }}
                                                onChange={selected => {
                                                    const { label, codeValue } = selected;

                                                    dispatch(updateWardSettingInfo({ exeCycleCode: codeValue }));
                                                    dispatch(updateWardSettingInfo({ exeCycleCodeName: label }));
                                                    commonDispatch(setExecutionCycleInfo(selected));
                                                    setActivityCycleSecond({ label, value: codeValue });
                                                }}
                                            />
                                        </>
                                    )}
                                {['EXE_CYCLE_1', 'EXE_CYCLE_2', 'EXE_CYCLE_3', 'EXE_CYCLE_4', 'EXE_CYCLE_7'].includes(
                                    activityCycleSecond.value,
                                ) && (
                                    <TextInput
                                        name={'exeCycleVal'}
                                        value={(exeCycleValue && exeCycleValue.value) || null}
                                        type={'number'}
                                        min={0}
                                        max={99999}
                                        handleChange={e => {
                                            const { name, value } = e.target;
                                            dispatch(updateWardSettingInfo({ [name]: value }));
                                            setExeCycleValue(value);
                                        }}
                                    />
                                )}
                                {['EXE_CYCLE_5'].includes(activityCycleSecond.value) && (
                                    <>
                                        <HandleReactSelect
                                            options={hourOption}
                                            value={exeCycleValue}
                                            onChange={selected => {
                                                const { value } = selected;
                                                dispatch(updateWardSettingInfo({ exeCycleVal: value }));
                                                setExeCycleValue({ label: value, value });
                                            }}
                                        />
                                        <HandleReactSelect
                                            options={hourOption}
                                            value={exeCycleValueSecond}
                                            onChange={selected => {
                                                const { value } = selected;
                                                dispatch(updateWardSettingInfo({ exeCycleVal2: value }));
                                                setExeCycleValueSecond({ label: value, value });
                                            }}
                                        />
                                        <HandleReactSelect
                                            options={hourOption}
                                            value={exeCycleValueThird}
                                            onChange={selected => {
                                                const { value } = selected;
                                                dispatch(updateWardSettingInfo({ exeCycleVal3: value }));
                                                setExeCycleValueThird({ label: value, value });
                                            }}
                                        />
                                    </>
                                )}
                                {['EXE_CYCLE_6'].includes(activityCycleSecond.value) && (
                                    <HandleReactSelect value={exeCycleValue} options={weekOption} onChange={onChange} />
                                )}
                            </>
                        }
                    />
                </div>
            </ActiveRecordCard>
        </>
    );
};
const weekOption = [
    { value: '일요일', label: '일요일' },
    { value: '월요일', label: '월요일' },
    { value: '화요일', label: '화요일' },
    { value: '수요일', label: '수요일' },
    { value: '목요일', label: '목요일' },
    { value: '금요일', label: '금요일' },
    { value: '토요일', label: '토요일' },
];
const hourOption = [
    { value: '00:00', label: '00:00' },
    { value: '00:30', label: '00:30' },
    { value: '01:00', label: '01:00' },
    { value: '01:30', label: '01:30' },
    { value: '02:00', label: '02:00' },
    { value: '02:30', label: '02:30' },
    { value: '03:00', label: '03:00' },
    { value: '03:30', label: '03:30' },
    { value: '04:00', label: '04:00' },
    { value: '04:30', label: '04:30' },
    { value: '05:00', label: '05:00' },
    { value: '05:30', label: '05:30' },
    { value: '06:00', label: '06:00' },
    { value: '06:30', label: '06:30' },
    { value: '07:00', label: '07:00' },
    { value: '07:30', label: '07:30' },
    { value: '08:00', label: '08:00' },
    { value: '08:30', label: '08:30' },
    { value: '09:00', label: '09:00' },
    { value: '09:30', label: '09:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
    { value: '21:00', label: '21:00' },
    { value: '21:30', label: '21:30' },
    { value: '22:00', label: '22:00' },
    { value: '22:30', label: '22:30' },
    { value: '23:00', label: '23:00' },
    { value: '23:30', label: '23:30' },
];
export default WardSettingEdit;
