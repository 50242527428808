import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';
import { onHandleAge } from '../util/dateChange';

const SET_ACTIVITY_SCHEDULE_LIST = 'activeSchedule/SET_ACTIVITY_SCHEDULE_LIST';
const SET_PATIENT_SCHEDULE_LIST = 'activeSchedule/SET_PATIENT_SCHEDULE_LIST';
const SET_PATIENT_INFO_LIST = 'activeSchedule/SET_PATIENT_INFO_LIST';
const SET_NURSE_INFO_LIST = 'activeSchedule/SET_NURSE_INFO_LIST';
const SET_NURSE_INFO = 'activeSchedule/SET_NURSE_INFO';
const UPDATE_NURSE_INFO = 'activeSchedule/UPDATE_NURSE_INFO';
const UPDATE_PATIENT_INFO = 'activeSchedule/UPDATE_PATIENT_INFO';
const SET_CARD_HEIGHT = 'activeSchedule/SET_CARD_HEIGHT';
const RESET = 'activeSchedule/RESET';
export const {
    setActivityScheduleList,
    setPatientScheduleList,
    setPatientInfoList,
    setNurseInfoList,
    setNurseInfo,
    updateNurseInfo,
    updatePatientInfo,
    setCardHeight,
} = createActions(
    {
        SET_ACTIVITY_SCHEDULE_LIST: activityScheduleList => activityScheduleList,
        SET_PATIENT_SCHEDULE_LIST: patientScheduleList => patientScheduleList,
        SET_PATIENT_INFO_LIST: patientInfoList => patientInfoList,
        SET_NURSE_INFO_LIST: nurseInfoList => nurseInfoList,
        SET_NURSE_INFO: nurseInfo => nurseInfo,
        UPDATE_NURSE_INFO: nurseInfo => nurseInfo,
        UPDATE_PATIENT_INFO: updatePatientInfo => updatePatientInfo,
        SET_CARD_HEIGHT: cardHeight => cardHeight,
    },
    { prefix: 'activeSchedule' },
);
export const activeScheduleInitialState = {
    activityScheduleList: { rows: [], pageSize: 20 },
    patientScheduleList: { rows: [], pageSize: 20 },
    patientInfoList: { rows: [], pageSize: 20 },
    nurseInfoList: { rows: [], pageSize: 10 },
    nurseInfo: {},
    patientInfo: null,
    cardHeight: 0,
};
const activeScheduleReducer = handleActions(
    {
        [SET_ACTIVITY_SCHEDULE_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.activityScheduleList = action.payload;
            });
        },
        [SET_PATIENT_SCHEDULE_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.patientScheduleList = action.payload;
            });
        },
        [SET_PATIENT_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                let educationInfo = [];
                const data = action.payload;
                educationInfo = data.rows.reduce((acc, curr, i) => {
                    acc.push({
                        targetName: curr.targetName,
                        targetId: curr.targetId,
                        age: onHandleAge(curr.properties.birthDay),
                        ...curr.properties,
                    });
                    return acc;
                }, []);

                draft.patientInfoList = {
                    pageSize: 20,
                    page: action.payload.page,
                    totalCount: action.payload.totalCount,
                    rows: educationInfo,
                };
            });
        },
        [SET_NURSE_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    let resultData = [];
                    resultData = data.rows.reduce((acc, curr, i) => {
                        acc.push({ ...curr.metaData, ...curr });
                        return acc;
                    }, []);
                    draft.nurseInfoList = { ...action.payload, rows: resultData };
                } else {
                    draft.nurseInfoList = { rows: [], pageSize: 10 };
                }
            });
        },
        [SET_NURSE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    const resultData = {
                        exeDate: data.exeDate,
                        exeUserName: data.exeUserName,
                        exeUserNum: data.exeUserNum,
                    };
                    draft.nurseInfo = { ...state.nurseInfo, ...resultData };
                } else {
                    draft.nurseInfo = {};
                }
            });
        },
        [UPDATE_NURSE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    let resultData = null;
                    if (!data['exeDate']) {
                        const { userName: exeUserName, userNum: exeUserNum } = data;
                        resultData = {
                            exeUserName,
                            exeUserNum,
                        };
                    } else {
                        resultData = data;
                    }
                    draft.nurseInfo = { ...state.nurseInfo, ...resultData };
                } else {
                    draft.nurseInfo = {};
                }
            });
        },

        [UPDATE_PATIENT_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.patientInfo = { ...data };
            });
        },
        [SET_CARD_HEIGHT]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.cardHeight = data;
            });
        },

        [RESET]: (state, action) => {
            return activeScheduleInitialState;
        },
    },

    activeScheduleInitialState,
);
export default activeScheduleReducer;
