import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_PATIENT_LIST = 'groupSetting/SET_PATIENT_LIST';
const SET_GROUP_STATUS = 'groupSetting/SET_GROUP_STATUS';
const SET_GROUP_LIST = 'groupSetting/SET_GROUP_LIST';
const SET_GROUP_NAME_LIST = 'groupSetting/SET_GROUP_NAME_LIST';
const SET_GROUP_PATIENT_LIST = 'groupSetting/SET_GROUP_PATIENT_LIST';
const SET_GROUP_TITLE = 'groupSetting/SET_GROUP_TITLE';
const SET_PATIENT_INFO = 'groupSetting/SET_PATIENT_INFO';

const SET_GROUP_INFO = 'groupSetting/SET_GROUP_INFO';
const UPDATE_GROUP_INFO = 'groupSetting/UPDATE_GROUP_INFO';

const SET_WARD_INFO_LIST = 'groupSetting/SET_WARD_INFO_LIST';
const RESET = 'groupSetting/RESET';

export const {
    setPatientList,
    setGroupStatus,
    setGroupList,
    setGroupNameList,
    setGroupPatientList,
    setGroupTitle,
    setPatientInfo,
    setGroupInfo,
    updateGroupInfo,
    setWardInfoList,
    reset,
} = createActions(
    {
        SET_PATIENT_LIST: patientList => patientList,
        SET_GROUP_STATUS: groupStatus => groupStatus,
        SET_GROUP_LIST: groupList => groupList,
        SET_GROUP_NAME_LIST: groupNameList => groupNameList,
        SET_GROUP_PATIENT_LIST: groupPatientList => groupPatientList,
        SET_GROUP_TITLE: groupTitle => groupTitle,
        SET_PATIENT_INFO: patientInfo => patientInfo,
        SET_GROUP_INFO: groupInfo => groupInfo,
        UPDATE_GROUP_INFO: groupInfo => groupInfo,
        SET_WARD_INFO_LIST: wardInfoList => wardInfoList,
        RESET: () => null,
    },
    { prefix: 'groupSetting' },
);

export const initialState = {
    patientList: { rows: [] },
    groupStatus: {},
    groupList: { rows: [] },
    groupNameList: { rows: [] },
    groupPatientList: { rows: [] },
    groupTitle: null,
    groupInfo: null,
    patientInfo: null,
    wardInfoList: { rows: [] },
};

const groupSettingReducer = handleActions(
    {
        [SET_PATIENT_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    const list = action.payload.rows;
                    let resultData = [];
                    resultData = list.reduce((acc, curr, i) => {
                        const { properties } = curr;
                        acc.push({ ...curr, ...properties });
                        return acc;
                    }, []);
                    draft.patientList = { ...data, rows: resultData };
                } else {
                    draft.patientList = { rows: [] };
                }
            });
        },
        [SET_GROUP_STATUS]: (state, action) => {
            return produce(state, draft => {
                draft.groupStatus = action.payload;
            });
        },
        [SET_GROUP_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.groupList = action.payload;
            });
        },
        [SET_GROUP_NAME_LIST]: (state, action) => {
            return produce(state, draft => {
                const groupList = action.payload.rows;
                draft.groupNameList.rows = groupList.map(item => {
                    return { label: item.patientGroupId, value: item.patientGroupNum };
                });
            });
        },
        [SET_GROUP_PATIENT_LIST]: (state, action) => {
            return produce(state, draft => {
                let totalData = {};
                let rows = [];
                const data = action.payload.res.rows || [];
                const page = action.payload.res.page;
                const totalPage = action.payload.res.totalPage;
                const totalCount = action.payload.res.totalCount;
                const type = action.payload.type;
                for (let i = 0; data.length > i; i++) {
                    let targetName = data[i].targetName;
                    let targetId = data[i].targetId;
                    let targetNum = data[i].targetNum;
                    let properties = data[i].properties;
                    let rowsData = { targetName, targetId, targetNum, ...properties };
                    rows.push(rowsData);
                }
                totalData = { page, type, totalPage, totalCount, rows };

                draft.groupPatientList = totalData;
            });
        },
        [SET_GROUP_TITLE]: (state, action) => {
            return produce(state, draft => {
                draft.groupTitle = action.payload;
            });
        },
        [SET_PATIENT_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.patientInfo = action.payload;
            });
        },
        [SET_GROUP_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.groupInfo = action.payload;
            });
        },
        [UPDATE_GROUP_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    draft.groupInfo = { ...state.groupInfo, ...data };
                } else {
                    draft.groupInfo = null;
                }
            });
        },
        [SET_WARD_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                let filterData = [];
                const data = action.payload;
                filterData = data.rows.reduce((acc, curr, i) => {
                    acc.push({ label: curr.wardNo, value: curr.wardNo });
                    return acc;
                }, []);

                const result = filterData.filter((val, idx, arr) => {
                    return arr.findIndex(item => item.label === val.label) === idx;
                });
                draft.wardInfoList.rows = result;
            });
        },
        [RESET]: (state, action) => {
            return produce(state, draft => {
                draft.groupInfo = null;
            });
        },
    },
    initialState,
);
export default groupSettingReducer;
