import React from 'react';
import { useTranslation } from 'react-i18next';

export const useAssetSummaryListColumns = () => {
    const { t } = useTranslation();
    return [
        {
            Header: t('AssetSummaryList;Category'),
            headerClassName: 'flex-center',
            accessor: 'categoryName',
            className: 'flex-center',
        },
        {
            Header: t('AssetSummaryList;Asset Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('AssetSummaryList;Asset Status'),
            headerClassName: 'flex-center',
            accessor: 'assetStatus',
            className: 'flex-center text-ellipsis',
            Cell: ({ value, original }) => {
                return value === '정상' || value === 'NORMAL' ? (
                    <div>{value}</div>
                ) : (
                    <div className={'pnt-txt txt-bold'}>{value}</div>
                );
            },
        },
        {
            Header: t('AssetSummaryList;Floor'),
            headerClassName: 'flex-center',
            accessor: 'floor',
            className: 'flex-center',
            Cell: ({ value, original }) => {
                return value || '-';
            },
        },
        {
            Header: t('AssetSummaryList;Geofence'),
            headerClassName: 'flex-center',
            accessor: 'geofence',
            className: 'flex-center text-ellipsis',
            Cell: ({ value, original }) => {
                return value && value;
            },
        },
        {
            Header: t('AssetSummaryList;Bookmark'),
            headerClassName: 'flex-center',
            accessor: 'isBookmark',
            className: 'flex-center',
        },
        {
            Header: t('AssetSummaryList;Last Detected Time'),
            headerClassName: 'flex-center',
            accessor: 'lastDate',
            className: 'flex-center',
            width: 150,
        },
    ];
};

export const useAssetListFloorDetailColumns = () => {
    const { t } = useTranslation();
    return [
        {
            Header: t('RealTimeLocationStatus;IoT Item Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('RealTimeLocationStatus;IoT Item ID'),
            headerClassName: 'flex-center',
            accessor: 'targetId',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('RealTimeLocationStatus;Category Name'),
            headerClassName: 'flex-center',
            accessor: 'categoryName',
            className: 'flex-center',
        },
        {
            Header: t('RealTimeLocationStatus;Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const useGeofenceTargetInfoColumns = () => {
    const { t } = useTranslation();
    return [
        {
            Header: t('GeofenceStatus;Target Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('GeofenceStatus;Category'),
            headerClassName: 'flex-center',
            accessor: 'category',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('GeofenceStatus;Authorized'),
            headerClassName: 'flex-center',
            accessor: 'authorized',
            className: 'flex-center',
        },
        {
            Header: t('GeofenceStatus;Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};
