import React, { useEffect, useState } from 'react';
import Filter from '../../Components/Filter/Filter';
import Search from '../../Components/Search/Search';
import CustomCard from '../../../Common/Card';
import * as column from '../../util/grid/columns';
import useAsync from '../../../../util/hooks/useAsync';
import { getSkinInfoList } from '../../../../api/activeScheduleHistory';
import { useDispatch } from 'react-redux';
import { setPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import { useHistory } from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import Table from '../../../Common/Table';
import { SingleSelect } from '../../Components/Search/Components/SingleSelect';
import SearchInput from '../../Components/Search/Components/SearchInput';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Risk from '../../Components/Filter/Components/Risk';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import useTranslation from '../../../../util/hooks/useTranslation';

const SkinConditionRecordManagement = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const columns = [
        column.ward(),
        column.patientName(),
        column.department(),
        column.decubitusDanger(),
        column.siteOfOccurrence(),
        column.classification(),
        column.progress(),
        column.dateOfOccurrence(),
    ];

    const [skinInfoList, setSkinInfoList] = useState({ rows: [] });
    const { promise: getPrevFilterList, state: skinInfoState } = useAsync({
        promise: getSkinInfoList,
        resolve: res => {
            setSkinInfoList(res);
        },
    });
    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
        setOptionData({ keyword: null, opt: null });
        setSearchOption(null);
    };
    const filterParam = useFilterParam();
    const [optionData, setOptionData] = useState({ keyword: '', opt: null });
    const handleValueChange = e => {
        const { value } = e.target;
        setOptionData({ ...optionData, keyword: value });
    };
    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearchClick();
        }
    };
    const [searchOption, setSearchOption] = useState(null);
    const handleOptionChange = e => {
        const { value, label, name } = e;
        setOptionData({ ...optionData, opt: value });
        setSearchOption({ name, value, label });
    };

    useEffect(() => {
        if (filterParam) {
            getPrevFilterList({ ...optionData, ...filterParam, page: 1, pageSize: 10 });
        }
    }, [filterParam]);

    const handlePageChange = pageIndex => {
        getPrevFilterList({
            ...filterParam,
            ...optionData,

            page: pageIndex,
            pageSize: 10,
        });
    };

    const handleSearchClick = () => {
        if (searchOption) {
            getPrevFilterList({
                ...filterParam,
                ...optionData,
                page: 1,
                pageSize: 10,
            });
        }
    };
    const onClick = data => {
        const { reportNum } = data;
        storeDispatch(setPatientDetailInfo(data));
        history.push(`/activity-history/skin-condition-record-management/${reportNum}`);
    };
    const [tableHeight, setTableHeight] = useState(null);
    return (
        <>
            <div>
                <ResizeDetector
                    handleWidth
                    handleHeight
                    onResize={(width, height) => {
                        setTableHeight(`calc(100% - ${height}px )`);
                    }}
                >
                    <>
                        <Filter
                            reset={reset}
                            Search={
                                <Search handleSearchClick={handleSearchClick}>
                                    <SingleSelect value={searchOption} handleOptionChange={handleOptionChange} />
                                    <SearchInput
                                        value={optionData}
                                        handleKeyDown={handleKeyDown}
                                        handleValueChange={handleValueChange}
                                    />
                                </Search>
                            }
                        >
                            <WardTeam />
                            <Risk bfRisk={false} />
                        </Filter>
                    </>
                </ResizeDetector>
            </div>
            <CustomCard
                className={'custom-card'}
                style={{ height: tableHeight }}
                header={{
                    title: '욕창 의뢰 목록',
                }}
            >
                <Table
                    onPageChange={handlePageChange}
                    columns={columns}
                    onTrClick={onClick}
                    data={skinInfoList}
                    loading={skinInfoState.isLoading}
                    noDataPlaceholder={t('Data does not exist.')}
                />
            </CustomCard>
        </>
    );
};

export default SkinConditionRecordManagement;
