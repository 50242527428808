import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from '../../Common/Select';
import TextInput from '../../Common/Input/TextInput';
import { fetchAssetSummaryList } from '../../../api/asset';
import useAsync from '../../../util/hooks/useAsync';
import SelectedAsset from './Components/SelectedAsset';
import Label from '../../Common/Label';
import Button from '../../Common/Button';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { LoadingBlock } from '../../Common/LoadingBlock';
import Table from '../../Common/Table';
import { useAssetSummaryListColumns } from '../../../util/grid/column';
import { useRouteMatch } from 'react-router-dom';
import GridLayoutCard from '../Components/GridLayoutCard';
import assetSummaryListReducer, {
    initialState,
    setAssetList,
    setSelectedAsset,
    setFloorInfo,
} from './assetSummaryListReducer';
import { useSelector } from 'react-redux';

export const AssetSummaryListContext = React.createContext();
export const AssetSummaryListDispatchContext = React.createContext();

const AssetSummaryList = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const { config } = widgetInfo;
    const match = useRouteMatch();

    const listWidgetRef = useRef();
    const specificAssetWidgetRef = useRef();

    const columns = useAssetSummaryListColumns();

    const searchOption = useMemo(() => {
        return [
            { value: '', label: t('AssetSummaryList;All Asset') },
            {
                value: 'caution',
                label: t('AssetSummaryList;All Cautionary Asset'),
            },
            { value: 'isUnPermitted', label: t('AssetSummaryList;Out of Authorized Zone') },
            { value: 'isLostSignal', label: t('AssetSummaryList;Lost Signal') },
            { value: 'isLowBattery', label: t('AssetSummaryList;Low Battery') },
            { value: 'isNeverDetected', label: t('AssetSummaryList;No Response') },
        ]
    }, [t])

    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const [state, dispatch] = useReducer(assetSummaryListReducer, initialState);
    const { assetList, selectedAsset } = state;

    const [tableInfo, setTableInfo] = useState({
        totalCount: 0,
        totalPage: 1,
    });
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ value: '', label: t('AssetSummaryList;All Asset') });
    const [searchInput, setSearchInput] = useState({
        target: '',
    });
    const [tableKey, setTableKey] = useState(0);

    // Target Info List에서 자산만 호출
    const { promise: getAssetList, state: assetListInfo } = useAsync({
        promise: fetchAssetSummaryList,
        keepState: true,
        fixedParam: { categoryCode: 'ASSET', pageSize: 10 },
        resolve: res => {
            setTableInfo({
                totalCount: res.totalCount,
                totalPage: res.totalPage,
            });

            const data = res.rows.map(asset => {
                const assetStatus = Object.keys(asset).filter(status => status.includes('Status'));
                let status = [];

                for (let i = 0; i < assetStatus.length; i++) {
                    const onOrOff = asset[assetStatus[i]] ? asset[assetStatus[i]].split('_') : '';
                    if ((asset[assetStatus[i]] && onOrOff[1] === 'ON') || onOrOff[2] === 'ON' || onOrOff[2] === 'LOW') {
                        switch (asset[assetStatus[i]]) {
                            case 'RESTRICTION_KEEP_ON':
                                status.push(t('AssetSummaryList;Out of Authorized Zone'));
                                break;
                            case 'LOSTSIGNAL_ON':
                                status.push(t('AssetSummaryList;Lost Signal'));
                                break;
                            case 'BATTERY_KEEP_LOW':
                                status.push(t('AssetSummaryList;Low Battery'));
                                break;
                        }
                    }
                }
                if (!asset.lastDate || asset.lastDate === 0) {
                    status.push(t('AssetSummaryList;No Response'));
                }

                return {
                    categoryName: asset.categoryName,
                    targetName: asset.targetName,
                    assetStatus: status.length > 0 ? status.join(', ') : t('AssetSummaryList;Normal'),
                    floor: asset.floor,
                    geofence:
                        asset.geofences &&
                        asset.geofences
                            .map(geofence => {
                                return geofence.fcName;
                            })
                            .join(', '),
                    isBookmark: asset.isBookmark,
                    lastDate: asset.lastDate ? moment.unix(asset.lastDate).format('MM-DD HH:mm:ss') : '-',
                    targetId: asset.targetId,
                    categoryCodePath: `ASSET > ${asset.categoryName}`,
                    latlng: [asset.lat, asset.lng],
                };
            });

            dispatch(setAssetList(data));
        },
        immediate: true,
    });

    useEffect(() => {
        if (selectedOption) {
            getAssetList({ status: selectedOption.value });
        }
    }, [selectedOption]);

    const handleSelectValueChange = value => {
        setSelectedOption(value);
    };

    const handleSearchInputChange = e => {
        setSearchInput({ ['target']: e.target.value });
    };
    const handleSearchButtonClick = e => {
        getAssetList({
            status: selectedOption && selectedOption.value,
            ...searchInput,
        });
        setTableKey(tableKey + 1);
    };

    const getTrProps = asset => {
        dispatch(setSelectedAsset(asset));
        dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === asset.floor)));
    };

    const handleGoBackClick = () => {
        dispatch(setSelectedAsset({}));
    };

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handlePageChange = pageIndex => {
        assetListInfo.request && (assetListInfo.request.target || assetListInfo.request.status)
            ? getAssetList({ ...assetListInfo.request, page: pageIndex })
            : getAssetList({ page: pageIndex });
    };

    const handleRefreshClick = () => {
        setSelectedOption({ value: '', label: t('AssetSummaryList;All Asset') });
        setSearchInput({
            target: '',
        });
        getAssetList();
        setTableKey(tableKey + 1);
    };

    useEffect(() => {
        if (selectedOption) {
            setSelectedOption(searchOption.find(v => v.value === selectedOption.value));
        }
    }, [searchOption]);

    return (
        <AssetSummaryListDispatchContext.Provider value={dispatch}>
            <AssetSummaryListContext.Provider value={state}>
                {Object.keys(selectedAsset).length === 0 ? (
                    <GridLayoutCard
                        widgetInfo={widgetInfo}
                        ref={listWidgetRef}
                        subTitle={
                            searchInput.target && Object.keys(selectedOption).length > 0
                                ? selectedOption.label + ' | ' + searchInput.target
                                : Object.keys(selectedAsset).length > 0
                                ? searchInput.target
                                : selectedOption.label
                        }
                        headerAction={
                            <>
                                <Button
                                    className="btn-danger btn-icon-only"
                                    iconClassName={'icon-refresh'}
                                    onClick={handleRefreshClick}
                                />
                                <div className="pnt-border border-vert" />
                                <Button className={'btn-blue'} onClick={handleSearchAreaClick}>
                                    {t('Button;Search')}
                                </Button>
                            </>
                        }
                        searchFilter={
                            showSearchArea && (
                                <div className={'card filter_ver3 absolute-filter'}>
                                    <div className={'filter__now'}>
                                        <div className="filter__info">
                                            <Label name={t('AssetSummaryList;Search')} />
                                        </div>
                                        <div className="filter__options">
                                            <div className={'select-container'}>
                                                <ReactSelect
                                                    name={'selectInput'}
                                                    value={selectedOption}
                                                    customControlStyles={{ width: '100%' }}
                                                    customMenuStyles={{ width: '100%' }}
                                                    customOptionStyles={{ width: '100%' }}
                                                    onChange={handleSelectValueChange}
                                                    options={searchOption}
                                                />
                                            </div>
                                            <TextInput
                                                name={'assetName'}
                                                size={'60'}
                                                value={searchInput.target}
                                                type={'text'}
                                                placeholder={t('AssetSummaryList;Enter the IoT item name Or ID')}
                                                handleChange={handleSearchInputChange}
                                            />

                                            <Button
                                                className="btn-icon-only btn-blue btn-filter-option btn-mobile-search"
                                                iconClassName={'icon-search'}
                                                onClick={handleSearchButtonClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {...restProps}
                    >
                        <div className={'he-100'}>
                            <LoadingBlock blocking={assetListInfo.isLoading}>
                                <div className={'he-100'}>
                                    <Table
                                        key={tableKey}
                                        columns={columns}
                                        data={{
                                            rows: assetList,
                                            pageSize: 10,
                                            totalCount: tableInfo.totalCount,
                                            totalPage: tableInfo.totalPage,
                                        }}
                                        onTrClick={getTrProps}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </LoadingBlock>
                        </div>
                        {children}
                    </GridLayoutCard>
                ) : (
                    <GridLayoutCard
                        widgetInfo={widgetInfo}
                        ref={specificAssetWidgetRef}
                        handleGoBackClick={handleGoBackClick}
                        {...restProps}
                    >
                        <SelectedAsset />
                        {children}
                    </GridLayoutCard>
                )}
            </AssetSummaryListContext.Provider>
        </AssetSummaryListDispatchContext.Provider>
    );
};

export default AssetSummaryList;
