import React, { useState, useEffect } from 'react';
import Card from '../Components/WidgetCard';
import { Row, Col, ModalHeader, ModalBody, Modal } from 'reactstrap';
import CountUp from 'react-countup';
import _ from 'lodash';
import useMonitor from '../util/useMonitor';
import { WindowDataGrid } from '../../Common/DataGrid/DataGrid';
import * as cols from '../Components/GridColumns';

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const TagCount = ({ start = 0, end = 0, label, prefix, suffix, onClick }) => {
    return (
        <div className={'flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'inline-block', cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>
                <CountUp
                    className={'font-weight-bold'}
                    style={{ fontSize: '2.5rem' }}
                    start={start}
                    end={end}
                    separator=""
                    decimals={0}
                    decimal=","
                    prefix=""
                    useEasing={false}
                    suffix=""
                    duration="1"
                />
                {suffix && (
                    <small className={'ml-1'} style={{ fontSize: '1.2rem' }}>
                        {suffix}
                    </small>
                )}
            </div>
            <h6
                className={'mt-2'}
                style={{ fontWeight: 500, fontSize: '0.88rem', textAlign: 'center', wordBreak: 'keep-all' }}
            >
                {label}
            </h6>
        </div>
    );
};

const defaultData = { recognized: 0, use: 0, wait: 0, usageRate: 0 };

const generateData = () => {
    return new Promise(function (resolve, reject) {
        const recognized = 56;
        const wait = _.random(5, 10);
        const use = recognized - wait;
        resolve({
            data: {
                recognized: recognized,
                use: use,
                wait: wait,
                usageRate: Number(((use / recognized) * 100).toFixed(2)),
            },
        });
    });
};

const columns = [
    { ...cols.patName, Header: '자산 이름' },
    { ...cols.patAge, Header: '자산 ID' },
    { ...cols.respiratoryRate, Header: '카테고리' },
    { ...cols.temperature, Header: '사용 여부' },
];

const gridData = [];
const disabled = _.random(7, 8);
for (let i = 1; i < 57; i++) {
    gridData.push({
        patNum: i,
        patName: `Asset_${i}`,
        patAge: `a${i}`,
        respiratoryRate: _.sample(['심박수 측정기', '체온계', '산소 호흡기', '온도계']),
        temperature: i % disabled ? 'O' : 'X',
    });
}

const AssetStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: generateData,
    });

    const [countData, setCountData] = useState({ prev: data, curr: data });

    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    useEffect(() => {
        setCountData(prev => ({
            prev: prev.curr,
            curr: data,
        }));
    }, [data]);

    return (
        <>
            <Card widgetInfo={widgetInfo} {...restProps}>
                <Row className={'flex-center'} style={{ height: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                        <CountWrapper>
                            <TagCount
                                start={countData.prev.recognized}
                                end={countData.curr.recognized}
                                label={'총 자산 개수'}
                            />
                        </CountWrapper>
                        <CountWrapper>
                            <TagCount start={countData.prev.use} end={countData.curr.use} label={'사용 중인 자산'} />
                        </CountWrapper>
                        <CountWrapper>
                            <TagCount start={countData.prev.wait} end={countData.curr.wait} label={'대기 중인 자산'} />
                        </CountWrapper>
                        <CountWrapper>
                            <TagCount
                                start={countData.prev.usageRate}
                                end={countData.curr.usageRate}
                                suffix={'%'}
                                label={'자산 사용률'}
                                onClick={e => {
                                    toggleModal();
                                }}
                            />
                        </CountWrapper>
                    </div>
                </Row>
                {children}
            </Card>
            <Modal isOpen={modal} toggle={toggleModal} backdrop={false}>
                <ModalHeader toggle={toggleModal}>자산 상세</ModalHeader>
                <ModalBody style={{ height: '400px' }}>
                    <WindowDataGrid
                        clickableRow={false}
                        columns={columns}
                        data={gridData}
                        pageSize={gridData.length}
                        showPagination={false}
                        style={{ height: '100%' }}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default AssetStatus;
