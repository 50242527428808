import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import cx from 'classnames';
import Button from '../../../Common/Button';
import { dateChange } from '../../../MainPages/util/dateChange';
import { useDispatch } from 'react-redux';
import { setTargetId } from '../../../../reducers/Common/PatientInfo';
import { onHandleTextChange } from '../../../MainPages/util/TextChange';

const FallPreventionAlarmList = ({ alarmInfo, handleRedirectClick }) => {
    const history = useHistory();
    const storeDispatch = useDispatch();
    const handlePage = targetId => {
        storeDispatch(setTargetId(targetId));
        history.push(`/home/fall-prevention-status`);
    };
    const alarmTypeChange = value => {
        let text = null;
        if (value === 'DANGER_ON') {
            text = '위험구역 방지 이벤트';
            return text;
        }
        if (value === 'DANGER_OFF') {
            text = '위험구역 방지 이벤트 해제';
            return text;
        }
        if (value === 'DANGER_TIME_ON') {
            text = '위험구역 위험시간 진입 이벤트';
            return text;
        }
        if (value === 'DANGER_TIME_OFF') {
            text = '위험구역 위험시간 진입 이벤트 해제';
            return text;
        }
        if (value === 'HIGH_RISK_NOBODY_AROUND_ON') {
            text = '안전구역 방치 이벤트';
            return text;
        }
        if (value === 'HIGH_RISK_NOBODY_AROUND_OFF') {
            text = '안전구역 방치 이벤트 해제';
            return text;
        }
        if (value === 'LOST_SIGNAL') {
            text = ' 신호상실 이벤트';
            return text;
        }
    };
    return (
        <Card>
            <CardBody className={cx({ 'custom-card-color': alarmInfo.interfaceCommandState === 'ON' })}>
                <div className="flx-sb w-100">
                    <div className={'w-60'}>
                        <div>
                            <span
                                style={{ cursor: 'pointer' }}
                                className={'mr-1 font-weight-bold'}
                                onClick={e => handlePage(alarmInfo.targetId)}
                            >
                                {alarmInfo.targetName}
                            </span>
                            <span>{onHandleTextChange([alarmInfo.wardNo, alarmInfo.roomNo, alarmInfo.bedNo])}</span>
                        </div>
                        <div className={'text-ellipsis'} title={alarmTypeChange(alarmInfo.interfaceCommandType)}>
                            {alarmTypeChange(alarmInfo.interfaceCommandType)}
                        </div>
                    </div>

                    <div className={'d-flex w-40 justify-content-between'}>
                        <div className={'mr-2'}>
                            <div>
                                <span
                                    className={cx(
                                        alarmInfo.interfaceCommandState === 'ON' ? 'color-danger' : 'color-secondary',
                                    )}
                                >
                                    {dateChange(alarmInfo.regDate)}
                                </span>
                            </div>
                        </div>
                        <Button
                            className={cx(
                                'pnt-btn btn-icon-only pl-1 pr-3',
                                alarmInfo.interfaceCommandState === 'ON' ? 'btn-danger' : 'btn-blue',
                            )}
                            iconClassName={'icon-next'}
                            onClick={() => {
                                const { targetId } = alarmInfo;
                                handleRedirectClick(targetId);
                            }}
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default FallPreventionAlarmList;
