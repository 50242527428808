import React, { useContext } from 'react';
import Card from '../../../../Common/Card';
import FallPatientDetail from './FallPatientDetail';
import * as column from '../../../util/grid/columns';
import FallPatientList from './FallPatientList';
import { resetMapInfo, setFallPatientDetailInfo } from '../../dashBoardReducer';
import Button from '../../../../Common/Button';
import { DashBoardDispatchContext, DashBoardStateContext } from '../index';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetId } from '../../../../../reducers/Common/PatientInfo';
import useColumns from "../../../../../util/hooks/useColumns";
import useTranslation from "../../../../../util/hooks/useTranslation";

const StaticCard = ({ tableHeight, handlePageChange}) => {
    const t = useTranslation('Common Columns')
    const state = useContext(DashBoardStateContext);
    const dispatch = useContext(DashBoardDispatchContext);
    const storeDispatch = useDispatch();
    const { targetId } = useSelector(state => state.PatientInfo);
    const { fallPatientList } = state;

    const onTrClick = data => {
        const { targetId } = data;
        dispatch(setFallPatientDetailInfo(data));
        storeDispatch(setTargetId(targetId));
    };
    const columns = useColumns([
        column.patientName(),
        column.wardRoom(),
        column.whetherYouWanderAlone(),
        column.status(),
        column.lastPosition(),
        column.locationRecognitionDateAndTime(),
    ],t);

    return (
        <>
            <Card
                style={{ height: tableHeight }}
                header={{
                    title: targetId ? t('Selected patient locations','Fall Prevention Status') : t('Patient lists'),
                    action: targetId ? (
                        <>
                            <Button
                                className="pnt-btn btn-gray"
                                onClick={e => {
                                    storeDispatch(setTargetId(null));
                                    dispatch(resetMapInfo(null));
                                    dispatch(setFallPatientDetailInfo(null));
                                }}
                            >
                                {t('Back','Active Dashboard')}
                            </Button>
                        </>
                    ) : null,
                }}
            >
                {targetId && <FallPatientDetail />}
                {!targetId && (
                    <FallPatientList
                        handlePageChange={handlePageChange}
                        columns={columns}
                        data={fallPatientList}
                        onTrClick={onTrClick}
                    />
                )}
            </Card>
        </>
    );
};

export default StaticCard;
