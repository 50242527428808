import React from 'react';
import CustomCard from '../../../../Common/Card';
import cx from 'classnames';

const ActiveStatusCard = ({
    className = { title: '', total: 'color-black' },
    key,
    title,
    statusInfo,
    totalInfo,
    detailInfos,
}) => {
    return (
        <CustomCard
            key={key}
            header={{
                title: <span className={className.title}>{title}</span>,
            }}
            className={'h-100'}
        >
            <div className="flx-sb">
                <div className="at__widgets--overviews--main">
                    <div className={cx('am__font--util', className.total)}>
                        {statusInfo && statusInfo[totalInfo.key]}
                    </div>
                    <div className={cx('am__font--util', className.total)}>
                        <span className={className.total}>{totalInfo.label}</span>
                    </div>
                </div>
                <div className="at__widgets--overviews--sub">
                    <ul className="">
                        {detailInfos.map((detailInfo, i) => {
                            return (
                                <li
                                    key={i}
                                    className={
                                        detailInfo.key === 'delayCount'
                                            ? 'color-red'
                                            : detailInfo.key === 'readyCount'
                                            ? 'color-secondary'
                                            : ''
                                    }
                                >
                                    {detailInfo.label}
                                    <b>{statusInfo && statusInfo[detailInfo.key]}</b>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </CustomCard>
    );
};

export default ActiveStatusCard;
