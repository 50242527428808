import React, { useState, useRef, useEffect } from 'react';
import Ionicon from 'react-ionicons';
import { Dropdown, DropdownToggle, DropdownMenu, Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { faArrowDown } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';
import useAsync from '../../../../util/hooks/useAsync';
import ActiveAlarmItem from '../../../MainPages/Home/ActiveDashboard/Components/ActiveAlarmItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { fetchAlramList } from '../../../../api/common';
import { LoadingBlock } from '../../../Common/LoadingBlock';
import { fetchPsUnreadAlertCnt } from '../../../../api/alert';
import eventType from '../../../../util/staticData/eventType';
import moment from 'moment';

const WrappedAlertBody = styled.div`
    margin: 1rem;
    height: 300px;
    .card {
        margin-bottom: 0.5rem;
        border-width: 1px;
        .card-body {
            border-radius: 5px;
        }
    }
`;

const SeeMoreBtn = styled.button`
    width: 120px;
    height: 35px;
    color: white;
    background-color: #6e6c96;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 0.5rem;
`;

const COUNT_TIME = 20000;
const HeaderDots = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const countIntvRef = useRef();
    const [open, setOpen] = useState(false);
    const [alertList, setAlertList] = useState([]);
    const [clickBtn, setClickBtn] = useState(false);
    const [showArrow, setShowArrow] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [count, setCount] = useState(0);
    const { promise: getAlertList, state } = useAsync({
        promise: fetchAlramList,
        resolve: res => {
            if (clickBtn && res.rows.length > 0) {
                setShowArrow(true);
                setClickBtn(false);
            }

            setAlertList(alertList.concat(res.rows));
            setPage(res.page);
            setTotalPage(res.totalPage);
        },
        reject: error => {
            console.log(error);
        },
    });

    const makeAlertList = alertList => {
        return alertList.reduce((acc, alert) => {
            let evtType = eventType.find(type => type.type === alert.interfaceCommandType);
            if (evtType) {
                let time = moment(alert.regDate * 1000).format('HH:mm');
                let hour = moment(alert.regDate * 1000).format('HH');
                hour >= 12 && hour <= 23 ? (time = `${time} PM`) : (time = `${time} AM`);

                acc.push({
                    level: 'warning',
                    type: evtType.name,
                    typeClassName: evtType.typeClassName,
                    dateStr: time,
                    targetName: alert.targetName,
                    location: alert.floorName,
                    targetNum: alert.targetNum,
                    interfaceCommandType: alert.interfaceCommandType,
                });
            }
            return acc;
        }, []);
    };
    const {
        promise: getUnreadCnt,
        state: { response },
    } = useAsync({
        promise: fetchPsUnreadAlertCnt,
        keepState: true,
        resolve: res => {
            if (res.count) {
                setCount(res.count);
            }
        },
    });

    const handleClick = e => {
        e.stopPropagation();

        if (totalPage !== page) {
            getAlertList({
                //endDate: expandDate,
                isAlertCheckStatus: 'Y',
                pageSize: 10,
                page: page + 1,
                categoryCodes: 'PATIENT',
            });
            setClickBtn(true);
        } else {
            //더보기 없다는 데이터
        }
    };

    const handleScrollEvent = event => {
        if (event) {
            setShowArrow(false);
        }
    };

    const click = e => {
        setAlertList([]);
        setCount(0);
        if (!open) {
            getAlertList({
                isAlertCheckStatus: 'Y',
                pageSize: 10,
                categoryCodes: 'PATIENT',
            });
        }
        setOpen(!open);
    };
    const handleRedirectClick = psNum => {
        history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
        setOpen(!open);
    };

    const clearCountIntv = () => {
        clearInterval(countIntvRef.current);
        countIntvRef.current = null;
    };

    useEffect(() => {
        if (countIntvRef.current) {
            clearCountIntv();
        }
        getUnreadCnt();
        countIntvRef.current = setInterval(() => {
            getUnreadCnt();
        }, COUNT_TIME);
        return () => {
            clearCountIntv();
        };
    }, []);

    return (
        <div className="header-dots">
            <Dropdown isOpen={open} toggle={click}>
                <DropdownToggle className="p-0 border-0" color="link">
                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <div className="icon-wrapper-bg bg-danger" />
                        <Ionicon beat={false} color="#d92550" fontSize="23px" icon="md-notifications-outline" />
                        {!!count && (
                            <div
                                className="badge badge-dot badge-pill badge-danger ml-0"
                                style={{
                                    width: 'initial',
                                    height: 'initial',
                                    textIndent: 0,
                                    fontSize: '1rem',
                                    fontWeight: 300,
                                    padding: '.1rem .3rem',
                                    borderRadius: '8px',
                                    transform: 'translateX(50%)',
                                    zIndex: 10,
                                }}
                            >
                                {count > 999 ? '999+' : count}
                            </div>
                        )}
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                    <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner bg-deep-blue">
                            <div
                                className="menu-header-image opacity-1"
                                style={{
                                    backgroundImage: 'url(' + city3 + ')',
                                }}
                            />
                            <div className="menu-header-content text-dark">
                                <h5 className="menu-header-title">{t('Alert;Alerts')}</h5>
                            </div>
                        </div>
                    </div>
                    <WrappedAlertBody onScroll={handleScrollEvent}>
                        {/*<TimeLine events={alertList} />*/}
                        {alertList.length > 0 ? (
                            //     <ActiveCardList listInfo={{rows: alertList}}/>
                            <PerfectScrollbar>
                                {alertList.map((alert, i) => {
                                    return (
                                        <ActiveAlarmItem
                                            key={i}
                                            alarmInfo={alert}
                                            handleRedirectClick={handleRedirectClick}
                                            className={
                                                alert && alert.rdate && alert.rdate.includes('-')
                                                    ? {
                                                          cardBody: 'custom-card-color',
                                                          timeText: 'color-danger',
                                                          redirectButton: 'btn-danger',
                                                      }
                                                    : {
                                                          cardBody: '',
                                                          timeText: 'color-secondary',
                                                          redirectButton: 'btn-blue',
                                                      }
                                            }
                                        />
                                    );
                                })}
                            </PerfectScrollbar>
                        ) : (
                            <LoadingBlock blocking={state.isLoading}>
                                <div
                                    style={{
                                        color: '#666',
                                        textAlign: 'center',
                                        paddingTop: '1.5rem',
                                    }}
                                >
                                    {alertList.length === 0 && !state.isLoading && t('TimeLine;No new notifications')}
                                </div>
                            </LoadingBlock>
                        )}
                        {showArrow && (
                            <FontAwesomeIcon
                                icon={faArrowDown}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 90,
                                    color: '#bdbebf',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}
                            />
                        )}
                    </WrappedAlertBody>
                    <Nav vertical>
                        <NavItem className="nav-item-divider" />
                        <NavItem className="nav-item-btn text-center">
                            <SeeMoreBtn className="btn-shadow btn-wide btn-pill" color="focus" onClick={handleClick}>
                                {t('Alert;See more')}
                            </SeeMoreBtn>
                        </NavItem>
                    </Nav>
                </DropdownMenu>
            </Dropdown>
            {/*<LanguageSelectBox />*/}
        </div>
    );
};

export default HeaderDots;
