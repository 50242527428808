import React, { useContext, useEffect, useState } from 'react';
import CustomCard from '../../../Common/Card';
import Button from '../../../Common/Button';
import cx from 'classnames';
import RecordTable from '../../Components/RecordTable';
import styled from 'styled-components';
import useAsync from '../../../../util/hooks/useAsync';
import {
    createGroupMapping,
    deleteGroupMapping,
    getGroupList,
    getMappingPatientList,
    fetchPatientListApi,
    getUnMappingPatientList,
    updateGroupMapping,
    createPatientInfo,
} from '../../../../api/groupSetting';
import {
    setGroupNameList,
    setPatientInfo,
    setGroupPatientList,
    updateGroupInfo,
    setPatientList,
    reset,
} from '../groupSettingReducer';
import * as column from '../../util/grid/columns';
import { GroupSettingDispatchContext, GroupSettingStateContext } from '../PatientGroupSetting';

import { useForm } from 'react-hook-form';
import ConfirmModal from '../../Components/ConfirmModal';
import CustomModal from '../../Components/Modal';
import TextInput from '../../../Common/Input/TextInput';
import ReactSelect from '../../../Common/Select';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #808080;
    font-weight: bold;
`;

const PatientList = () => {
    const t = useTranslation('Group');
    const tCommon = useTranslation('Common Columns');
    const state = useContext(GroupSettingStateContext);
    const dispatch = useContext(GroupSettingDispatchContext);
    const { groupNameList, patientInfo, groupInfo, groupPatientList, groupTitle, patientList } = state;
    const [modalState, setModalState] = useState({ groupEdit: false, patientEdit: false });
    const [errorModalState, setErrorModalState] = useState(false);
    const [contentModalState, setContentModalState] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const patientColumns = useColumns(
        [column.patientName(), column.wardName(), column.wardRoom(), column.wardRoom2()],
        tCommon,
    );
    const { register } = useForm();

    const { promise: getGroupNameList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            dispatch(setGroupNameList(res));
        },
    });

    const { promise: getPatientList, state: patientListState } = useAsync({
        promise: getMappingPatientList,
        resolve: res => {
            dispatch(setGroupPatientList({ res, type: 'mapping' }));
        },
    });

    // 그룹에 속하지 않은 환자 호출 API
    const { promise: unMappingPatientList, state: unMappingListState } = useAsync({
        promise: getUnMappingPatientList,
        resolve: res => {
            dispatch(setGroupPatientList({ res, type: 'unMapping' }));
        },
    });

    const onGetUnMappingPatientList = () => {
        dispatch(reset());
        if (!groupInfo) {
            unMappingPatientList({ order: 'ASC', pageSize: 10 });
        }
    };

    // 환자 그룹 매핑 변경
    const { promise: changeGroup } = useAsync({
        promise: updateGroupMapping,
        resolve: res => {
            if (res.result === 'success') {
                dispatch(setPatientInfo(null));
                getPatientList({ patientGroupNum: groupInfo.patientGroupNum });
                patientGroupEditModalToggle();
            }
        },
        reject: err => {
            console.log(err);
            setErrorText('선택하신 그룹에 이미 등록 되어있습니다.');
            errorModalStateToggle();
        },
    });
    const { promise: addPatientInfo } = useAsync({
        promise: createPatientInfo,
        resolve: res => {
            if (res.result === 'success') {
                getPatientList({ page: 1, pageSize: 10, patientGroupNum: groupInfo.patientGroupNum });
            }
        },
    });
    const { promise: getPatientInfoList, state: patientInfoListState } = useAsync({
        promise: fetchPatientListApi,
        resolve: res => {
            dispatch(setPatientList(res));
        },
    });

    useEffect(() => {
        getGroupNameList({ order: 'ASC', isAll: 'Y' });
    }, []);

    const onHandleChangeGroup = () => {
        const bolResult = groupInfo.hasOwnProperty('modPatientGroupNum');
        if (bolResult) {
            const { patientGroupNum: nowPatientGroupNum } = groupInfo;
            const { modPatientGroupNum } = groupInfo;
            changeGroup({ targetNum: patientInfo.targetNum, modPatientGroupNum, nowPatientGroupNum });
            togglePatientEditModal();
        } else {
            setErrorText(t('Select the groups you want to move'));
            errorModalStateToggle();
        }
    };

    // 환자 그룹 매핑 삭제
    const { promise: groupRelease } = useAsync({
        promise: deleteGroupMapping,
        resolve: res => {
            if (res.result === 'success') {
                getPatientList({ patientGroupNum: groupInfo.patientGroupNum });
            }
            togglePatientEditModal();
            setModalState({ patientEdit: !modalState.patientEdit });
        },
    });

    const onHandleGroupRelease = () => {
        let patientGroupNum = null;
        if (groupInfo) {
            patientGroupNum = groupInfo.patientGroupNum;
        } else {
        }
        const targetNum = patientInfo.targetNum;
        if (patientGroupNum) {
            groupRelease({ patientGroupNum, targetNum });
        } else {
            toggleGroupReleaseFailModal();
        }
    };
    const { promise: mappingGroup } = useAsync({
        promise: createGroupMapping,
        resolve: res => {
            if (res.result === 'success') {
                unMappingPatientList({ order: 'ASC', pageSize: 10 });
                patientGroupEditModalToggle();
            }
        },
    });

    const onHandleMappingGroup = () => {
        if (groupInfo) {
            const { modPatientGroupNum } = groupInfo;
            mappingGroup({ targetNum: patientInfo.targetNum, patientGroupNum: modPatientGroupNum });
            togglePatientEditModal();
        } else {
            setErrorText(t('Select the groups you want to move'));
            errorModalStateToggle();
        }
    };

    const onChange = e => {
        dispatch(updateGroupInfo({ modPatientGroupNum: e.value }));
    };

    const onTrClick = e => {
        dispatch(setPatientInfo(e));
    };

    const onPageChange = pageIndex => {
        groupPatientList.type === 'mapping'
            ? getPatientList({ page: pageIndex, pageSize: 10, patientGroupNum: groupInfo.patientGroupNum })
            : unMappingPatientList({ page: pageIndex, order: 'ASC', pageSize: 10 });
    };

    const loadingHandle = value => {
        if (value === 'mapping') {
            return patientListState.isLoading;
        } else {
            return unMappingListState.isLoading;
        }
    };

    const [keyword, setKeyword] = useState(null);
    const handleInputChange = e => {
        const { value } = e.target;
        setKeyword(value);
    };

    const onHandleSearch = () => {
        getPatientInfoList({ opt: 'targetName', keyword });
    };
    const [targetNum, setTargetNum] = useState(null);
    const handlePatientInfo = data => {
        setTargetNum(data.targetNum);
    };
    const handleAddPatient = () => {
        addPatientInfo({ targetNum, patientGroupNum: groupInfo.patientGroupNum });
    };
    const patientEditModalToggle = () => {
        setModalState({ patientEdit: !modalState.patientEdit });
    };
    const patientGroupEditModalToggle = () => {
        setModalState({ groupEdit: !modalState.groupEdit });
    };
    const toggleContentModal = () => {
        setContentModalState(!contentModalState);
        dispatch(setPatientList(null));
    };
    const [patientEditState, setPatientEditState] = useState(false);
    const togglePatientEditModal = () => {
        setPatientEditState(!patientEditState);
    };
    const [groupReleaseFail, setGroupReleaseFail] = useState(false);
    const toggleGroupReleaseFailModal = () => {
        setGroupReleaseFail(!groupReleaseFail);
    };
    const errorModalStateToggle = () => {
        setErrorModalState(!errorModalState);
    };

    return (
        <CustomCard
            className={'h-100'}
            header={{
                title: groupTitle ? `${t('Patient List')} - ${groupTitle.patientGroupId}` : `${t('Patient List')}`,
                action: (
                    <>
                        <Button
                            disabled={groupTitle === null}
                            className={cx('pnt-btn', (!groupTitle && 'btn-lightgray') || 'btn-orange')}
                            onClick={toggleContentModal}
                        >
                            {t('Patient registration')}
                        </Button>
                        <Button
                            className={'pnt-btn btn-blue btn-icon'}
                            iconClassName={'icon-profile'}
                            onClick={onGetUnMappingPatientList}
                        >
                            {t('Unaffiliated Group Patients')}
                        </Button>
                        <Button
                            className={cx('pnt-btn btn-icon', (patientInfo && 'btn-blue') || 'btn-lightgray')}
                            iconClassName={'icon-editnote'}
                            onClick={togglePatientEditModal}
                            disabled={!patientInfo === true}
                        >
                            {t('Move a patient group')}
                        </Button>
                    </>
                ),
            }}
        >
            {groupPatientList.rows.length > 0 ? (
                <RecordTable
                    columns={patientColumns}
                    onPageChange={onPageChange}
                    data={{
                        rows: groupPatientList.rows,
                        totalCount: groupPatientList.totalCount,
                        totalPage: groupPatientList.totalPage,
                        pageSize: 10,
                    }}
                    rowSelect={{
                        selected: patientInfo,
                        rowKey: 'targetNum',
                        selectedRowClassName: 'selected-color',
                    }}
                    loading={loadingHandle(groupPatientList.type)}
                    onClick={onTrClick}
                />
            ) : (
                <NoDataWrapper>
                    <p className={'icon-infofill icon-filter-gray'} style={{ transform: 'scale(1.5)' }}></p>
                    <p>{t('Select a group to see a list of patients')}</p>
                </NoDataWrapper>
            )}

            <ConfirmModal
                initModal={modalState.patientEdit}
                headerText={t('Change Complete')}
                modalToggle={patientEditModalToggle}
                confirmText={t('Change to unaffiliated group complete')}
            />
            <ConfirmModal
                initModal={modalState.groupEdit}
                headerText={t('Change Complete')}
                modalToggle={patientGroupEditModalToggle}
                confirmText={t('Changed to the group you selected')}
            />
            <ConfirmModal
                initModal={errorModalState}
                headerText={t('Error')}
                modalToggle={errorModalStateToggle}
                confirmText={errorText}
            />
            <CustomModal
                openModal={contentModalState}
                headerTitle={t('Patient registration')}
                handleClick={handleAddPatient}
                onModalClickYes={toggleContentModal}
                content={
                    <>
                        <div className="d-flex mb-4">
                            <TextInput
                                name={'exeUserName'}
                                placeholder={t('Please enter patient name or ID')}
                                handleChange={handleInputChange}
                                size={55}
                            />
                            <button className={`pnt-btn btn-icon btn-blue btn-icon-only ml-2`} onClick={onHandleSearch}>
                                <span className="icon-search" />
                            </button>
                        </div>
                        <div style={{ height: '300px' }}>
                            <RecordTable
                                paging={false}
                                loading={patientInfoListState.isLoading}
                                columns={patientColumns}
                                data={patientList}
                                onClick={handlePatientInfo}
                                rowSelect={{
                                    selected: patientList.rows,
                                    rowKey: 'targetNum',
                                    selectedRowClassName: 'selected-color',
                                }}
                            />
                        </div>
                    </>
                }
            />
            <CustomModal
                openModal={patientEditState}
                headerTitle={t('Move a group')}
                handleClick={groupPatientList.type === 'mapping' ? onHandleChangeGroup : onHandleMappingGroup}
                onModalClickYes={togglePatientEditModal}
                content={
                    <>
                        <div className={'flx-col gap-2 pnt-label-8 p-3'}>
                            <div className={'pnt-label--group'}>
                                <span className="label-options">
                                    <div className="pnt-input--group">
                                        <div>
                                            <span
                                                className={'icon-infofill mr-3'}
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                            <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                {patientInfo && patientInfo.targetName}
                                            </span>
                                            <span> {t('Change the group')} </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className={'pnt-label--group'}>
                                <span className="label-options">
                                    <ReactSelect
                                        size={300}
                                        name={'groupNum'}
                                        styles={selectStyle}
                                        options={groupNameList.rows}
                                        onChange={onChange}
                                        {...register('groupNum')}
                                        customControlStyles={{ width: '200px' }}
                                        customOptionStyles={{ width: '200px' }}
                                    />
                                    <Button className={'btn-blue'} onClick={onHandleGroupRelease}>
                                        {t('Unaffiliated Group Patients')}
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </>
                }
            />
            <CustomModal
                openModal={groupReleaseFail}
                headerTitle={t('Move a group')}
                onModalClickYes={toggleGroupReleaseFailModal}
                content={
                    <>
                        <span
                            style={{
                                lineHeight: '1.7',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: 0,
                                width: '100%',
                                textAlign: 'center',
                                color: 'black',
                            }}
                        >
                            {t('The group is already unaffiliated')}
                        </span>
                    </>
                }
            />
        </CustomCard>
    );
};
const selectStyle = {
    control: provided => {
        return {
            ...provided,
            width: '10vw',
        };
    },
    option: provided => {
        return {
            ...provided,
            width: '10vw',
        };
    },
};
export default PatientList;
