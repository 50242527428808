import React from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import ScheduleByActivity from './ScheduleByActivity';
import { Route, withRouter } from 'react-router-dom';
import ScheduleByActivityDetail from './Components/ScheduleByActivityDetail';
import AddActivity from './Components/AddActivity';
import CarryingOutActivities from './Components/CarryingOutActivities';
import ScheduleByPatient from './ScheduleByPatient';

const ActiveSchedule = ({ match }) => {
    return (
        <>
            <Container fluid>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}`} component={ScheduleByActivity} />
                    <Route exact path={`${match.path}/schedule-by-patient`} component={ScheduleByPatient} />
                    <Route
                        exact
                        path={`${match.path}/schedule-by-activity-detail/:targetNum`}
                        component={ScheduleByActivityDetail}
                    />
                    <Route
                        exact
                        path={[`${match.path}/add-activity`, `${match.path}/add-activity/:targetNum/:key`]}
                        component={AddActivity}
                    />
                    <Route
                        exact
                        path={[
                            `${match.path}/carrying-out-activities/:psNum`,
                            `${match.path}/carrying-out-activities/:psNum/:pageState`,
                            `${match.path}/carrying-out-activities/:psNum/:skinNum/:pageState`,
                        ]}
                        component={CarryingOutActivities}
                    />
                </ErrorHandleSwitch>
            </Container>
        </>
    );
};

export default withRouter(ActiveSchedule);
