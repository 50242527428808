import React, { useContext, useEffect } from 'react';
import CustomCard from '../../../../Common/Card';
import Button from '../../../../Common/Button';
import { SkinConditionRecordDispatchContext, SkinConditionRecordStateContext } from './SkinConditionRecord';
import {
    setGradeOption,
    setSkinConditionRecordList,
    setSkinInfo,
    updateSkinInfo,
} from '../skinConditionRecordManagementReducer';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSkinDetailInfo, getSkinDetailInfoList, updateSkinInfoApi } from '../../../../../api/activeScheduleHistory';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import { setBsWholeBodySkinOption } from '../../../../../reducers/Common/PerformanceRecordOption';
import { useDispatch, useSelector } from 'react-redux';
import { DescriptionRow, HandleReactSelect } from '../../../util/componentChange/componentChange';

const SkinConditionInfo = () => {
    const state = useContext(SkinConditionRecordStateContext);
    const dispatch = useContext(SkinConditionRecordDispatchContext);
    const storeDispatch = useDispatch();
    const { bsWholeBodySkinOption } = useSelector(state => state.PerformanceRecordOption);
    const { bsReportGrade } = bsWholeBodySkinOption;
    const { skinInfo, gradeOption, request, skinConditionRecordList } = state;

    const { promise: getSelectOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBsWholeBodySkinOption(res));
        },
    });

    useEffect(() => {
        getSelectOption({ clCode: 'BS_REPORT_GRADE', codeDepth: '2' }); // 욕창 단계
    }, []);

    const { promise: getSkinAssmangementInfo } = useAsync({
        promise: getSkinDetailInfo,
        resolve: res => {
            dispatch(setSkinInfo(res));
        },
    });
    const { promise: getSkinAssmangementList } = useAsync({
        promise: getSkinDetailInfoList,
        resolve: res => {
            dispatch(setSkinConditionRecordList(res));
        },
    });

    const handlePrev = () => {
        let data = null;
        if (skinInfo.skinNum) {
            const { rows } = skinConditionRecordList;
            const reverseRows = [...rows].reverse();
            data = reverseRows.find(v => {
                if (skinInfo.skinNum > v.skinNum) {
                    return v;
                }
            });
            if (data) {
                getSkinAssmangementInfo({ skinNum: data.skinNum });
            }
        }
    };
    const handleNext = () => {
        let data = null;
        if (skinInfo.skinNum) {
            data = skinConditionRecordList.rows.find(v => {
                if (skinInfo.skinNum < v.skinNum) {
                    return v;
                }
            });
            if (data) {
                getSkinAssmangementInfo({ skinNum: data.skinNum });
            }
        }
    };
    const { promise: editSkinInfo } = useAsync({
        promise: updateSkinInfoApi,
        resolve: res => {
            getSkinAssmangementList({ reportNum: skinInfo.reportNum });
            getSkinAssmangementInfo({ skinNum: skinInfo.skinNum });
        },
    });
    const skinInfoUpdate = () => {
        const { skinNum, bsReportGradeCode } = skinInfo;
        editSkinInfo({ skinNum, bsReportGradeCode });
    };

    const onGradeSelected = selected => {
        const { label, value } = selected;
        dispatch(updateSkinInfo({ bsReportGradeCodeName: label, bsReportGradeCode: value }));
        dispatch(setGradeOption({ bsReportGradeCodeName: label, bsReportGradeCode: value }));
    };

    return (
        <CustomCard
            className={'custom-card'}
            header={{
                title: '피부사정정보',
                action: (
                    <>
                        <Button
                            disabled={!skinInfo}
                            iconClassName={'icon-back'}
                            className={'pnt-btn btn-icon btn-lightgray'}
                            onClick={handlePrev}
                        >
                            이전
                        </Button>
                        <Button disabled={!skinInfo} onClick={skinInfoUpdate} className={'pnt-btn  btn-blue btn-icon'}>
                            저장
                        </Button>
                        <Button
                            disabled={!skinInfo}
                            reverseIconClassName={'icon-next'}
                            className={'pnt-btn  btn-icon btn-lightgray'}
                            onClick={handleNext}
                        >
                            다음
                        </Button>
                    </>
                ),
            }}
        >
            <div className="p-0 pnt-label-7 row">
                <DescriptionRow
                    label={'전문가 판단'}
                    className={'col-xl-6 col-lg-12 mb-4'}
                    component={
                        <HandleReactSelect
                            options={bsReportGrade}
                            readOnly={request.bsStatusCode !== '01'}
                            isMulti={false}
                            value={gradeOption}
                            onChange={onGradeSelected}
                            isDisabled={!skinInfo}
                            text={request.bsStatusCodeName}
                        />
                    }
                />

                <div className={'col-xl-6 col-lg-12 mb-4'}>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">AI판단</span>
                        <span className="label-options">{skinInfo && skinInfo.aiGrade}</span>
                    </div>
                </div>
                <div style={{ width: '100%', height: '100px' }}>
                    <img
                        style={{ width: '100%' }}
                        src={skinInfo && skinInfo.attachNum && skinInfo.attachUrl}
                        alt={'이미지가 존재하지 않습니다'}
                    />
                </div>
            </div>
        </CustomCard>
    );
};

export default SkinConditionInfo;
