import React from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import ActiveDashboard from './ActiveDashboard';
import FallPreventionStatus from './FallPreventionStatus';
const Home = ({ match }) => {
    return (
        <>
            <Container fluid>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}`} component={ActiveDashboard} />
                    <Route exact path={`${match.path}/fall-prevention-status`} component={FallPreventionStatus} />
                </ErrorHandleSwitch>
            </Container>
        </>
    );
};
//`${match.path}/fall-prevention-status/:targetNum`,
export default Home;
