import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleFileInput,
    HandleImage,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleText,
    HandleTextInput,
    HandleTextInputAndButton,
} from '../../util/componentChange/componentChange';
import { useHistory, useParams } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAntecedentRequestOption,
    setBsWholeBodySkinOption,
    setSelectSkinPart,
} from '../../../../reducers/Common/PerformanceRecordOption';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from './CarryingOutActivities';
import CustomSearchModal from '../util/CustomSearchModal';
import {
    setWholeBodySkinRecordInfo,
    updateWholeBodySkinRecordInfo,
} from '../ScheduleByActivity/scheduleByActivityReducer';
import {
    createWholeSkinAssessmentApi,
    getAntecedentRequestListApi,
    getWholeSkinAssessmentApi,
    getWholeSkinStateCheckApi,
    updateWholeSkinAssessmentApi,
} from '../../../../api/activeSchedule';
import { dateChange } from '../../util/dateChange';
import ConfirmModal from '../../Components/ConfirmModal';
import Modal from '../../Components/Modal/ConfirmModal/index';
import { TextChange, validationCheck } from '../util/Validation';
import { updateNurseInfo } from '../../../../reducers/Common/CommonInfo';
import CustomModal from '../../Components/Modal';
import Button from '../../../Common/Button';
import skinImage from '../../../../assets/images/BS_BACK_IMG.JPG';
import moment from 'moment';
import * as column from '../../util/grid/columns';
import RecordTable from '../../Components/RecordTable';
import useTranslation from '../../../../util/hooks/useTranslation';

const SkinSelectBox = ({ id, bsHappPart, style }) => {
    const storeDispatch = useDispatch();
    const onClick = e => {
        const { id } = e.target;
        let skinPart = [];
        skinPart = bsHappPart.filter(v => {
            if (id === 'Head') {
                if (['Head', 'Neck', 'Head+Neck(Cervical vertebrae)', 'Head+Neck(Occiput)'].includes(v.label)) {
                    return v;
                }
            } else {
                if (v.label.includes(id)) {
                    return v;
                }
            }
        });
        storeDispatch(setSelectSkinPart(skinPart));
    };
    return (
        <div
            onClick={onClick}
            id={id}
            style={{
                position: 'absolute',
                top: style.top,
                left: style.left,
                backgroundColor: '#f10547',
                width: style.width,
                height: style.height,
                cursor: 'pointer',
            }}
        />
    );
};
const columns = [column.siteOfOccurrenceSelect()];
const AddRecord = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const param = useParams();
    const { pageState, skinNum } = param;
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const commonState = useContext(CommonStateContext);
    const { cardHeight } = commonState;
    const { actItemCodeName, actTypeCodeName, psNum, targetNum } = patientDetailInfo;
    const [selectSkinNum, setSelectSkinNum] = useState(null);
    const validationData = () => {
        if (pageState === 'add') {
            return false;
        }
        if (pageState === 'read') {
            return true;
        }
    };
    // read state And write state Management
    const [recordState, setRecordState] = useState(validationData);
    const [editPartState, setEditPartState] = useState(validationData);
    //제어컴포넌트 상태 관리
    const [requestDate, setRequestDate] = useState(null); //의뢰일자
    const [requestClassification, setRequestClassification] = useState({ label: t('Select'), value: 0 }); //의뢰일자
    const [generationDate, setGenerationDate] = useState(null); //발생일자
    const [step, setStep] = useState({ label: t('Select'), value: 0 }); //단계
    const [occuringArea, setOccuringArea] = useState({ label: t('Select'), value: 0 }); //발생장소
    const [medicalDevice, setMedicalDevice] = useState({ label: t('Select'), value: 0 }); // medicalDevice
    const [partData, setPartData] = useState(null);
    const [validationResultState, setValidationResultState] = useState([]);
    const [validation, setValidation] = useState({
        bsHappPartCode: '발생부위',
        bsReportDivisionCode: '의뢰구분',
        bsReportGradeCode: '욕창단계',
        bsHappPlaceCode: '발생장소',
        bsDeviceCode: 'Medical device',
    });

    //리덕스,리듀서
    const storeDispatch = useDispatch();
    const { bsWholeBodySkinOption, antecedentRequestOption, selectSkinPart } = useSelector(
        state => state.PerformanceRecordOption,
    );

    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { wholeBodySkinRecordInfo } = state;
    const { bsDevice, bsHappPlace, bsReportDivision, bsReportGrade, bsHappPart } = bsWholeBodySkinOption;

    const { promise: getSelectOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBsWholeBodySkinOption(res));
        },
    });
    const { promise: addWholeSkinAssessment } = useAsync({
        promise: createWholeSkinAssessmentApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                history.goBack();
            }
        },
    });
    const { promise: editWholeSkinAssessment } = useAsync({
        promise: updateWholeSkinAssessmentApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                history.goBack();
            }
        },
    });
    const { promise: getWholeSkinAssessment } = useAsync({
        promise: getWholeSkinAssessmentApi,
        resolve: res => {
            dispatch(setWholeBodySkinRecordInfo(res));
        },
    });
    const { promise: getWholeSkinStateCheck } = useAsync({
        promise: getWholeSkinStateCheckApi,
        resolve: res => {
            const { aiGrade, aiType } = res;
            if (!aiGrade && !aiType) {
                setFileInfo({ name: t('Please select a photo') });

                toggleImgConfirmModal();
            } else {
                dispatch(updateWholeBodySkinRecordInfo(res));
            }
        },
        reject: err => {
            console.log(err);
        },
    });
    useAsync({
        promise: getAntecedentRequestListApi,
        immediate: true,
        fixedParam: { targetNum },
        resolve: res => {
            storeDispatch(setAntecedentRequestOption(res));
        },
    });
    useEffect(() => {
        getSelectOption({ clCode: 'BS_REPORT_GRADE', codeDepth: '2' }); // 욕창 단계
        getSelectOption({ clCode: 'BS_REPORT_DIVISION', codeDepth: '2' }); // 의뢰 구분
        getSelectOption({ clCode: 'BS_HAPP_PLACE', codeDepth: '3' }); //발생장소
        getSelectOption({ clCode: 'BS_DEVICE', codeDepth: '3' }); //디바이스
        getSelectOption({ clCode: 'BS_HAPP_PART', codeDepth: '3' }); //발생부의
        if (skinNum) {
            getWholeSkinAssessment({ skinNum });
            setRecordState(true);
            setValidation({});
        }
        return () => {
            dispatch(updateWholeBodySkinRecordInfo(null));
            storeDispatch(setAntecedentRequestOption([]));
        };
    }, []);

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    //사진 선택
    const [fileInfo, setFileInfo] = useState({ name: t('Please select a photo') });
    const onFileChange = e => {
        const { name } = e.target.files[0];
        const files = e.target.files[0];
        setFileInfo({ name });
        getWholeSkinStateCheck({ files });
    };

    const onAdd = () => {
        const validationResult = validationCheck(validation);
        !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
        !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        if (wholeBodySkinRecordInfo) {
            !wholeBodySkinRecordInfo.hasOwnProperty('reqDate') && validationResult.push('의뢰일자');
            !wholeBodySkinRecordInfo.hasOwnProperty('happDate') && validationResult.push('발생일자');
        } else {
            validationResult.push('의뢰일자');
            validationResult.push('발생일자');
        }
        if (!validationResult.length) {
            if (recordState) {
                editWholeSkinAssessment({
                    exeStatusCode: 'C',
                    ...nurseInfo,
                    psNum,
                    targetNum,
                    ...wholeBodySkinRecordInfo,
                });
            } else {
                addWholeSkinAssessment({
                    exeStatusCode: 'C',
                    ...nurseInfo,
                    psNum,
                    targetNum,
                    ...wholeBodySkinRecordInfo,
                });
            }
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    const onEdit = () => {
        setEditPartState(!editPartState);
    };
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [reportModalState, setReportModalState] = useState(false);
    const reportToggleModal = () => {
        setReportModalState(!reportModalState);
    };
    const [skinImageModalState, setSkinImageModalState] = useState(false);
    const skinImageToggleModal = () => {
        setSkinImageModalState(!skinImageModalState);
    };
    const [antecedentRequestModalState, setAntecedentRequestModalState] = useState(false);
    const antecedentRequestToggleModal = () => {
        setAntecedentRequestModalState(!antecedentRequestModalState);
    };
    const antecedentRequest = () => {
        getWholeSkinAssessment({ skinNum: selectSkinNum });
        setValidation({});
        setRecordState(true);
    };
    const handleTextChange = e => {
        const { name, value } = e.target;
        dispatch(updateWholeBodySkinRecordInfo({ [name]: value }));
    };
    const [confirmImgModalState, setConfirmImgModalState] = useState(false);

    const toggleImgConfirmModal = () => {
        setConfirmImgModalState(!confirmImgModalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const selectReqDate = selected => {
        dispatch(updateWholeBodySkinRecordInfo({ reqDate: selected / 1000 }));
        setRequestDate(selected);
    };
    const selectHappDate = selected => {
        dispatch(updateWholeBodySkinRecordInfo({ happDate: selected / 1000 }));
        setGenerationDate(selected);
    };
    const [selectSkinPartInfo, setSelectSkinPartInfo] = useState({});
    const onSelectSkinPart = selected => {
        const { occurrencePart, value } = selected;
        setSelectSkinPartInfo({ bsHappPartCodeName: occurrencePart, bsHappPartCode: value });
    };
    const onSelectedSkinPart = () => {
        if (selectSkinPartInfo.hasOwnProperty('bsHappPartCodeName')) {
            dispatch(updateWholeBodySkinRecordInfo(selectSkinPartInfo));
            setValidation({ ...validation, bsHappPartCode: null });
        }

        storeDispatch(setSelectSkinPart(null));
    };
    const antecedentRequestOptionRows = antecedentRequestOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bsReportDivisionRows = bsReportDivision.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bsReportGradeRows = bsReportGrade.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bsDeviceRows = bsDevice.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    return (
        cardHeight && (
            <>
                <ActiveRecordCard
                    style={{ height: cardHeight }}
                    headerTitle={`${editPartState ? t('Edit record') : t('Add record')} : ${t(
                        actTypeCodeName,
                        'ActTypeCode',
                    )}>${t(actItemCodeName, 'ActTypeCode')}`}
                    buttonOption={[
                        {
                            label: editPartState ? t('Modify', 'Active Dashboard') : t('Save', 'Evaluation'),
                            onClick: editPartState ? onEdit : onAdd,
                            className: 'icon-save ',
                            iconBtnClass: 'btn-icon',
                            borderOption: true,
                        },
                    ]}
                    url={`${url.wmsUrl}/v2/html/aum/guide/2_2.html`}
                    spanIcon={['icon-info']}
                >
                    <div className="p-0 pnt-label-7 row">
                        <DescriptionRow
                            label={t('Nurse', 'Evaluation')}
                            className="col-xl-6 col-lg-12 mb-4"
                            component={
                                <HandleNurseTextInput
                                    name={'nurse'}
                                    placeholder={`${t('Nurse', 'Evaluation')}||${t('Number', 'Evaluation')}`}
                                    readOnly={editPartState}
                                    onClick={toggleModal}
                                    value={
                                        nurseInfo.hasOwnProperty('exeUserName') &&
                                        nurseInfo.exeUserName &&
                                        `${nurseInfo.exeUserName} || ${nurseInfo.exeUserNum}`
                                    }
                                    className={'d-flex'}
                                    text={
                                        wholeBodySkinRecordInfo &&
                                        wholeBodySkinRecordInfo.hasOwnProperty('exeUserName') &&
                                        wholeBodySkinRecordInfo.exeUserName &&
                                        `${wholeBodySkinRecordInfo.exeUserName} || ${wholeBodySkinRecordInfo.exeUserNum}`
                                    }
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('When performed')}
                            component={
                                <HandleDateInput
                                    value={startDate}
                                    readOnly={editPartState}
                                    handleDateValueChange={handleStartDateValueChange}
                                    valueType={'ms'}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.exeDate}
                                    minDate={moment().valueOf()}
                                />
                            }
                        />

                        <DescriptionRow
                            label={t('Occurrences')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                editPartState ? (
                                    <div>
                                        {(wholeBodySkinRecordInfo &&
                                            wholeBodySkinRecordInfo.bsHappPartCodeName &&
                                            wholeBodySkinRecordInfo.bsHappPartCodeName) ||
                                            (partData && partData.label)}
                                    </div>
                                ) : (
                                    <div className={'d-flex'}>
                                        <div
                                            style={{
                                                width: '192px',
                                                borderRadius: '5px',
                                                textAlign: 'left',
                                                lineHeight: '31px',
                                                padding: '0px 10px',
                                            }}
                                            className={'border-color'}
                                        >
                                            {(wholeBodySkinRecordInfo &&
                                                wholeBodySkinRecordInfo.bsHappPartCodeName &&
                                                wholeBodySkinRecordInfo.bsHappPartCodeName) ||
                                                (partData && partData.label)}
                                        </div>
                                        <Button
                                            className={'pnt-btn btn-blue btn-icon-only ml-2'}
                                            onClick={skinImageToggleModal}
                                            iconClassName={'icon-search'}
                                        />
                                    </div>
                                )
                            }
                        />

                        <DescriptionRow
                            label={t('Occurrences photo')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <>
                                    <HandleImage
                                        readOnly={editPartState}
                                        src={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.attachUrl}
                                        value={fileInfo.name}
                                        attachNum={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.attachNum}
                                    />
                                    <HandleFileInput readOnly={editPartState} onChange={onFileChange} />
                                </>
                            }
                        />
                        <DescriptionRow
                            label={t('Pressure Ulcer - AI')}
                            className="col-xl-6 col-lg-12 mb-4"
                            component={<HandleText value={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.aiType} />}
                        />
                        <DescriptionRow
                            label={t('Pressure Ulcer Stage - AI')}
                            className="col-xl-6 col-lg-12 mb-4"
                            component={
                                <HandleText value={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.aiGrade} />
                            }
                        />

                        <DescriptionRow
                            label={t('Preceding request', 'Evaluation')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={antecedentRequestOptionRows.length > 0 && antecedentRequestOptionRows}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    text={
                                        wholeBodySkinRecordInfo &&
                                        `${wholeBodySkinRecordInfo.bsHappPartCodeName} - ${dateChange(
                                            wholeBodySkinRecordInfo.happDate,
                                        )} - ${dateChange(wholeBodySkinRecordInfo.reqDate)}`
                                    }
                                    onChange={selected => {
                                        const { value } = selected;
                                        if (!value) {
                                            setRecordState(false);
                                            setEditPartState(false);
                                        } else {
                                            setSelectSkinNum(value);
                                            antecedentRequestToggleModal();
                                        }
                                    }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Categorizing Requests')}
                            className="col-xl-6 col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsReportDivisionRows}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    value={requestClassification}
                                    text={
                                        wholeBodySkinRecordInfo &&
                                        t(wholeBodySkinRecordInfo.bsReportDivisionCodeName, 'ActTypeCode')
                                    }
                                    onChange={selected => {
                                        const { value } = selected;
                                        setRequestClassification(selected);
                                        dispatch(updateWholeBodySkinRecordInfo({ bsReportDivisionCode: value }));
                                        setValidation({ ...validation, bsReportDivisionCode: null });
                                    }}
                                />
                            }
                        />

                        <DescriptionRow
                            label={t('Pressure Ulcer Stage')}
                            className="col-xl-6 col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsReportGradeRows}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    text={
                                        wholeBodySkinRecordInfo &&
                                        t(wholeBodySkinRecordInfo.bsReportGradeCodeName, 'ActTypeCode')
                                    }
                                    value={step}
                                    onChange={selected => {
                                        const { value } = selected;
                                        setStep(selected);
                                        dispatch(updateWholeBodySkinRecordInfo({ bsReportGradeCode: value }));
                                        setValidation({ ...validation, bsReportGradeCode: null });
                                    }}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Request Date')}
                            className="col-xl-12  col-lg-12 mb-4"
                            component={
                                <HandleDateInput
                                    value={requestDate}
                                    readOnly={recordState}
                                    handleDateValueChange={selectReqDate}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.reqDate}
                                    valueType={'ms'}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Date of Occurrence')}
                            className="col-xl-6  col-lg-12 mb-4"
                            component={
                                <HandleDateInput
                                    value={generationDate}
                                    readOnly={recordState}
                                    handleDateValueChange={selectHappDate}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.happDate}
                                    valueType={'ms'}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Where it happens')}
                            className="col-xl-6  col-lg-12 mb-4"
                            component={
                                <HandleReactSelect
                                    readOnly={recordState}
                                    options={bsHappPlace}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    value={occuringArea}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.bsHappPlaceCodeName}
                                    onChange={e => {
                                        const { value } = e;
                                        setOccuringArea(e);
                                        dispatch(updateWholeBodySkinRecordInfo({ bsHappPlaceCode: value }));
                                        setValidation({ ...validation, bsHappPlaceCode: null });
                                    }}
                                />
                            }
                        />

                        <DescriptionRow
                            label={t('Medical device')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <>
                                    <HandleReactSelect
                                        readOnly={recordState}
                                        options={bsDevice}
                                        isMulti={false}
                                        closeMenuOnSelect={false}
                                        value={medicalDevice}
                                        text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.bsDeviceCodeName}
                                        onChange={e => {
                                            const { value, label } = e;
                                            setMedicalDevice(e);
                                            dispatch(updateWholeBodySkinRecordInfo({ bsDeviceCode: value }));
                                            dispatch(updateWholeBodySkinRecordInfo({ bsDeviceCodeName: label }));
                                            setValidation({ ...validation, bsDeviceCode: null });
                                        }}
                                    />
                                </>
                            }
                        />
                        <DescriptionRow
                            label={t('Reporting to')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <HandleTextInputAndButton
                                    name={'dr'}
                                    readOnly={recordState}
                                    value={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.drName}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.drName}
                                    onClick={reportToggleModal}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Report Content')}
                            className="col-xl-12  col-lg-12 mb-4"
                            component={
                                <HandleTextInput
                                    readOnly={recordState}
                                    placeholder={t('Please enter your report')}
                                    name={'report'}
                                    value={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.report}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.report}
                                    onChange={handleTextChange}
                                    size={200}
                                />
                            }
                        />
                        <DescriptionRow
                            label={t('Remarks', 'Evaluation')}
                            className="col-xl-12 col-lg-12 mb-4"
                            component={
                                <HandleTextInput
                                    name={'remark'}
                                    readOnly={recordState}
                                    value={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.remark}
                                    text={wholeBodySkinRecordInfo && wholeBodySkinRecordInfo.remark}
                                    placeholder={t('Enter any remarks.', 'Evaluation')}
                                    onChange={handleTextChange}
                                    size={200}
                                />
                            }
                        />
                    </div>
                    <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                    <CustomSearchModal
                        openModal={reportModalState}
                        searchTarget={'doctor'}
                        onModalClickYes={reportToggleModal}
                    />

                    <CustomModal
                        openModal={skinImageModalState}
                        onModalClickYes={skinImageToggleModal}
                        handleClick={onSelectedSkinPart}
                        subTitle={''}
                        content={
                            <>
                                <SkinSelectBox
                                    id={'Ear'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '47px', left: '94px', width: '28px', height: '16px' }}
                                />
                                <SkinSelectBox
                                    id={'Face'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '26px', left: '106px', width: '39px', height: '16px' }}
                                />
                                <SkinSelectBox
                                    id={'Head'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '47px', left: '125px', width: '28px', height: '33px' }}
                                />
                                <SkinSelectBox
                                    id={'Chest'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '37px', left: '165px', width: '28px', height: '15px' }}
                                />
                                <SkinSelectBox
                                    id={'Shoulder'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '64px', left: '165px', width: '28px', height: '15px' }}
                                />
                                <SkinSelectBox
                                    id={'Hand'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '23px', left: '193px', width: '28px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Forearm'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '46px', left: '199px', width: '28px', height: '15px' }}
                                />
                                <SkinSelectBox
                                    id={'Elbow'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '67px', left: '199px', width: '28px', height: '15px' }}
                                />
                                <SkinSelectBox
                                    id={'Abdomen'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '35px', left: '216px', width: '28px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Hip'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '58px', left: '229px', width: '55px', height: '11px' }}
                                />
                                <SkinSelectBox
                                    id={'Genital'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '35px', left: '258px', width: '50px', height: '11px' }}
                                />
                                <SkinSelectBox
                                    id={'Thigh'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '50px', left: '287px', width: '28px', height: '19px' }}
                                />
                                <SkinSelectBox
                                    id={'Knee'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '46px', left: '323px', width: '28px', height: '13px' }}
                                />
                                <SkinSelectBox
                                    id={'Tibia+Fibula'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '60px', left: '357px', width: '24px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Malleolus'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '57px', left: '393px', width: '24px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Heel'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '77px', left: '419px', width: '24px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Foot'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '49px', left: '424px', width: '24px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Toe'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '36px', left: '440px', width: '24px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'Back'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '134px', left: '187px', width: '81px', height: '10px' }}
                                />
                                <SkinSelectBox
                                    id={'TibiaFibula'}
                                    bsHappPart={bsHappPart}
                                    style={{ top: '60px', left: '357px', width: '24px', height: '10px' }}
                                />
                                <img style={{ width: '100%' }} src={skinImage} />
                                <div style={{ height: '200px' }}>
                                    <RecordTable
                                        textAlign={'left'}
                                        columns={columns}
                                        paging={false}
                                        onClick={onSelectSkinPart}
                                        noDataPlaceholder={t('Data does not exist.', 'Common Columns')}
                                        data={selectSkinPart}
                                        rowSelect={{
                                            selected: selectSkinPartInfo,
                                            rowKey: 'bsHappPartCode',
                                            selectedRowClassName: 'selected-color',
                                        }}
                                    />
                                </div>
                            </>
                        }
                    />
                    <ConfirmModal
                        initModal={confirmImgModalState}
                        modalToggle={toggleImgConfirmModal}
                        headerText={t('Notification')}
                        confirmText={
                            <span>
                                {t(
                                    'Failed to get pressure ulcer presence or pressure ulcer stage information.',
                                    'Modal',
                                )}
                                <br />
                                {t('Please try again.', 'Modal')}
                            </span>
                        }
                    />
                    <Modal
                        openModal={antecedentRequestModalState}
                        headerTitle={t('Notification')}
                        onModalClickYes={antecedentRequestToggleModal}
                        handleClick={antecedentRequest}
                        content={t('Would you like to go to your prior referral history?', 'Modal')}
                    />
                    <ConfirmModal
                        initModal={confirmModalState}
                        headerText={t('Notification')}
                        modalToggle={toggleConfirmModal}
                        confirmText={TextChange(validationResultState)}
                    />
                </ActiveRecordCard>
            </>
        )
    );
};

export default AddRecord;
