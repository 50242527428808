import React from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import HistoryByActivity from './HistoryByActivity';
import HistoryByPatient from './HistoryByPatient';
import ActivityPatientInfoDetail from './HistoryByActivity/Components/PatientInfoDetail';
import SkinConditionRecordManagement from './SkinConditionRecordManagement';
import HistoryPatientInfoDetail from './HistoryByPatient/Components/PatientInfoDetail';
import SkinConditionRecord from './SkinConditionRecordManagement/Components/SkinConditionRecord';

const ActivityHistory = ({ match }) => {
    return (
        <>
            <Container fluid>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}`} component={HistoryByActivity} />
                    <Route exact path={`${match.path}/detail/:targetNum`} component={ActivityPatientInfoDetail} />

                    <Route exact path={`${match.path}/history-by-patient`} component={HistoryByPatient} />
                    <Route
                        exact
                        path={`${match.path}/history-by-patient/detail/:targetNum`}
                        component={HistoryPatientInfoDetail}
                    />
                    <Route
                        exact
                        path={`${match.path}/skin-condition-record-management`}
                        component={SkinConditionRecordManagement}
                    />
                    <Route
                        exact
                        path={`${match.path}/skin-condition-record-management/:reportNum`}
                        component={SkinConditionRecord}
                    />
                </ErrorHandleSwitch>
            </Container>
        </>
    );
};

export default ActivityHistory;
