import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Hamburger from 'react-hamburgers';

import cx from 'classnames';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'reactstrap';

import { setEnableMobileMenu, setEnableMobileMenuSmall } from '../../../reducers/ThemeOptions';

const AppMobileMenu = props => {
    const dispatch = useDispatch();
    const { enableMobileMenu, enableMobileMenuSmall } = useSelector(state => state.ThemeOptions);

    const [activeMobile, setActiveMobile] = useState(false);
    const toggleActiveMobile = () => {
        setActiveMobile(!activeMobile);
    };

    const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(false);
    const toggleActiveSecondaryMenuMobile = () => {
        setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile);
    };

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const toggleMobileSmall = () => {
        dispatch(setEnableMobileMenuSmall(!enableMobileMenuSmall));
    };

    return (
        <>
            <div className="app-header__mobile-menu">
                <div onClick={toggleMobileSidebar}>
                    <Hamburger active={enableMobileMenu} type="elastic" onClick={toggleActiveMobile} />
                </div>
            </div>
            <div className="app-header__menu">
                <span onClick={toggleMobileSmall}>
                    <Button
                        size="sm"
                        className={cx('btn-icon btn-icon-only btn-org', {
                            active: activeSecondaryMenuMobile,
                        })}
                        onClick={toggleActiveSecondaryMenuMobile}
                    >
                        <div className="btn-icon-wrapper">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </div>
                    </Button>
                </span>
            </div>
        </>
    );
};

export default AppMobileMenu;
