import React from 'react';
import { FeatureGroup, Polygon, Tooltip } from 'react-leaflet';

const GeofenceLayer = ({ geofenceList, handleClick, authState }) => {
    return (
        <FeatureGroup>
            {geofenceList.map(
                geofence =>
                    geofence.llList.length > 0 && (
                        <Polygon
                            key={geofence.fcNum}
                            positions={geofence.bounds}
                            onClick={e => {
                                if (typeof handleClick === 'function') {
                                    handleClick(geofence);
                                }
                            }}
                        >
                            <Tooltip
                                key={`tooltip-${geofence.fcNum}`}
                                permanent={true}
                                interactive={true}
                                direction={'center'}
                                className={'geofence-name-label'}
                            >
                                <div style={{ textAlign: 'center' }}>{geofence.fcName}</div>
                                {authState && (
                                    <div style={{ textAlign: 'center' }}>
                                        (
                                        {`${geofence.authorized ? geofence.authorized.length : 0} / ${
                                            geofence.unAuthorized ? geofence.unAuthorized.length : 0
                                        }`}
                                        )
                                    </div>
                                )}
                            </Tooltip>
                        </Polygon>
                    ),
            )}
        </FeatureGroup>
    );
};
export default GeofenceLayer;
