import React, { Fragment } from 'react';
import CustomCard from '../../Common/Card';
import Button from '../../Common/Button';

//카드 하나로 공통 사용하기
const RecordCard = ({ headerTitle, subTitle, buttonOption, url, spanIcon, children }) => {
    return (
        <>
            <CustomCard
                header={{
                    title: headerTitle,
                    subTitle: subTitle,
                    action: (
                        <>
                            {buttonOption &&
                                buttonOption.length > 0 &&
                                buttonOption.map((buttonOption, i) => (
                                    <Fragment key={i}>
                                        <Button className={` ${buttonOption.className}`} onClick={buttonOption.onClick}>
                                            {buttonOption.iconClassName && (
                                                <span className={`${buttonOption.iconClassName}`}></span>
                                            )}

                                            {buttonOption.label}
                                        </Button>
                                        {buttonOption.borderOption && <div className="pnt-border border-vert"></div>}
                                    </Fragment>
                                ))}
                            {spanIcon &&
                                spanIcon.length > 0 &&
                                spanIcon.map((icon, i) => (
                                    <Button
                                        key={i}
                                        onClick={() => window.open(url, '_blank')}
                                        className="btn-blue btn-icon-only"
                                        iconClassName={icon}
                                    />
                                ))}
                        </>
                    ),
                }}
            >
                {children}
            </CustomCard>
        </>
    );
};

export default RecordCard;
