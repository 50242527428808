import React, { createContext, useEffect, useReducer, useState } from 'react';
import FallPreventionStatusCard from './Components/FallPreventionStatusCard';
import Filter from '../../Components/Filter/Filter';
import StaticCard from './Components/StaticCard';
import dashBoardReducer, {
    initialState,
    setFallPatientList,
    setSummaryOfFallPreventionDashboard,
} from '../dashBoardReducer';
import useAsync from '../../../../util/hooks/useAsync';
import { getFallPreventionInfoApi } from '../../../../api/dashboard';
import { getFallStatusPatientInfoList } from '../../../../api/activeSchedule';
import { useSelector } from 'react-redux';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Risk from '../../Components/Filter/Components/Risk';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import useTranslation from "../../../../util/hooks/useTranslation";

export const DashBoardDispatchContext = createContext();
export const DashBoardStateContext = createContext();

const FallPreventionStatus = () => {
    const t = useTranslation('Fall Prevention Status');
    const [tableHeight, setTableHeight] = useState(null);
    const [state, dispatch] = useReducer(dashBoardReducer, initialState);
    const { targetId } = useSelector(state => state.PatientInfo);
    const { summaryOfFallPreventionDashboard } = state;

    const { promise: getSummaryOfFallPrevention } = useAsync({
        promise: getFallPreventionInfoApi,
        resolve: res => {
            dispatch(setSummaryOfFallPreventionDashboard(res));
        },
    });
    const { promise: getPatientList } = useAsync({
        promise: getFallStatusPatientInfoList,
        resolve: res => {
            dispatch(setFallPatientList(res));
        },
    });
    const filterParam = useFilterParam();

    useEffect(() => {
        if (filterParam) {
            getSummaryOfFallPrevention({ ...filterParam });
            if (!targetId) {
                getPatientList({ ...filterParam });
            }
        }
    }, [filterParam]);
    const handlePageChange = pageIndex => {
        if (filterParam) {
            getPatientList({ ...filterParam, page: pageIndex, pageSize: 10 });
        }
    };
    return (
        <div className="grid-main-container">
            <DashBoardDispatchContext.Provider value={dispatch}>
                <DashBoardStateContext.Provider value={state}>
                    <div>
                        <Filter>
                            <WardTeam />
                            <Risk bsRisk={false} />
                        </Filter>
                        <FallPreventionStatusCard
                            title={t('Fall prevention status summary')}
                            className={{
                                title: 'color-black ',
                                total: 'color-black',
                                subTitle: 'color-orange',
                            }}
                            cardClassName={'card at__widgets--overviews'}
                            statusInfo={summaryOfFallPreventionDashboard}
                            t={t}
                        />
                    </div>
                    <StaticCard tableHeight={tableHeight} handlePageChange={handlePageChange}/>
                </DashBoardStateContext.Provider>
            </DashBoardDispatchContext.Provider>
        </div>
    );
};

export default FallPreventionStatus;
