import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import useTranslation from '../../../util/hooks/useTranslation';

const CustomModalBody = styled(ModalBody)`
    min-height: 90px;
`;

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;
const ConfirmText = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    width: 100%;
    text-align: center;
    color: black;
`;

const ConfirmModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    okCallback,
    cancelCallback,
    callbackParam,
    header = { title: 'Confirm', subTitle: '', className: '' },
    confirmText = 'Are you sure?',
    removeCancel,
    okClassName,
    bodyClassName,
    cancelClassName,
    ...restProps
}) => {
    const t = useTranslation('Modal');
    return (
        <Modal isOpen={initModal} toggle={toggleModal} {...restProps}>
            <ModalHeader className={header.className} toggle={toggleModal}>
                {header.title}
                {!!header.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
            </ModalHeader>
            <CustomModalBody className={'flex-center flx-col'}>
                <ConfirmText> {typeof confirmText === 'string' ? t(confirmText) : confirmText}</ConfirmText>
            </CustomModalBody>
            <ModalFooter
            //className={cx(!removeCancel && 'justify-content-between')}
            >
                {!removeCancel && (
                    <Button
                        onClick={e => {
                            if (typeof cancelCallback === 'function') {
                                cancelCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {t('Close')}
                    </Button>
                )}
                <Button
                    className={okClassName}
                    onClick={e => {
                        if (typeof okCallback === 'function') {
                            okCallback(callbackParam);
                        }
                        toggleModal();
                    }}
                >
                    {t('Confirm')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
