import React, { createContext, useEffect, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import Filter from '../../Components/Filter/Filter';
import dashBoardReducer, {
    initialState,
    setActivityDashboardEnforced,
    setActivityDashboardEnforcedCount,
    setActivityDashboardNotEnforced,
    setActivityDashboardNotEnforcedCount,
    setActivityDashboardPatientRecord,
    setActivityDashboardPatientRecordCount,
} from '../dashBoardReducer';
import useAsync from '../../../../util/hooks/useAsync';
import {
    getActivityDashboardEnforcedApi,
    getActivityDashboardEnforcedCountApi,
    getActivityDashboardNotEnforcedApi,
    getActivityDashboardNotEnforcedCountApi,
    getActivityDashboardPatientRecordApi,
    getActivityDashboardPatientRecordCountApi,
} from '../../../../api/dashboard';
import ActiveStatusWithList from './Components/ActiveStatusWithList';
import { useSelector } from 'react-redux';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import useTranslation from '../../../../util/hooks/useTranslation';

// const MenuList = props => {
//     const length = props.children.length;
//
//     let optionValue = props.options.map((item, index) => {
//         return item.value;
//     });
//     return (
//         <components.MenuList {...props}>
//             <div>선택</div>
//             <div>
//                 {props.getValue().length !== 0 &&
//                     props.getValue().map((item, index) => {
//                         console.log(props.getValue());
//                         return (
//                             <div>
//                                 <Label>
//                                     <input
//                                         type={'checkbox'}
//                                         value={item.value}
//                                         id={item.value}
//                                         onClick={e => {
//                                             console.log(e.target.value);
//                                         }}
//                                     />
//                                     {item.label}
//                                 </Label>
//                             </div>
//                         );
//                     })}
//             </div>
//             {length && (
//                 <>
//                     <div>전체 선택</div>
//
//                     {props.children.map((item, index) => {
//                         return (
//                             <>
//                                 <div key={item.key} {...item.props.innerProps}>
//                                     <Label htmlFor={item.props.children}>
//                                         <input
//                                             type={'checkbox'}
//                                             value={props.options[index].value}
//                                             id={item.props.children}
//                                             onClick={e => {
//                                                 props.setValue(optionValue.filter(item => item === e.target.value));
//                                             }}
//                                         />
//                                         {props.options[index].label}
//                                     </Label>
//                                 </div>
//                             </>
//                         );
//                     })}
//                 </>
//             )}
//         </components.MenuList>
//     );
// };
// const ValueContainer = ({ children, ...props }) => {
//     const { getValue, hasValue } = props;
//     const nbValues = getValue().length;
//     if (!hasValue) {
//         return <components.ValueContainer {...props}>진료과 : 진료과명</components.ValueContainer>;
//     }
//     return <components.ValueContainer {...props}>{`${nbValues} items selected`}</components.ValueContainer>;
// };
export const ActivityDashBoardDispatchContext = createContext();
export const ActivityDashBoardDStateContext = createContext();
const ActiveDashboard = () => {
    const t = useTranslation('Active Dashboard');
    const [state, dispatch] = useReducer(dashBoardReducer, initialState);
    const { profile } = useSelector(state => state.UserInfo);
    const {
        activityDashboardNotEnforcedCount,
        activityDashboardEnforcedCount,
        activityDashboardPatientRecordCount,
        activityDashboardNotEnforced,
        activityDashboardEnforced,
        activityDashboardPatientRecord,
    } = state;
    const DETAIL_INFOS = [
        { key: 'riskCount', label: t('Risk assessment') },
        { key: 'eduCount', label: t('Prevention activities') },
        { key: 'recordCount', label: t('Managing treatments') },
    ];
    const tsFilterData = {
        actTypeCodes: 'BS_REPORT',
        actTypeCodesName: t('Managing pressure ulcer care', 'Pressure ulcers'),
    };
    const { promise: getActivityDashboardNotEnforcedCount } = useAsync({
        promise: getActivityDashboardNotEnforcedCountApi,
        resolve: res => {
            dispatch(setActivityDashboardNotEnforcedCount(res));
        },
    });
    const { promise: getActivityDashboardEnforcedCount } = useAsync({
        promise: getActivityDashboardEnforcedCountApi,
        resolve: res => {
            dispatch(setActivityDashboardEnforcedCount(res));
        },
    });
    const { promise: getActivityDashboardPatientRecordCount } = useAsync({
        promise: getActivityDashboardPatientRecordCountApi,
        resolve: res => {
            dispatch(setActivityDashboardPatientRecordCount(res));
        },
    });
    const { promise: getActivityDashboardNotEnforced } = useAsync({
        promise: getActivityDashboardNotEnforcedApi,
        resolve: res => {
            dispatch(setActivityDashboardNotEnforced(res));
        },
    });
    const { promise: getActivityDashboardEnforced } = useAsync({
        promise: getActivityDashboardEnforcedApi,
        resolve: res => {
            dispatch(setActivityDashboardEnforced(res));
        },
    });
    const { promise: getActivityDashboardPatientRecord } = useAsync({
        promise: getActivityDashboardPatientRecordApi,
        resolve: res => {
            dispatch(setActivityDashboardPatientRecord(res));
        },
    });
    const filterParam = useFilterParam();
    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getActivityDashboardNotEnforced({ ...filterParam });
                getActivityDashboardEnforced({ ...filterParam });
                getActivityDashboardPatientRecord({ ...filterParam });
                getActivityDashboardNotEnforcedCount({ ...filterParam });
                getActivityDashboardEnforcedCount({ ...filterParam });
                getActivityDashboardPatientRecordCount({ ...filterParam });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getActivityDashboardNotEnforced({
                    ...filterParam,
                    ...tsFilterData,
                });
                getActivityDashboardEnforced({
                    ...filterParam,
                    ...tsFilterData,
                });
                getActivityDashboardPatientRecord({
                    ...filterParam,
                    ...tsFilterData,
                });
                getActivityDashboardNotEnforcedCount({
                    ...filterParam,
                    ...tsFilterData,
                });
                getActivityDashboardEnforcedCount({
                    ...filterParam,
                    ...tsFilterData,
                });
                getActivityDashboardPatientRecordCount({
                    ...filterParam,
                    ...tsFilterData,
                });
            }
        }
    }, [profile, filterParam]);
    return (
        <>
            <ActivityDashBoardDispatchContext.Provider value={dispatch}>
                <ActivityDashBoardDStateContext.Provider value={state}>
                    <Filter withoutSearchBtn={true}>
                        <WardTeam />
                        <Activity />
                        <Risk />
                    </Filter>
                    <Row className={'w-100'}>
                        <Col lg={'4'} md={6}>
                            <ActiveStatusWithList
                                statusCardInfo={{
                                    title: t('Undone activities'),
                                    className: { title: 'color-danger', total: 'color-danger' },
                                    statusInfo: activityDashboardNotEnforcedCount,
                                    totalInfo: { key: 'totalCount', label: t('Target Patients') },
                                    detailInfos: DETAIL_INFOS,
                                }}
                                listInfo={{
                                    listInfo: activityDashboardNotEnforced,
                                }}
                            />
                        </Col>
                        <Col lg={'4'} md={6}>
                            <ActiveStatusWithList
                                statusCardInfo={{
                                    title: t('Upcoming activities'),
                                    className: { title: 'color-secondary', total: 'color-secondary' },
                                    statusInfo: activityDashboardEnforcedCount,
                                    totalInfo: { key: 'totalCount', label: t('Target Patients') },
                                    detailInfos: DETAIL_INFOS,
                                }}
                                listInfo={{
                                    listInfo: activityDashboardEnforced,
                                }}
                            />
                        </Col>
                        <Col lg={'4'} md={6}>
                            <ActiveStatusWithList
                                statusCardInfo={{
                                    title: t('Pressure Ulcer History Patient'),
                                    statusInfo: activityDashboardPatientRecordCount,
                                    totalInfo: { key: 'totalCount', label: t('Target Patients') },
                                    detailInfos: [
                                        { key: 'readyCount', label: t('Scheduled activities') },
                                        { key: 'delayCount', label: t('Delayed activities') },
                                    ],
                                }}
                                listInfo={{
                                    listInfo: activityDashboardPatientRecord,
                                }}
                            />
                        </Col>
                    </Row>
                </ActivityDashBoardDStateContext.Provider>
            </ActivityDashBoardDispatchContext.Provider>
        </>
    );
};

export default ActiveDashboard;
