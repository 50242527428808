import React, { useContext } from 'react';
import { RealTimeSensorMonitoringContext, RealTimeSensorMonitoringDispatchContext } from '../index';
import { setFloorInfo, setSelectedFloor } from '../../RealTimeLocationStatus/realTimeLocationReducer';
import Label from '../../../Common/Label';
import { useTranslation } from 'react-i18next';
import SensingFilter from './SensingFilter';
import SearchableSelect from '../../../Common/Select/SearchableSelect';

const SensorType = () => {
    const { t } = useTranslation();

    return (
        <div className={'card filter_ver3 absolute-filter'}>
            <div className={'filter__now'}>
                <div className="filter__info">
                    <Label name={t('AssetCountStatus;Search')} />
                </div>

                <SensingFilter />
            </div>
        </div>
    );
};
export default SensorType;
