import React from 'react';
import Education from './Decubitus/Education';
import InitialEvaluation from './Decubitus/InitialEvaluation';
import TreatmentRecord from './Decubitus/TreatmentRecord';
import WholeBodySkinRecord from './Decubitus/WholeBodySkinRecord';
import LyingDown from './Decubitus/LyingDown';
import Record from './Decubitus/Record';
import FallChildEducation from './Fall/FallChildEducation';
import FallPreventionActivities from './Fall/FallPreventionActivities';
import UrinationRound from './Fall/UrinationRound';
import FallPersonEducation from './Fall/FallPersonEducation';
import FallEmergencyEducation from './Fall/FallEmergencyEducation';
import FallDetail from './Fall/FallDetail';
import FallDetailChild from './Fall/FallDetailChild';
import FallEmergency from './Fall/FallEmergency';
import { useSelector } from 'react-redux';
import AddRecord from '../AddRecord';
import EditTreatmentRecord from '../EditTreatmentRecord';

const PerformanceRecordCard = ({ value, page }) => {
    return page
        ? ('BS_IV_1' === value.actItemCode && <AddRecord />) || <EditTreatmentRecord />
        : (value.actItemCode === 'BS_EDU_1' && <Education />) ||
              ([
                  'BS_RATING_1',
                  'BS_RATING_2',
                  'BS_RATING_3',
                  'BS_RATING_4',
                  'BS_RATING_5',
                  'BS_RATING_6',
                  'BS_RATING_7',
                  'BS_RATING_8',
              ].includes(value.actItemCode) && <InitialEvaluation />) ||
              (value.actItemCode === 'BS_REPORT_1' && <TreatmentRecord />) ||
              (value.actItemCode === 'BS_IV_1' && <WholeBodySkinRecord />) ||
              (value.actItemCode === 'BS_IV_2' && <LyingDown />) ||
              (['BS_IV_3', 'BS_IV_4', 'BS_IV_5', 'BS_IV_6', 'BS_IV_7', 'BS_IV_8'].includes(value.actItemCode) && (
                  <Record />
              )) ||
              (value.actItemCode === 'BF_EDU_IV_1' && <FallChildEducation />) ||
              (value.actItemCode === 'BF_EDU_IV_2' && <FallPreventionActivities />) ||
              (value.actItemCode === 'BF_EDU_IV_3' && <UrinationRound />) ||
              (value.actItemCode === 'BF_EDU_IV_4' && <FallPersonEducation />) ||
              (value.actItemCode === 'BF_EDU_IV_5' && <FallEmergencyEducation />) ||
              (['BF_RATING_1', 'BF_RATING_6', 'BF_RATING_9', 'BF_RATING_12', 'BF_RATING_15', 'BF_RATING_18'].includes(
                  value.actItemCode,
              ) && <FallDetail />) ||
              (['BF_RATING_2', 'BF_RATING_7', 'BF_RATING_10', 'BF_RATING_13', 'BF_RATING_16', 'BF_RATING_19'].includes(
                  value.actItemCode,
              ) && <FallDetailChild />) ||
              (['BF_RATING_3', 'BF_RATING_8', 'BF_RATING_11', 'BF_RATING_17', 'BF_RATING_20'].includes(
                  value.actItemCode,
              ) && <FallEmergency />);
};

const PerformanceRecord = ({ pageState }) => {
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);

    return <>{patientDetailInfo && <PerformanceRecordCard value={patientDetailInfo} page={pageState} />}</>;
};

export default PerformanceRecord;
