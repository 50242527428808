import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleSearchableSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBfEmergencyOption,
    setBfHighRisk,
    setZoneOption,
} from '../../../../../../reducers/Common/PerformanceRecordOption';
import {
    createEmergencyFallDownRecord,
    getEmergencyFallDownRecord,
    updateEmergencyFallDownRecord,
} from '../../../../../../api/activeSchedule';
import CustomSearchModal from '../../../util/CustomSearchModal';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from '../../CarryingOutActivities';
import {
    setActivityFallEvaluationScheduleInfo,
    setFlag,
    updateActivityFallEvaluationScheduleInfo,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { TextChange, validationCheck } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import { filterProcess } from '../../../util';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const FallEmergency = () => {
    const t = useTranslation('Evaluation');
    const storeDispatch = useDispatch();
    const history = useHistory();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const commonState = useContext(CommonStateContext);
    const { bfHighRiskOption, bfEmergencyOption, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { actItemCodeName, actTypeCodeName, actItemCode, targetNum, psNum, exeDate } = patientDetailInfo;
    const { cardHeight } = commonState;
    const { activityFallEvaluationScheduleInfo } = state;
    const {
        bfRatingItemFallexp2,
        bfRatingItemConfusion,
        bfRatingItemAlcohol,
        bfRatingItemWalking2,
        bfRatingItemWalkingeqpt,
        bfRatingItemExcretion,
    } = bfEmergencyOption;
    const handleDisabled = option => !option.includes('0');
    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const [selectOption, setSelectOption] = useState({
        BF_HIGH_RISK: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_FALLEXP2: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_CONFUSION: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_ALCOHOL: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_WALKING2: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_WALKINGEQPT: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_EXCRETION: [{ label: t('Select'), id: 0 }],
    });
    const [validation, setValidation] = useState({
        BF_HIGH_RISK: t('High Risk Invariant'),
        BF_RATING_ITEM_FALLEXP2: t('Fall experience'),
        BF_RATING_ITEM_CONFUSION: t('Confusion or loss of orientation'),
        BF_RATING_ITEM_ALCOHOL: t('Alcohol consumption or sedation'),
        BF_RATING_ITEM_WALKING2: t('Gait disorder'),
        BF_RATING_ITEM_WALKINGEQPT: t('Walking aid use'),
        BF_RATING_ITEM_EXCRETION: t('Excretory disorders'),
    });
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BF_HIGH_RISK', codeDepth: '2' },
        resolve: res => {
            let optionCode = [];
            optionCode = ['0', '7', '8', '10', '11'];
            storeDispatch(setBfHighRisk({ res, optionCode }));
        },
    });
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    const { promise: getSelectOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBfEmergencyOption(res));
        },
    });
    const defaultActivitySchedule = {
        bfHighRiskCodes: ['0'],
        bfRatingItemAlcoholCode: '',
        bfRatingItemConfusionCode: '',
        bfRatingItemExcretionCode: '',
        bfRatingItemFallExp2Code: '',
        bfRatingItemWalking2Code: '',
        bfRatingItemWalkingEqptCode: '',
        mcfrat: 0,
        mcfratStatus: '고위험',
    };
    useEffect(() => {
        getSelectOption({ clCode: 'BF_RATING_ITEM_FALLEXP2', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_CONFUSION', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_ALCOHOL', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_WALKING2', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_WALKINGEQPT', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_EXCRETION', codeDepth: '2' });
        if (actItemCode === 'BF_RATING_11') {
            setSelectOption({
                ...selectOption,
                BF_HIGH_RISK: [{ label: t('Not applicable'), id: '0' }],
            });
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_FALLEXP2: null,
                BF_RATING_ITEM_CONFUSION: null,
                BF_RATING_ITEM_ALCOHOL: null,
                BF_RATING_ITEM_WALKING2: null,
                BF_RATING_ITEM_WALKINGEQPT: null,
                BF_RATING_ITEM_EXCRETION: null,
            });
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                }),
            );
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, []);
    useEffect(() => {
        if (exeDate) {
            getFallDownRecord({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
    }, [exeDate]);
    const [mcfrat, setMcfrat] = useState(0);

    //includeBol 값이 true 일 경우 해당없음 값을 셋팅 해주고 return
    //false 일 경우 선택한 옵션 값을 셋팅 해주고 return  해준다
    const [selectValue, setSelectValue] = useState([]);

    const handleMainSelectChange = selected => {
        let multipleSelection = filterProcess(selected);
        setSelectValue(multipleSelection.map(v => v.value));
        if (!multipleSelection.map(item => item.value).includes('0')) {
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => t(item.label, 'ActTypeCode')).join(','),
                }),
            );
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_FALLEXP2: null,
                BF_RATING_ITEM_CONFUSION: null,
                BF_RATING_ITEM_ALCOHOL: null,
                BF_RATING_ITEM_WALKING2: null,
                BF_RATING_ITEM_WALKINGEQPT: null,
                BF_RATING_ITEM_EXCRETION: null,
            });
        } else {
            dispatch(
                setActivityFallEvaluationScheduleInfo({
                    exeDate: activityFallEvaluationScheduleInfo.exeDate,
                    exeUserName: activityFallEvaluationScheduleInfo.exeUserName,
                    exeUserNum: activityFallEvaluationScheduleInfo.exeUserNum,
                    wardCode: activityFallEvaluationScheduleInfo.wardCode,
                    wardCodeName: activityFallEvaluationScheduleInfo.wardCodeName,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => item.label).join(','),
                }),
            );

            setValidation({ ...validation, BF_HIGH_RISK: null });
        }
        setMcfrat(0);
        setSelectOption({
            ...selectOption,
            BF_RATING_ITEM_FALLEXP2: [{ label: t('Select'), id: 0 }],
            BF_RATING_ITEM_CONFUSION: [{ label: t('Select'), id: 0 }],
            BF_RATING_ITEM_ALCOHOL: [{ label: t('Select'), id: 0 }],
            BF_RATING_ITEM_WALKING2: [{ label: t('Select'), id: 0 }],
            BF_RATING_ITEM_WALKINGEQPT: [{ label: t('Select'), id: 0 }],
            BF_RATING_ITEM_EXCRETION: [{ label: t('Select'), id: 0 }],
        });
    };
    //select Option 변경에 따라서 mcfrat Scale의 값이 변경된다
    const handleSelectValueChange = e => {
        const { value, name, codeLabel, label, id, mark, codeName } = e;
        if (id) {
            const beforeValue = selectOption[name][0].id || 0;
            const gap = beforeValue - Number(mark);
            const resultScore = mcfrat - Number(gap);
            setMcfrat(resultScore);
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: mark, value: value }] });

            let mcfratStatus = '';

            if (mcfrat >= 5) {
                mcfratStatus = t('High Risk', 'Risk');
            }

            if ([3, 4].includes(mcfrat)) {
                mcfratStatus = t('Medium Risk', 'Risk');
            } else if (mcfrat <= 2) {
                mcfratStatus = t('Low Risk', 'Risk');
            }

            if (selectValue.length && !selectValue.includes('0')) {
                mcfratStatus = t('High Risk', 'Risk');
            }

            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    [codeName]: id,
                    [`${codeName}Name`]: codeLabel,
                    mcfrat: resultScore,
                    mcfratStatus,
                }),
            );
            setValidation({ ...validation, [name]: null });
        } else {
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: id, value: value }] });
            dispatch(updateActivityFallEvaluationScheduleInfo({ [`${codeName}Name`]: codeLabel, [codeName]: value }));
            setValidation({ ...validation, [name]: null });
        }
    };
    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityFallEvaluationScheduleInfo({ wardCode: value }));
        setZoneState({ label, value });
    };

    const { promise: addFallDownRecord } = useAsync({
        promise: createEmergencyFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });

    const { promise: getFallDownRecord } = useAsync({
        promise: getEmergencyFallDownRecord,
        resolve: res => {
            const { mcfrat, wardCodeName, wardCode } = res;
            dispatch(setActivityFallEvaluationScheduleInfo(res));
            storeDispatch(setNurseInfo(res));
            dispatch(setFlag());
            setZoneState({ label: wardCodeName, value: wardCode });
            setMcfrat(mcfrat);
        },
    });
    const { promise: editFallDownRecord } = useAsync({
        promise: updateEmergencyFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });

    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfHighRiskOptionRows = bfHighRiskOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const [validationResultState, setValidationResultState] = useState([]);
    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editFallDownRecord({
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      psNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addFallDownRecord({
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      psNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };
    const onHandleEdit = () => {
        setRecordState(!recordState);
    };

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };

    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityFallEvaluationScheduleInfo({ [name]: value }));
    };

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: noFallDownRecord } = useAsync({
        promise: createEmergencyFallDownRecord,
        resolve: () => {
            history.push('/activity-schedule');
        },
    });
    const onHandleNotDone = () => {
        noFallDownRecord({
            ...defaultActivitySchedule,
            bfHighRiskCodes: ['0'],
            exeStatusCode: 'N',
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            mcfrat: '',
            mcfratStatus: '',
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onHandleSave,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onHandleEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };

    const handleScale = ({ score = 0 }) => {
        let scale = '';
        if (score >= 5) {
            scale = t('High Risk', 'Risk');
        }
        if ([3, 4].includes(mcfrat)) {
            scale = t('Medium Risk', 'Risk');
        } else if (score <= 2) {
            scale = t('Low Risk', 'Risk');
        }
        if (selectValue.length && !selectValue.includes('0')) {
            scale = t('High Risk', 'Risk');
        }

        return `${score}${t('Score', 'Risk')}/${scale}`;
    };

    const bfRatingItemFallexp2Rows = bfRatingItemFallexp2.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemConfusionRows = bfRatingItemConfusion.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemAlcoholRows = bfRatingItemAlcohol.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemWalking2Rows = bfRatingItemWalking2.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemWalkingeqptRows = bfRatingItemWalkingeqpt.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemExcretionRows = bfRatingItemExcretion.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    return (
        cardHeight && (
            <ActiveRecordCard
                style={{ height: cardHeight }}
                headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={buttonOptionChange(recordState)}
                spanIcon={['icon-info icon-filter-white']}
                url={
                    actItemCodeName === '초기평가 - 응급'
                        ? `${url.wmsUrl}/v2/html/aum/guide/4_3.html`
                        : `${url.wmsUrl}/v2/html/aum/guide/4_6.html`
                }
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse')}
                        component={
                            <HandleNurseTextInput
                                name={'nurse'}
                                readOnly={nurseRecordState}
                                onClick={toggleModal}
                                placeholder={`${t('Nurse')}||${t('Number')}`}
                                className={'d-flex'}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    nurseInfo.exeUserName &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    activityFallEvaluationScheduleInfo.hasOwnProperty('exeUserName') &&
                                    activityFallEvaluationScheduleInfo.exeUserName &&
                                    `${activityFallEvaluationScheduleInfo.exeUserName} || ${activityFallEvaluationScheduleInfo.exeUserNum}`
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('When performed')}
                        component={
                            <HandleDateInput
                                value={startDate}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                                readOnly={nurseRecordState}
                                minDate={moment().valueOf()}
                                text={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.exeDate}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Zones')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                options={zoneOptionRows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleZoneSelect}
                                value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                text={
                                    (activityFallEvaluationScheduleInfo.wardCodeName &&
                                        t(activityFallEvaluationScheduleInfo.wardCodeName, 'ActTypeCode')) ||
                                    '-'
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('High-risk invariants')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleSearchableSelect
                                readOnly={recordState}
                                options={bfHighRiskOptionRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleMainSelectChange}
                                selected={t(selectValue, 'ActTypeCode')}
                                text={{
                                    code: activityFallEvaluationScheduleInfo.bfHighRiskCode,
                                    rows: bfHighRiskOptionRows,
                                }}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Falls in the last 3 months')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemFallexp2Rows}
                                isMulti={false}
                                // isDisabled={handleDisabled(selectValue)}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_FALLEXP2']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemFallExp2Code}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemFallExp2CodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Confusion or Disorientation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemConfusionRows}
                                isMulti={false}
                                // isDisabled={handleDisabled(selectValue)}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_CONFUSION']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemConfusionCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemConfusionCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Alcohol consumption or sedation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemAlcoholRows}
                                isMulti={false}
                                //    isDisabled={handleDisabled(selectValue)}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_ALCOHOL']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemAlcoholCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemAlcoholCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Gait disorder')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemWalking2Rows}
                                isMulti={false}
                                //   isDisabled={handleDisabled(selectValue)}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_WALKING2']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemWalking2Code}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemWalking2CodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Walking aid use')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemWalkingeqptRows}
                                isMulti={false}
                                //     isDisabled={handleDisabled(selectValue)}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_WALKINGEQPT']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemWalkingEqptCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemWalkingEqptCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Excretory disorders')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                //  isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemExcretionRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput'}
                                value={selectOption['BF_RATING_ITEM_EXCRETION']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemExcretionCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemExcretionCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={'MEFRAT'}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<>{handleScale({ score: mcfrat })}</>}
                    />

                    <DescriptionRow
                        label={t('Remarks')}
                        className="col-xl-12 col-lg-12 mb-3"
                        spanClassName={'w-50'}
                        component={
                            <HandleTextInput
                                size={200}
                                placeholder={t('Enter any remarks.')}
                                value={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.remark}
                                readOnly={recordState}
                                name={'remark'}
                                onChange={onHandleTextChange}
                            />
                        }
                    />
                </div>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ActiveRecordCard>
        )
    );
};

export default FallEmergency;
