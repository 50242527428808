import React from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import WardSetting from './WardSetting';
import ActivitySetting from './ActivitySetting';
import ActivitySettingEdit from './ActivitySetting/Components/ActivitySettingEdit';
import ActivitySettingAdd from './ActivitySetting/Components/ActivitySettingAdd';
import WardSettingAdd from './WardSetting/Components/WardSettingAdd';
import WardSettingEdit from './WardSetting/Components/WardSettingEdit';
const Setting = ({ match }) => {
    return (
        <Container fluid>
            <ErrorHandleSwitch>
                <Route exact path={`${match.path}/term-setting`} component={ActivitySetting} />
                <Route exact path={`${match.path}/term-setting/add`} component={ActivitySettingAdd} />
                <Route exact path={`${match.path}/term-setting/edit/:configNum`} component={ActivitySettingEdit} />
                <Route exact path={`${match.path}/ward-setting`} component={WardSetting} />
                <Route exact path={`${match.path}/ward-setting/add`} component={WardSettingAdd} />
                <Route exact path={`${match.path}/ward-setting/edit/:pgConfigNum`} component={WardSettingEdit} />
            </ErrorHandleSwitch>
        </Container>
    );
};

export default Setting;
