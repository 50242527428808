import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import userImg from '../../../../../assets/images/unknown_user.png';
import { useTranslation } from 'react-i18next';
import { ModalTitle } from '../UserBox';

const ProfileModal = ({ initModal, userInfo, toggleModal }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={initModal}>
            <ModalHeader>
                <ModalTitle>{t('UserBox;Profile')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Col>
                    <Row>
                        <div>
                            <div className="profile-picture__wrap">
                                <img
                                    src={userImg}
                                    alt="profileImg"
                                    style={{ width: '150px', height: '150px', marginRight: '1rem' }}
                                />
                            </div>
                        </div>
                        <div className="flx-col">
                            <div className="pnt-label--group">
                                <div className="label-main label-must">{t('UserBox;Name')}</div>
                                <div className="label-options">{userInfo.userName}</div>
                            </div>
                            <div className="pnt-label--group">
                                <div className="label-main label-must">{t('UserBox;ID')}</div>
                                <div className="label-options">{userInfo.userID}</div>
                            </div>
                            <div className="pnt-label--group">
                                <div className="label-main label-must">{t('UserBox;Email')}</div>
                                <div className="label-options">{userInfo.userEmail}</div>
                            </div>
                        </div>
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
                <button className="pnt-btn btn-lightgray w-20" onClick={toggleModal}>
                    {t('UserBox;Close')}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ProfileModal;
