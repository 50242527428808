import React, { useEffect, useReducer, useState } from 'react';
import ActiveRecordCard from '../../../Components/ActiveRecordCard';
import { useHistory, useParams } from 'react-router-dom';
import useAsync from '../../../../../util/hooks/useAsync';
import {
    deleteActivitySettingApi,
    fetchActivitySettingApi,
    updateActivitySettingApi,
} from '../../../../../api/activitySetting';
import activitySettingReducer, {
    initialState,
    setActivityCycleInfo,
    updateActivityCycleInfo,
} from '../activitySettingReducer';
import { HandleReactSelect, HandleTextInput } from '../../../util/componentChange/componentChange';
import { useDispatch, useSelector } from 'react-redux';
import settingReducer, { commonInitialState, setExecutionCycleInfo, setExecutionCycleType } from '../../settingReducer';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import {
    setExecutionCycleOption,
    setExecutionCycleTypeOption,
} from '../../../../../reducers/Common/PerformanceRecordOption';

import ConfirmModal from '../../../Components/ConfirmModal';
import useTranslation from '../../../../../util/hooks/useTranslation';
const ActivitySettingEdit = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const param = useParams();
    const { configNum } = param;

    const storeDispatch = useDispatch();
    const { executionCycleOption, executionCycleTypeOption } = useSelector(state => state.PerformanceRecordOption);
    const [commonState, commonDispatch] = useReducer(settingReducer, commonInitialState);
    const [state, dispatch] = useReducer(activitySettingReducer, initialState);
    const { single, repeat } = executionCycleOption;
    const { activityCycleInfo } = state;

    //제어 컴포넌트
    const [readState, setReadState] = useState(true);
    const [activityCycle, setActivityCycle] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [activityCycleSecond, setActivityCycleSecond] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const weekOption = [
        { value: '일요일', label: t('Sunday') },
        { value: '월요일', label: t('Monday') },
        { value: '화요일', label: t('Tuesday') },
        { value: '수요일', label: t('Wednesday') },
        { value: '목요일', label: t('Thursday') },
        { value: '금요일', label: t('Friday') },
        { value: '토요일', label: t('Saturday') },
    ];
    const onHandleEdit = () => {
        setReadState(!readState);
    };

    const buttonOptionChange = () => {
        return (
            (readState && [
                {
                    label: t('Back', 'Active Dashboard'),
                    color: 'btn-gray',
                    borderOption: true,
                    onClick: onHandleBackPage,
                },
                { label: t('Delete', 'Active Dashboard'), color: 'btn-danger', onClick: onHandleDelete },
                { label: t('Ward-specific settings', 'Activity Setting'), onClick: onHandleSettingPage },
                {
                    label: t('Edit', 'Evaluation'),
                    className: 'icon-editnote ',
                    iconBtnClass: 'btn-icon',
                    onClick: onHandleEdit,
                },
            ]) || [
                {
                    label: t('Save', 'Evaluation'),
                    className: 'icon-save  ',
                    iconBtnClass: 'btn-icon',
                    onClick: onHandleSave,
                },
            ]
        );
    };
    const { promise: getExecutionCycleOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleInfo(data));
        },
    });

    const { promise: getExecutionCycleTypeOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleTypeOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleType({ type: data, executionCycleOption }));
        },
    });

    const [exeCycleValue, setExeCycleValue] = useState(null);
    const [exeCycleValueSecond, setExeCycleValueSecond] = useState(null);
    const [exeCycleValueThird, setExeCycleValueThird] = useState(null);
    const { promise: getActivitySettingInfo } = useAsync({
        promise: fetchActivitySettingApi,
        resolve: res => {
            const {
                exeCycleCode,
                exeCycleCodeName,
                exeCycleTypeCode,
                exeCycleTypeCodeName,
                exeCycleVal,
                exeCycleVal2,
                exeCycleVal3,
            } = res;

            setActivityCycle({ label: exeCycleTypeCodeName, value: exeCycleTypeCode });
            setActivityCycleSecond({ label: exeCycleCodeName, value: exeCycleCode });
            setExeCycleValue({ label: exeCycleVal, value: exeCycleVal });
            if (exeCycleCode === 'EXE_CYCLE_5') {
                setExeCycleValueSecond({ label: exeCycleVal2, value: exeCycleVal2 });
                setExeCycleValueThird({ label: exeCycleVal3, value: exeCycleVal3 });
            }
            dispatch(setActivityCycleInfo(res));
        },
    });

    const { promise: editActivitySetting } = useAsync({
        promise: updateActivitySettingApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                onHandleBackPage();
            }
        },
        reject: error => {
            console.log(error);
            const { result } = error.data;
            if (result === 'error') {
                setErrorText(
                    t(
                        'Cannot be modified because it is in use in the per-ward performance cycle settings.',
                        'Activity Setting',
                    ),
                );
                errorConfirmModal();
            }
        },
    });
    const { promise: removeActivitySetting } = useAsync({
        promise: deleteActivitySettingApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                onHandleBackPage();
            }
        },
        reject: error => {
            console.log(error);
            const { result } = error.data;
            if (result === 'error') {
                setErrorText(
                    t(
                        'Cannot be deleted because it is in use in the per-ward performance cycle settings.',
                        'Activity Setting',
                    ),
                );
                errorConfirmModal();
            }
        },
    });

    useEffect(() => {
        getExecutionCycleOption({ clCode: 'EXE_CYCLE', codeDepth: '2' });
        getExecutionCycleTypeOption({ clCode: 'EXE_CYCLE_TYPE', codeDepth: '2' });
        getActivitySettingInfo({ configNum });
    }, []);

    const onHandleSave = () => {
        editActivitySetting({ ...activityCycleInfo });
    };
    const onHandleDelete = () => {
        removeActivitySetting({ configNum });
    };
    const onHandleBackPage = () => {
        history.goBack();
    };

    const onHandleSettingPage = () => {
        history.push('/active/ward-setting/add');
    };
    const [errorConfirmModalState, setErrorConfirmModalState] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const errorConfirmModal = () => {
        setErrorConfirmModalState(!errorConfirmModalState);
    };

    const executionCycleTypeOptionRows = executionCycleTypeOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const singleRows = single.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const repeatRows = repeat.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        <>
            <ActiveRecordCard
                headerTitle={t('Set Periodicity for Activities', 'Activity Setting')}
                buttonOption={buttonOptionChange()}
                headerTitleSub={t(
                    'The function to set up separate activity conditions and activity cycles.',
                    'Activity Setting',
                )}
            >
                <div className={'flx-col gap-2 pnt-label-10'}>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Activity', 'Active Dashboard')}</span>
                        <span className="label-options">
                            {activityCycleInfo &&
                                `${t(activityCycleInfo.actTypeCodeName, 'ActTypeCode')}/${t(
                                    activityCycleInfo.actItemCodeName,
                                    'ActTypeCode',
                                )}`}
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Performance Conditions', 'Active Dashboard')}</span>
                        <span className="label-options">
                            {activityCycleInfo && `${t(activityCycleInfo.exeTypeDetailCodeName, 'ActTypeCode')}`}
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Risk categorization', 'Active Dashboard')}</span>
                        <span className="label-options">
                            {activityCycleInfo &&
                                `${activityCycleInfo.riskGradeCodesName
                                    .split(',')
                                    .map(ele => t(ele, 'ActTypeCode'))
                                    .join(',')}`}
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Performance cycle', 'Common Columns')}</span>
                        <span className="label-options">
                            <HandleReactSelect
                                options={executionCycleTypeOptionRows}
                                readOnly={readState}
                                value={{ label: t(activityCycle.label, 'ActTypeCode'), value: activityCycle.value }}
                                text={
                                    activityCycleInfo &&
                                    `${t(activityCycleInfo.exeCycleTypeCodeName, 'ActTypeCode')}/${
                                        activityCycleInfo.exeCycleVal
                                    }${
                                        activityCycleInfo.exeCycleVal2 === ''
                                            ? ''
                                            : '/' + activityCycleInfo.exeCycleVal2
                                    }${
                                        activityCycleInfo.exeCycleVal3 === ''
                                            ? ''
                                            : '/' + activityCycleInfo.exeCycleVal3
                                    }/${t(activityCycleInfo.exeCycleCodeName, 'ActTypeCode')}`
                                }
                                onChange={selected => {
                                    const { label, codeName, value } = selected;
                                    dispatch(updateActivityCycleInfo({ exeCycleTypeCode: codeName }));
                                    commonDispatch(setExecutionCycleType({ type: selected, executionCycleOption }));
                                    setActivityCycle({ label, value });
                                    setActivityCycleSecond({ label: t('Select', 'Evaluation'), value: 0 });
                                    setExeCycleValue(null);
                                }}
                            />
                            <HandleReactSelect
                                options={
                                    activityCycle && t(activityCycle.label, 'ActTypeCode') === t('단일', 'ActTypeCode')
                                        ? singleRows
                                        : repeatRows
                                }
                                readOnly={readState}
                                value={{
                                    label: t(activityCycleSecond.label, 'ActTypeCode'),
                                    value: activityCycleSecond.value,
                                }}
                                onChange={selected => {
                                    const { label, codeValue } = selected;
                                    console.log(label);
                                    setExeCycleValue(null);
                                    dispatch(updateActivityCycleInfo({ exeCycleCode: codeValue }));
                                    commonDispatch(setExecutionCycleInfo(selected));
                                    setActivityCycleSecond({ label, value: codeValue });
                                }}
                            />
                            {['EXE_CYCLE_1', 'EXE_CYCLE_2', 'EXE_CYCLE_3', 'EXE_CYCLE_4', 'EXE_CYCLE_7'].includes(
                                activityCycleSecond.value,
                            ) &&
                                !readState && (
                                    <HandleTextInput
                                        name={'exeCycleVal'}
                                        type={'number'}
                                        min={0}
                                        max={99999}
                                        value={(exeCycleValue && exeCycleValue.value) || null}
                                        onChange={e => {
                                            const { name, value } = e.target;
                                            setExeCycleValue(value);
                                            dispatch(updateActivityCycleInfo({ [name]: value }));
                                            dispatch(updateActivityCycleInfo({ exeCycleVal2: null }));
                                            dispatch(updateActivityCycleInfo({ exeCycleVal3: null }));
                                        }}
                                    />
                                )}
                            {['EXE_CYCLE_5'].includes(activityCycleSecond.value) && (
                                <>
                                    <HandleReactSelect
                                        value={exeCycleValue}
                                        options={hourOption}
                                        readOnly={readState}
                                        onChange={selected => {
                                            const { value, label } = selected;
                                            dispatch(updateActivityCycleInfo({ exeCycleVal: value }));
                                            setExeCycleValue({ label, value });
                                        }}
                                    />
                                    <HandleReactSelect
                                        value={exeCycleValueSecond}
                                        options={hourOption}
                                        readOnly={readState}
                                        onChange={selected => {
                                            const { value, label } = selected;
                                            dispatch(updateActivityCycleInfo({ exeCycleVal2: value }));
                                            setExeCycleValueSecond({ label, value });
                                        }}
                                    />
                                    <HandleReactSelect
                                        value={exeCycleValueThird}
                                        options={hourOption}
                                        readOnly={readState}
                                        onChange={selected => {
                                            const { value, label } = selected;
                                            dispatch(updateActivityCycleInfo({ exeCycleVal3: value }));
                                            setExeCycleValueThird({ label, value });
                                        }}
                                    />
                                </>
                            )}
                            {['EXE_CYCLE_6'].includes(activityCycleSecond.value) && (
                                <HandleReactSelect
                                    value={exeCycleValue}
                                    readOnly={readState}
                                    options={weekOption}
                                    onChange={selected => {
                                        const { value, label } = selected;
                                        dispatch(updateActivityCycleInfo({ exeCycleVal: value }));
                                        setExeCycleValue({ label, value });
                                    }}
                                />
                            )}
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Can be set per ward', 'Activity Setting')}</span>
                        <span className="label-options">
                            <HandleReactSelect
                                options={wardSettingOption}
                                readOnly={readState}
                                text={
                                    activityCycleInfo &&
                                    `${
                                        activityCycleInfo.patientGroupFlag === 'Y'
                                            ? t('Yes', 'Active Dashboard')
                                            : t('No', 'Active Dashboard')
                                    }`
                                }
                                onChange={selected => {
                                    const { name, value } = selected;
                                    dispatch(updateActivityCycleInfo({ [name]: value }));
                                }}
                            />
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Advance notification', 'Activity Setting')}</span>
                        <span className="label-options">
                            <HandleTextInput
                                name={'readyMin'}
                                readOnly={readState}
                                onChange={e => {
                                    const { name, value } = e.target;

                                    dispatch(updateActivityCycleInfo({ [name]: value }));
                                }}
                                type={'number'}
                                min={0}
                                max={99999}
                                value={activityCycleInfo && activityCycleInfo.readyMin}
                            />
                            <span>{t('minute ago', 'Activity Setting')}</span>
                        </span>
                    </div>
                    <div className={'pnt-label--group'}>
                        <span className="label-main label-dot">{t('Delay notification', 'Activity Setting')}</span>
                        <span className="label-options">
                            <HandleTextInput
                                name={'delayMin'}
                                readOnly={readState}
                                min={0}
                                max={99999}
                                type={'number'}
                                value={activityCycleInfo && activityCycleInfo.delayMin}
                                onChange={e => {
                                    const { name, value } = e.target;
                                    dispatch(updateActivityCycleInfo({ [name]: value }));
                                }}
                            />
                            <span>{t('minutes after', 'Activity Setting')}</span>
                        </span>
                    </div>
                </div>
            </ActiveRecordCard>
            <ConfirmModal
                initModal={errorConfirmModalState}
                headerText={t('Notification')}
                modalToggle={errorConfirmModal}
                confirmText={<span style={{ textAlign: 'center', fontWeight: 'bold' }}>{errorText}</span>}
            />
        </>
    );
};

const wardSettingOption = [
    { value: 'Y', label: 'Y', name: 'patientGroupFlag' },
    { value: 'N', label: 'N', name: 'patientGroupFlag' },
];

const hourOption = [
    { value: '00:00', label: '00:00' },
    { value: '00:30', label: '00:30' },
    { value: '01:00', label: '01:00' },
    { value: '01:30', label: '01:30' },
    { value: '02:00', label: '02:00' },
    { value: '02:30', label: '02:30' },
    { value: '03:00', label: '03:00' },
    { value: '03:30', label: '03:30' },
    { value: '04:00', label: '04:00' },
    { value: '04:30', label: '04:30' },
    { value: '05:00', label: '05:00' },
    { value: '05:30', label: '05:30' },
    { value: '06:00', label: '06:00' },
    { value: '06:30', label: '06:30' },
    { value: '07:00', label: '07:00' },
    { value: '07:30', label: '07:30' },
    { value: '08:00', label: '08:00' },
    { value: '08:30', label: '08:30' },
    { value: '09:00', label: '09:00' },
    { value: '09:30', label: '09:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
    { value: '21:00', label: '21:00' },
    { value: '21:30', label: '21:30' },
    { value: '22:00', label: '22:00' },
    { value: '22:30', label: '22:30' },
    { value: '23:00', label: '23:00' },
    { value: '23:30', label: '23:30' },
];
export default ActivitySettingEdit;
