import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAsync from '../../../../../../util/hooks/useAsync';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import RecordCard from '../../../../Components/RecordCard';
import RecordTable from '../../../../Components/RecordTable';
import * as column from '../../../../util/grid/columns';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import { setActivityRecordInfo, updateActivityRecordInfo } from '../../../ScheduleByActivity/scheduleByActivityReducer';
import {
    createCureRecord,
    getCureAssessmentListApi,
    getCureRecord,
    updateRecord,
} from '../../../../../../api/activeSchedule';
import CustomSearchModal from '../../../util/CustomSearchModal';
import { TextChange, validationCheck } from '../../../util/Validation';
import CustomModal from '../../../../Components/Modal';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { ScheduleByActivityDispatchContext, ScheduleByActivityStateContext } from '../../CarryingOutActivities';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import useColumns from '../../../../../../util/hooks/useColumns';

const TreatmentRecord = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const { zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { actItemCodeName, actTypeCodeName, targetNum, psNum, exeDate } = patientDetailInfo;
    const { activityRecordInfo } = state;
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const columns = useColumns(
        [
            column.ward(),
            column.patientName(),
            column.department(),
            column.decubitusDanger(),
            column.siteOfOccurrence(),
            column.classification(),
            column.progress(),
            column.dateOfOccurrence(),
            column.activity({
                history,
                Cell: ({ row }) => {
                    const { recordNum } = row.original;
                    return (
                        <>
                            {!row.original.exeDate ? (
                                //exeStatusCode === 'R'
                                <button
                                    type={'button'}
                                    disabled={exeDate}
                                    className="pnt-txt txt-border txt-bold color-secondary"
                                    onClick={() => {
                                        history.push(`/activity-schedule/carrying-out-activities/${recordNum}/read`);
                                    }}
                                >
                                    {t('Perform')}
                                </button>
                            ) : (
                                <button
                                    type={'button'}
                                    className="pnt-txt txt-border txt-btn-org"
                                    onClick={() => {
                                        history.push(`/activity-schedule/carrying-out-activities/${recordNum}/read`);
                                    }}
                                >
                                    <span className={'icon-check'}></span>
                                </button>
                            )}
                        </>
                    );
                },
            }),
        ],
        t,
    );

    const [filterList, setFilterList] = useState({ rows: [], pageSize: 20 });
    const [zoneState, setZoneState] = useState({ label: t('Not applicable', 'Evaluation'), value: '0' });
    const [validation, setValidation] = useState({});
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });

    const { promise: getPrevFilterList } = useAsync({
        promise: getCureAssessmentListApi,
        resolve: res => {
            setFilterList(res);
        },
    });

    useEffect(() => {
        getPrevFilterList({ psNum, page: 1, pageSize: 20 });
    }, []);

    useEffect(() => {
        if (exeDate) {
            getCureRecordInfo({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
        return () => {
            //  storeDispatch(resetPatientDetailInfo());
        };
    }, [exeDate]);

    //DatePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };

    const handleZoneSelect = e => {
        const { value, name, label } = e;
        dispatch(updateActivityRecordInfo({ wardCode: value }));
        setZoneState({ label, value });
        setValidation({ [name]: null });
    };
    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityRecordInfo({ [name]: value }));
    };

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const { promise: addCureRecordInfo } = useAsync({
        promise: createCureRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getCureRecordInfo({ psNum });
            }
        },
    });
    const { promise: getCureRecordInfo } = useAsync({
        promise: getCureRecord,
        resolve: res => {
            const { wardCode, wardCodeName } = res;
            setZoneState({ label: wardCodeName, value: wardCode });
            storeDispatch(setNurseInfo(res));
            dispatch(setActivityRecordInfo(res));
            setNurseRecordState(true);
            setRecordState(true);
        },
    });
    const { promise: editCureRecordInfo } = useAsync({
        promise: updateRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getCureRecordInfo({ psNum });
            }
        },
    });
    const [validationResultState, setValidationResultState] = useState([]);
    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            nurseRecordState || setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editCureRecordInfo({
                      ...activityRecordInfo,
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addCureRecordInfo({
                      targetNum,
                      ...nurseInfo,
                      ...activityRecordInfo,
                      psNum,
                      exeStatusCode: 'C',
                      ip: profile.clientIp,
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };
    //수정 로직 추가 예정
    const onHandleEdit = () => {
        setRecordState(!recordState);
        nurseRecordState || setNurseRecordState(!nurseRecordState);
    };

    const [saveModalState, setSaveModalState] = useState(false);
    const toggleSaveModal = () => {
        setSaveModalState(!saveModalState);
    };
    //모달 창
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: NoActivityCureRecordInfo } = useAsync({
        promise: createCureRecord,
        resolve: res => {
            history.push('/activity-schedule');
        },
    });
    const onHandleNotDone = () => {
        NoActivityCureRecordInfo({
            exeDate: '',
            exeStatusCode: 'N',
            exeUserName: '',
            exeUserNum: '',
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
            ip: profile.clientIp,
        });
    };
    const buttonOptionChange = value => {
        let filterData = [];
        if (filterList.rows.length > 0) {
            filterData = filterList.rows.filter(v => {
                if (!v.exeDate) {
                    return v;
                }
            });
        }

        if (!value) {
            return [
                {
                    label: t('Save', 'Evaluation'),
                    onClick: (filterData.length > 0 && toggleSaveModal) || onHandleSave,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed', 'Evaluation'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit', 'Evaluation'),
                    onClick: onHandleEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };
    return (
        <>
            <ActiveRecordCard
                headerTitle={`${t('Performance History', 'Evaluation')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={buttonOptionChange(recordState)}
                spanIcon={['icon-info']}
                url={`${url.wmsUrl}/v2/html/aum/guide/3.html`}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse', 'Evaluation')}
                        component={
                            <HandleNurseTextInput
                                name={'nurse'}
                                className={'d-flex'}
                                onClick={toggleModal}
                                placeholder={`${t('Nurse', 'Evaluation')}||${t('Number', 'Evaluation')}`}
                                readOnly={nurseRecordState}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    nurseInfo.exeUserName &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    activityRecordInfo &&
                                    activityRecordInfo.hasOwnProperty('exeUserName') &&
                                    activityRecordInfo.exeUserName &&
                                    `${activityRecordInfo.exeUserName} || ${activityRecordInfo.exeUserNum}`
                                }
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('When performed', 'Evaluation')}
                        component={
                            <HandleDateInput
                                value={startDate}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                                readOnly={nurseRecordState}
                                text={activityRecordInfo && activityRecordInfo.exeDate}
                                minDate={moment().valueOf()}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Zones', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                options={zoneOptionRows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleZoneSelect}
                                value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                text={(activityRecordInfo && t(activityRecordInfo.wardCodeName, 'ActTypeCode')) || '-'}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Remarks', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        spanClassName={'w-50'}
                        component={
                            <HandleTextInput
                                readOnly={recordState}
                                name={'remark'}
                                onChange={onHandleTextChange}
                                value={activityRecordInfo && activityRecordInfo.remark}
                                placeholder={t('Enter any remarks.', 'Evaluation')}
                                size={200}
                            />
                        }
                    />
                </div>
            </ActiveRecordCard>
            <RecordCard
                headerTitle={t('Dermatological Examination in Progress')}
                subTitle={t(
                    'This function is for viewing the referral history of pressure ulcers/wounds/blood loss by patient area and recording skin history.',
                )}
                spanIcon={['icon-info']}
                url={`${url.wmsUrl}/v2/html/aum/guide/3.html`}
                keyValue={'treatmentRecordSub'}
            >
                <div style={{ height: '200px' }}>
                    <RecordTable
                        paging={false}
                        columns={columns}
                        data={filterList}
                        noDataPlaceholder={t('No Data', 'Evaluation')}
                    />
                </div>
            </RecordCard>
            <ConfirmModal
                initModal={confirmModalState}
                headerText={t('Required Items', 'Evaluation')}
                modalToggle={toggleConfirmModal}
                confirmText={TextChange(validationResultState)}
            />
            <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
            <CustomModal
                openModal={saveModalState}
                headerTitle={t('Confirm activity completion', 'Evaluation')}
                subTitle={t('Patient search function.', 'Evaluation')}
                onModalClickYes={toggleSaveModal}
                headerClassName={'bg-error'}
                titleClassName={'text-white'}
                btnClassName={'bg-error text-white'}
                color={'white'}
                handleClick={onHandleSave}
                content={
                    <span>
                        <p style={{ textAlign: 'center', padding: '15px 0 0 0', fontWeight: 'bold' }}>
                            {t('The record is not complete for all requests.', 'Evaluation')} <br />
                            {t('Do you want to save and exit the activity?', 'Evaluation')}
                        </p>
                    </span>
                }
            />
        </>
    );
};

export default TreatmentRecord;
