import React, { useEffect, useState } from 'react';
import Search from '../../Components/Search/Search';
import Filter from '../../Components/Filter/Filter';
import CustomCard from '../../../Common/Card';
import RecordTable from '../../Components/RecordTable';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../../util/hooks/useAsync';
import * as column from '../../util/grid/columns';
import { useHistory } from 'react-router-dom';
import { getActiveScheduleHistoryList } from '../../../../api/activeScheduleHistory';
import { SingleSelect } from '../../Components/Search/Components/SingleSelect';
import SearchInput from '../../Components/Search/Components/SearchInput';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import { resetPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import useTranslation from "../../../../util/hooks/useTranslation";
import useColumns from "../../../../util/hooks/useColumns";

const HistoryByActivity = () => {
    const t = useTranslation('Common Columns');
    const storeDispatch = useDispatch();
    const { profile } = useSelector(state => state.UserInfo);
    const history = useHistory();
    const columns = useColumns([
        column.ward(),
        column.patientName({
            Cell: ({ value, idx, row }) => {
                const { targetNum, patid } = row.original;
                const textChange = (no, name) => (no && `[${no}]${name}`) || name;
                return (
                    <span
                        key={idx}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(`/activity-history/detail/${targetNum}`);
                        }}
                        title={textChange(patid, value)}
                    >
                        {textChange(patid, value)}
                    </span>
                );
            },
        }),
        column.department(),
        column.decubitusDanger(),
        column.activityExpected(),
        column.activityName(),
        column.performanceNurse(),
        column.performanceDate(),
    ],t);
    const [list, setList] = useState({ rows: [], pageSize: 10 });
    const { promise: getPrevFilterList, state: activeHistoryState } = useAsync({
        promise: getActiveScheduleHistoryList,
        resolve: res => {
            setList(res);
        },
    });
    const filterParam = useFilterParam();
    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
        setOptionData({ keyword: '', opt: null });
        setSearchOption(null);
    };
    const [optionData, setOptionData] = useState({ keyword: '', opt: null });
    const handleValueChange = e => {
        const { name, value } = e.target;
        setOptionData({ ...optionData, [name]: value });
    };
    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearchClick();
        }
    };
    const [searchOption, setSearchOption] = useState(null);
    const handleOptionChange = e => {
        const { value, label, name } = e;
        setOptionData({ ...optionData, opt: value });
        setSearchOption({ name, value, label });
    };

    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({ ...optionData, ...filterParam, page: 1, pageSize: 10 });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
        storeDispatch(resetPatientDetailInfo());
    }, [filterParam, profile]);

    const handlePageChange = pageIndex => {
        if (profile && profile.metaData.drst === 'nr') {
            getPrevFilterList({ ...filterParam, ...optionData, page: pageIndex, pageSize: 10 });
        }
        if (profile && profile.metaData.drst === 'ts') {
            getPrevFilterList({
                ...filterParam,
                ...optionData,
                page: pageIndex,
                pageSize: 10,
                actTypeCodes: 'BS_REPORT,BS_EDU',
                actItemCodes: 'BS_REPORT_1,BS_IV_1',
                actTypeCodesName: '욕창치료관리,욕창예방활동',
                actItemCodesName: '욕창치료관리,전신피부상태확인',
            });
        }
    };

    const handleSearchClick = () => {
        if (searchOption) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
    };

    const onTrClick = e => {
        const { psNum } = e;
        history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
    };
    return (
        <div className="grid-main-container">
            <Filter
                reset={reset}
                Search={
                    <Search handleSearchClick={handleSearchClick}>
                        <SingleSelect value={searchOption} handleOptionChange={handleOptionChange} />
                        <SearchInput
                            value={optionData}
                            handleKeyDown={handleKeyDown}
                            handleValueChange={handleValueChange}
                        />
                    </Search>
                }
            >
                <WardTeam />
                <Activity />
                <Risk />
            </Filter>
            <CustomCard
                header={{
                    title: t('History by Activity - List','Active History'),
                    subTitle: t('Function to view activities performed on a patient','Active History'),
                }}
            >
                <RecordTable
                    textAlign={'center'}
                    columns={columns}
                    data={{
                        rows: list.rows,
                        pageSize: 10,
                        totalCount: list.totalCount,
                        totalPage: list.totalPage,
                    }}
                    onPageChange={handlePageChange}
                    onClick={onTrClick}
                    noDataPlaceholder={t('Data does not exist.')}
                    loading={activeHistoryState.isLoading}
                />
            </CustomCard>
        </div>
    );
};

export default HistoryByActivity;
