import React from 'react';
import Select, { OptionTypeBase, StylesConfig } from 'react-select';
import { OptionType, SelectProps } from '../type';

const ReactSelect = ({
    name,
    value,
    options,
    defaultValue,
    closeMenuOnSelect = true,
    isSearchable = false,
    //isClearable = false,
    isMulti = false,
    onChange,
    //onInputChange,
    customControlStyles,
    //customMenuStyles,
    customOptionStyles,
    placeholder,
    isDisabled = false,
}: SelectProps): JSX.Element => {
    const selectStyle: StylesConfig<OptionType, boolean> = {
        placeholder: (provided, state) => {
            return {
                ...provided,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                ...customControlStyles,
            };
        },
        control: (provided, state) => {
            return {
                ...provided,
                width: '190',
                borderColor: '#3E66FB',
                ...customControlStyles,
            };
        },
        // menu: provided => ({
        //     ...provided,
        //     width: '150',
        //     ...customMenuStyles,
        // }),
        option: (provided, state) => {
            return {
                ...provided,
                width: '10vw',
                ...customOptionStyles,
            };
        },
        menuPortal: base => ({ ...base, zIndex: 9999 }),
    };

    return (
        <Select
            menuPortalTarget={document.getElementById('selectContainer')}
            name={name}
            value={value}
            options={options}
            defaultValue={defaultValue ? defaultValue : options[0]}
            onChange={onChange}
            isDisabled={isDisabled}
            //onInputChange={onInputChange}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            //isClearable={isClearable}

            styles={selectStyle}
            isMulti={isMulti}
            placeholder={placeholder || 'Select...'}
        />
    );
};

export default ReactSelect;
