import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleSearchableSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBfHighRisk,
    setBfPersonOption,
    setZoneOption,
} from '../../../../../../reducers/Common/PerformanceRecordOption';
import {
    createPersonFallDownRecord,
    getPersonFallDownRecord,
    updatePersonFallDownRecord,
} from '../../../../../../api/activeSchedule';
import CustomSearchModal from '../../../util/CustomSearchModal';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from '../../CarryingOutActivities';
import {
    setActivityFallEvaluationScheduleInfo,
    setFlag,
    updateActivityFallEvaluationScheduleInfo,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { TextChange, validationCheck } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { updateNurseInfo, setNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import { filterProcess } from '../../../util';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const FallDetailChild = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const commonState = useContext(CommonStateContext);
    const { bfHighRiskOption, bfPersonOption, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { actItemCodeName, actTypeCodeName, actItemCode, targetNum, psNum, exeDate } = patientDetailInfo;

    const { activityFallEvaluationScheduleInfo } = state;
    const { cardHeight } = commonState;
    const {
        bfRatingItemFallexp,
        bfRatingItemSeconddiagnosis,
        bfRatingItemWalking,
        bfRatingItemIv,
        bfRatingItemStep,
        bfRatingItemCognition,
    } = bfPersonOption;
    const handleDisabled = option => !option.includes('0');
    const [selectOption, setSelectOption] = useState({
        BF_HIGH_RISK: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_FALLEXP: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_SECONDDIAGNOSIS: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_WALKING: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_IV: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_STEP: [{ label: t('Select'), id: 0 }],
        BF_RATING_ITEM_COGNITION: [{ label: t('Select'), id: 0 }],
    });
    const [validation, setValidation] = useState({
        BF_HIGH_RISK: '고위험불변요인',
        BF_RATING_ITEM_FALLEXP: '낙상경험',
        BF_RATING_ITEM_SECONDDIAGNOSIS: '이차적인 진단',
        BF_RATING_ITEM_WALKING: '보행',
        BF_RATING_ITEM_IV: 'IU/IV Lock',
        BF_RATING_ITEM_STEP: '걸음걸이',
        BF_RATING_ITEM_COGNITION: '인지장애',
    });
    const defaultActivitySchedule = {
        bfHighRiskCodes: ['0'],
        bfRatingItemActiveCode: '',
        bfRatingItemAgeCode: '',
        bfRatingItemBehaviorCode: '',
        bfRatingItemDiagnosisCode: '',
        bfRatingItemFacilityCode: '',
        bfRatingItemHealthCode: '',
        bfRatingItemRearerCode: '',
        morse: 0,
        morseStatus: '고위험군',
    };
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BF_HIGH_RISK', codeDepth: '2' },
        resolve: res => {
            let optionCode = [];
            optionCode = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            storeDispatch(setBfHighRisk({ res, optionCode }));
        },
    });
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfHighRiskOptionRows = bfHighRiskOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'WARD', codeDepth: '2' },
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    const { promise: getSelectOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBfPersonOption(res));
        },
    });

    useEffect(() => {
        getSelectOption({ clCode: 'BF_RATING_ITEM_FALLEXP', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_SECONDDIAGNOSIS', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_WALKING', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_IV', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_STEP', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_COGNITION', codeDepth: '2' });
        if (actItemCode === 'BF_RATING_10') {
            setSelectOption({
                ...selectOption,
                BF_HIGH_RISK: [{ label: t('Not applicable'), id: '0' }],
            });
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_FALLEXP: null,
                BF_RATING_ITEM_SECONDDIAGNOSIS: null,
                BF_RATING_ITEM_WALKING: null,
                BF_RATING_ITEM_IV: null,
                BF_RATING_ITEM_STEP: null,
                BF_RATING_ITEM_COGNITION: null,
            });
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_FALLEXP: null,
                BF_RATING_ITEM_SECONDDIAGNOSIS: null,
                BF_RATING_ITEM_WALKING: null,
                BF_RATING_ITEM_IV: null,
                BF_RATING_ITEM_STEP: null,
                BF_RATING_ITEM_COGNITION: null,
            });
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                }),
            );
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, []);
    useEffect(() => {
        if (exeDate) {
            getFallDownRecord({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
    }, [exeDate]);

    // useEffect(() => {
    //     bfPersonOption.map(ele => {
    //         ele.map(row => {
    //             return {
    //                 ...row,
    //                 label: t(row.label, 'ActTypeCode'),
    //             };
    //         });
    //     });
    // }, []);

    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityFallEvaluationScheduleInfo({ wardCode: value }));
        setZoneState({ label, value });
    };

    const [morseFallScale, setMorseFallScale] = useState(0);
    const [selectValue, setSelectValue] = useState([]);
    // const filterProcess = selected => {
    //     if (selected.length) {
    //         const includeBol = selected.map(item => item.value).includes('0');
    //         setSelectValue(includeBol ? ['0'] : selected.map(v => v.value));
    //         return includeBol ? selected.filter(item => item.value === '0' && item) : selected.map(select => select);
    //     }
    //     setSelectValue([]);
    //     return [];
    // };

    const handleMainSelectChange = selected => {
        let multipleSelection = filterProcess(selected);
        setSelectValue(multipleSelection.map(v => v.value));
        if (!multipleSelection.map(item => item.value).includes('0')) {
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                    ...activityFallEvaluationScheduleInfo,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => t(item.label, 'ActTypeCode')).join(','),
                }),
            );
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_FALLEXP: null,
                BF_RATING_ITEM_SECONDDIAGNOSIS: null,
                BF_RATING_ITEM_WALKING: null,
                BF_RATING_ITEM_IV: null,
                BF_RATING_ITEM_STEP: null,
                BF_RATING_ITEM_COGNITION: null,
            });
        } else {
            dispatch(
                setActivityFallEvaluationScheduleInfo({
                    exeDate: activityFallEvaluationScheduleInfo.exeDate,
                    exeUserName: activityFallEvaluationScheduleInfo.exeUserName,
                    exeUserNum: activityFallEvaluationScheduleInfo.exeUserNum,
                    wardCode: activityFallEvaluationScheduleInfo.wardCode,
                    wardCodeName: activityFallEvaluationScheduleInfo.wardCodeName,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => item.label).join(','),
                }),
            );
            setValidation({
                ...validation,
                BF_HIGH_RISK: null,
            });
        }
        setSelectOption({
            ...selectOption,
            // BF_RATING_ITEM_FALLEXP: [{ label: '선택', id: 0 }],
            // BF_RATING_ITEM_SECONDDIAGNOSIS: [{ label: '선택', id: 0 }],
            // BF_RATING_ITEM_WALKING: [{ label: '선택', id: 0 }],
            // BF_RATING_ITEM_IV: [{ label: '선택', id: 0 }],
            // BF_RATING_ITEM_STEP: [{ label: '선택', id: 0 }],
            // BF_RATING_ITEM_COGNITION: [{ label: '선택', id: 0 }],
        });
        //setMorseFallScale(0);
    };
    const handleSelectValueChange = e => {
        const { id, name, label, codeNum, value, codeLabel, codeName } = e;
        if (id) {
            const beforeValue = selectOption[name][0].id || 0;
            const gap = beforeValue - Number(id);
            const morse = morseFallScale - Number(gap);
            setMorseFallScale(morse);
            setValidation({ ...validation, [name]: null });
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: id, value: value }] });
            let morseStatus = '';
            if (morse >= 51) {
                morseStatus = t('High Risk', 'Risk');
            }
            if (50 >= morse && morse >= 25) {
                morseStatus = t('Medium Risk', 'Risk');
            } else if (morse <= 24) {
                morseStatus = t('Low Risk', 'Risk');
            }
            if (selectValue.length && !selectValue.includes('0')) {
                morseStatus = t('High Risk', 'Risk');
            }

            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    [codeName]: codeNum,
                    morse,
                    [`${codeName}Name`]: codeLabel,
                    morseStatus,
                }),
            );
        } else {
            setValidation({ ...validation, [name]: null });
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: id, value: value }] });
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    [`${codeName}Name`]: codeLabel,
                    [codeName]: value,
                }),
            );
        }
    };

    const { promise: addFallDownRecord } = useAsync({
        promise: createPersonFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });

    const { promise: getFallDownRecord } = useAsync({
        promise: getPersonFallDownRecord,
        resolve: res => {
            const { wardCodeName, wardCode, morse } = res;
            storeDispatch(setNurseInfo(res));
            dispatch(setActivityFallEvaluationScheduleInfo(res));
            dispatch(setFlag());
            setMorseFallScale(morse);
            setZoneState({ label: wardCodeName, value: wardCode });
        },
    });

    const { promise: editFallDownRecord } = useAsync({
        promise: updatePersonFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const [validationResultState, setValidationResultState] = useState([]);

    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editFallDownRecord({
                      ip: profile.clientIp,
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      psNum,
                      exeStatusCode: 'C',
                  })
                : addFallDownRecord({
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      psNum,
                      exeStatusCode: 'C',
                      ip: profile.clientIp,
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    const onHandleEdit = () => {
        setRecordState(!recordState);
    };

    //onChnage
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };

    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityFallEvaluationScheduleInfo({ [name]: value }));
    };
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: noFallDownRecord } = useAsync({
        promise: createPersonFallDownRecord,
        resolve: () => {
            history.push('/activity-schedule');
        },
    });

    const onHandleNotDone = () => {
        noFallDownRecord({
            ...defaultActivitySchedule,
            exeStatusCode: 'N',
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            morse: '',
            morseStatus: '',
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const buttonOptionChange = value => {
        return !value
            ? [
                  {
                      label: t('Save'),
                      onClick: onHandleSave,

                      className: 'icon-save',
                      iconBtnClass: 'btn-icon',
                  },
                  {
                      label: t('Not performed'),
                      onClick: onHandleNotDone,
                      borderOption: true,
                  },
              ]
            : [
                  {
                      label: t('Edit'),
                      onClick: onHandleEdit,
                      borderOption: true,
                      className: 'icon-save',
                      iconBtnClass: 'btn-icon',
                  },
              ];
    };

    const handleScale = ({ score = 0 }) => {
        let scale = '';
        if (score >= 51) {
            scale = t('High Risk', 'Risk');
        }
        if (50 >= score && score >= 25) {
            scale = t('Medium Risk', 'Risk');
        } else if (score <= 24) {
            scale = t('Low Risk', 'Risk');
        }
        if (selectValue.length && !selectValue.includes('0')) {
            scale = t('High Risk', 'Risk');
        }

        return `${score}${t('Score', 'Risk')}/${scale}`;
    };

    const bfRatingItemFallexpRows = bfRatingItemFallexp.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemSeconddiagnosisRows = bfRatingItemSeconddiagnosis.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemWalkingRows = bfRatingItemWalking.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemIvRows = bfRatingItemIv.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemStepRows = bfRatingItemStep.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemCognitionRows = bfRatingItemCognition.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    return (
        cardHeight && (
            <ActiveRecordCard
                style={{ height: cardHeight }}
                headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={buttonOptionChange(recordState)}
                spanIcon={['icon-info']}
                url={
                    actItemCodeName === '초기평가-7세이상 소아,성인'
                        ? `${url.wmsUrl}/v2/html/aum/guide/4_2.html`
                        : `${url.wmsUrl}/v2/html/aum/guide/4_4.html`
                }
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse')}
                        component={
                            <HandleNurseTextInput
                                readOnly={nurseRecordState}
                                name={'nurse'}
                                className={'d-flex '}
                                onClick={toggleModal}
                                placeholder={`${t('Nurse')}||${t('Number')}`}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    nurseInfo.exeUserName &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    activityFallEvaluationScheduleInfo.hasOwnProperty('exeUserName') &&
                                    activityFallEvaluationScheduleInfo.exeUserName &&
                                    `${activityFallEvaluationScheduleInfo.exeUserName} || ${activityFallEvaluationScheduleInfo.exeUserNum}`
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('When performed')}
                        component={
                            <HandleDateInput
                                readOnly={nurseRecordState}
                                value={startDate}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                                minDate={moment().valueOf()}
                                text={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.exeDate}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Zones')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                options={zoneOptionRows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleZoneSelect}
                                value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                                text={
                                    (activityFallEvaluationScheduleInfo.wardCodeName &&
                                        t(activityFallEvaluationScheduleInfo.wardCodeName, 'ActTypeCode')) ||
                                    '-'
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('High-risk invariants')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleSearchableSelect
                                readOnly={recordState}
                                options={bfHighRiskOptionRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleMainSelectChange}
                                selected={t(selectValue, 'ActTypeCode')}
                                text={{
                                    code: activityFallEvaluationScheduleInfo.bfHighRiskCode,
                                    rows: bfHighRiskOptionRows,
                                }}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Falls in the last 3 months')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                //isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemFallexpRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput3'}
                                value={selectOption['BF_RATING_ITEM_FALLEXP']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemFallExpCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemFallExpCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Secondary diagnostics')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                // isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemSeconddiagnosisRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput4'}
                                value={selectOption['BF_RATING_ITEM_SECONDDIAGNOSIS']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemSecondDiagnosisCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemSecondDiagnosisCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Walking')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                // isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemWalkingRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput5'}
                                value={selectOption['BF_RATING_ITEM_WALKING']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemWalkingCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemWalkingCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('IU/IV Lock')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                // isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemIvRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput6'}
                                value={selectOption['BF_RATING_ITEM_IV']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemIvCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemIvCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Gait')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                //isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemStepRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput7'}
                                value={selectOption['BF_RATING_ITEM_STEP']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemStepCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemStepCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Cognitive disorders')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                //isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemCognitionRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                name={'selectInput8'}
                                value={selectOption['BF_RATING_ITEM_COGNITION']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemCognitionCode}-${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemCognitionCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />

                    <DescriptionRow
                        label={'Morse fall scale'}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<>{handleScale({ score: morseFallScale })}</>}
                    />
                    <DescriptionRow
                        label={t('Remarks')}
                        className="col-xl-12 col-lg-12 mb-3"
                        spanClassName={'w-50'}
                        component={
                            <HandleTextInput
                                size={200}
                                onChange={onHandleTextChange}
                                readOnly={recordState}
                                name={'remark'}
                                value={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.remark}
                                placeholder={t('Enter any remarks.')}
                            />
                        }
                    />
                </div>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ActiveRecordCard>
        )
    );
};

export default FallDetailChild;
