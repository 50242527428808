import React from 'react';
import { Col, Row } from 'reactstrap';
import ActiveStatusCard from './ActiveStatusCard';
import ActiveCardList from './ActiveCardList';
import { useHistory } from 'react-router-dom';

const ActiveStatusWithList = ({ statusCardInfo, listInfo }) => {
    const history = useHistory();
    const handleRedirectClick = psNum => {
        history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
    };
    return (
        <Col className={'h-100'}>
            <Row>
                <Col>
                    <ActiveStatusCard {...statusCardInfo} />
                </Col>
            </Row>
            <Row style={{ height: 'calc(100vh - 400px)' }}>
                <Col className={'h-90'}>
                    <ActiveCardList handleRedirectClick={handleRedirectClick} {...listInfo} />
                </Col>
            </Row>
        </Col>
    );
};

export default ActiveStatusWithList;
