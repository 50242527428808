import React from 'react';
import TextInput from '../../../../Common/Input/TextInput';
import { useSelector } from 'react-redux';
import useTranslation from "../../../../../util/hooks/useTranslation";

const SearchInput = ({ value, handleValueChange, handleKeyDown }) => {
    const { keyword } = value;
    const t = useTranslation('Search')
    return (
        <>
            <TextInput
                type={'text'}
                size={'60'}
                handleChange={handleValueChange}
                disabled={!value}
                className={'ml-1 mr-3'}
                value={keyword}
                placeholder={t('Please enter a search term')}
                name={'keyword'}
                handleKeyDown={handleKeyDown}
            />
        </>
    );
};

export default SearchInput;
