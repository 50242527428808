import React, { useEffect } from 'react';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import useAsync from '../../../../../util/hooks/useAsync';
import { getGroupList } from '../../../../../api/groupSetting';
import { setFilter, setWardInfoList, updateSearchFilter } from '../../../../../reducers/Common/SearchFilter';
import { useDispatch, useSelector } from 'react-redux';
import FilterOptionBox from './FilterOptionBox';
import useTranslation from "../../../../../util/hooks/useTranslation";

const WardTeam = () => {
    const translation = useTranslation('Filter');
    const storeDispatch = useDispatch();
    const { wardInfoList } = useSelector(state => state.SearchFilter);
    const { patientGroupNums } = useSelector(state => state.SearchFilter.filter);
    const { promise: getWardInfoList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            storeDispatch(setWardInfoList(res));
        },
        reject: error => {
            console.log(error);
        },
    });

    useEffect(() => {
        getWardInfoList({ isAll: 'Y' });
    }, []);

    const handleWardFilterChange = selected => {
        let arrLabel = [];
        let arrValue = [];
        if (selected.length) {
            arrLabel = selected.map(v => v.label);
            arrValue = selected.map(v => v.value);
            storeDispatch(setFilter({ patientGroupNames: arrLabel, patientGroupNums: arrValue }));
            storeDispatch(updateSearchFilter({ patientGroupNames: arrLabel, patientGroupNums: arrValue }));
        } else {
            storeDispatch(setFilter({ patientGroupNames: null, patientGroupNums: null }));
            storeDispatch(updateSearchFilter({ patientGroupNames: null, patientGroupNums: null }));
        }
    };
    return (
        <FilterOptionBox>
            <SearchableSelect
                title={translation('Wards')}
                selected={patientGroupNums}
                data={wardInfoList.rows}
                onChange={handleWardFilterChange}
            />
        </FilterOptionBox>
    );
};

export default WardTeam;
