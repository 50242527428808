import { takeLeading } from 'redux-saga/effects';
import { createAction } from 'redux-actions';

const LOG_IN = 'saga/login/LOG_IN';
const GET_COMPANY_INFO = 'saga/login/GET_COMPANY_INFO';

export const login = createAction(LOG_IN, accInfo => accInfo);

export const fetchCompany = createAction(GET_COMPANY_INFO);

// const loginAsync = createRequestSaga(SET_TOKEN, api.login);
// const fetchCompanyAsync = createRequestSaga(SET_COMPANY_INFO, api.fetchCompany);
const test = function* () {
    yield 1;
};

export function* watchLoginAsync() {
    // yield takeLeading(LOG_IN, loginAsync);
    // yield takeLeading(GET_COMPANY_INFO, fetchCompanyAsync);
    yield takeLeading(LOG_IN, test);
}
