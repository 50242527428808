import React, { useContext } from 'react';
import cx from 'classnames';
import Button from '../../../Common/Button';
import { LoginDispatchContext } from '../index';
import { setStep, initialState } from '../reducer';

const LoginTitle = ({ alignCenter, title, subTitle, canGoBack }) => {
    const dispatch = useContext(LoginDispatchContext);
    const handleClick = () => {
        dispatch(setStep(initialState));
    };
    return (
        <div className="title-box">
            <div className="txt--top">
                <Button
                    className={cx('pnt-icon-only p-0', !canGoBack && 'd-none')}
                    iconClassName={'icon-back'}
                    onClick={handleClick}
                />
                <h2 className={cx('txt__main', alignCenter && 'w-100 tc')}>{title}</h2>
            </div>
            <div className="txt--bottom">
                <h3 className={cx('txt__sub', alignCenter && 'w-100 tc')}>{subTitle}</h3>
            </div>
        </div>
    );
};

export default LoginTitle;
