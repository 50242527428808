import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';
import moment from 'moment';

const SET_ACTIVITY_SCHEDULE_INFO = 'scheduleByActivity/SET_ACTIVITY_SCHEDULE_INFO';
const UPDATE_ACTIVITY_SCHEDULE_INFO = 'scheduleByActivity/UPDATE_ACTIVITY_SCHEDULE_INFO';
const SET_ACTIVITY_EDUCATION_SCHEDULE_INFO = 'scheduleByActivity/SET_ACTIVITY_EDUCATION_SCHEDULE_INFO';
const UPDATE_ACTIVITY_EDUCATION_SCHEDULE_INFO = 'scheduleByActivity/UPDATE_ACTIVITY_EDUCATION_SCHEDULE_INFO';
const SET_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO =
    'scheduleByActivity/SET_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO';
const UPDATE_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO =
    'scheduleByActivity/UPDATE_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO';
const SET_ACTIVITY_LYING_DOWN_SCHEDULE_INFO = 'scheduleByActivity/SET_ACTIVITY_LYING_DOWN_SCHEDULE_INFO';
const UPDATE_ACTIVITY_LYING_DOWN_SCHEDULE_INFO = 'scheduleByActivity/UPDATE_ACTIVITY_LYING_DOWN_SCHEDULE_INFO';
const SET_ACTIVITY_RECORD_INFO = 'scheduleByActivity/SET_ACTIVITY_RECORD_INFO';
const UPDATE_ACTIVITY_RECORD_INFO = 'scheduleByActivity/UPDATE_ACTIVITY_RECORD_INFO';
const SET_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO = 'scheduleByActivity/SET_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO';
const UPDATE_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO =
    'scheduleByActivity/UPDATE_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO';
const SET_ACTIVITY_FALL_EDUCATION_INFO = 'scheduleByActivity/SET_ACTIVITY_FALL_EDUCATION_INFO';
const UPDATE_ACTIVITY_FALL_EDUCATION_INFO = 'scheduleByActivity/UPDATE_ACTIVITY_FALL_EDUCATION_INFO';
const SET_URINATION_ROUND_INFO = 'scheduleByActivity/SET_URINATION_ROUND_INFO';
const UPDATE_URINATION_ROUND_INFO = 'scheduleByActivity/UPDATE_URINATION_ROUND_INFO';
const SET_WHOLE_BODY_SKIN_RECORD_INFO = 'scheduleByActivity/SET_WHOLE_BODY_SKIN_RECORD_INFO';
const UPDATE_WHOLE_BODY_SKIN_RECORD_INFO = 'scheduleByActivity/UPDATE_WHOLE_BODY_SKIN_RECORD_INFO';
const SET_TREATMENT_SKIN_RECORD_INFO = 'scheduleByActivity/SET_TREATMENT_SKIN_RECORD_INFO';
const UPDATE_TREATMENT_SKIN_RECORD_INFO = 'scheduleByActivity/UPDATE_TREATMENT_SKIN_RECORD_INFO';

const SET_FLAG = 'scheduleByActivity/SET_FLAG';

export const {
    setActivityScheduleInfo,
    updateActivityScheduleInfo,
    setActivityEducationScheduleInfo,
    updateActivityEducationScheduleInfo,
    setActivityInitialEvaluationScheduleInfo,
    updateActivityInitialEvaluationScheduleInfo,
    setActivityLyingDownScheduleInfo,
    updateActivityLyingDownScheduleInfo,
    setActivityRecordInfo,
    updateActivityRecordInfo,
    setActivityFallEvaluationScheduleInfo,
    updateActivityFallEvaluationScheduleInfo,
    setActivityFallEducationInfo,
    updateActivityFallEducationInfo,
    setUrinationRoundInfo,
    updateUrinationRoundInfo,
    setWholeBodySkinRecordInfo,
    updateWholeBodySkinRecordInfo,
    setTreatmentSkinRecordInfo,
    updateTreatmentSkinRecordInfo,
    setFlag,
} = createActions(
    {
        SET_ACTIVITY_SCHEDULE_INFO: activityScheduleInfo => activityScheduleInfo,
        UPDATE_ACTIVITY_SCHEDULE_INFO: activityScheduleInfo => activityScheduleInfo,
        SET_ACTIVITY_EDUCATION_SCHEDULE_INFO: activityEducationScheduleInfo => activityEducationScheduleInfo,
        UPDATE_ACTIVITY_EDUCATION_SCHEDULE_INFO: activityEducationScheduleInfo => activityEducationScheduleInfo,
        SET_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO: activityInitialEvaluationScheduleInfo =>
            activityInitialEvaluationScheduleInfo,
        UPDATE_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO: activityInitialEvaluationScheduleInfo =>
            activityInitialEvaluationScheduleInfo,
        SET_ACTIVITY_LYING_DOWN_SCHEDULE_INFO: activityLyingDownScheduleInfo => activityLyingDownScheduleInfo,
        UPDATE_ACTIVITY_LYING_DOWN_SCHEDULE_INFO: activityLyingDownScheduleInfo => activityLyingDownScheduleInfo,
        SET_ACTIVITY_RECORD_INFO: activityRecordInfo => activityRecordInfo,
        UPDATE_ACTIVITY_RECORD_INFO: activityRecordInfo => activityRecordInfo,
        SET_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO: activityFallEvaluationScheduleInfo =>
            activityFallEvaluationScheduleInfo,
        UPDATE_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO: activityFallEvaluationScheduleInfo =>
            activityFallEvaluationScheduleInfo,
        SET_ACTIVITY_FALL_EDUCATION_INFO: activityFallEducationInfo => activityFallEducationInfo,
        UPDATE_ACTIVITY_FALL_EDUCATION_INFO: activityFallEducationInfo => activityFallEducationInfo,
        SET_URINATION_ROUND_INFO: urinationRoundInfo => urinationRoundInfo,
        UPDATE_URINATION_ROUND_INFO: urinationRoundInfo => urinationRoundInfo,
        SET_WHOLE_BODY_SKIN_RECORD_INFO: wholeBodySkinRecordInfo => wholeBodySkinRecordInfo,
        UPDATE_WHOLE_BODY_SKIN_RECORD_INFO: wholeBodySkinRecordInfo => wholeBodySkinRecordInfo,
        SET_TREATMENT_SKIN_RECORD_INFO: treatmentSkinRecordInfo => treatmentSkinRecordInfo,
        UPDATE_TREATMENT_SKIN_RECORD_INFO: treatmentSkinRecordInfo => treatmentSkinRecordInfo,
        SET_FLAG: flag => flag,
    },
    { prefix: 'scheduleByActivity' },
);

export const initialState = {
    activityScheduleInfo: { sdate: moment().valueOf() / 1000, edate: moment().add(1, 'h').valueOf() / 1000 },
    activityEducationScheduleInfo: { wardCode: '0' },
    activityInitialEvaluationScheduleInfo: { wardCode: '0' },
    activityLyingDownScheduleInfo: { wardCode: '0' },
    activityFallEvaluationScheduleInfo: { wardCode: '0' },
    activityRecordInfo: { wardCode: '0' },
    activityFallEducationInfo: { wardCode: '0' },
    urinationRoundInfo: { wardCode: '0', remark: '낙상예방을 위해 침대높이를 최저로 낮추고 교육함' },
    wholeBodySkinRecordInfo: null,
    treatmentSkinRecordInfo: null,
    activityValidationInfo: {
        exeUserName: '간호사를 지정해주세요.',
        exeDate: '수행일시를 지정해주세요.',
    },
    flag: false,
};

const scheduleByActivityReducer = handleActions(
    {
        [SET_ACTIVITY_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityScheduleInfo = data;
            });
        },
        [UPDATE_ACTIVITY_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityScheduleInfo = { ...state.activityScheduleInfo, ...data };
            });
        },
        [SET_ACTIVITY_EDUCATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                let bsEduCodes = [];
                for (let key in data) {
                    let result = null;
                    result = key.match('[0-9]{1,2}');
                    if (result && result['input'].includes('Name')) {
                        let value = data[key];
                        bsEduCodes.push(value);
                    }
                }
                draft.activityEducationScheduleInfo = { ...data, bsEduCodes };
            });
        },
        [UPDATE_ACTIVITY_EDUCATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                const preData = { ...state.activityEducationScheduleInfo };
                const value = Object.values(data);
                if (!value[0]) {
                    const keyArr = Object.keys(data);
                    const key = keyArr[0];
                    const codeName = key + 'Name';
                    if (preData.hasOwnProperty(codeName)) {
                        draft.activityEducationScheduleInfo = { ...preData, [codeName]: '', [key]: '' };
                    } else {
                        draft.activityEducationScheduleInfo = { ...preData, [key]: '' };
                    }
                } else {
                    draft.activityEducationScheduleInfo = { ...preData, ...data };
                }
            });
        },

        [SET_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityInitialEvaluationScheduleInfo = { ...data };
            });
        },
        [UPDATE_ACTIVITY_INITIAL_EVALUATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityInitialEvaluationScheduleInfo = {
                    ...state.activityInitialEvaluationScheduleInfo,
                    ...data,
                };
            });
        },
        [SET_ACTIVITY_LYING_DOWN_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.activityLyingDownScheduleInfo = action.payload;
            });
        },
        [UPDATE_ACTIVITY_LYING_DOWN_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityLyingDownScheduleInfo = { ...state.activityLyingDownScheduleInfo, ...data };
            });
        },
        [SET_ACTIVITY_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                draft.activityRecordInfo = { ...data };
            });
        },
        [UPDATE_ACTIVITY_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityRecordInfo = { ...state.activityRecordInfo, ...data };
            });
        },
        [SET_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityFallEvaluationScheduleInfo = { ...data };
            });
        },
        [UPDATE_ACTIVITY_FALL_EVALUATION_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityFallEvaluationScheduleInfo = { ...state.activityFallEvaluationScheduleInfo, ...data };
            });
        },

        [SET_ACTIVITY_FALL_EDUCATION_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                let bfEduCodes = [];
                for (let key in data) {
                    let result = null;
                    result = key.match('[0-9]{1,2}');
                    if (result && result['input'].includes('Name')) {
                        let value = data[key];
                        bfEduCodes.push(value);
                    }
                }
                draft.activityFallEducationInfo = { ...data, bfEduCodes: bfEduCodes.filter(v => v !== null) };
            });
        },
        [UPDATE_ACTIVITY_FALL_EDUCATION_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                const preData = { ...state.activityFallEducationInfo };
                const value = Object.values(data);
                if (!value[0]) {
                    const keyArr = Object.keys(data);
                    const key = keyArr[0];
                    const codeName = key + 'Name';
                    if (preData.hasOwnProperty(codeName)) {
                        draft.activityFallEducationInfo = { ...preData, [codeName]: '', [key]: '' };
                    } else {
                        draft.activityFallEducationInfo = { ...preData, [key]: '' };
                    }
                } else {
                    draft.activityFallEducationInfo = { ...preData, ...data };
                }
            });
        },

        [SET_URINATION_ROUND_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.urinationRoundInfo = data;
            });
        },

        [UPDATE_URINATION_ROUND_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                const exeFlag = data.exeFlag || {};

                if (exeFlag === 'Y' || exeFlag === 'N') {
                    if (exeFlag === 'Y') {
                        draft.urinationRoundInfo = { ...state.urinationRoundInfo, exeFlag };
                    } else {
                        draft.urinationRoundInfo = { ...state.urinationRoundInfo, exeFlag, bfUrinationCode: null };
                    }
                } else {
                    draft.urinationRoundInfo = { ...state.urinationRoundInfo, ...data };
                }
            });
        },
        [SET_WHOLE_BODY_SKIN_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.wholeBodySkinRecordInfo = data;
            });
        },
        [UPDATE_WHOLE_BODY_SKIN_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (!data) {
                    draft.wholeBodySkinRecordInfo = null;
                } else {
                    draft.wholeBodySkinRecordInfo = { ...state.wholeBodySkinRecordInfo, ...data };
                }
            });
        },
        [SET_TREATMENT_SKIN_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.treatmentSkinRecordInfo = data;
            });
        },
        [UPDATE_TREATMENT_SKIN_RECORD_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (!data) {
                    draft.treatmentSkinRecordInfo = null;
                } else {
                    draft.treatmentSkinRecordInfo = { ...state.treatmentSkinRecordInfo, ...data };
                }
            });
        },

        [SET_FLAG]: (state, action) => {
            return produce(state, draft => {
                draft.flag = !state.flag;
            });
        },
    },
    initialState,
);

export default scheduleByActivityReducer;
