import { useSettings } from '../util/useSettings';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomSelectWithValue, HiddenHeader, WidgetName } from '../Components/WidgetSettingOptions';
import React from 'react';

const FloorSummarySetting = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    ...restProps
}) => {
    const { config, layout } = widgetInfo;
    const settings = useSettings(config);
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const { settings: customSettings, defaultSettings } = config;
    const { t } = useTranslation();
    const options = [
        { value: 'ALL TARGET', label: t('RealTimeLocationStatus;All Target') },
        { value: 'SINGLE TARGET', label: t('RealTimeLocationStatus;Single Target') },
    ];

    const onSubmit = data => {
        // console.log(data);
        // const targetOption = getValues('targetOption');
        // if (targetOption === 'ALL TARGET') {
        //     selectedValue = t('Widget.RealTimeLocationStatus');
        // } else {
        //     selectedValue = t('Widget.RealTimeLocationSearch');
        // }

        dispatch(
            editWidgetSettings({
                id: widgetInfo.id,
                settings: data,
            }),
        );
        toggleModal();
    };

    // const handleChange = e => {
    //     const targetOption = getValues('targetOption');
    //     console.log(targetOption);
    //     if (targetOption === 'ALL TARGET') {
    //         selectedValue = t('Widget.RealTimeLocationStatus');
    //     } else {
    //         selectedValue = t('Widget.RealTimeLocationSearch');
    //     }
    //     console.log(selectedValue);
    // };

    return (
        <Modal isOpen={modal} toggle={toggleModal} {...restProps}>
            <ModalHeader toggle={toggleModal}>
                {t('RealTimeLocationStatus;Setting For Monitoring Realtime Location')}
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <Input type="hidden" name="id" innerRef={register()} defaultValue={layout.i} />
                    <Col>
                        <WidgetName
                            innerRef={register({ required: true })}
                            defaultValue={
                                customSettings && customSettings.name
                                    ? customSettings.name
                                    : t(`Widget;${defaultSettings.name}`)
                            }
                        />
                    </Col>
                    <Col>
                        <HiddenHeader
                            innerRef={register()}
                            defaultValue={
                                customSettings && customSettings.hiddenHeader ? customSettings.hiddenHeader : false
                            }
                        />
                    </Col>
                    {/*<Col>*/}
                    {/*    <IntervalTime innerRef={register({ required: true })} defaultValue={settings.intervalTime} />*/}
                    {/*</Col>*/}
                    <Col>
                        <CustomSelectWithValue
                            label={t('RealTimeLocationStatus;Target Monitoring Option')}
                            name={'targetOption'}
                            id={'targetOption'}
                            innerRef={register({ required: true })}
                            options={options}
                            multiple={false}
                            // onChange={handleChange}
                            defaultValue={settings.targetOption}
                        />
                    </Col>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    {t('Button;Ok')}
                </Button>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        toggleModal();
                    }}
                >
                    {t('Button;Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FloorSummarySetting;
