import React, { useState } from 'react';
import Button from '../../../Common/Button';
import ResizeDetector from 'react-resize-detector';
import { Row, Collapse } from 'reactstrap';
import styled from 'styled-components';
import { RESPONSIVE_FILTER_WIDTH } from '../Filter/Filter';
import useTranslation from "../../../../util/hooks/useTranslation";

const SearchRow = styled(Row)`
    margin: 0;
    padding: 0.3rem 1rem;
    flex-wrap: nowrap;
    background-color: white;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(240, 240, 245, 1) 0%, rgba(240, 240, 245, 0.5) 100%);
`;

const SearchSpan = styled.span`
    font-size: 0.8rem;
    font-weight: 700;
    margin-right: 0.5rem;
`;

const MobileFilterRow = styled(Row)`
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    > * {
        margin: 0.3rem;
    }
`;

const FilterBox = styled.div`
    display: flex;
`;
const FilterAction = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
`;

const Search = ({ handleSearchClick = () => {}, withoutSearchBtn = false, children }) => {
    const t = useTranslation('Search')
    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                return (
                    <>
                        <SearchRow>
                            <SearchSpan className="filter-title">{t('Search')}</SearchSpan>

                            {!isSmall && <FilterBox>{children}</FilterBox>}

                            <FilterAction>
                                {!isSmall && !withoutSearchBtn && (
                                    <Button
                                        className={'pnt-btn btn-blue btn-icon-only none-under-770 ml-2'}
                                        iconClassName={'icon-search'}
                                        onClick={handleSearchClick}
                                    />
                                )}

                                {isSmall && (
                                    <>
                                        <Button
                                            className={'pnt-btn btn-blue mr-1 btn-icon-only none-under-770 ml-2'}
                                            iconClassName={'icon-search'}
                                            onClick={handleSearchClick}
                                        />
                                        <Button
                                            className="pnt-btn btn-gray btn-icon-only btn-filter-open"
                                            onClick={toggleFilter}
                                            iconClassName={'icon-down'}
                                        />
                                    </>
                                )}
                            </FilterAction>
                        </SearchRow>
                        {isSmall && (
                            <Collapse isOpen={filterOpen}>
                                <MobileFilterRow>{children}</MobileFilterRow>
                            </Collapse>
                        )}
                    </>
                );
            }}
        />
    );
};

export default Search;
