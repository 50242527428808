import React, { useContext } from 'react';
import LoginTitle from './LoginTitle';
import useTranslation from '../../../../util/hooks/useTranslation';
import { LoginDispatchContext } from '../index';
import { setNextStep } from '../reducer';
import Button from '../../../Common/Button';

const CompleteForm = () => {
    const t = useTranslation('Login');
    const dispatch = useContext(LoginDispatchContext);
    const handleGoLogin = () => {
        dispatch(setNextStep());
    };
    return (
        <>
            <LoginTitle
                alignCenter
                title={t('Change Complete')}
                subTitle={t('The password you entered has been reset.')}
            />
            <div className="cont-box">
                <div className="cont-box__wrap flex-col">
                    <div className="cont--txt flx-col">
                        <div className="icon-check" />
                        <h3 className="txt__main">
                            {t('Password change')} <br /> {t('is completed')}
                        </h3>
                        <p className="txt__sub">{t('Log in again with the changed password')}</p>
                    </div>
                    <div className="cont--btn">
                        <Button className="btn-orange btn-full" onClick={handleGoLogin}>
                            {t('Go to login')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompleteForm;
