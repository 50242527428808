import React, { useContext, useEffect, useState } from 'react';
import Map from '../../../../Common/Map/Components/Map';
import { LayersControl, Marker, FeatureGroup } from 'react-leaflet';
import { DashBoardDispatchContext, DashBoardStateContext } from '../index';
import { dateChange } from '../../../util/dateChange';
import RotatedImageOverlay from '../../../../Common/Map/Components/RotatedImageOverlay';
import GeofenceLayer from '../../../../Common/Map/Layers/GeofenceLayer';
import { resetMapInfo, setFallPatientDetailInfo, setFloor, setGeofence } from '../../dashBoardReducer';
import useAsync from '../../../../../util/hooks/useAsync';
import { getFloorInfoApi, getGeofenceInfoApi } from '../../../../../api/dashboard';
import L from 'leaflet';
import { onHandleTextChange } from '../../../util/TextChange';
import { useDispatch, useSelector } from 'react-redux';
import { getFallStatusPatientInfoList } from '../../../../../api/activeSchedule';
import { setTargetId } from '../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../util/hooks/useTranslation';
const FallPatientDetail = () => {
    const t = useTranslation('Common Columns');
    const [error, setError] = useState(null);
    const state = useContext(DashBoardStateContext);
    const dispatch = useContext(DashBoardDispatchContext);
    const storeDispatch = useDispatch();
    const { fallPatientDetailInfo, floor, geofence } = state;

    const { targetId } = useSelector(state => state.PatientInfo);
    const { promise: getPatientList } = useAsync({
        promise: getFallStatusPatientInfoList,
        resolve: res => {
            const { rows } = res;
            if (rows.length) {
                const [patientInfo] = res.rows;

                dispatch(setFallPatientDetailInfo(patientInfo));
                if (patientInfo.floor) {
                    getFloorInfo({ floorId: patientInfo.floor });
                }
            } else {
            }
        },
    });
    const { promise: getFloorInfo } = useAsync({
        promise: getFloorInfoApi,
        resolve: res => {
            dispatch(setFloor(res));
            getGeofenceInfo({
                floorId: res.floorId,
                categoryCodes: fallPatientDetailInfo.categoryCodes,
            });
        },
    });
    const { promise: getGeofenceInfo } = useAsync({
        promise: getGeofenceInfoApi,
        resolve: res => {
            dispatch(setGeofence(res));
        },
    });

    useEffect(() => {
        getPatientList({ target: targetId });
        return () => {
            if (floor && geofence) {
                dispatch(resetMapInfo(null));
            }
            storeDispatch(setTargetId(null));
        };
    }, []);

    return (
        <>
            <div className="row">
                <div className={'col-xl-6'}>
                    <div className="pnt-label--group">
                        <span className="label-main label-dot">{t('Patient name')}</span>
                        <span className="label-options">
                            {fallPatientDetailInfo && fallPatientDetailInfo.targetName}
                        </span>
                    </div>
                </div>

                <div className={'col-xl-6'}>
                    <div className="pnt-label--group">
                        <span className="label-main label-dot">{t('ID')}</span>
                        <span className="label-options">{fallPatientDetailInfo && fallPatientDetailInfo.targetId}</span>
                    </div>
                </div>
            </div>
            <div className="pnt-border border-width" style={{ width: '100%', height: ' 0.5px' }} />
            <div className="row">
                <div className={'col-xl-12'}>
                    <div className="pnt-label--group">
                        <span className="label-main label-dot">{t('Ward Room')}</span>
                        <span className="label-options">
                            {fallPatientDetailInfo &&
                                onHandleTextChange([
                                    fallPatientDetailInfo.properties.wardNo,
                                    fallPatientDetailInfo.properties.roomNo,
                                    fallPatientDetailInfo.properties.bedNo,
                                ])}
                        </span>
                    </div>
                </div>
            </div>
            <div className="pnt-border border-width" style={{ width: '100%', height: ' 0.5px' }} />
            <div className="row">
                <div className={'col-xl-6'}>
                    <div className="pnt-label--group">
                        <span className="label-main label-dot">{t('Last recognized location')}</span>
                        <span className="label-options">
                            {fallPatientDetailInfo &&
                                onHandleTextChange([fallPatientDetailInfo.floorName, fallPatientDetailInfo.geofences])}
                        </span>
                    </div>
                </div>

                <div className={'col-xl-6'}>
                    <div className="pnt-label--group">
                        <span className="label-main label-dot">{t('Last Recognized Time')}</span>
                        <span className="label-options">
                            {fallPatientDetailInfo && dateChange(fallPatientDetailInfo.lastDate)}
                        </span>
                    </div>
                </div>
            </div>
            {floor && floor.neLat && (
                <Map tile={false}>
                    {floor && floor.neLat && (
                        <RotatedImageOverlay
                            key={floor.imgURL}
                            //Map load 시킬 이미지
                            url={floor.imgURL}
                            //이미지를 load 시킬 위도경도 북동위도,북동경도,남서위도,남서경도
                            bounds={[
                                [floor.neLat, floor.neLng],
                                [floor.swLat, floor.swLng],
                            ]}
                            deg={floor.deg}
                            onLoad={e => {
                                setError(false);
                            }}
                            onError={e => {
                                setError(true);
                            }}
                        />
                    )}
                    <LayersControl position="topright">
                        <LayersControl.Overlay name={t('Danger zones')} checked={true}>
                            <FeatureGroup>{geofence && <GeofenceLayer geofenceList={geofence} />}</FeatureGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name={t('Marker')} checked={true}>
                            <FeatureGroup>
                                {fallPatientDetailInfo && (
                                    <Marker
                                        position={{
                                            lat: fallPatientDetailInfo.lat,
                                            lng: fallPatientDetailInfo.lng,
                                        }}
                                        icon={L.divIcon({
                                            className: 'category-marker',
                                            html:
                                                fallPatientDetailInfo.lostSignalStatus === 'LOSTSIGNAL_ON'
                                                    ? `<div class="marker-img"><div class="icon-gps"></div></div>`
                                                    : `<div class="marker-img"><div class="icon-gps-fill"></div></div>`,
                                        })}
                                    />
                                )}
                            </FeatureGroup>
                        </LayersControl.Overlay>
                    </LayersControl>
                </Map>
            )}
            {!floor && <Map></Map>}
        </>
    );
};

export default FallPatientDetail;
