import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import TextInput from '../../../Common/Input/TextInput';
import Table from '../../../Common/Table';
import * as column from '../../util/grid/columns';
import useAsync from '../../../../util/hooks/useAsync';
import { updateWholeBodySkinRecordInfo } from '../ScheduleByActivity/scheduleByActivityReducer';
import { getHospitalStaffInfoList } from '../../../../api/activeSchedule';
import { ScheduleByActivityDispatchContext } from '../Components/CarryingOutActivities';
import { useDispatch, useSelector } from 'react-redux';
import { setNurseInfoList, updateNurseInfo } from '../../../../reducers/Common/CommonInfo';
import useTranslation from '../../../../util/hooks/useTranslation';

const StyledModalTitle = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.color || 'black'};
`;

const StyledFooter = styled.div`
    height: 5rem;
    background-color: #f8f9fa;
    border-top: 1px solid #d1d1d1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
`;

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;

const columns = [column.staffName({}), column.modalWard({}), column.department({})];

const CustomSearchModal = ({
    openModal,
    subTitle = 'The ability to search for doctors and nurses.',
    searchTarget = 'nurseAndDoctor',
    onModalClickYes = () => {},
    headerClassName = 'black',
    btnClassName = 'btn-orange',
}) => {
    const t = useTranslation('Modal');
    const storeDispatch = useDispatch();
    const [nurseInfo, setNurseInfo] = useState(null);
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const { nurseInfoList } = useSelector(state => state.CommonInfo);

    //임시 데이터
    const [keyword, setKeyword] = useState(null);
    const handleInputChange = e => {
        const { value } = e.target;
        setKeyword(value);
    };

    const { promise: getNurseInfoList } = useAsync({
        promise: getHospitalStaffInfoList,
        resolve: res => {
            storeDispatch(setNurseInfoList(res));
        },
    });
    const onHandleSearch = () => {
        if (searchTarget === 'nurseAndDoctor') {
            getNurseInfoList({ drst: 'nr', keyword });
        }
        if (searchTarget === 'doctor') {
            getNurseInfoList({ drst: 'dc', keyword });
        }
    };
    const handleTrClick = e => {
        setNurseInfo(e);
    };
    const handleClick = () => {
        if (searchTarget === 'nurseAndDoctor') {
            if (nurseInfo) {
                storeDispatch(updateNurseInfo({ exeUserName: nurseInfo.userName, exeUserNum: nurseInfo.userNum }));
            }
        }
        if (searchTarget === 'doctor') {
            if (nurseInfo) {
                dispatch(updateWholeBodySkinRecordInfo({ dr: nurseInfo.userNum, drName: nurseInfo.userName }));
            }
        }
        storeDispatch(setNurseInfoList(null));
    };

    return (
        <Modal isOpen={openModal}>
            <ModalHeader
                className={headerClassName}
                toggle={() => {
                    onModalClickYes();
                    storeDispatch(setNurseInfoList(null));
                }}
            >
                <StyledModalTitle>{t('Search for a doctor/nurse')}</StyledModalTitle>
                {!!subTitle && <SubTitle>{t(subTitle)}</SubTitle>}
            </ModalHeader>
            <ModalBody>
                <div className="d-flex mb-4">
                    <TextInput
                        name={'exeUserName'}
                        placeholder={t('Please enter the name or ID of the doctor/nurse.')}
                        handleChange={handleInputChange}
                        size={55}
                    />
                    <button className={`pnt-btn btn-icon btn-blue btn-icon-only ml-2`} onClick={onHandleSearch}>
                        <span className="icon-search" />
                    </button>
                </div>
                <div style={{ height: '300px' }}>
                    <Table
                        paging={false}
                        columns={columns}
                        onTrClick={handleTrClick}
                        data={nurseInfoList}
                        noDataPlaceholder={t('Please search for a doctor/nurse')}
                        rowSelect={{
                            selected: nurseInfo,
                            rowKey: 'userNum',
                            selectedRowClassName: 'selected-color',
                        }}
                    />
                </div>
            </ModalBody>
            <StyledFooter>
                <button className="pnt-btn  color-orange btn-back mr-1 " onClick={onModalClickYes}>
                    {/*{t('Button;Close')}*/}
                    {t('Close')}
                </button>
                <button
                    className={`pnt-btn   border w-30 ${btnClassName}`}
                    onClick={() => {
                        handleClick();
                        onModalClickYes();
                    }}
                >
                    <span />
                    {/*{t('Button;Select')}*/}
                    {t('Confirm')}
                </button>
            </StyledFooter>
        </Modal>
    );
};

export default CustomSearchModal;
