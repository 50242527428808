import React, { useEffect } from 'react';
import SearchableSelect from '../../../../Common/Select/SearchableSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBfRiskInfoList,
    setBsRiskInfoList,
    setFilter,
    updateSearchFilter,
} from '../../../../../reducers/Common/SearchFilter';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import useAsync from '../../../../../util/hooks/useAsync';
import FilterOptionBox from './FilterOptionBox';
import useTranslation from '../../../../../util/hooks/useTranslation';

const Risk = ({ bsRisk = true, bfRisk = true }) => {
    const t = useTranslation('Filter');
    const storeDispatch = useDispatch();
    const { profile } = useSelector(state => state.UserInfo);
    const { bsRiskInfoList, bfRiskInfoList } = useSelector(state => state.SearchFilter);
    const { riskGradeCodes } = useSelector(state => state.SearchFilter.filter);
    const { promise: getBsRiskInfoList } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBsRiskInfoList(res));
        },
    });
    const { promise: getBfRiskInfoList } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBfRiskInfoList(res));
        },
    });
    useEffect(() => {
        if (profile && profile.metaData.drst === 'nr') {
            if (bfRisk) {
                getBfRiskInfoList({ clCode: 'BF_RISK', codeDepth: '3' });
            }
            if (bsRisk) {
                getBsRiskInfoList({ clCode: 'BS_RISK', codeDepth: '3' });
            }
        }
        if (profile && profile.metaData.drst === 'ts') {
            getBsRiskInfoList({ clCode: 'BS_RISK', codeDepth: '3' });
        }
    }, [profile]);
    const handleChange = selected => {
        let arrLabel = [];
        let arrValue = [];
        if (selected.length > 0) {
            arrLabel = selected.map(v => v.label);
            arrValue = selected.map(v => v.value);
            storeDispatch(setFilter({ riskGradeCodesName: arrLabel, riskGradeCodes: arrValue }));
            storeDispatch(updateSearchFilter({ riskGradeCodesName: arrLabel, riskGradeCodes: arrValue }));
        } else {
            storeDispatch(setFilter({ riskGradeCodesName: null, riskGradeCodes: null }));
            storeDispatch(updateSearchFilter({ riskGradeCodesName: null, riskGradeCodes: null }));
        }
    };
    const handleOption = (bsOptions, bfOptions) => {
        if (bfRisk && bsRisk) {
            let options = [];
            options = [...bsOptions, ...bfOptions];
            options = options.map(ele => {
                return {
                    ...ele,
                    label: t(ele.label, 'ActTypeCode'),
                };
            });
            return options;
        } else if (bfRisk) {
            return bfOptions;
        } else if (bsRisk) {
            return bsOptions;
        }
    };
    return (
        <FilterOptionBox>
            <SearchableSelect
                title={t('Risk')}
                selected={riskGradeCodes}
                data={handleOption(bsRiskInfoList.rows, bfRiskInfoList.rows)}
                onChange={handleChange}
            />
        </FilterOptionBox>
    );
};

export default Risk;
