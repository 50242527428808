import React, { useEffect, useState } from 'react';
import cx from 'classnames';

const RadioButton = ({ name = '', radioList = [], onChange, value = radioList[0].value, type = 'full' }) => {
    const [radioChecked, setRadioChecked] = useState();

    useEffect(() => {
        setRadioChecked(value);
    }, [value]);

    const handleRadioChange = e => {
        setRadioChecked(e.target.value);
        onChange(e.target.value);
    };

    return (
        <ul className={cx(type === 'full' ? 'pnt-radio-full' : 'pnt-radio-short')}>
            {radioList.map(({ label, value }) => {
                return (
                    <li key={value} className={cx(radioChecked === value && 'checked')}>
                        <input
                            type={'radio'}
                            id={value}
                            value={value}
                            checked={radioChecked === value}
                            name={name}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor={value}>{label}</label>
                    </li>
                );
            })}
        </ul>
    );
};

export default RadioButton;
