import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socketio from 'socket.io-client';

import { getApiURL } from '../../../api';
import { getUuid } from '../../common/util';
import { setSocket, setEventHandler } from '../../../reducers/SocketInfo';

const useSocket = ({ connectCallback, disconnectCallback } = {}) => {
    const dispatch = useDispatch();
    const { socket } = useSelector(state => state.SocketInfo);

    const setSocketEvent = useCallback(
        (messageType, callback) => {
            dispatch(setEventHandler({ messageType, callback }));
        },
        [dispatch],
    );

    const closeSocket = useCallback(
        socket => {
            if (socket) {
                socket.close();
                dispatch(setSocket(null));
            }
        },
        [dispatch],
    );

    useEffect(() => {
        if (!socket) {
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    if (typeof connectCallback === 'function') {
                        connectCallback(ws);
                    }
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    if (typeof disconnectCallback === 'function') {
                        disconnectCallback(ws);
                    }
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    if (typeof connectCallback === 'function') {
                        connectCallback(ws);
                    }
                });
                dispatch(setSocket(ws));
            });
        }
    }, []);

    return { socket, setSocketEvent, closeSocket };
};

export default useSocket;
