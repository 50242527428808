import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_HISTORY_PATIENT_LIST = 'historyByPatient/SET_HISTORY_PATIENT_LIST';
const SET_HISTORY_PATIENT_SUB_ROWS = 'historyByPatient/SET_HISTORY_PATIENT_SUB_ROWS';
const SET_EXPANDED = 'historyByPatient/SET_EXPANDED';
export const { setHistoryPatientList, setHistoryPatientSubRows, setExpanded } = createActions(
    {
        SET_HISTORY_PATIENT_LIST: historyPatientList => historyPatientList,
        SET_HISTORY_PATIENT_SUB_ROWS: historyPatientSubRows => historyPatientSubRows,
        SET_EXPANDED: expanded => expanded,
    },
    {
        prefix: 'historyByPatient',
    },
);

export const initialState = {
    historyPatientList: { rows: [] },
    expanded: {},
};

const historyByPatientReducer = handleActions(
    {
        [SET_HISTORY_PATIENT_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.historyPatientList = data;
            });
        },
        [SET_HISTORY_PATIENT_SUB_ROWS]: (state, action) => {
            return produce(state, draft => {
                const { targetNum, rows } = action.payload;
                draft.historyPatientList.rows = draft.historyPatientList.rows.map(row => {
                    if (row.targetNum === targetNum) {
                        row.list = rows;
                        row.isExpanded = true;
                    }
                    return row;
                });
            });
        },
        [SET_EXPANDED]: (state, action) => {
            return produce(state, draft => {
                const targetNum = action.payload;
                if (targetNum) {
                    draft.expanded[targetNum] = !draft.expanded[targetNum];
                } else {
                    draft.expanded = {};
                }
            });
        },
    },
    initialState,
);
export default historyByPatientReducer;
