export const CustomNav = [
    {
        id: 'main',
        icon: 'pe-7s-settings',
        label: 'Home',
        content: [
            {
                label: 'Activity Dashboards',
                to: '#/home',
            },
            {
                label: 'Fall Prevention Status',
                to: '#/home/fall-prevention-status',
            },
        ],
    },
    {
        id: 'activitySchedule',
        icon: 'pe-7s-settings',
        label: 'Active Schedule',
        content: [
            {
                label: 'Activity Schedule',
                to: '#/activity-schedule',
            },
            {
                label: 'Patient Schedule',
                to: '#/activity-schedule/schedule-by-patient',
            },
        ],
    },
    {
        id: 'activityHistory',
        icon: 'pe-7s-settings',
        label: 'Active History',
        content: [
            {
                label: 'Activity History',
                to: '#/activity-history',
            },
            {
                label: 'Patient History',
                to: '#/activity-history/history-by-patient',
            },
            {
                label: 'Skin condition record management',
                to: '#/activity-history/skin-condition-record-management',
            },
        ],
    },
];

export const SettingsNav = [
    {
        id: 'active',
        icon: 'pe-7s-settings',
        label: 'Activity Setting',
        content: [
            {
                label: 'Set the execution cycle for each activity',
                to: '#/active/term-setting',
            },
            {
                label: 'Set the execution cycle for each ward',
                to: '#/active/ward-setting',
            },
        ],
    },
    {
        id: 'group',
        icon: 'pe-7s-settings',
        label: 'Group Setting',
        content: [
            {
                label: 'Patient Group Setting',
                to: '#/patient-group/setting',
            },
        ],
    },
    // {
    //     id: 'code',
    //     icon: 'pe-7s-settings',
    //     label: 'Common Code Management',
    //     content: [
    //         {
    //             label: 'Common Code List',
    //             to: '#/common-code',
    //         },
    //     ],
    // },
];
