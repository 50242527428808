import React from 'react';
import moment from 'moment';
import { getRiskCircleNamePlate, RiskCircleNamePlate } from '../riskNamePlate';
import { dateChange, MinToDhm, minToDhm } from '../dateChange';
import { onHandleTextChange } from '../TextChange';
import useTranslation from '../../../../util/hooks/useTranslation';

export const ward = () => {
    return {
        Header: 'Room/Bed',
        headerClassName: 'text-ellipsis',
        id: 'wardNo',
        accessor: 'wardNo',
        width: 100,
        Cell: ({ value, idx, row }) => {
            const text = onHandleTextChange([value, row.original.roomNo]);
            return (
                <span key={idx} title={text}>
                    {text}
                </span>
            );
        },
    };
};
export const modalWard = () => {
    return {
        Header: 'Ward Room',
        id: 'wardNo',
        accessor: 'wardNo',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const patientName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient name',
        headerClassName: 'text-ellipsis',
        id: 'targetName',
        accessor: 'targetName',
        width: 150,
        className: 'text-ellipsis',
        Cell: ({ value, idx, row }) => {
            const { patid } = row.original;
            const textChange = (no, name) => (no && `[${no}]${name}`) || name;
            return (
                <span key={idx} title={textChange(patid, value)}>
                    {textChange(patid, value)}
                </span>
            );
        },
        ...restProps,
    };
};

export const department = () => {
    return {
        Header: 'Medical specialty',
        headerClassName: 'text-ellipsis',
        id: 'medDept',
        accessor: 'medDept',
        width: 120,
        className: 'text-ellipsis',
        Cell: ({ value, i }) => {
            const text = onHandleTextChange([value]);
            return (
                <span key={i} title={text}>
                    {text}
                </span>
            );
        },
    };
};
export const activityExpected = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Activity classification',
        headerClassName: 'text-ellipsis',
        id: 'actTypeCodeName',
        accessor: 'actTypeCodeName',
        className: 'text-ellipsis',
        width: 100,
        Cell: ({ value, idx }) => {
            return <span key={idx}>{t(value)}</span>;
        },
    };
};
export const decubitusDanger = () => {
    return {
        Header: 'Risk classification',
        headerClassName: 'text-ellipsis',
        title: 'Risk classification',
        id: 'riskGradeCodeName',
        width: 80,
        className: 'text-ellipsis',
        accessor: 'riskGradeCodeName',
        Cell: ({ row }) => {
            const { riskGradeCode } = row.original;
            return <RiskCircleNamePlate riskCode={riskGradeCode} />;
        },
    };
};
export const activityName = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Activity name',
        headerClassName: 'text-ellipsis',
        id: 'actItemCodeName',
        accessor: 'actItemCodeName',
        width: 120,
        className: 'text-ellipsis',
        Cell: ({ value, idx }) => {
            return (
                <span title={value} key={idx}>
                    {t(value)}
                </span>
            );
        },
    };
};
export const classification = () => {
    return {
        Header: 'Classification',
        headerClassName: 'text-ellipsis',
        id: 'bsReportDivisionCodeName',
        accessor: 'bsReportDivisionCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const classificationOfPerformance = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Performance classification',
        headerClassName: 'text-ellipsis',
        id: 'exeStatusCodeName',
        accessor: 'exeStatusCodeName',
        width: 80,

        Cell: ({ idx, row }) => {
            const { rdate } = row.original;
            return (
                (rdate.includes('-') && (
                    <span key={idx} title={'Delay'} style={{ color: 'red' }}>
                        {t('Delayed')}
                    </span>
                )) || (
                    <span key={idx} title={'Scheduled'} style={{ color: 'blue' }}>
                        {t('Scheduled')}
                    </span>
                )
            );
        },
    };
};
export const performanceNurse = () => {
    return {
        Header: 'Nurse who perform',
        headerClassName: 'text-ellipsis',
        id: 'exeUserName',
        accessor: 'exeUserName',
        width: 120,
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const performanceDate = () => {
    return {
        Header: 'When performed',
        headerClassName: 'text-ellipsis',
        id: 'exeDate',
        accessor: 'exeDate',
        width: 120,
        Cell: ({ value, idx }) => {
            const dateResult = dateChange(value);
            return (
                <span key={idx} title={dateResult}>
                    {dateResult}
                </span>
            );
        },
    };
};
export const classificationOfActivities = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Activity classification',
        id: 'actTypeCodeName',
        accessor: 'actTypeCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {t(value)}
                </span>
            );
        },
    };
};
export const timeRemaining = () => {
    const t = useTranslation('Date Picker');
    return {
        Header: 'Time remaining',
        headerClassName: 'text-ellipsis',
        id: 'rdate',
        accessor: 'rdate',
        Cell: ({ value, row, idx }) => {
            // const dateResult = minToDhm(value);
            const operator = value.includes('-') ? '-' : null;
            return <MinToDhm value={value} />;
        },
    };
};
export const expectedDate = () => {
    return {
        Header: 'Upcoming Date',
        id: 'sdate',
        accessor: 'sdate',
        Cell: ({ value, idx }) => {
            const dateResult = moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');
            return (
                <span title={dateResult} key={idx}>
                    {dateResult}
                </span>
            );
        },
    };
};

export const activity = ({ history, ...restProps }) => {
    return {
        Header: 'Activity',
        width: 170,
        accessor: 'actItemCode',
        ...restProps,
    };
};
export const detailActivity = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Detail Activity',
        id: 'actItemCodeName',
        className: 'text-ellipsis',
        accessor: 'actItemCodeName',

        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {t(value)}
                </span>
            );
        },
    };
};
export const condition = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Condition',
        id: 'exeTypeCodeName',
        accessor: 'exeTypeCodeName',
        Cell: ({ value }) => {
            return value && <span title={value}>{t(value)}</span>;
        },
    };
};
export const detailCondition = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Detail condition',
        id: 'exeTypeDetailCodeName',
        accessor: 'exeTypeDetailCodeName',
        Cell: ({ value }) => {
            return value && <span title={value}>{t(value)}</span>;
        },
    };
};
export const lastDecubitusDivision = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Final pressure ulcer risk classification',
        headerClassName: 'text-ellipsis',
        id: 'riskGradeCodeName',
        accessor: 'riskGradeCodeName',
        className: 'text-ellipsis',
        title: 'Final pressure ulcer risk classification',
        Cell: ({ row, idx }) => {
            const { riskGradeCodesName } = row.original;
            const riskArray = riskGradeCodesName
                .split(',')
                .map(ele => t(ele))
                .join(',');
            return (
                <span key={idx} title={riskGradeCodesName}>
                    {(riskGradeCodesName && riskArray) || 'N/A'}
                </span>
            );
        },
    };
};
export const executionCycle = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Performance cycle',
        id: 'exeCycleTypeCodeName',
        accessor: 'exeCycleTypeCodeName',
        Cell: ({ row, idx }) => {
            const { exeCycleVal, exeCycleTypeCodeName, exeCycleCodeName } = row.original;

            return (
                <span key={idx} title={{ exeCycleTypeCodeName } | { exeCycleVal } | { exeCycleCodeName }}>
                    {t(exeCycleTypeCodeName)} | {exeCycleVal} | {t(exeCycleCodeName)}
                </span>
            );
        },
    };
};
export const siteOfOccurrence = () => {
    return {
        Header: 'Occurrences',
        id: 'bsHappPartCodeName',
        accessor: 'bsHappPartCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const division = () => {
    return {
        Header: 'Division',
        id: 'bsReportDivisionCodeName',
        accessor: 'bsReportDivisionCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const progress = () => {
    return {
        Header: 'Progress',
        id: 'bsStatusCodeName',
        accessor: 'bsStatusCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const orderDateAndTime = () => {
    return {
        Header: 'Request Date',
        id: 'reqDate',
        accessor: 'reqDate',
        Cell: ({ value }) => {
            const dateResult = dateChange(value);
            return <span title={dateResult}>{dateResult}</span>;
        },
    };
};
export const action = ({ psNum, history, exeDate }) => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Action',
        id: 'action',
        accessor: 'action',
        Cell: ({ row }) => {
            const { skinNum } = row.original;
            return (
                <>
                    {!row.original.exeDate ? (
                        <button
                            key={psNum}
                            className="pnt-txt txt-border txt-btn-org"
                            type={'button'}
                            disabled={exeDate}
                            onClick={e => {
                                history.push(`/activity-schedule/carrying-out-activities/${psNum}/${skinNum}/add`);
                            }}
                        >
                            {t('Perform')}
                        </button>
                    ) : (
                        <button
                            key={psNum}
                            className="pnt-txt txt-border txt-btn-org"
                            type={'button'}
                            onClick={e => {
                                history.push(`/activity-schedule/carrying-out-activities/${psNum}/${skinNum}/read`);
                            }}
                        >
                            <span className={'icon-check'}></span>
                        </button>
                    )}
                </>
            );
        },
    };
};
export const dateOfOccurrence = () => {
    return {
        Header: 'Date of Occurrence',
        id: 'happDate',
        accessor: 'happDate',
        Cell: ({ value, idx }) => {
            const dateResult = dateChange(value);
            return value ? (
                <span key={idx} title={dateResult}>
                    {dateResult}
                </span>
            ) : null;
        },
    };
};

export const recordDate = () => {
    return {
        Header: '기록일시',
        id: 'exeDate',
        accessor: 'exeDate',
        Cell: ({ value, idx }) => {
            const dateResult = dateChange(value);
            return (
                <span key={idx} title={dateResult}>
                    {dateResult}
                </span>
            );
        },
    };
};
export const aiJudgment = () => {
    return {
        Header: 'ai판단',
        id: 'aiGrade',
        accessor: 'aiGrade',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const expertJudgment = () => {
    return {
        Header: '전문가판단',
        id: 'bsReportGradeCodeName',
        accessor: 'bsReportGradeCodeName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const expander = () => {
    return {
        Header: () => null,
        id: 'expander',
        Cell: ({ row, idx }) => (
            <span key={idx} {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? '👇' : '👉'}
            </span>
        ),
        SubCell: () => null,
    };
};

export const patientGroupName = ({ ...restProps }) => {
    return {
        Header: '그룹명',
        accessor: 'patientGroupName',
        className: 'text-ellipsis',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
    };
};
export const patientGroupId = ({ ...restProps }) => {
    return {
        Header: '그룹아이디',
        accessor: 'patientGroupId',
        className: 'text-ellipsis',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
    };
};
export const wardName = ({ ...restProps }) => {
    return {
        Header: 'Ward',
        accessor: 'wardNo',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
    };
};
export const wardSettingPatientGroupName = ({ ...restProps }) => {
    return {
        Header: 'Ward',
        accessor: 'patientGroupName',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
    };
};
export const useOrNot = ({ ...restProps }) => {
    return {
        Header: '사용유무',
        accessor: 'useFlag',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
        // Cell: value => {
        //     <span></span>;
        // },
    };
};
export const wardTeam = ({ ...restProps }) => {
    return {
        Header: '병동팀',
        accessor: 'teamCd',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
        ...restProps,
    };
};
export const wardRoom = () => {
    return {
        Header: 'Ward Room',
        accessor: 'roomNo',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const wardRoom2 = () => {
    return {
        Header: 'Bed',
        accessor: 'bedNo',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};

export const staffName = () => {
    return {
        Header: 'Name',
        accessor: 'userName',
        className: 'text-ellipsis',
        Cell: ({ value, row }) => {
            const id = row.original.userID;
            const textResult = `${value}/${id}`;
            return <span title={textResult}>{textResult}</span>;
        },
    };
};
// export const patientGroupName = () => {
//     return {
//         Header: '그룹명',
//         accessor: 'patientGroupName',
//     };
// };

////////헤더 그룹 컬럼
export const targetId = () => {
    return {
        Header: '환자 ID',
        accessor: 'targetId',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const whetherYouWanderAlone = () => {
    return {
        Header: 'Solo Wandering',
        accessor: 'whetherYouWanderAlone',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const status = () => {
    return {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const lastPosition = () => {
    return {
        Header: 'Final Position',
        accessor: 'lastPosition',
        Cell: ({ value, i }) => {
            return (
                <span key={i} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const locationRecognitionDateAndTime = () => {
    return {
        Header: 'Date of location recognition',
        headerClassName: 'text-ellipsis',
        accessor: 'lastDate',
        Cell: ({ value, i }) => {
            const dateResult = dateChange(value);
            return (
                <span key={i} title={dateResult}>
                    {dateResult}
                </span>
            );
        },
    };
};
export const headerGroupWard = () => {
    return {
        Header: 'Room/Bed',
        width: 100,
        columns: [
            {
                Header: '',
                id: 'wardNo',
                width: 100,
                accessor: (d, idx) => {
                    if (!d.actItemCode) {
                        return (
                            <span key={idx} title={onHandleTextChange([d.wardNo, d.roomNo])}>
                                {onHandleTextChange([d.wardNo, d.roomNo])}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};

export const headerGroupPatientName = ({ path, history } = {}) => {
    const onClick = value => {
        path && history.push(path + '/' + value);
    };

    return {
        Header: 'Patient name',
        width: 150,
        columns: [
            {
                Header: () => null,
                id: 'targetName',
                className: 'text-ellipsis',
                width: 150,
                accessor: (d, idx) => {
                    const { targetNum, targetId, targetName, actItemCode } = d;
                    if (!actItemCode) {
                        return (
                            <span
                                key={idx}
                                style={{ cursor: 'pointer' }}
                                onClick={() => onClick(targetNum)}
                                title={targetName}
                                className={'text-ellipsis'}
                            >
                                [{targetId}]{targetName}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};

export const headerGroupDepartment = () => {
    return {
        Header: 'Medical specialty',
        headerClassName: 'text-ellipsis',
        id: 'medDept',
        width: 80,
        columns: [
            {
                Header: '',
                id: 'medDept',
                width: 80,
                accessor: (d, idx) => {
                    if (!d.actItemCode) {
                        return (
                            <span key={idx} title={onHandleTextChange([d.medDept])}>
                                {onHandleTextChange([d.medDept])}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};
export const headerGroupActivityExpected = () => {
    const t = useTranslation('ActTypeCode');
    return {
        Header: 'Activity classification',
        id: 'actTypeCodeName',
        accessor: 'actTypeCodeName',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {t(value)}
                </span>
            );
        },
    };
};
export const headerGroupDecubitusDanger = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Risk classification',
        columns: [
            {
                Header: t('Activity classification'),
                id: 'actTypeCodeName',
                accessor: (d, idx) => {
                    if (d.actTypeCodeName) {
                        return (
                            <span key={idx} title={t(d.actTypeCodeName, 'ActTypeCode')}>
                                {t(d.actTypeCodeName, 'ActTypeCode')}
                            </span>
                        );
                    } else {
                        if (!d.bfRisk && !d.bsRisk) {
                            return '-';
                        } else {
                            return (
                                <>
                                    <span key={idx} title={d.bfRisk}>
                                        {t(d.bfRisk, 'ActTypeCode')}
                                    </span>
                                    <span key={idx} title={d.bsRisk}>
                                        {t(d.bsRisk, 'ActTypeCode')}
                                    </span>
                                </>
                            );
                        }
                    }
                },
            },
            {
                Header: t('Activity name'),
                headerClassName: 'text-ellipsis',
                id: 'actItemCodeName',
                className: 'text-ellipsis',
                accessor: (d, idx) => {
                    if (d.actItemCodeName) {
                        return (
                            <span key={idx} title={d.activityName}>
                                {t(d.actItemCodeName, 'ActTypeCode')}
                            </span>
                        );
                    } else {
                        return '-';
                    }
                },
            },
        ],
    };
};
export const headerGroupActivityName = () => {
    return {
        Header: '활동명',
        id: 'actItemCodeName',
        className: 'text-ellipsis',
        accessor: 'actItemCodeName',
        Cell: ({ value, row, idx }) => {
            return (
                <span key={idx} title={`${value}-${row.original.actTypeCodeName}`}>
                    {value}-{row.original.actTypeCodeName}
                </span>
            );
        },
    };
};

export const headerGroupClassificationOfPerformance = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Scheduled Activities',
        headerClassName: 'text-ellipsis',
        id: 'readyCount',
        accessor: 'readyCount',
        columns: [
            {
                Header: t('Performance divisions'),
                headerClassName: 'text-ellipsis',
                id: 'exeStatusCodeName',
                width: 80,
                accessor: (d, idx) => {
                    if (d.actItemCodeName) {
                        const bolResult = d.rdate.includes('-');
                        return (
                            <span
                                key={idx}
                                style={{ color: bolResult ? 'red' : 'blue' }}
                                title={bolResult ? t('Delay') : t('Scheduled')}
                            >
                                {bolResult ? t('Delay') : t('Scheduled')}
                            </span>
                        );
                    } else {
                        return (
                            <span key={idx} title={d.readyCount + t('case')}>
                                {d.readyCount}
                                {t('case')}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};

export const headerGroupClassificationOfActivities = () => {
    return {
        Header: '활동구분',
        id: 'classificationOfActivities',
        accessor: 'classificationOfActivities',
    };
};
export const headerGroupTimeRemaining = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Today',
        columns: [
            {
                Header: t('Time remaining'),
                id: 'rdate',
                accessor: (d, idx) => {
                    if (!d.actItemCode) {
                        return (
                            <span key={idx} title={d.todayCount + t('case')}>
                                {d.todayCount}
                                {t('case')}
                            </span>
                        );
                    } else {
                        const dateResult = minToDhm(d.rdate);
                        const bolResult = d.rdate.includes('-');
                        return <MinToDhm value={d.rdate} />;
                    }
                },
            },
        ],
    };
};
export const headerGroupExpectedDate = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: '1 hour or less',
        id: 'hourCount',
        accessor: 'hourCount',
        Cell: ({ value }) => {
            return moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');
        },
        columns: [
            {
                Header: t('Upcoming Date'),
                id: 'exeDate',
                accessor: (d, idx) => {
                    if (!d.actItemCode) {
                        return (
                            <span key={idx} title={d.hourCount + t('case')}>
                                {d.hourCount}
                                {t('case')}
                            </span>
                        );
                    } else {
                        const dateResult = dateChange(d.sdate);
                        return (
                            <span key={idx} title={dateResult}>
                                {dateResult}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};

export const headerGroupActivity = ({ history, test, dispatch, ...restProps }) => {
    return {
        Header: '',
        id: 'activity',
        ...restProps,
    };
};

export const headerGroupDelay = ({ columns }) => {
    return {
        Header: 'Delayed',
        id: 'delayTime',
        accessor: 'delayTime',
        columns: columns,
    };
};
export const headerGroupExpander = ({ columns }) => {
    return {
        Header: ' ',
        columns: columns,
    };
};

/// 환자별 이력
export const headerGroupActivityDate = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Date Performed',
        columns: [
            {
                Header: t('Activity classification'),
                id: 'actTypeCodeName',
                accessor: (d, idx) => {
                    if (d.actTypeCodeName) {
                        return (
                            <span key={idx} title={onHandleTextChange([d.actTypeCodeName])} className={'text-ellipsis'}>
                                {t(onHandleTextChange([d.actTypeCodeName]), 'ActTypeCode')}
                            </span>
                        );
                    } else {
                        return '-';
                    }
                },
            },
        ],
    };
};

export const headerGroupDanger = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Risk Name',
        columns: [
            {
                Header: t('Activity name'),
                id: 'actItemCodeName',
                headerClassName: 'text-ellipsis',
                accessor: 'actItemCodeName',
                className: 'text-ellipsis',
                Cell: ({ value, idx }) => {
                    if (typeof value === 'object') {
                        if (!value) {
                            return '-';
                        } else {
                            const riskResult = getRiskCircleNamePlate(value[0] && value[0]);
                            const riskResultSecond = getRiskCircleNamePlate(value[1] && value[1]);
                            return (
                                <div key={idx} title={riskResult + ',' + riskResultSecond} className={'d-flex'}>
                                    {riskResult}
                                    {riskResultSecond === '-' && null}
                                </div>
                            );
                        }
                    } else if (typeof value === 'string') {
                        return (
                            <span key={idx} title={value} className={'text-ellipsis'}>
                                {t(value, 'ActTypeCode')}
                            </span>
                        );
                    }
                },
            },
        ],
    };
};

export const headerDangerEvaluation = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Risk Assessment',
        columns: [
            {
                Header: '',
                id: 'ratingCount',
                accessor: 'ratingCount',
                Cell: ({ value, idx }) => {
                    let count = 0;
                    count = value > -1 ? `${value}${t('times')}` : '';

                    return (
                        <span key={idx} title={count}>
                            {count}
                        </span>
                    );
                },
            },
        ],
    };
};

export const headerEducation = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Education',
        columns: [
            {
                Header: '',
                id: 'eduCount',
                accessor: 'eduCount',
                Cell: ({ value, idx }) => {
                    let count;
                    count = value > -1 ? `${value}${t('times')}` : '';
                    return (
                        <span key={idx} title={count}>
                            {count}
                        </span>
                    );
                },
            },
        ],
    };
};
export const headerGroupDirectInterventionAndNurse = () => {
    const t = useTranslation('Common Columns');
    return {
        Header: 'Direct moderation',
        columns: [
            {
                Header: t('Nurse in charge'),
                id: 'exeUserName',
                accessor: 'exeUserName',
                Cell: ({ value, idx }) => {
                    let valueForTableRow;

                    if (typeof value === 'number') {
                        valueForTableRow = `${value}${t('times')}`;
                    } else if (typeof value === 'string') {
                        valueForTableRow = value;
                    } else {
                        valueForTableRow = '';
                    }
                    return (
                        <span key={idx} title={valueForTableRow}>
                            {valueForTableRow}
                        </span>
                    );
                },
            },
        ],
    };
};
export const headerGroupTreatmentAndPerformanceDateAndTime = ({ columns }) => {
    return {
        Header: 'Treatment',
        columns: columns,
    };
};

export const codeNum = () => {
    return {
        Header: 'Sequence',
        accessor: 'codeNum',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const clCode = () => {
    return {
        Header: 'Classification code',
        accessor: 'clCode',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const clCodeName = () => {
    return {
        Header: 'Classification name',
        accessor: 'clCodeName',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};

export const code = () => {
    return {
        Header: 'code',
        accessor: 'code',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const codeName = () => {
    return {
        Header: 'code name',
        accessor: 'codeName',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
export const orderNum = () => {
    return {
        Header: 'Sorting',
        accessor: 'orderNum',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};

export const useFlag = () => {
    return {
        Header: 'Enabled or not',
        accessor: 'useFlag',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};

export const siteOfOccurrenceSelect = () => {
    return {
        Header: 'Occurrences',
        accessor: 'occurrencePart',
        Cell: ({ value, idx }) => {
            return (
                <span key={idx} title={value}>
                    {value}
                </span>
            );
        },
    };
};
