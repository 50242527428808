import { createSlice } from '@reduxjs/toolkit';
import { dateChange } from '../../Components/MainPages/util/dateChange';
import { toCamelCase } from '../../Components/MainPages/util/regularExpression';

export const initialState = {
    activityOption: { rows: [] },
    activitySecondOption: {
        row: [{ label: '   ', value: 0 }],
    },
    activityThirdOption: {
        row: [],
    },
    bsEducationInfo: {},
    antecedentRequestOption: {
        rows: [{ label: '없음-선행의뢰', value: 0 }],
    },
    bsSelectOption: {
        bsSense: [],
        bsHumidity: [],
        bsActivity: [],
        bsMobility: [],
        bsNutrition: [],
        bsFrictionstress: [],
    },
    bsWholeBodySkinOption: {
        bsReportGrade: [],
        bsReportDivision: [],
        bsHappPlace: [],
        bsDevice: [],
        bsHappPart: [],
    },
    bsPositionOption: { before: [], after: [] },
    bfHighRiskOption: { rows: [] },
    bfEmergencyOption: {
        bfRatingItemFallexp2: [],
        bfRatingItemConfusion: [],
        bfRatingItemAlcohol: [],
        bfRatingItemWalking2: [],
        bfRatingItemWalkingeqpt: [],
        bfRatingItemExcretion: [],
    },
    bfEducationInfo: { rows: [] },
    bfPaEducationInfo: { rows: [] },
    bfChildOption: {
        bfRatingItemAge: [],
        bfRatingItemActive: [],
        bfRatingItemBehavior: [],
        bfRatingItemHealth: [],
        bfRatingItemDiagnosis: [],
        bfRatingItemFacility: [],
        bfRatingItemRearer: [],
    },
    bfPersonOption: {
        bfRatingItemFallexp: [],
        bfRatingItemSeconddiagnosis: [],
        bfRatingItemWalking: [],
        bfRatingItemIv: [],
        bfRatingItemStep: [],
        bfRatingItemCognition: [],
    },
    bfUrinationRoundOption: {
        rows: [],
    },
    executionCycleOption: {
        single: [],
        repeat: [],
    },
    zoneOption: { rows: [] },
    bsRecordDressing: { rows: [] },
    selectSkinPart: { rows: [] },
    executionCycleTypeOption: {
        rows: [],
    },
};

const activeScheduleReducer = createSlice({
    name: 'activeSchedule',
    initialState,
    reducers: {
        setBsEducationInfo: (state, action) => {
            let educationInfo = [];
            const data = action.payload;
            const codeName = action.payload.rows[0].clCode;
            educationInfo = data.rows.reduce((acc, curr, i) => {
                let name = `${toCamelCase(curr.clCode) + 'Code' + (i + 1)}`;
                acc.push({
                    label: curr.codeName,
                    name,
                    value: curr.code,
                    id: curr.code,
                });
                return acc;
            }, []);
            state.bsEducationInfo = { [codeName]: educationInfo };
        },
        setAntecedentRequestOption: (state, action) => {
            let antecedentRequestInfo = [];
            const data = action.payload.rows || [];
            if (data.length > 0) {
                antecedentRequestInfo = data.reduce((acc, curr) => {
                    acc.push({
                        label: `${curr.bsHappPartCodeName}-${dateChange(curr.happDate)}-${dateChange(curr.reqDate)}`,
                        recordNum: curr.recordNum,
                        value: curr.skinNum,
                    });
                    return acc;
                }, []);
                state.antecedentRequestOption.rows = antecedentRequestInfo;
            }
        },
        setActivityOption: (state, action) => {
            let option = [];
            const data = action.payload.res;
            const profile = action.payload.profile;
            if ((profile && profile.metaData.drst === 'nr') || !profile) {
                option = data.rows.reduce((acc, curr) => {
                    acc.push({
                        label: curr.codeName,
                        name: 'actTypeCode',
                        value: curr.code,
                        id: curr.code,
                    });
                    return acc;
                }, []);
            }
            if (profile && profile.metaData.drst === 'ts') {
                option = data.rows.reduce((acc, curr) => {
                    if (curr.code.includes('BS')) {
                        acc.push({
                            label: curr.codeName,
                            name: 'actTypeCode',
                            value: curr.code,
                            id: curr.code,
                        });
                    }
                    return acc;
                }, []);
            }
            state.activityOption.rows = option;
        },
        setActivitySecondOption: (state, action) => {
            let educationInfo = [];
            const data = action.payload;
            educationInfo = data.rows.reduce((acc, curr) => {
                acc.push({
                    label: curr.codeName,
                    name: curr.clCode,
                    value: curr.code,
                    id: curr.code,
                });
                return acc;
            }, []);
            state.activitySecondOption = {
                ...state.activitySecondOption,
                [educationInfo[0].name]: educationInfo,
            };
        },
        setActivityThirdOption: (state, action) => {
            let educationInfo = [];
            const data = action.payload;
            educationInfo = data.rows.reduce((acc, curr) => {
                acc.push({ label: curr.codeName, name: curr.clCode, value: curr.code, id: curr.code });
                return acc;
            }, []);

            state.activityThirdOption.row = educationInfo;
        },
        setBsSelectOption: (state, action) => {
            let optionData = [];
            const clCode = action.payload.rows[0].clCode;
            const bsSelectOption = state.bsSelectOption;
            const data = action.payload.rows;
            optionData = data.reduce((acc, curr) => {
                let name = null;
                name = `${toCamelCase(curr.clCode) + 'Code'}`;
                if (['BS_FRICTIONSTRESS'].includes(curr.clCode)) {
                    name = 'bsFrictionStressCode';
                }
                acc.push({
                    label: `${curr.code} - ${curr.codeName}`,
                    codeName: curr.codeName,
                    value: curr.code,
                    id: curr.clCode,
                    name,
                });
                return acc;
            }, []);
            bsSelectOption[toCamelCase(clCode)] = optionData;
        },
        setBsWholeBodySkinOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            const clCode = action.payload.rows[0].clCode;
            const bsSelectOption = state.bsWholeBodySkinOption;
            option = optionData.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    value: option.code,
                    name: option.clCode,
                });
                return acc;
            }, []);
            bsSelectOption[toCamelCase(clCode)] = option;
        },
        setBsPositionOption: (state, action) => {
            let optionBeforePositionData = [];
            let optionAfterPositionData = [];
            const data = action.payload;

            optionBeforePositionData = data.rows.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    value: option.code,
                    id: option.clCode,
                    name: 'bsPositionCode1',
                });
                return acc;
            }, []);
            optionAfterPositionData = data.rows.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    value: option.code,
                    id: option.clCode,
                    name: 'bsPositionCode2',
                });
                return acc;
            }, []);
            state.bsPositionOption.before = optionBeforePositionData;
            state.bsPositionOption.after = optionAfterPositionData;
        },
        setBfHighRisk: (state, action) => {
            let optionData = [];
            const optionCode = action.payload.optionCode;
            const data = action.payload.res;
            optionData = data.rows.reduce((acc, option) => {
                const result = optionCode.find(code => (code === option.code ? true : false));
                if (result) {
                    acc.push({
                        label: option.codeName,
                        value: option.code,
                        name: option.clCode,
                        codeName: 'bfHighRiskCode',
                    });
                }
                return acc;
            }, []);
            state.bfHighRiskOption.rows = optionData;
        },
        setBfEmergencyOption: (state, action) => {
            let optionData = [];
            const clCode = action.payload.rows[0].clCode;
            const bfEmergencyOption = state.bfEmergencyOption;

            const data = action.payload.rows;
            optionData = data.reduce((acc, curr) => {
                let name = null;
                const codeName = () =>
                    (curr.codeName.indexOf('Yes') && curr.codeName.replace('No', '없음')) ||
                    curr.codeName.replace('Yes', '있음');
                if (!['BF_RATING_ITEM_WALKINGEQPT', 'BF_RATING_ITEM_FALLEXP2'].includes(curr.clCode)) {
                    name = `${toCamelCase(curr.clCode) + 'Code'}`;
                } else {
                    name =
                        curr.clCode === 'BF_RATING_ITEM_WALKINGEQPT'
                            ? 'bfRatingItemWalkingEqptCode'
                            : 'bfRatingItemFallExp2Code';
                }

                acc.push({
                    label: `[${curr.mark}] ${codeName()}`,
                    id: curr.code,
                    value: curr.codeNum,
                    mark: curr.mark,
                    name: curr.clCode,
                    codeLabel: curr.codeName,
                    codeName: name,
                });
                return acc;
            }, []);

            bfEmergencyOption[toCamelCase(clCode)] = optionData;
        },

        setBfEducationInfo: (state, action) => {
            let educationInfo = [];
            const data = action.payload.res;
            const optionIndex = action.payload.optionIndex;
            educationInfo = data.rows.reduce((acc, curr, i) => {
                const result = optionIndex.find(code => code === i);
                if (result !== undefined) {
                    let name = toCamelCase(curr.clCode) + 'Code' + (i + 1);
                    acc.push({
                        label: curr.codeName,
                        name,
                        value: curr.code,
                        id: curr.code,
                        codeName: curr.clCode,
                    });
                }
                return acc;
            }, []);

            state.bfEducationInfo = educationInfo;
        },

        setBfPaEducationInfo: (state, action) => {
            let educationInfo = [];
            let optionIndex = [];
            const data = action.payload.res;
            const part = action.payload.part;
            const { age, status, wardNo, day } = part;

            if (age > 7) {
                if (status === '응급') {
                    optionIndex = [0, 1, 2, 3, 4, 9, 12, 13];
                } else {
                    //응급이 아니고 간호간 병동 소속이면 정기라운딩 생성
                    if (['063W', '071W', '072W', '132W'].includes(wardNo)) {
                        optionIndex = [0, 1, 2, 3, 4, 6, 7, 12, 13];
                    } else {
                        optionIndex = [0, 1, 2, 3, 4, 6, 12, 13];
                    }
                }
            } else {
                if (status === '응급') {
                    optionIndex = [0, 1, 2, 3, 4, 9, 10, 11];
                } else {
                    //응급이 아니고 간호간 병동 소속이면 정기라운딩 생성
                    if (['063W', '071W', '072W', '132W'].includes(wardNo)) {
                        if (day) {
                            optionIndex = [0, 1, 2, 3, 4, 6, 7, 8, 10, 11];
                        } else {
                            optionIndex = [0, 1, 2, 3, 4, 6, 7, 10, 11];
                        }
                    } else {
                        if (day) {
                            optionIndex = [0, 1, 2, 3, 4, 6, 8, 10, 11];
                        } else {
                            optionIndex = [0, 1, 2, 3, 4, 6, 10, 11];
                        }
                    }
                }
            }
            educationInfo = data.rows.reduce((acc, curr, i) => {
                const result = optionIndex.find(code => code === i);
                if (result !== undefined) {
                    const name = toCamelCase(curr.clCode) + 'Code' + (i + 1);
                    acc.push({
                        label: curr.codeName,
                        name,
                        value: curr.code,
                        id: curr.code,
                    });
                }
                return acc;
            }, []);
            state.bfPaEducationInfo = educationInfo;
        },
        setBfChildOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            const clCode = action.payload.rows[0].clCode;
            const bsSelectOption = state.bfChildOption;
            option = optionData.reduce((acc, option) => {
                let codeName = `${toCamelCase(option.clCode) + 'Code'}`;
                acc.push({
                    label: `[${option.mark}] ${option.codeName}`,
                    id: option.mark,
                    value: option.codeNum,
                    name: option.clCode,
                    codeName,
                    codeLabel: option.codeName,
                    code: option.code,
                });
                return acc;
            }, []);
            bsSelectOption[toCamelCase(clCode)] = option;
        },

        setBfPersonOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            const clCode = action.payload.rows[0].clCode;
            const bsSelectOption = state.bfPersonOption;
            option = optionData.reduce((acc, option) => {
                let codeName = null;
                if (!['BF_RATING_ITEM_FALLEXP', 'BF_RATING_ITEM_SECONDDIAGNOSIS'].includes(option.clCode)) {
                    codeName = `${toCamelCase(option.clCode) + 'Code'}`;
                } else {
                    codeName =
                        option.clCode === 'BF_RATING_ITEM_FALLEXP'
                            ? 'bfRatingItemFallExpCode'
                            : 'bfRatingItemSecondDiagnosisCode';
                }
                acc.push({
                    label: `[${option.mark}] ${option.codeName}`,
                    id: option.mark,
                    value: option.codeNum,
                    name: option.clCode,
                    codeName,
                    codeNum: option.code,
                    codeLabel: option.codeName,
                });
                return acc;
            }, []);
            bsSelectOption[toCamelCase(clCode)] = option;
        },
        setBfUrinationRoundOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            option = optionData.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    id: option.clCode,
                    value: option.code,
                    name: 'bfEduIv3Code',
                });
                return acc;
            }, []);
            state.bfUrinationRoundOption = option;
        },
        setExecutionCycleOption: (state, action) => {
            let repeatOption = [];
            const singleOption = [];
            const optionData = action.payload.rows;
            singleOption.push({
                label: optionData[0].codeName,
                id: optionData[0].code,
                value: optionData[0].codeNum,
                name: optionData[0].clCode,
                codeValue: optionData[0].code,
            });
            repeatOption = optionData.reduce((acc, option, i) => {
                if (i) {
                    acc.push({
                        label: option.codeName,
                        id: option.code,
                        value: option.codeNum,
                        name: option.clCode,
                        codeValue: option.code,
                    });
                }
                return acc;
            }, []);
            state.executionCycleOption.single = singleOption;
            state.executionCycleOption.repeat = repeatOption;
        },
        setExecutionCycleTypeOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            option = optionData.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    id: option.mark,
                    value: option.codeNum,
                    name: option.clCode,
                    codeName: option.code,
                });
                return acc;
            }, []);
            state.executionCycleTypeOption.rows = option;
        },
        setZoneOption: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            option = optionData.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    id: option.mark,
                    value: option.code,
                    name: option.clCode,
                });
                return acc;
            }, []);
            state.zoneOption.rows = option;
        },
        setBsRecordDressing: (state, action) => {
            let option = [];
            const optionData = action.payload.rows;
            option = optionData.reduce((acc, option) => {
                acc.push({
                    label: option.codeName,
                    id: option.orderNum,
                    value: option.code,
                    name: option.clCode,
                });
                return acc;
            }, []);
            state.bsRecordDressing.rows = option;
        },
        setSelectSkinPart: (state, action) => {
            let option = [];
            const optionData = action.payload;
            if (optionData) {
                option = optionData.reduce((acc, option) => {
                    acc.push({
                        occurrencePart: option.label,
                        value: option.value,
                        bsHappPartCode: option.value,
                    });
                    return acc;
                }, []);
                state.selectSkinPart.rows = option;
            } else {
                state.selectSkinPart = { rows: [] };
            }
        },
        updatePatientInfo: (state, action) => {
            state.patientInfo = action.payload;
        },
        reset: (state, action) => {
            return initialState;
        },
    },
});

export const {
    setActivityOption,
    setActivitySecondOption,
    setActivityThirdOption,
    setBsEducationInfo,
    setAntecedentRequestOption,
    setBsSelectOption,
    setBsWholeBodySkinOption,
    setBsPositionOption,
    setBfHighRisk,
    setBfEmergencyOption,
    setBfEducationInfo,
    setBfPaEducationInfo,
    setBfChildOption,
    setBfPersonOption,
    setBfUrinationRoundOption,
    setExecutionCycleOption,
    setExecutionCycleTypeOption,
    setZoneOption,
    setBsRecordDressing,
    setSelectSkinPart,
    updatePatientInfo,
    reset,
} = activeScheduleReducer.actions;
export default activeScheduleReducer.reducer;
