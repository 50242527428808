import React, { useMemo, useCallback, useEffect } from 'react';
import { useTable, useRowSelect, useBlockLayout, usePagination } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// import scrollbarWidth from './util/scrollbarWidth';
import styled from 'styled-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoadingBlock } from '../LoadingBlock';

const TableWrapper = styled.div`
    height: 100%;
    .pagination {
        display: block;
    }
    .table {
        display: inline-block;
        border-spacing: 0;
        .border-box {
            height: 100%;
            border-top: 2px solid black;
            border-bottom: 2px solid black;

            .tbody {
                .tr {
                    :nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    :hover {
                        background-color: #ebf0fa;
                    }
                }
                .tr.clickable {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
            .tr {
                :last-child {
                    .td {
                        border-bottom: 0;
                    }
                }
            }
            .th {
                font-weight: 700;
                background-color: #d8e0fe;
                text-align: ${props => props.textAlign};
            }
            .td {
                text-align: ${props => props.textAlign};
            }
            .th,
            .td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
`;

const NoDataWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

const defaultRowPropGetter = ({ style }) => ({ style });

function Table({
    columns,
    data: { rows: list, totalCount = 0, totalPage = 0, pageSize = 20, page: initPage = 1 },
    onPageChange,
    paging = true,
    textAlign = 'left',
    onTrClick,
    manualPagination = true,
    autoResetPage = true,
    manualRowSelectedKey = true,
    getRowProps = defaultRowPropGetter,
    loading,
    noDataPlaceholder = 'No Data',
    rowSelect = {},
}) {
    const defaultColumn = useMemo(
        () => ({
            width: 150,
        }),
        [],
    );

    // const scrollBarSize = useMemo(() => scrollbarWidth(), []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data: list,
            initialState: { pageIndex: initPage - 1, pageSize },
            defaultColumn,
            manualPagination: manualPagination,
            manualRowSelectedKey: manualRowSelectedKey,
            pageCount: totalPage,
            autoResetPage: autoResetPage,
        },

        useBlockLayout,
        // useSortBy,
        usePagination,
        useRowSelect,
    );

    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = page[index];

            prepareRow(row);
            let { className: rowClassName, ...restRowProps } = getRowProps({ row, style });
            const { selected, rowKey, selectedRowClassName } = rowSelect;
            if (selected && row.original[rowKey] === selected[rowKey]) {
                rowClassName += ` ${selectedRowClassName}`;
            }

            return (
                <div
                    {...row.getRowProps(restRowProps)}
                    className={cx('tr', typeof onTrClick === 'function' && 'clickable', rowClassName)}
                    onClick={e => {
                        if (typeof onTrClick === 'function') {
                            onTrClick(row.original, e);
                        }
                    }}
                >
                    {row.cells.map(cell => {
                        const { style: cellStyle, ...cellProps } = cell.getCellProps({
                            style: cell.column.style,
                        });

                        const className = cell.column.className;
                        return (
                            <div
                                {...cellProps}
                                className={['td', className].join(' ')}
                                style={{
                                    ...cellStyle,
                                    display: 'inline-block !important',
                                    flex: `${cellStyle.width.replace('px', '').replace('%', '')} 0 auto`,
                                }}
                                title={className && className.includes('text-ellipsis') ? cell.value : ''}
                            >
                                {cell.render('Cell')}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [prepareRow, page, rowSelect.selected],
    );

    useEffect(() => {
        if (typeof onPageChange === 'function') {
            // 처음 렌더링시 발생하는 호출을 방지하기 위해
            if (pageIndex) {
                onPageChange(pageIndex + 1);
            } else {
                onPageChange(pageIndex + 1);
            }
        }
    }, [pageIndex]);

    return (
        <LoadingBlock blocking={loading}>
            <TableWrapper textAlign={textAlign}>
                {paging && (
                    <div className="pagination">
                        <div className="pnt-table--paging__wrap">
                            <button className="icon-back-double" onClick={() => gotoPage(0)}>
                                first
                            </button>
                            <button className="icon-back" onClick={() => previousPage()}>
                                before-page
                            </button>
                            <span className="now">{`${pageIndex * pageSize + (page.length ? 1 : 0)}-${
                                totalCount < pageIndex * pageSize + page.length
                                    ? totalCount
                                    : pageIndex * pageSize + page.length
                            }`}</span>
                            <span className="whole">{`/ ${totalCount}`}</span>
                            <button className="icon-next" onClick={() => nextPage()}>
                                next-page
                            </button>
                            <button className="icon-next-double" onClick={() => gotoPage(totalPage - 1)}>
                                last
                            </button>
                        </div>
                    </div>
                )}
                <div
                    {...getTableProps()}
                    className="table"
                    style={{
                        height: paging ? 'calc(100% - 30px)' : '100%',
                        width: '100%',
                        color: '#585252',
                        fontSize: '.8rem',
                        // width: totalColumnsWidth + scrollBarSize + 2,
                    }}
                >
                    <div className={'border-box'} style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                        <div className={'thead'} style={{ overflow: 'visible' }}>
                            {headerGroups.map(headerGroup => {
                                const { style: thGroupStyle, ...thGroupProps } = headerGroup.getHeaderGroupProps();

                                return (
                                    <div {...thGroupProps} className="tr" style={{ ...thGroupStyle, width: '100%' }}>
                                        {headerGroup.headers.map(column => {
                                            const { style: thStyle, ...thProps } = column.getHeaderProps({
                                                style: column.headerStyle,
                                            });
                                            return (
                                                <div
                                                    //    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    {...thProps}
                                                    className={['th', column.headerClassName].join(' ')}
                                                    style={{
                                                        ...thStyle,
                                                        flex: `${thStyle.width
                                                            .replace('px', '')
                                                            .replace('%', '')} 0 auto`,
                                                    }}
                                                >
                                                    {column.render('Header')}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div {...getTableBodyProps()} className={'tbody'} style={{ height: 'calc(100% - 36px)' }}>
                            {page.length > 0 ? (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <FixedSizeList
                                            style={{ overflow: 'overlay' }}
                                            width={width > totalColumnsWidth ? width : totalColumnsWidth}
                                            height={height}
                                            // width={totalColumnsWidth + scrollBarSize}
                                            // height={800}
                                            itemCount={page.length}
                                            itemSize={35}
                                        >
                                            {RenderRow}
                                        </FixedSizeList>
                                    )}
                                </AutoSizer>
                            ) : (
                                <NoDataWrapper>
                                    <NoDataComponent>{noDataPlaceholder}</NoDataComponent>
                                </NoDataWrapper>
                            )}
                        </div>
                    </div>
                </div>
            </TableWrapper>
        </LoadingBlock>
    );
}

export default Table;
