import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CustomModalBody = styled(ModalBody)`
    text-align: center;
    font-weight: bold;
`;
const ConfirmModal = ({
    initModal = false,
    modalToggle = function () {
        initModal = !initModal;
    },
    reset,
    okCallback,
    cancelCallback,
    callbackParam,
    headerText = '확인',
    confirmText = '진행 하시겠습니까?',
    removeCancel,
    ...restProps
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={initModal} toggle={modalToggle} {...restProps}>
            <ModalHeader toggle={modalToggle}>
                {typeof headerText === 'string' ? t(headerText) : headerText}
            </ModalHeader>
            <CustomModalBody>{typeof confirmText === 'string' ? t(confirmText) : confirmText}</CustomModalBody>
            <ModalFooter>
                {/*{!removeCancel && (*/}
                {/*    <Button*/}
                {/*        onClick={e => {*/}
                {/*            if (typeof cancelCallback === 'function') {*/}
                {/*                cancelCallback(callbackParam);*/}
                {/*            }*/}
                {/*            modalToggle();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*       취소 */}
                {/*    </Button>*/}
                {/*)}*/}
                <Button
                    color="primary"
                    onClick={e => {
                        if (typeof okCallback === 'function') {
                            okCallback(callbackParam);
                        }
                        modalToggle();
                        if (typeof reset === 'function') {
                            reset();
                        }
                    }}
                >
                    확인
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export const AlertNoDiseaseModal = ({ toggle, handleToggle }) => {
    const { t } = useTranslation();
    return (
        <ConfirmModal
            initModal={toggle}
            modalToggle={handleToggle}
            headerText={'Alert'}
            confirmText={t('New tracing registration is not possible because disease information does not exist.')}
            removeCancel
        />
    );
};

export const AlertSelectTarget = ({ toggle, handleToggle }) => {
    const { t } = useTranslation();
    return (
        <ConfirmModal
            initModal={toggle}
            modalToggle={handleToggle}
            headerText={'Alert'}
            confirmText={
                <span>
                    {t('The same name exists.')}
                    <br />
                    {t('Please select a name.')}
                </span>
            }
            removeCancel
        />
    );
};

export default ConfirmModal;
