import React from 'react';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { CategoryCode, WidgetName } from '../Components/WidgetSettingOptions';
import { useSettings } from '../util/useSettings';

const ItemDetailStatusSetting = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    ...restProps
}) => {
    const { config, layout } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings, defaultSettings } = config;
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();
    const onSubmit = data => {
        dispatch(
            editWidgetSettings({
                id: widgetInfo.id,
                settings: data,
                // settings: { ...data, intervalTime: Number(data.intervalTime) },
            }),
        );
        toggleModal();
    };

    return (
        <Modal isOpen={modal} toggle={toggleModal} {...restProps}>
            <ModalHeader toggle={toggleModal}>{t('ItemDetailStatus;Iot Item Detail Status Setting')}</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <Input type="hidden" name="id" innerRef={register()} defaultValue={layout.i} />
                    <Col>
                        <WidgetName
                            innerRef={register({ required: true })}
                            defaultValue={
                                customSettings && customSettings.name
                                    ? customSettings.name
                                    : t(`Widget;${defaultSettings.name}`)
                            }
                        />
                    </Col>
                    <Col>
                        <CategoryCode innerRef={register()} defaultValue={settings.categoryCode} />
                    </Col>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    {t('Button;Ok')}
                </Button>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        toggleModal();
                    }}
                >
                    {t('Button;Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ItemDetailStatusSetting;
