import React, { useContext, useEffect, useState } from 'react';
import {
    DescriptionRow,
    HandleCustomInput,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { setBfEducationInfo, setZoneOption } from '../../../../../../reducers/Common/PerformanceRecordOption';
import CustomSearchModal from '../../../util/CustomSearchModal';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from '../../CarryingOutActivities';
import {
    setActivityFallEducationInfo,
    updateActivityFallEducationInfo,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import { TextChange, validationCheck } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import {
    createFallDownEducation,
    getFallDownEducation,
    updateFallDownEducation,
} from '../../../../../../api/activeSchedule';

import { useHistory } from 'react-router-dom';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const FallChildEducation = () => {
    const t = useTranslation('Evaluation');
    const history = useHistory();
    const storeDispatch = useDispatch();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const commonState = useContext(CommonStateContext);
    const { cardHeight } = commonState;
    const { bfEducationInfo, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { actItemCodeName, actTypeCodeName, targetNum, psNum, exeDate } = patientDetailInfo;
    const { activityFallEducationInfo } = state;
    const [zoneState, setZoneState] = useState({ label: t('Not applicable'), value: '0' });
    const [validation, setValidation] = useState({ BF_EDU: '교육항목' });
    const { promise: getCheckOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            const optionIndex = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
            storeDispatch(setBfEducationInfo({ res, optionIndex }));
        },
    });
    const { promise: getZoneInfoList } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    useEffect(() => {
        getCheckOption({ clCode: 'BF_EDU', codeDepth: '2' });
        getZoneInfoList({ clCode: 'WARD', codeDepth: '2' });
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, []);
    useEffect(() => {
        if (exeDate) {
            getFallEduCationActivity({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
    }, [exeDate]);
    //For Select
    const { promise: addFallDownEducationRecord } = useAsync({
        promise: createFallDownEducation,
        resolve: res => {
            const { pkValue: psNum } = res;
            getFallEduCationActivity({ psNum });
        },
    });

    const { promise: getFallEduCationActivity } = useAsync({
        promise: getFallDownEducation,
        resolve: res => {
            const { wardCode, wardCodeName } = res;
            let bfEduCodes = [];
            for (let key in res) {
                let result = null;
                result = key.match('[0-9]{1,2}');
                if (result && result['input'].includes('bfEduCode')) {
                    let value = res[key];
                    bfEduCodes.push(value);
                }
            }
            setZoneState({ label: wardCodeName, value: wardCode });
            dispatch(setActivityFallEducationInfo(res));
            storeDispatch(setNurseInfo(res));
        },
    });
    const { promise: editFallEduCationActivity } = useAsync({
        promise: updateFallDownEducation,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallEduCationActivity({ psNum });
            }
        },
    });
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityFallEducationInfo({ wardCode: value, wardCodeName: label }));
        setZoneState({ label, value });
    };

    const [checkState, setCheckState] = useState({});
    const onChangeCheck = e => {
        const { value, name, checked } = e.currentTarget;
        setValidation({ [name]: null });
        let temp = [];
        if (checkState[name]) {
            temp = checkState[name];
        }
        if (checked) {
            temp.push(value);
            setCheckState({
                ...checkState,
                [name]: temp,
            });
            dispatch(updateActivityFallEducationInfo({ [name]: value }));
        } else {
            setCheckState({
                ...checkState,
                [name]: temp.filter(v => v !== value),
            });
            dispatch(updateActivityFallEducationInfo({ [name]: null }));
        }
    };

    useEffect(() => {
        for (let name in checkState) {
            if (checkState[name].length) {
                setValidation({ [name]: null });
            } else if (checkState[name].length === 0) {
                setValidation({ [name]: '교육항목' });
            }
        }
    }, [checkState]);

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const [validationResultState, setValidationResultState] = useState([]);
    const onAdd = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editFallEduCationActivity({
                      ...nurseInfo,
                      psNum,
                      targetNum,
                      bfExeTypeCode: 7,
                      ...activityFallEducationInfo,
                      ip: profile.clientIp,
                      exeStatusCode: 'C',
                  })
                : addFallDownEducationRecord({
                      ...nurseInfo,
                      exeStatusCode: 'C',
                      psNum,
                      ip: profile.clientIp,
                      targetNum,
                      bfExeTypeCode: 7,
                      ...activityFallEducationInfo,
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };
    const onEdit = () => {
        setRecordState(!recordState);
    };

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityFallEducationInfo({ [name]: value }));
    };

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const { promise: NoFallDownEducationRecord } = useAsync({
        promise: createFallDownEducation,
        resolve: res => {
            history.push('/activity-schedule');
        },
    });

    const onHandleNotDone = () => {
        NoFallDownEducationRecord({
            bfEduCode1: '',
            bfEduCode10: '',
            bfEduCode11: '',
            bfEduCode12: '',
            bfEduCode13: '',
            bfEduCode14: '',
            bfEduCode15: '',
            bfEduCode2: '',
            bfEduCode3: '',
            bfEduCode4: '',
            bfEduCode5: '',
            bfEduCode6: '',
            bfEduCode7: '',
            bfEduCode8: '',
            bfEduCode9: '',
            bfExeTypeCode: '',
            exeStatusCode: 'N',
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const buttonOptionChange = value => {
        if (!value) {
            return [
                {
                    label: t('Save'),
                    onClick: onAdd,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
                {
                    label: t('Not performed'),
                    onClick: onHandleNotDone,
                    borderOption: true,
                },
            ];
        } else {
            return [
                {
                    label: t('Edit'),
                    onClick: onEdit,
                    borderOption: true,
                    className: 'icon-save',
                    iconBtnClass: 'btn-icon',
                },
            ];
        }
    };

    return (
        cardHeight && (
            <ActiveRecordCard
                style={{ height: cardHeight }}
                headerTitle={`${t('Performance History')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={buttonOptionChange(recordState)}
                spanIcon={['icon-info']}
                url={`${url.wmsUrl}/v2/html/aum/guide/5_1.html`}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse')}
                        component={
                            <HandleNurseTextInput
                                onClick={toggleModal}
                                name={'nurse'}
                                readOnly={nurseRecordState}
                                className={'d-flex'}
                                placeholder={`${t('Nurse')}||${t('Number')}`}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    activityFallEducationInfo &&
                                    activityFallEducationInfo.hasOwnProperty('exeUserName') &&
                                    activityFallEducationInfo.exeUserName &&
                                    `${activityFallEducationInfo.exeUserName} || ${activityFallEducationInfo.exeUserNum}`
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('When performed')}
                        component={
                            <HandleDateInput
                                value={startDate}
                                readOnly={nurseRecordState}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                                text={activityFallEducationInfo && activityFallEducationInfo.exeDate}
                                minDate={moment().valueOf()}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Zones')}
                        className="col-xl-12 col-lg-12 mb-4"
                        component={
                            <HandleReactSelect
                                options={zoneOptionRows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleZoneSelect}
                                value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                                text={
                                    activityFallEducationInfo &&
                                    t(activityFallEducationInfo.wardCodeName, 'ActTypeCode')
                                }
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Education Topics')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <>
                                {recordState && true
                                    ? activityFallEducationInfo &&
                                      activityFallEducationInfo.bfEduCodes &&
                                      activityFallEducationInfo.bfEduCodes.length > 0 &&
                                      activityFallEducationInfo.bfEduCodes.map((activityFallEducationInfo, i) => (
                                          <HandleCustomInput
                                              key={activityFallEducationInfo}
                                              id={activityFallEducationInfo}
                                              type={'checkbox'}
                                              className={'col-12 mb-3'}
                                              value={activityFallEducationInfo}
                                              label={t(activityFallEducationInfo, 'ActTypeCode')}
                                              checkedState={true}
                                              readOnly={recordState}
                                          />
                                      ))
                                    : bfEducationInfo.length > 0 &&
                                      bfEducationInfo.map(bfEducationInfo => (
                                          <HandleCustomInput
                                              key={bfEducationInfo.value}
                                              type={'checkbox'}
                                              id={bfEducationInfo.id}
                                              className={'col-12 mb-3'}
                                              name={bfEducationInfo.name}
                                              value={bfEducationInfo.value}
                                              onChange={onChangeCheck}
                                              readOnly={recordState}
                                              label={t(bfEducationInfo.label, 'ActTypeCode')}
                                              checkState={
                                                  activityFallEducationInfo && nurseRecordState === true
                                                      ? activityFallEducationInfo
                                                      : checkState
                                              }
                                          />
                                      ))}
                            </>
                        }
                    />

                    <DescriptionRow
                        label={t('Remarks')}
                        className="col-xl-12 col-lg-12 mb-3"
                        spanClassName={'w-50'}
                        component={
                            <HandleTextInput
                                size={200}
                                onChange={onHandleTextChange}
                                readOnly={recordState}
                                name={'remark'}
                                value={activityFallEducationInfo && activityFallEducationInfo.remark}
                                placeholder={t('Enter any remarks.')}
                            />
                        }
                    />
                </div>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ActiveRecordCard>
        )
    );
};

export default FallChildEducation;
