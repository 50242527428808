import { reqGet, reqPost, reqPatch, reqDelete } from './index';

export const fetchDashboardList = param => reqGet('/v3/api/common/user/widget/menu', { ...param, isAll: 'Y' });
export const fetchDashboard = param => reqGet(`/v3/api/common/user/widget/menu/${param.menuNum}`, param);
export const createDashboard = param => reqPost('/v3/api/common/user/widget/menu', param);
export const updateDashboard = param => reqPatch(`/v3/api/common/user/widget/menu/${param.menuNum}`, param);
export const deleteDashboard = param => reqDelete(`/v3/api/common/user/widget/menu/${param.menuNum}`);

//활동별 대시보드
//미시행
//getActivityDashboardNotEnforcedApi;
//getActivityDashboardNotEnforcedReq;
export const getActivityDashboardNotEnforcedApi = param => reqGet(`/v3/api/ps01/dashboard/delay`, param);
//미시행 카운트
export const getActivityDashboardNotEnforcedCountApi = param => reqGet(`/v3/api/ps01/dashboard/delay/count`, param);
//시횅
export const getActivityDashboardEnforcedApi = param => reqGet(`/v3/api/ps01/dashboard/ready`, param);
// 시행 카운트
export const getActivityDashboardEnforcedCountApi = param => reqGet(`/v3/api/ps01/dashboard/ready/count`, param);
//욕창 기록환자
export const getActivityDashboardPatientRecordApi = param => reqGet(`/v3/api/ps01/dashboard/complete`, param);
//욕창 기록 환자 카운트
export const getActivityDashboardPatientRecordCountApi = param =>
    reqGet(`/v3/api/ps01/dashboard/complete/count`, param);

// 낙상예방 현황
export const getFallPreventionInfoApi = param => reqGet(`/v3/api/ps01-aum/fall/dashboard/summary`, param);

// 지오펜스 정보
export const getGeofenceInfoApi = param => reqGet(`/v3/api/common/realtime/geofence/info`, param);
// 층정보
export const getFloorInfoApi = param => reqGet(`/v3/api/common/floor/${param.floorId}`);
