import React, { useEffect, useReducer, useState } from 'react';
import RecordCard from '../../Components/RecordCard';
import { DescriptionRow, HandleDateInput, HandleReactSelect } from '../../util/componentChange/componentChange';
import Button from '../../../Common/Button';
import useConfirmModal from '../../../Common/ConfirmModal/useConfirmModal';
import { useHistory, useParams } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../api/performanceRecord';
import { setActivityOption, setActivitySecondOption } from '../../../../reducers/Common/PerformanceRecordOption';
import { setPatientDetailInfo, setPatientInfoList } from '../../../../reducers/Common/PatientInfo';
import { useDispatch, useSelector } from 'react-redux';
import scheduleByActivityReducer, {
    initialState,
    updateActivityScheduleInfo,
    setActivityScheduleInfo,
} from '../ScheduleByActivity/scheduleByActivityReducer';
import CustomModal from '../../Components/Modal';
import {
    createActivityAssessment,
    deleteActivityAssessment,
    getActivityAssessmentInfo,
    getPatientInfo,
    getPatientInfoList,
    updateActivityAssessment,
} from '../../../../api/activeSchedule';
import TextInput from '../../../Common/Input/TextInput';
import Table from '../../../Common/Table';
import * as column from '../../util/grid/columns';
import { TextChange } from '../util/Validation';
import ConfirmModal from '../../Components/ConfirmModal';
import { getRiskStatus, RiskStatus } from '../../util/riskNamePlate';
import { AiOutlineAlert } from 'react-icons/ai';
import { onHandleTextChange } from '../../util/TextChange';
import { minToDhm, MinToDhm } from '../../util/dateChange';
import moment from 'moment';
import useTranslation from '../../../../util/hooks/useTranslation';

const columns = [column.ward(), column.patientName({}), column.department()];
const AddActivity = props => {
    const history = useHistory();
    const param = useParams();
    const t = useTranslation('Active Dashboard');
    //redux
    const storeDispatch = useDispatch();
    const { activityOption, activitySecondOption } = useSelector(state => state.PerformanceRecordOption);
    const { patientInfoList, patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { profile } = useSelector(state => state.UserInfo);
    // useReducer
    const [state, dispatch] = useReducer(scheduleByActivityReducer, initialState);
    const { activityScheduleInfo } = state;
    //옵션 컴포넌트 제어값
    const [option, setOption] = useState({ label: t('Select an activity classification'), value: 0 });
    const [secondOption, setSecondOption] = useState({ label: t('Select an activity detail'), value: 0 });
    const validationData = () => {
        if (param.hasOwnProperty('targetNum') === false) {
            return false;
        }
        if (param.hasOwnProperty('targetNum') === true) {
            return true;
        }
    };
    const optionsRows = activityOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const [patientInfo, setPatientInfo] = useState(null);
    const [performanceSchedule, setPerformanceSchedule] = useState(validationData);
    const [readState, setReadState] = useState(validationData);
    const [placeHolderState, setPlaceHolderState] = useState(t('Search for a patient.'));
    //활동수행 조회 API 및 기능
    const { promise: getActivityAssessment } = useAsync({
        promise: getActivityAssessmentInfo,
        resolve: res => {
            dispatch(setActivityScheduleInfo(res));
        },
    });
    //환자 정보 조회 API
    const { promise: getPatientDetailInfo } = useAsync({
        promise: getPatientInfo,
        resolve: res => {
            storeDispatch(setPatientDetailInfo(res));
        },
        reject: error => {
            console.log({ error });
        },
    });

    //등록 API 및 기능
    const [psNum, setPsNum] = useState(0);
    const { promise: addActivityAssessment } = useAsync({
        promise: createActivityAssessment,
        resolve: res => {
            if (res.result === 'success') {
                readState || setReadState(!readState);
                performanceSchedule || setPerformanceSchedule(!performanceSchedule);
                getActivityAssessment({ psNum: res.pkValue });
                if (param.key === 'false') {
                    setPsNum(res.pkValue);
                }
            }
        },
        reject: error => {
            console.log(error);
            toggleErrorConfirmModal();
        },
    });

    //수정 API 및 기능
    const { promise: editActivityAssessment } = useAsync({
        promise: updateActivityAssessment,
        resolve: res => {
            if (res.result === 'success') {
                performanceSchedule || setPerformanceSchedule(!performanceSchedule);
                getActivityAssessment({ psNum: res.pkValue });
                if (param.key === 'false') {
                    setPsNum(res.pkValue);
                }
            }
        },
    });

    //활동별 일정 삭제 API
    const { promise: removeActivityAssessment } = useAsync({
        promise: deleteActivityAssessment,
        resolve: res => {
            const result = res.result;
            if (result === 'success') {
                history.push('/activity-schedule');
            }
        },
    });
    //환자 검색 기능 및 api
    const { promise: getPatientList } = useAsync({
        promise: getPatientInfoList,
        resolve: res => {
            const length = res.rows.length;
            if (!length) {
                setPlaceHolderState(t('There is no information for the patient you searched for.'));
            }
            storeDispatch(setPatientInfoList(res));
        },
    });
    const { promise: getActivityOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivityOption({ res, profile }));
        },
    });
    const { promise: getActivitySecondOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setActivitySecondOption(res));
        },
    });
    useEffect(() => {
        getActivityOption({ clCode: 'ACT_TYPE', codeDepth: '2' });
        getActivitySecondOption({ clCode: 'BS_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_EDU', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BS_REPORT', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_RATING', codeDepth: '3' });
        getActivitySecondOption({ clCode: 'BF_EDU_IV', codeDepth: '3' });
        if (param.targetNum) {
            getPatientDetailInfo({ keyType: 'targetNum', targetKey: param.targetNum });
        }
        if (param.hasOwnProperty('key')) {
            getActivityAssessment({ psNum: param.key });
        }
        return () => {
            storeDispatch(setPatientDetailInfo());
        };
    }, [profile]);

    //select value Change
    const handleSelectValueChange = e => {
        const { name, label, value } = e;
        dispatch(updateActivityScheduleInfo({ [name]: value, actItemCode: null }));
        setOption({ label, value });
        setSecondOption({ label: t('Select an activity detail'), value: 0 });
    };
    //select value Change
    const handleSelectSecondValueChange = e => {
        const { label, value } = e;
        dispatch(updateActivityScheduleInfo({ actItemCode: value }));
        setSecondOption({ label, value });
    };

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());

    const handleStartDateValueChange = selected => {
        dispatch(updateActivityScheduleInfo({ sdate: selected / 1000 }));
        setStartDate(selected);
    };

    //datePicker value Change
    const [endDate, setEndDate] = useState(moment().add(1, 'h').valueOf());

    const handleEndDateValueChange = selected => {
        dispatch(updateActivityScheduleInfo({ edate: selected / 1000 }));
        setEndDate(selected);
    };
    const optionChange = optionData => {
        if (activityScheduleInfo && activityScheduleInfo.actTypeCode) {
            return optionData[activityScheduleInfo.actTypeCode].map(ele => ({
                ...ele,
                label: t(ele.label, 'ActTypeCode'),
            }));
        } else {
            return optionData.row;
        }
    };

    const riskChange = value => {
        const result = value.indexOf('BS');
        if (!result) {
            return (patientDetailInfo && patientDetailInfo.bsRisk) || '';
        } else {
            return (patientDetailInfo && patientDetailInfo.bfRisk) || '';
        }
    };
    const [modalText, setModalText] = useState([]);
    const onAdd = e => {
        if (!patientInfo && !patientDetailInfo) {
            return toggleNotSelectConfirmModal();
        }
        if (activityScheduleInfo === null) {
            setModalText([t('Activity'), t('Activity details')]);
            return toggleConfirmModal();
        } else {
            let text = [];
            if (!activityScheduleInfo.hasOwnProperty('actTypeCode') || !activityScheduleInfo.actTypeCode) {
                text.push(t('Categorizing activities'));
            }
            if (!activityScheduleInfo.hasOwnProperty('actItemCode') || !activityScheduleInfo.actItemCode) {
                text.push(t('Activity details'));
            }

            if (text.length !== 0) {
                setModalText([...text]);
                return toggleConfirmModal();
            }
        }
        const { targetNum } = patientDetailInfo;
        if (!readState) {
            patientDetailInfo &&
                addActivityAssessment({
                    riskGradeCode: riskChange(activityScheduleInfo.actTypeCode),
                    targetNum,
                    ...activityScheduleInfo,
                });
        } else {
            editActivityAssessment({
                psNum,
                riskGradeCode: riskChange(activityScheduleInfo.actTypeCode),
                targetNum,
                ...activityScheduleInfo,
            });
        }
    };
    //수정기능
    const onEdit = e => {
        e.preventDefault();
        setPerformanceSchedule(!performanceSchedule);
    };

    // 활동 수행 페이지 이동
    const onHandlePage = () => {
        const { psNum } = activityScheduleInfo;
        history.push(`/activity-schedule/carrying-out-activities/${psNum}`);
    };
    const onHandleBackPage = () => {
        history.goBack();
    };

    //검색
    const [keyword, setKeyword] = useState(null);
    const handleInputChange = e => {
        const { value } = e.target;
        setKeyword(value);
    };
    const onHandleSearch = () => {
        getPatientList({ keyword, opt: 'targetName', categoryCodes: 'PATIENT', isAll: 'Y' });
    };

    const onHandlePatient = e => {
        setPatientInfo(e);
    };
    const handleClick = () => {
        storeDispatch(setPatientDetailInfo(patientInfo));
    };
    //삭제
    const onHandleDelete = e => {
        removeActivityAssessment({ psNum: activityScheduleInfo.psNum });
    };

    //수정,조회에 따른 버튼 리턴
    const buttonOption = () => {
        return (
            (!readState && [
                {
                    label: t('Save'),
                    key: 'save',
                    onClick: onAdd,
                    className: 'btn-icon btn-blue',
                    iconClassName: 'icon-save',
                },
            ]) ||
            (performanceSchedule && [
                {
                    label: t('Perform activities'),
                    key: 'active',
                    onClick: onHandlePage,
                    className: 'btn-orange',
                    borderOption: true,
                },
                {
                    label: t('Delete'),
                    key: 'delete',
                    onClick: onToggle,
                    className: 'btn-icon btn-danger ',
                    iconClassName: 'icon-delete',
                },
                {
                    label: t('Modify'),
                    key: 'update',
                    onClick: onEdit,
                    className: 'btn-icon btn-blue ',
                    iconClassName: 'icon-editnote',
                },
            ]) ||
            (readState &&
                !performanceSchedule && [
                    {
                        label: t('Save'),
                        key: 'save',
                        onClick: onAdd,
                        className: 'btn-icon btn-blue',
                        iconClassName: 'icon-save',
                    },
                ])
        );
    };

    const headerTitle = () => {
        return (
            (!readState && t('Tasks-Add')) ||
            (performanceSchedule && t('To-do list-view')) ||
            (readState && !performanceSchedule && t('To-Do List-Edit'))
        );
    };

    //저장,수정,조회에 따른 subTitle 값 리턴
    const subTitle = () => {
        return (
            (!readState && t('Function to add a schedule of activities to be performed on a patient.')) ||
            (performanceSchedule && t('Function to view the schedule of activities performed on a patient.')) ||
            (readState &&
                !performanceSchedule &&
                t('Function to modify the schedule of activities performed on a patient.'))
        );
    };
    //저장,

    // 검색 창 모달
    const [patientModal, setPatientModal] = useState(false);
    const togglePatientModal = () => {
        setPatientModal(!patientModal);
        storeDispatch(setPatientInfoList(null));
    };

    // 유효성 검사 모달
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    // 유효성 검사 모달
    const [errorConfirmModalState, setErrorConfirmModalState] = useState(false);
    const toggleErrorConfirmModal = () => {
        setErrorConfirmModalState(!errorConfirmModalState);
    };
    const [notSelectConfirmModalState, setNotSelectConfirmModalState] = useState(false);
    const toggleNotSelectConfirmModal = () => {
        setNotSelectConfirmModalState(!notSelectConfirmModalState);
    };
    //삭제기능 모달 호출
    const onToggle = e => {
        toggleModal();
    };

    const { Modal, toggleModal } = useConfirmModal({
        initModal: false,
        header: {
            title: (
                <>
                    <AiOutlineAlert /> {t('Delete notifications')}
                </>
            ),
            className: 'bg-error text-white',
        },
        okClassName: 'bg-error modal-btn',
        confirmText: t('Are you sure you want to delete the selected to-do?', 'Active Dashboard'),

        okCallback: onHandleDelete,
    });
    const handleText = value => value || '';
    const getSexInKorean = value => {
        let word;
        switch (value) {
            case 'male':
                word = t('Male');
                break;
            case 'female':
                word = t('Female');
                break;
        }
        return word;
    };

    return (
        <div className="grid-inquire-container">
            <RecordCard
                headerTitle={t('Patient Information')}
                subTitle={t('Function to display selected patient information')}
                buttonOption={[{ key: 'back', label: t('Back'), onClick: onHandleBackPage, className: 'btn-gray' }]}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Patient Information')}
                        component={
                            <>
                                {(patientDetailInfo &&
                                    `${handleText(patientDetailInfo.targetName)}/${handleText(
                                        patientDetailInfo.targetId,
                                    )}[${getSexInKorean(patientDetailInfo.sex)}]
                                [${handleText(patientDetailInfo.age)}]
                                [${handleText(patientDetailInfo.status)} - KTAS${handleText(
                                        patientDetailInfo.ktas,
                                    )}]`) ||
                                    t('Search for patient information')}
                                {!patientDetailInfo && (
                                    <Button
                                        className={'pnt-btn btn-blue btn-icon-only'}
                                        onClick={togglePatientModal}
                                        iconClassName={'icon-search'}
                                    />
                                )}
                            </>
                        }
                    />
                    <DescriptionRow
                        label={t('Department/Primary Care Physician')}
                        component={
                            <>
                                {patientDetailInfo &&
                                    onHandleTextChange([patientDetailInfo.medDept, patientDetailInfo.chadrname])}
                            </>
                        }
                    />
                    <DescriptionRow
                        label={t('Ward/Room/Bed')}
                        component={
                            <>
                                {patientDetailInfo &&
                                    onHandleTextChange([
                                        patientDetailInfo.wardNo,
                                        patientDetailInfo.roomNo,
                                        patientDetailInfo.bedNo,
                                    ])}
                            </>
                        }
                    />
                    <DescriptionRow
                        label={t('Risk categorization')}
                        component={
                            <>
                                {patientDetailInfo && patientDetailInfo.bsRisk && (
                                    <RiskStatus value={patientDetailInfo.bsRisk} />
                                )}
                                {patientDetailInfo && patientDetailInfo.bfRisk && (
                                    <RiskStatus value={patientDetailInfo.bsRisk} />
                                )}
                            </>
                        }
                    />
                </div>
            </RecordCard>

            <RecordCard
                keyValue={'activePerform'}
                headerTitle={headerTitle()}
                subTitle={subTitle()}
                buttonOption={buttonOption()}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Activities', 'Common Columns')}
                        component={
                            <>
                                <HandleReactSelect
                                    readOnly={readState}
                                    options={optionsRows}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    onChange={handleSelectValueChange}
                                    customControlStyles={{ width: '250px' }}
                                    customOptionStyles={{ width: '250px' }}
                                    value={option}
                                    text={
                                        activityScheduleInfo &&
                                        `${t(activityScheduleInfo.actTypeCodeName, 'ActTypeCode')}>${t(
                                            activityScheduleInfo.actItemCodeName,
                                            'ActTypeCode',
                                        )}`
                                    }
                                    t={t}
                                />
                                <HandleReactSelect
                                    readOnly={readState}
                                    options={optionChange(activitySecondOption)}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    onChange={handleSelectSecondValueChange}
                                    customControlStyles={{ width: '200px' }}
                                    customOptionStyles={{ width: '200px' }}
                                    value={secondOption}
                                />
                            </>
                        }
                    />
                    {readState && activityScheduleInfo && (
                        <DescriptionRow
                            label={t('Performance divisions', 'Common Columns')}
                            component={
                                <span
                                    className={
                                        activityScheduleInfo &&
                                        activityScheduleInfo.rdate &&
                                        activityScheduleInfo.rdate.includes('-')
                                            ? 'color-danger'
                                            : 'color-secondary'
                                    }
                                >
                                    {activityScheduleInfo &&
                                    activityScheduleInfo.rdate &&
                                    activityScheduleInfo.rdate.includes('-')
                                        ? t('Delay', 'Common Columns')
                                        : t('Scheduled', 'Common Columns')}
                                </span>
                            }
                        />
                    )}

                    <DescriptionRow
                        label={t('Due Date', 'Common Columns')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <>
                                {performanceSchedule && activityScheduleInfo && activityScheduleInfo.rdate && (
                                    <MinToDhm value={activityScheduleInfo.rdate} />
                                )}
                                <HandleDateInput
                                    value={startDate}
                                    readOnly={performanceSchedule}
                                    handleDateValueChange={handleStartDateValueChange}
                                    valueType={'ms'}
                                    text={activityScheduleInfo && activityScheduleInfo.sdate}
                                    minDate={startDate}
                                    title={t('Due Date', 'Common Columns')}
                                />
                                <span> ~ </span>
                                <HandleDateInput
                                    value={endDate}
                                    readOnly={performanceSchedule}
                                    handleDateValueChange={handleEndDateValueChange}
                                    valueType={'ms'}
                                    text={activityScheduleInfo && activityScheduleInfo.edate}
                                    minDate={startDate}
                                />
                            </>
                        }
                    />
                </div>
            </RecordCard>
            <CustomModal
                openModal={patientModal}
                headerTitle={t('Patient search', 'Common Columns')}
                onModalClickYes={togglePatientModal}
                handleClick={handleClick}
                content={
                    <>
                        <div className="d-flex mb-4">
                            <TextInput
                                name={'keyword'}
                                placeholder={t('Please search for the patient name', 'Common Columns')}
                                handleChange={handleInputChange}
                                size={55}
                            />
                            <button className={`pnt-btn btn-icon btn-blue btn-icon-only ml-2`} onClick={onHandleSearch}>
                                <span className="icon-search" />
                            </button>
                        </div>
                        <div style={{ height: '400px' }}>
                            <Table
                                paging={false}
                                columns={columns}
                                onTrClick={onHandlePatient}
                                data={patientInfoList}
                                noDataPlaceholder={placeHolderState}
                                rowSelect={{
                                    selected: patientInfo,
                                    rowKey: 'targetNum',
                                    selectedRowClassName: 'selected-color',
                                }}
                            />
                        </div>
                    </>
                }
            />
            <Modal />
            <ConfirmModal
                initModal={errorConfirmModalState}
                headerText={t('Notification', 'Common Columns')}
                modalToggle={toggleErrorConfirmModal}
                confirmText={
                    <span style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {t('This information is already registered.')}
                    </span>
                }
            />
            <ConfirmModal
                initModal={notSelectConfirmModalState}
                headerText={t('Required fields Item', 'Evaluation')}
                modalToggle={toggleNotSelectConfirmModal}
                confirmText={
                    <span style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {t('Please select Patient Information', 'Common Columns')}
                    </span>
                }
            />
            <ConfirmModal
                initModal={confirmModalState}
                headerText={t('Required fields Item', 'Evaluation')}
                modalToggle={toggleConfirmModal}
                confirmText={TextChange(modalText)}
            />
        </div>
    );
};

export default AddActivity;
