import React, { useEffect, useReducer, useState } from 'react';
import ActiveRecordCard from '../../../Components/ActiveRecordCard';
import TextInput from '../../../../Common/Input/TextInput';
import ReactSelect from '../../../../Common/Select';
import { useHistory } from 'react-router-dom';

import {
    initialState,
    setExecutionCycleInfo,
    setExecutionCycleType,
    updateActivityScheduleInfo,
} from '../../ActivitySetting/activitySettingReducer';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../api/performanceRecord';
import {
    setExecutionCycleOption,
    setExecutionCycleTypeOption,
} from '../../../../../reducers/Common/PerformanceRecordOption';
import settingReducer, { setWardInfoList } from '../../settingReducer';
import { createWardSettingApi, fetchActivitySettingSelectListApi } from '../../../../../api/activitySetting';
import { getGroupList } from '../../../../../api/groupSetting';
import wardSettingReducer, {
    setActivityCycleInfoList,
    updateWardSettingInfo,
    wardSettingInitialState,
} from '../wardSettingReducer';
import { DescriptionRow } from '../../../util/componentChange/componentChange';
import useTranslation from '../../../../../util/hooks/useTranslation';
const WardSettingAdd = () => {
    const t = useTranslation('Activity Setting');
    const history = useHistory();
    //리덕스
    const storeDispatch = useDispatch();
    const { executionCycleOption, executionCycleTypeOption } = useSelector(state => state.PerformanceRecordOption);

    const { single, repeat } = executionCycleOption;

    //리듀서
    const [commonState, commonDispatch] = useReducer(settingReducer, initialState);
    const [state, dispatch] = useReducer(wardSettingReducer, wardSettingInitialState);
    const { executionCycleInfo, wardInfoList, executionCycleType } = commonState;
    const { type } = executionCycleType;
    const { activityCycleInfoList, wardSettingInfo } = state;

    //제어 컴포넌트 관리
    const [wardNoState, setWardNoState] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [activityCycleState, setActivityCycleState] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [activityCycle, setActivityCycle] = useState({ label: t('Select', 'Evaluation'), value: 0 });
    const [activityCycleSecond, setActivityCycleSecond] = useState({ label: t('Select', 'Evaluation'), value: 0 });

    const [activityText, setActivityText] = useState('');
    const [cycleText, setCycleText] = useState('');
    const [riskText, setRiskText] = useState('');
    const weekOption = [
        { value: '일요일', label: t('Sunday', 'Common Columns') },
        { value: '월요일', label: t('Monday', 'Common Columns') },
        { value: '화요일', label: t('Tuesday', 'Common Columns') },
        { value: '수요일', label: t('Wednesday', 'Common Columns') },
        { value: '목요일', label: t('Thursday', 'Common Columns') },
        { value: '금요일', label: t('Friday', 'Common Columns') },
        { value: '토요일', label: t('Saturday', 'Common Columns') },
    ];

    const { promise: getWardInfoList } = useAsync({
        promise: getGroupList,
        resolve: res => {
            commonDispatch(setWardInfoList(res));
        },
    });

    const { promise: getActivitySettingSelectList } = useAsync({
        promise: fetchActivitySettingSelectListApi,
        resolve: res => {
            dispatch(setActivityCycleInfoList(res));
        },
    });
    const { promise: getExecutionCycleOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleInfo(data));
        },
    });
    const { promise: getExecutionCycleTypeOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setExecutionCycleTypeOption(res));
            const data = { label: res.rows[0].codeName, value: res.rows[0].codeNum };
            commonDispatch(setExecutionCycleType({ type: data, executionCycleOption }));
        },
    });
    useEffect(() => {
        getWardInfoList({});
        getActivitySettingSelectList({});
        getExecutionCycleOption({ clCode: 'EXE_CYCLE', codeDepth: '2' });
        getExecutionCycleTypeOption({ clCode: 'EXE_CYCLE_TYPE', codeDepth: '2' });
    }, []);

    const { promise: addExecutionCycleTypeOption } = useAsync({
        promise: createWardSettingApi,
        resolve: res => {
            const { result } = res;
            if (result === 'success') {
                history.push('/active/ward-setting/');
            }
        },
    });

    const onHandleBackPage = () => {
        history.goBack();
    };

    const onHandleSave = () => {
        addExecutionCycleTypeOption({ ...wardSettingInfo });
    };

    const [day, setDay] = useState({ value: '일요일', label: t('Sunday', 'Common Columns') });
    const onChange = selected => {
        const { value, label } = selected;
        setDay({ value, label });
        dispatch(updateActivityScheduleInfo({ exeCycleVal: value }));
    };
    const activityCycleInfoListRows = activityCycleInfoList.rows.map(ele => {
        return {
            ...ele,
            label: ele.label
                .split(' | ')
                .map(ele => t(ele, 'ActTypeCode'))
                .join(' | '),
        };
    });
    const executionCycleTypeOptionRows = executionCycleTypeOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const singleRows = single.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const repeatRows = repeat.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    return (
        <>
            <ActiveRecordCard
                headerTitle={t('Add a per-ward performance interval setting')}
                buttonOption={[
                    {
                        label: t('Back', 'Active Dashboard'),
                        color: 'btn-gray',
                        borderOption: true,
                        onClick: onHandleBackPage,
                    },
                    {
                        label: t('Save', 'Active Dashboard'),
                        className: 'icon-editnote',
                        iconBtnClass: 'btn-icon',
                        onClick: onHandleSave,
                    },
                ]}
                headerTitleSub={t('Ability to set separate pressure ulcer activity conditions and activity frequency.')}
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Select cycles by activity')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <ReactSelect
                                options={activityCycleInfoListRows}
                                value={activityCycleState}
                                isMulti={false}
                                isSearchable={false}
                                onChange={selected => {
                                    const { label, value } = selected;
                                    dispatch(updateWardSettingInfo({ configNum: value }));
                                    setActivityCycleState({ label, value });
                                    const text = label.split('|');
                                    setActivityText(text[0]);
                                    setCycleText(`${text[1]}/${text[2]}`);
                                    setRiskText(text[3]);
                                }}
                                customControlStyles={{ width: '1000px' }}
                                customOptionStyles={{ width: '1000px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Select a ward')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <ReactSelect
                                options={wardInfoList || [{ label: t('Select', 'Evaluation'), value: '0' }]}
                                value={wardNoState}
                                onChange={selected => {
                                    const { label, value } = selected;
                                    dispatch(updateWardSettingInfo({ patientGroupNum: value }));
                                    setWardNoState({ label, value });
                                }}
                                customControlStyles={{ width: '200px' }}
                            />
                        }
                    />

                    {/*활동별 주기 선택시 텍스트 변경 */}
                    <DescriptionRow
                        label={t('Pressure Ulcer Activity')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<span className="label-options">{activityText}</span>}
                    />

                    <DescriptionRow
                        label={t('Performance Conditions')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<span className="label-options">{cycleText}</span>}
                    />
                    <DescriptionRow
                        label={t('Pressure Ulcer Risk Classification')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<span className="label-options">{riskText}</span>}
                    />

                    <DescriptionRow
                        label={t('Performance cycle', 'Common Columns')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <>
                                {executionCycleTypeOption.rows.length > 0 &&
                                    executionCycleOption.single.length > 0 &&
                                    type && (
                                        <>
                                            <ReactSelect
                                                options={executionCycleTypeOptionRows}
                                                value={activityCycle}
                                                onChange={selected => {
                                                    const { label, codeName, value } = selected;
                                                    dispatch(updateWardSettingInfo({ exeCycleTypeCode: codeName }));
                                                    commonDispatch(
                                                        setExecutionCycleType({ type: selected, executionCycleOption }),
                                                    );
                                                    setActivityCycle({ label, value });
                                                    setActivityCycleSecond({
                                                        label: t('Select', 'Evaluation'),
                                                        value: 0,
                                                    });
                                                }}
                                                customControlStyles={{ width: '200px' }}
                                            />
                                            <ReactSelect
                                                options={
                                                    executionCycleType && type.label === '단일'
                                                        ? singleRows
                                                        : repeatRows
                                                }
                                                value={activityCycleSecond}
                                                onChange={selected => {
                                                    const { label, codeValue, value } = selected;
                                                    dispatch(updateWardSettingInfo({ exeCycleCode: codeValue }));
                                                    commonDispatch(setExecutionCycleInfo(selected));
                                                    setActivityCycleSecond({ label, value });
                                                }}
                                                customControlStyles={{ width: '150px' }}
                                            />
                                        </>
                                    )}
                                {executionCycleInfo.optionData &&
                                    [
                                        'EXE_CYCLE_1',
                                        'EXE_CYCLE_2',
                                        'EXE_CYCLE_3',
                                        'EXE_CYCLE_4',
                                        'EXE_CYCLE_7',
                                    ].includes(executionCycleInfo.optionData.codeValue) && (
                                        <TextInput
                                            name={'exeCycleVal'}
                                            type={'number'}
                                            min={0}
                                            max={99999}
                                            handleChange={e => {
                                                const { name, value } = e.target;

                                                dispatch(updateWardSettingInfo({ [name]: value }));
                                            }}
                                        />
                                    )}
                                {executionCycleInfo.optionData &&
                                    ['EXE_CYCLE_5'].includes(executionCycleInfo.optionData.codeValue) && (
                                        <>
                                            <ReactSelect
                                                options={hourOption}
                                                onChange={selected => {
                                                    const { value } = selected;
                                                    dispatch(updateWardSettingInfo({ exeCycleVal: value }));
                                                }}
                                            />
                                            <ReactSelect
                                                options={hourOption}
                                                onChange={selected => {
                                                    const { value } = selected;
                                                    dispatch(updateWardSettingInfo({ exeCycleVal2: value }));
                                                }}
                                            />
                                            <ReactSelect
                                                options={hourOption}
                                                onChange={selected => {
                                                    const { value } = selected;
                                                    dispatch(updateWardSettingInfo({ exeCycleVal3: value }));
                                                }}
                                            />
                                        </>
                                    )}
                                {executionCycleInfo.optionData &&
                                    ['EXE_CYCLE_6'].includes(executionCycleInfo.optionData.codeValue) && (
                                        <ReactSelect value={day} options={weekOption} onChange={onChange} />
                                    )}
                            </>
                        }
                    />
                </div>
            </ActiveRecordCard>
        </>
    );
};
const hourOption = [
    { value: '00:00', label: '00:00' },
    { value: '00:30', label: '00:30' },
    { value: '01:00', label: '01:00' },
    { value: '01:30', label: '01:30' },
    { value: '02:00', label: '02:00' },
    { value: '02:30', label: '02:30' },
    { value: '03:00', label: '03:00' },
    { value: '03:30', label: '03:30' },
    { value: '04:00', label: '04:00' },
    { value: '04:30', label: '04:30' },
    { value: '05:00', label: '05:00' },
    { value: '05:30', label: '05:30' },
    { value: '06:00', label: '06:00' },
    { value: '06:30', label: '06:30' },
    { value: '07:00', label: '07:00' },
    { value: '07:30', label: '07:30' },
    { value: '08:00', label: '08:00' },
    { value: '08:30', label: '08:30' },
    { value: '09:00', label: '09:00' },
    { value: '09:30', label: '09:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
    { value: '21:00', label: '21:00' },
    { value: '21:30', label: '21:30' },
    { value: '22:00', label: '22:00' },
    { value: '22:30', label: '22:30' },
    { value: '23:00', label: '23:00' },
    { value: '23:30', label: '23:30' },
];
export default WardSettingAdd;
