import React, { useContext, useEffect, useState } from 'react';
import ActiveRecordCard from '../../../../Components/ActiveRecordCard';
import {
    DescriptionRow,
    HandleDateInput,
    HandleNurseTextInput,
    HandleReactSelect,
    HandleSearchableSelect,
    HandleTextInput,
} from '../../../../util/componentChange/componentChange';
import useAsync from '../../../../../../util/hooks/useAsync';
import { getSelectOptions } from '../../../../../../api/performanceRecord';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBfChildOption,
    setBfHighRisk,
    setZoneOption,
} from '../../../../../../reducers/Common/PerformanceRecordOption';
import {
    createChildFallDownRecord,
    getChildFallDownRecord,
    getPatientInfo,
    updateChildFallDownRecord,
} from '../../../../../../api/activeSchedule';
import {
    updateActivityFallEvaluationScheduleInfo,
    setActivityFallEvaluationScheduleInfo,
    setFlag,
} from '../../../ScheduleByActivity/scheduleByActivityReducer';
import CustomSearchModal from '../../../util/CustomSearchModal';
import { TextChange, validationCheck } from '../../../util/Validation';
import ConfirmModal from '../../../../Components/ConfirmModal';
import {
    CommonStateContext,
    ScheduleByActivityDispatchContext,
    ScheduleByActivityStateContext,
} from '../../CarryingOutActivities';
import { useHistory } from 'react-router-dom';
import { setNurseInfo, updateNurseInfo } from '../../../../../../reducers/Common/CommonInfo';
import moment from 'moment';
import { resetPatientDetailInfo } from '../../../../../../reducers/Common/PatientInfo';
import { monthChange } from '../../../../util/dateChange';
import { filterProcess } from '../../../util';
import useTranslation from '../../../../../../util/hooks/useTranslation';

const FallDetail = () => {
    const t = useTranslation('Fall');
    const storeDispatch = useDispatch();
    const history = useHistory();
    const dispatch = useContext(ScheduleByActivityDispatchContext);
    const state = useContext(ScheduleByActivityStateContext);
    const commonState = useContext(CommonStateContext);
    const { bfHighRiskOption, bfChildOption, zoneOption } = useSelector(state => state.PerformanceRecordOption);
    const { nurseInfo, url } = useSelector(state => state.CommonInfo);
    const { patientDetailInfo } = useSelector(state => state.PatientInfo);
    const { profile } = useSelector(state => state.UserInfo);
    const { activityFallEvaluationScheduleInfo } = state;
    const { cardHeight } = commonState;
    const { actItemCodeName, actTypeCodeName, actItemCode, targetNum, psNum, exeDate } = patientDetailInfo;

    const {
        bfRatingItemAge,
        bfRatingItemActive,
        bfRatingItemBehavior,
        bfRatingItemHealth,
        bfRatingItemDiagnosis,
        bfRatingItemFacility,
        bfRatingItemRearer,
    } = bfChildOption;

    const [zoneState, setZoneState] = useState({ label: t('Not applicable', 'Evaluation'), value: '0' });
    const [selectOption, setSelectOption] = useState({
        BF_HIGH_RISK: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_AGE: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_ACTIVE: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_BEHAVIOR: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_HEALTH: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_DIAGNOSIS: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_FACILITY: [{ label: t('Select', 'Common Columns'), id: 0 }],
        BF_RATING_ITEM_REARER: [{ label: t('Select', 'Common Columns'), id: 0 }],
    });
    const [validation, setValidation] = useState({
        BF_RATING_ITEM_ACTIVE: '활동상태',
        BF_RATING_ITEM_BEHAVIOR: '행동상태',
        BF_RATING_ITEM_HEALTH: '건강상태',
        BF_RATING_ITEM_DIAGNOSIS: '진단명',
        BF_RATING_ITEM_FACILITY: '시설',
        BF_RATING_ITEM_REARER: '양육자와의 관계',
    });
    useAsync({
        promise: getPatientInfo,
        immediate: true,
        fixedParam: { targetKey: patientDetailInfo.targetNum },
        resolve: res => {
            const optionResult = monthChange(res.properties.birthDay);
            setSelectOption({ ...selectOption, ...optionResult });
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    bfRatingItemAgeCode: optionResult['BF_RATING_ITEM_AGE'][0].value,
                }),
            );
        },
    });

    useAsync({
        promise: getSelectOptions,
        immediate: true,
        fixedParam: { clCode: 'BF_HIGH_RISK', codeDepth: '2' },
        resolve: res => {
            let optionCode = [];
            optionCode = ['0', '2', '3', '4', '5', '6', '7', '8', '9'];
            storeDispatch(setBfHighRisk({ res, optionCode }));
        },
    });

    const { promise: getSelectOption } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setBfChildOption(res));
        },
    });
    const { promise: getZoneInfoList } = useAsync({
        promise: getSelectOptions,
        resolve: res => {
            storeDispatch(setZoneOption(res));
        },
    });
    const defaultActivitySchedule = {
        bfHighRiskCodes: '0',
        bfRatingItemActiveCode: '',
        bfRatingItemBehaviorCode: '',
        bfRatingItemDiagnosisCode: '',
        bfRatingItemFacilityCode: '',
        bfRatingItemHealthCode: '',
        bfRatingItemRearerCode: '',
        nanda: 0,
        nandaStatus: '고위험',
    };
    useEffect(() => {
        getSelectOption({ clCode: 'BF_RATING_ITEM_AGE', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_ACTIVE', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_BEHAVIOR', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_HEALTH', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_DIAGNOSIS', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_FACILITY', codeDepth: '2' });
        getSelectOption({ clCode: 'BF_RATING_ITEM_REARER', codeDepth: '2' });
        getZoneInfoList({ clCode: 'WARD', codeDepth: '2' });
        if (actItemCode === 'BF_RATING_9') {
            setSelectValue(['0']);
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_ACTIVE: null,
                BF_RATING_ITEM_BEHAVIOR: null,
                BF_RATING_ITEM_HEALTH: null,
                BF_RATING_ITEM_DIAGNOSIS: null,
                BF_RATING_ITEM_FACILITY: null,
                BF_RATING_ITEM_REARER: null,
            });
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                }),
            );
        }
        return () => {
            storeDispatch(resetPatientDetailInfo());
        };
    }, []);
    useEffect(() => {
        if (exeDate) {
            getFallDownRecord({ psNum });
            setRecordState(true);
            setNurseRecordState(true);
        }
    }, [exeDate]);
    const [nandaFallScale, setNandaFallScale] = useState(0);

    const handleDisabled = option => !option.includes('0');

    //includeBol 값이 true 일 경우 해당없음 값을 셋팅 해주고 return
    //false 일 경우 선택한 옵션 값을 셋팅 해주고 return  해준다
    const [selectValue, setSelectValue] = useState([]);

    const handleMainSelectChange = selected => {
        let multipleSelection = filterProcess(selected);

        setSelectValue(multipleSelection.map(v => v.value));
        if (!multipleSelection.map(item => item.value).includes('0')) {
            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    ...defaultActivitySchedule,
                    ...activityFallEvaluationScheduleInfo,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => item.label).join(','),
                }),
            );
            setValidation({
                BF_HIGH_RISK: null,
                BF_RATING_ITEM_ACTIVE: null,
                BF_RATING_ITEM_BEHAVIOR: null,
                BF_RATING_ITEM_HEALTH: null,
                BF_RATING_ITEM_DIAGNOSIS: null,
                BF_RATING_ITEM_FACILITY: null,
                BF_RATING_ITEM_REARER: null,
            });
        } else {
            dispatch(
                setActivityFallEvaluationScheduleInfo({
                    ...activityFallEvaluationScheduleInfo,
                    exeDate: activityFallEvaluationScheduleInfo.exeDate,
                    exeUserName: activityFallEvaluationScheduleInfo.exeUserName,
                    exeUserNum: activityFallEvaluationScheduleInfo.exeUserNum,
                    wardCode: activityFallEvaluationScheduleInfo.wardCode,
                    wardCodeName: activityFallEvaluationScheduleInfo.wardCodeName,
                    bfHighRiskCodes: multipleSelection.map(item => item.value),
                    bfHighRiskCodeName: multipleSelection.map(item => item.label).join(','),
                }),
            );
            setValidation({
                BF_HIGH_RISK: null,
                ...validation,
            });
        }
        setSelectOption({
            ...selectOption,
            BF_RATING_ITEM_ACTIVE: [{ label: t('Select', 'Common Columns'), id: 0 }],
            BF_RATING_ITEM_BEHAVIOR: [{ label: t('Select', 'Common Columns'), id: 0 }],
            BF_RATING_ITEM_HEALTH: [{ label: t('Select', 'Common Columns'), id: 0 }],
            BF_RATING_ITEM_DIAGNOSIS: [{ label: t('Select', 'Common Columns'), id: 0 }],
            BF_RATING_ITEM_FACILITY: [{ label: t('Select', 'Common Columns'), id: 0 }],
            BF_RATING_ITEM_REARER: [{ label: t('Select', 'Common Columns'), id: 0 }],
        });
        setNandaFallScale(0);
    };

    const handleSelectValueChange = e => {
        const { id, name, label, value, codeName, codeLabel, code } = e;
        if (id) {
            const beforeValue = selectOption[name][0].id || 0;
            const gap = beforeValue - Number(id);
            const nanda = nandaFallScale - Number(gap);
            setNandaFallScale(nanda);
            setValidation({ ...validation, [name]: null });
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: id, value: value }] });

            let nandaStatus = '';
            if (nanda <= 3) {
                nandaStatus = '고위험군';
            }
            if (nanda <= 2) {
                nandaStatus = '중위험군';
            } else if (nanda <= 1) {
                nandaStatus = '저위험군';
            }
            if (selectValue.length && !selectValue.includes('0')) {
                nandaStatus = '고위험군';
            }

            dispatch(
                updateActivityFallEvaluationScheduleInfo({
                    [codeName]: code,
                    [`${codeName}Name`]: codeLabel,
                    nanda,
                    nandaStatus,
                }),
            );
        } else {
            setValidation({ ...validation, [name]: null });
            setSelectOption({ ...selectOption, [name]: [{ label: label, id: id, value: value }] });
            dispatch(updateActivityFallEvaluationScheduleInfo({ [codeName]: value, [`${codeName}Name`]: codeLabel }));
        }
    };
    const handleZoneSelect = e => {
        const { value, label } = e;
        dispatch(updateActivityFallEvaluationScheduleInfo({ wardCode: value }));
        setZoneState({ label, value });
    };
    const { promise: addFallDownRecord } = useAsync({
        promise: createChildFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });

    const { promise: getFallDownRecord } = useAsync({
        promise: getChildFallDownRecord,
        resolve: res => {
            const { nanda, wardCodeName, wardCode } = res;
            storeDispatch(setNurseInfo(res));
            dispatch(setActivityFallEvaluationScheduleInfo(res));
            dispatch(setFlag());
            setZoneState({ label: wardCodeName, value: wardCode });
            setNandaFallScale(nanda);
        },
    });

    const { promise: editFallDownRecord } = useAsync({
        promise: updateChildFallDownRecord,
        resolve: res => {
            const { result, pkValue: psNum } = res;
            if (result === 'success') {
                getFallDownRecord({ psNum });
            }
        },
    });
    const zoneOptionRows = zoneOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const [recordState, setRecordState] = useState(false);
    const [nurseRecordState, setNurseRecordState] = useState(false);
    const [validationResultState, setValidationResultState] = useState([]);
    const onHandleSave = () => {
        let validationResult = [];
        if (!nurseRecordState) {
            validationResult = validationCheck(validation);
            !nurseInfo.hasOwnProperty('exeUserName') && validationResult.push('간호사');
            !nurseInfo.hasOwnProperty('exeDate') && validationResult.push('수행일시');
        }
        if (!validationResult.length) {
            setRecordState(!recordState);
            !nurseRecordState && setNurseRecordState(!nurseRecordState);
            nurseRecordState
                ? editFallDownRecord({
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      ip: profile.clientIp,
                      psNum,
                      exeStatusCode: 'C',
                  })
                : addFallDownRecord({
                      ...activityFallEvaluationScheduleInfo,
                      ...nurseInfo,
                      targetNum,
                      ip: profile.clientIp,
                      psNum,
                      exeStatusCode: 'C',
                  });
        } else {
            setValidationResultState(validationResult);
            toggleConfirmModal();
        }
    };

    const onHandleEdit = () => {
        setRecordState(!recordState);
    };

    //datePicker value Change
    const [startDate, setStartDate] = useState(moment().valueOf());
    const handleStartDateValueChange = selected => {
        storeDispatch(updateNurseInfo({ exeDate: selected / 1000 }));
        setStartDate(selected);
    };
    const onHandleTextChange = e => {
        const { value, name } = e.target;
        dispatch(updateActivityFallEvaluationScheduleInfo({ [name]: value }));
    };
    const [confirmModalState, setConfirmModalState] = useState(false);
    const toggleConfirmModal = () => {
        setConfirmModalState(!confirmModalState);
    };
    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    };
    const { promise: NoFallDownRecord } = useAsync({
        promise: createChildFallDownRecord,
        resolve: () => {
            history.push('/activity-schedule');
        },
    });

    const onHandleNotDone = () => {
        NoFallDownRecord({
            ...defaultActivitySchedule,
            bfRatingItemAgeCode: '',
            bfHighRiskCodes: ['0'],
            exeStatusCode: 'N',
            exeDate: nurseInfo.exeDate,
            exeUserName: nurseInfo.exeUserName,
            exeUserNum: nurseInfo.exeUserNum,
            ip: profile.clientIp,
            nanda: '',
            nandaStatus: '',
            psNum,
            remark: '',
            targetNum,
            wardCode: '',
            wardCodeName: '',
        });
    };
    const buttonOptionChange = value => {
        return !value
            ? [
                  {
                      label: t('Save', 'Evaluation'),
                      onClick: onHandleSave,
                      className: 'icon-save',
                      iconBtnClass: 'btn-icon',
                  },
                  {
                      label: t('Not performed', 'Evaluation'),
                      onClick: onHandleNotDone,
                      borderOption: true,
                  },
              ]
            : [
                  {
                      label: t('Edit', 'Evaluation'),
                      onClick: onHandleEdit,
                      borderOption: true,
                      className: 'icon-save',
                      iconBtnClass: 'btn-icon',
                  },
              ];
    };

    const handleScale = ({ score = 0 }) => {
        let scale = '';
        if (score >= 3) {
            scale = t('High Risk', 'Risk');
        }
        if (score <= 2 && selectOption['BF_RATING_ITEM_AGE'] && selectOption['BF_RATING_ITEM_AGE'][0].id !== '0') {
            scale = t('Medium Risk', 'Risk');
        } else if (score <= 1) {
            scale = t('Low Risk', 'Risk');
        }
        if (selectValue.length && !selectValue.includes('0')) {
            scale = t('High Risk', 'Risk');
        }

        return `${score}${t('Score', 'Risk')}/${scale}`;
    };
    const bfHighRiskOptionRows = bfHighRiskOption.rows.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    const bfRatingItemAgeRows = bfRatingItemAge.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemActiveRows = bfRatingItemActive.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemBehaviorRows = bfRatingItemBehavior.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemHealthRows = bfRatingItemHealth.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemDiagnosisRows = bfRatingItemDiagnosis.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemFacilityRows = bfRatingItemFacility.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });

    const bfRatingItemRearerRows = bfRatingItemRearer.map(ele => {
        return {
            ...ele,
            label: t(ele.label, 'ActTypeCode'),
        };
    });
    return (
        cardHeight && (
            <ActiveRecordCard
                style={{ height: cardHeight }}
                headerTitle={`${t('Performance History', 'Evaluation')} : ${t(actTypeCodeName, 'ActTypeCode')} > ${t(
                    actItemCodeName,
                    'ActTypeCode',
                )}`}
                buttonOption={buttonOptionChange(recordState)}
                spanIcon={['icon-info']}
                url={
                    actItemCodeName === '초기평가-7세미만 소아'
                        ? `${url.wmsUrl}/v2/html/aum/guide/4_1.html`
                        : `${url.wmsUrl}/v2/html/aum/guide/4_4.html`
                }
            >
                <div className="p-0 pnt-label-7 row">
                    <DescriptionRow
                        label={t('Nurse', 'Evaluation')}
                        component={
                            <HandleNurseTextInput
                                name={'nurse'}
                                onClick={toggleModal}
                                className={'d-flex'}
                                placeholder={`${t('Nurse', 'Evaluation')}||${t('Number', 'Evaluation')}`}
                                readOnly={nurseRecordState}
                                value={
                                    nurseInfo.hasOwnProperty('exeUserName') &&
                                    `${nurseInfo.exeUserName} | ${nurseInfo.exeUserNum}`
                                }
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    activityFallEvaluationScheduleInfo.hasOwnProperty('exeUserName') &&
                                    activityFallEvaluationScheduleInfo.exeUserName &&
                                    `${activityFallEvaluationScheduleInfo.exeUserName} || ${activityFallEvaluationScheduleInfo.exeUserNum}`
                                }
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('When performed', 'Evaluation')}
                        component={
                            <HandleDateInput
                                value={startDate}
                                handleDateValueChange={handleStartDateValueChange}
                                valueType={'ms'}
                                readOnly={nurseRecordState}
                                minDate={moment().valueOf()}
                                text={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.exeDate}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Zones', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                options={zoneOptionRows}
                                readOnly={recordState}
                                isMulti={false}
                                onChange={handleZoneSelect}
                                value={{ label: t(zoneState.label, 'ActTypeCode'), value: zoneState.value }}
                                text={
                                    (activityFallEvaluationScheduleInfo.wardCodeName &&
                                        t(activityFallEvaluationScheduleInfo.wardCodeName, 'ActTypeCode')) ||
                                    '-'
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('High-risk invariants', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleSearchableSelect
                                readOnly={recordState}
                                options={bfHighRiskOptionRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleMainSelectChange}
                                selected={selectValue}
                                text={{
                                    code: activityFallEvaluationScheduleInfo.bfHighRiskCode,
                                    rows: bfHighRiskOptionRows,
                                }}
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('age', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                // isDisabled={false}
                                options={bfRatingItemAgeRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_AGE']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemAgeCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemAgeCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Activity status', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                // isDisabled={handleDisabled(selectValue)}
                                options={bfRatingItemActiveRows}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_ACTIVE']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemActiveCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemActiveCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />

                    <DescriptionRow
                        label={t('Behavioral Status', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemBehaviorRows}
                                // isDisabled={handleDisabled(selectValue)}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_BEHAVIOR']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemBehaviorCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemBehaviorCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Health', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemHealthRows}
                                // isDisabled={handleDisabled(selectValue)}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_HEALTH']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemHealthCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemHealthCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Diagnosis name', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemDiagnosisRows}
                                //  isDisabled={handleDisabled(selectValue)}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_DIAGNOSIS']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemDiagnosisCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemDiagnosisCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Facilities', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemFacilityRows}
                                //  isDisabled={handleDisabled(selectValue)}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_FACILITY']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemFacilityCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemFacilityCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('Relationships with caregivers', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={
                            <HandleReactSelect
                                readOnly={recordState}
                                options={bfRatingItemRearerRows}
                                //  isDisabled={handleDisabled(selectValue)}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                onChange={handleSelectValueChange}
                                value={selectOption['BF_RATING_ITEM_REARER']}
                                text={
                                    activityFallEvaluationScheduleInfo &&
                                    `${activityFallEvaluationScheduleInfo.bfRatingItemRearerCode}- ${t(
                                        activityFallEvaluationScheduleInfo.bfRatingItemRearerCodeName,
                                        'ActTypeCode',
                                    )}`
                                }
                                customControlStyles={{ width: '235px' }}
                                customOptionStyles={{ width: '235px' }}
                            />
                        }
                    />
                    <DescriptionRow
                        label={t('NANDA fall scale', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        component={<>{handleScale({ score: nandaFallScale })}</>}
                    />
                    <DescriptionRow
                        label={t('Remarks', 'Evaluation')}
                        className="col-xl-12 col-lg-12 mb-3"
                        spanClassName={'w-50'}
                        component={
                            <HandleTextInput
                                size={200}
                                readOnly={recordState}
                                name={'remark'}
                                onChange={onHandleTextChange}
                                placeholder={t('Enter any remarks.', 'Evaluation')}
                                value={activityFallEvaluationScheduleInfo && activityFallEvaluationScheduleInfo.remark}
                            />
                        }
                    />
                </div>
                <CustomSearchModal openModal={modalState} onModalClickYes={toggleModal} />
                <ConfirmModal
                    initModal={confirmModalState}
                    headerText={t('Required fields Item', 'Evaluation')}
                    modalToggle={toggleConfirmModal}
                    confirmText={TextChange(validationResultState)}
                />
            </ActiveRecordCard>
        )
    );
};

export default FallDetail;
