import React, { useEffect, useReducer } from 'react';
import * as column from '../../util/grid/columns';
import { useHistory } from 'react-router-dom';
import useAsync from '../../../../util/hooks/useAsync';
import CustomCard from '../../../Common/Card';
import Button from '../../../Common/Button';
import RecordTable from '../../Components/RecordTable';
import { fetchActivitySettingListApi } from '../../../../api/activitySetting';
import activitySettingReducer, { initialState, setActivitySettingList } from './activitySettingReducer';
import useTranslation from '../../../../util/hooks/useTranslation';
import useColumns from '../../../../util/hooks/useColumns';

const ActivitySetting = () => {
    const t = useTranslation('Common Columns');
    const history = useHistory();
    const [state, dispatch] = useReducer(activitySettingReducer, initialState);
    const { activitySettingList } = state;
    const columns = useColumns(
        [
            column.classificationOfActivities(),
            column.detailActivity(),
            column.detailCondition(),
            column.lastDecubitusDivision(),
            column.executionCycle(),
        ],
        t,
    );
    const { promise: getPrevFilterList, state: activitySettingState } = useAsync({
        promise: fetchActivitySettingListApi,
        resolve: res => {
            // 리덕스로 관리 예정
            dispatch(setActivitySettingList(res));
        },
    });
    useEffect(() => {
        getPrevFilterList({ isAll: 'Y' });
    }, []);
    const onClick = e => {
        history.push(`/active/term-setting/edit/${e.configNum}`);
    };
    const onHandleAddPage = e => {
        history.push('/active/term-setting/add');
    };
    return (
        <>
            <CustomCard
                className={'basic-card'}
                header={{
                    title: t('Set Periodicity for Activities - List', 'Activity Setting'),
                    subTitle: t(
                        'Ability to set conditions for separate pressure ulcer/fall activity and activity frequency.',
                        'Activity Setting',
                    ),
                    action: (
                        <Button
                            onClick={onHandleAddPage}
                            className="pnt-btn btn-blue btn-icon"
                            iconClassName={'icon-plus'}
                        >
                            {t('Add')}
                        </Button>
                    ),
                }}
            >
                <RecordTable
                    noDataPlaceholder={t('Data does not exist.', 'Common Columns')}
                    columns={columns}
                    loading={activitySettingState.isLoading}
                    data={activitySettingList}
                    onClick={onClick}
                    paging={false}
                    t={t}
                />
            </CustomCard>
        </>
    );
};
export default ActivitySetting;
