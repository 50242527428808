import React, { useContext, useEffect, useRef } from 'react';
import Label from '../../../Common/Label';
import Map from '../../../Common/Map/Components/Map';
import { useTranslation } from 'react-i18next';
import { RealTimeSensorMonitoringContext, RealTimeSensorMonitoringDispatchContext } from '../index';
import { useSelector } from 'react-redux';
import { setFloorInfo } from '../realTimeSensorMonitoringReducer';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';
import RotatedImageOverlay from '../../../Common/Map/Components/RotatedImageOverlay';

const SelectedSensor = () => {
    const { t } = useTranslation();
    const { selectedSensor, floorInfo } = useContext(RealTimeSensorMonitoringContext);
    const dispatch = useContext(RealTimeSensorMonitoringDispatchContext);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);

    const mapRef = useRef();

    useEffect(() => {
        dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === selectedSensor.floorId)));
        const map = mapRef.current.leafletElement;
        if (map) {
            map.setView([selectedSensor.lat, selectedSensor.lng]);
        }
    }, []);

    return (
        <>
            <div>
                <div className={'pnt-txt txt-bold txt-dot'}>{t('RealTimeSensorMonitoring;Location Information')}</div>

                <div className="pnt-border border-horiz border-bold mb-4" />

                <div className={'p-0 mb-4 pnt-label-7 pnt-label-top row'}>
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <Label
                            name={t('RealTimeSensorMonitoring;Target Name')}
                            value={selectedSensor.targetName || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <Label
                            name={t('RealTimeSensorMonitoring;Sensor Name')}
                            value={selectedSensor.sensorName || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <Label
                            name={t('RealTimeSensorMonitoring;Current Floor')}
                            value={floorInfo.floorName || '-'}
                            labelValueClassName={'label-dot label-must'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <div className={`pnt-label--group`}>
                            <span className={`label-main label-dot label-must`}>{t('RealTimeSensorMonitoring;Measuring Time')}</span>
                             <span className={`label-options`}>{selectedSensor.lastDate === 0
                                ? '-'
                                : moment.unix(selectedSensor.lastDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                        </div>
                    </div>
                </div>

                <div className="pnt-border border-horiz border-bold mb-4" />

                <Map ref={mapRef} style={{ height: '100vh' }}>
                    {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([selectedSensor.lat, selectedSensor.lng]);
                                }
                            }}
                        />
                    )}

                    <Marker
                        key={selectedSensor.targetId}
                        position={[selectedSensor.lat, selectedSensor.lng]}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />
                </Map>
            </div>
        </>
    );
};

export default SelectedSensor;
