import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const StyledModalTitle = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.color || 'black'};
`;

const StyledFooter = styled.div`
    height: 5rem;
    background-color: #f8f9fa;
    border-top: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
`;

const ConfirmModal = ({
    openModal,
    onModalClickYes,
    content,
    headerTitle,
    headerClassName = 'black',
    handleClick,
    btnClassName = 'btn-orange',
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={openModal}>
            <ModalHeader className={headerClassName} toggle={onModalClickYes}>
                <StyledModalTitle>{headerTitle}</StyledModalTitle>
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
            <StyledFooter>
                <button className="pnt-btn btn-gray btn-back mr-1 " onClick={onModalClickYes}>
                    {/*{t('Button;Close')}*/}
                    취소
                </button>
                <button
                    className={`pnt-btn btn-icon  border w-30 ${btnClassName}`}
                    onClick={() => {
                        handleClick();
                        onModalClickYes();
                    }}
                >
                    <span className="icon-check" />
                    {/*{t('Button;Select')}*/}
                    확인
                </button>
            </StyledFooter>
        </Modal>
    );
};

export default ConfirmModal;
