import React, { useState } from 'react';
import Card from '../Components/WidgetCard';
import { Row, Col, Table } from 'reactstrap';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import ReactResizeDetector from 'react-resize-detector';
import ResponsivePieChart from '../../Common/Charts/PieChart';
import _ from 'lodash';
import { fetchIotItemCount } from '../../../api/asset';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const defaultData = [
    { key: 'ASSET', name: 'Asset', value: _.random(9, 10) },
    { key: 'PEOPLE', name: 'People', value: _.random(30, 35) },
    { key: 'SENSOR', name: 'Sensor', value: _.random(10, 11) },
];

const ItemDetailStatus = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const [tableHeight, setTableHeight] = useState();

    const { data } = useMonitor({
        config,
        paramInfo: ['categoryCode'],
        defaultData,
        fetchData: fetchIotItemCount,
        makeData: ({ newData }) => {
            const iotItemCountList = newData.rows || [];

            let chartData = [];
            if (iotItemCountList && iotItemCountList.length > 0) {
                const length = iotItemCountList.length < 5 ? iotItemCountList.length : 5;
                for (let i = 0; i < length; i++) {
                    chartData.push({
                        key: iotItemCountList[i].categoryCode,
                        name: iotItemCountList[i].categoryName,
                        value: Number(iotItemCountList[i].targetCnt),
                    });
                }
            }
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row style={{ height: '15rem' }}>
                        <ResponsivePieChart data={data} settings={settings} />
                    </Row>
                    <Row>
                        <ReactResizeDetector
                            handleHeight
                            onResize={(width, height) => {
                                setTableHeight(height);
                            }}
                        >
                            <Col>
                                {t('ItemDetailStatus;Current Top 5')}
                                <Table className={'mb-0'}>
                                    <thead>
                                    <tr>
                                        <th className={'text-center'} style={{ width: '33.3%' }}>
                                            {t('ItemDetailStatus;Ranking')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '33.3%' }}>
                                            {t('ItemDetailStatus;Name')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '33.3%' }}>
                                            {t('ItemDetailStatus;Count')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((v, i) => (
                                        <tr key={v.key}>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {i + 1}
                                            </td>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {v.name || '-'}
                                            </td>
                                            <td className={'text-center'} style={{ width: '33.3%' }}>
                                                {v.value || '-'}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </ReactResizeDetector>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default ItemDetailStatus;
