import React from 'react';
import Card from '../../../../Common/Card';
import cx from 'classnames';

const FallPreventionStatusCard = ({ title, cardClassName, className, statusInfo, t }) => {
    return (
        statusInfo && (
            <Card
                cardClassName={cardClassName}
                header={{
                    title: title,
                }}
            >
                <div className="flx-sb">
                    <div className="text-center at__widgets--overviews--main ml-3">
                        <div className={cx('am__font--util', className.total)}>{statusInfo.totalCount}</div>
                        <div className={cx('am__font--util', className.total)}>
                            <span className={className.total}>{t('Number of patients')}</span>
                        </div>
                    </div>
                    <div className="at__widgets--overviews--sub">
                        <ul className="">
                            <li>
                                {t('High risk/concern')}
                                <b>
                                    {statusInfo.bf03Count}/{statusInfo.bf04Count}
                                </b>
                            </li>
                            <li>
                                {t('Medium risk')}
                                <b>{statusInfo.bf02Count}</b>
                            </li>
                            <li>
                                {t('Low risk')}
                                <b>{statusInfo.bf01Count}</b>
                            </li>
                        </ul>
                    </div>
                    <div className={'flx-sb'}>
                        <div className=" at__widgets--overviews--main">
                            <div className={cx('am__font--util ', className.subTitle)}>
                                {statusInfo.safetyZoneInAloneCount}
                            </div>
                            <div className={cx('am__font--util', className.subTitle)}>
                                <span className={className.subTitle}>{t('Safe Zone (Holo)')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flx-sb'}>
                        <div className=" at__widgets--overviews--main">
                            <div className={cx('am__font--util ', className.subTitle)}>
                                {statusInfo.dangerZoneInCount}
                            </div>
                            <div className={cx('am__font--util', className.subTitle)}>
                                <span className={className.subTitle}>{t('Danger zones')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flx-sb'}>
                        <div className=" at__widgets--overviews--main">
                            <div className={cx('am__font--util ', className.subTitle)}>
                                {statusInfo.dangerTimeZoneInCount}
                            </div>
                            <div className={cx('am__font--util', className.subTitle)}>
                                <span className={className.subTitle}>{t('Critical Time')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flx-sb'}>
                        <div className=" at__widgets--overviews--main">
                            <div className={cx('am__font--util ', className.subTitle)}>{statusInfo.sosCount}</div>
                            <div className={cx('am__font--util', className.subTitle)}>
                                <span className={className.subTitle}>{t('Ask for help')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="at__widgets--overviews--main">
                        <div className={cx('am__font--util', className.total)}>
                            {statusInfo.patientSignalDetectionCount}/{statusInfo.totalCount}
                        </div>
                        <div className={cx('am__font--util', className.total)}>
                            <span className={className.total}>{t('Location Detection')}</span>
                        </div>
                    </div>
                    <div className="at__widgets--overviews--sub">
                        <ul className="">
                            <li>
                                {t('Battery warnings')}
                                <b>{statusInfo.batteryWarningCount}</b>
                            </li>
                            <li>
                                {t('Location unknown')}
                                <b>{statusInfo.unidentifiedPatientsCount}</b>
                            </li>
                            <li>
                                {t('Signal loss')}
                                <b>{statusInfo.lostSignalStateCount}</b>
                            </li>
                        </ul>
                    </div>
                </div>
            </Card>
        )
    );
};

export default FallPreventionStatusCard;
