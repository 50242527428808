import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';
const SET_ACTIVITY_SETTING_LIST = 'setting/SET_ACTIVITY_SETTING_LIST';
const SET_ACTIVITY_SCHEDULE_INFO = 'setting/SET_ACTIVITY_SCHEDULE_INFO';
const UPDATE_ACTIVITY_SCHEDULE_INFO = 'setting/UPDATE_ACTIVITY_SCHEDULE_INFO';
const SET_ACTIVITY_TYPE_OPTION = 'setting/SET_ACTIVITY_TYPE_OPTION';
const UPDATE_ACTIVITY_TYPE_OPTION = 'setting/UPDATE_ACTIVITY_TYPE_OPTION';
const SET_ACTIVITY_TYPE_SECOND_OPTION = 'setting/SET_ACTIVITY_TYPE_SECOND_OPTION';
const UPDATE_ACTIVITY_TYPE_SECOND_OPTION = 'setting/UPDATE_ACTIVITY_TYPE_SECOND_OPTION';
const SET_EXECUTION_CYCLE_INFO = 'setting/SET_EXECUTION_CYCLE_INFO';
const SET_EXECUTION_CYCLE_TYPE = 'setting/SET_EXECUTION_CYCLE_TYPE';
const SET_RISK_GRADE_INFO_OPTION = 'setting/SET_RISK_GRADE_INFO_OPTION';
const SET_ACTIVITY_CYCLE_INFO = 'setting/SET_ACTIVITY_CYCLE_INFO';
const UPDATE_ACTIVITY_CYCLE_INFO = 'setting/UPDATE_ACTIVITY_CYCLE_INFO';

export const {
    setActivitySettingList,
    setActivityScheduleInfo,
    updateActivityScheduleInfo,
    setActivityTypeOption,
    updateActivityTypeOption,
    setActivityTypeSecondOption,
    updateActivityTypeSecondOption,
    setExecutionCycleInfo,
    setExecutionCycleType,
    setRiskGradeInfoOption,
    setActivityCycleInfo,
    updateActivityCycleInfo,
} = createActions(
    {
        SET_ACTIVITY_SETTING_LIST: activitySettingList => activitySettingList,
        SET_ACTIVITY_SCHEDULE_INFO: activityScheduleInfo => activityScheduleInfo,
        UPDATE_ACTIVITY_SCHEDULE_INFO: activityScheduleInfo => activityScheduleInfo,
        SET_ACTIVITY_TYPE_OPTION: activityTypeOption => activityTypeOption,
        UPDATE_ACTIVITY_TYPE_OPTION: activityTypeOption => activityTypeOption,
        SET_ACTIVITY_TYPE_SECOND_OPTION: activityTypeSecondOption => activityTypeSecondOption,
        UPDATE_ACTIVITY_TYPE_SECOND_OPTION: activityTypeSecondOption => activityTypeSecondOption,
        SET_EXECUTION_CYCLE_INFO: executionCycleInfo => executionCycleInfo,
        SET_EXECUTION_CYCLE_TYPE: executionCycleType => executionCycleType,
        SET_RISK_GRADE_INFO_OPTION: riskGradeInfoOption => riskGradeInfoOption,
        SET_ACTIVITY_CYCLE_INFO: activityCycleInfo => activityCycleInfo,
        UPDATE_ACTIVITY_CYCLE_INFO: activityCycleInfo => activityCycleInfo,
    },
    { prefix: 'setting' },
);

export const initialState = {
    activitySettingList: { rows: [] },
    activityScheduleInfo: { exeCycleVal: '일요일' },
    activityTypeOption: { rows: [] },
    activityTypeSecondOption: null,
    executionCycleInfo: { optionData: null },
    executionCycleType: { type: null },
    riskGradeInfoOption: { BS_RISK: [], BF_RISK: [] },
    activityCycleInfo: null,
};

const activitySettingReducer = handleActions(
    {
        [SET_ACTIVITY_SETTING_LIST]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activitySettingList = data;
            });
        },
        [SET_ACTIVITY_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityScheduleInfo = data;
            });
        },
        [UPDATE_ACTIVITY_SCHEDULE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityScheduleInfo = { ...state.activityScheduleInfo, ...data };
            });
        },

        [SET_ACTIVITY_TYPE_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload.rows;

                let optionData = [];
                optionData = data.reduce((acc, curr, i) => {
                    acc.push({
                        label: `${curr.codeName}`,
                        value: curr.code,
                        id: curr.clCode,
                    });
                    return acc;
                }, []);
                draft.activityTypeOption.rows = optionData;
            });
        },

        [UPDATE_ACTIVITY_TYPE_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                draft.activityScheduleInfo = { ...state.activityScheduleInfo, ...data };
            });
        },

        [SET_ACTIVITY_TYPE_SECOND_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload.rows || [];
                const name = action.payload.rows[0].clCode || [];
                let optionData = [];
                if (data.length > 0) {
                    optionData = data.reduce((acc, curr, i) => {
                        acc.push({
                            label: `${curr.codeName}`,
                            value: curr.code,
                            id: curr.clCode,
                        });
                        return acc;
                    }, []);
                }

                draft.activityTypeSecondOption = { ...state.activityTypeSecondOption, [name]: optionData };
            });
        },

        [UPDATE_ACTIVITY_TYPE_SECOND_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                draft.activityScheduleInfo = { ...state.activityScheduleInfo, ...data };
            });
        },

        [SET_EXECUTION_CYCLE_INFO]: (state, action) => {
            return produce(state, draft => {
                const optionData = action.payload;
                draft.executionCycleInfo = { optionData };
            });
        },
        [SET_EXECUTION_CYCLE_TYPE]: (state, action) => {
            return produce(state, draft => {
                const type = action.payload;

                draft.executionCycleType = { type };
            });
        },
        [SET_RISK_GRADE_INFO_OPTION]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload.rows;
                let optionData = [];
                optionData = data.reduce((acc, curr, i) => {
                    acc.push({
                        label: curr.codeName,
                        value: curr.code,
                        id: curr.clCode,
                        name: curr.clCode,
                    });
                    return acc;
                }, []);
                if (optionData[0].name === 'BS_RISK') {
                    draft.riskGradeInfoOption.BS_RISK = optionData;
                } else {
                    draft.riskGradeInfoOption.BF_RISK = optionData;
                }
            });
        },
        [SET_ACTIVITY_CYCLE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                draft.activityCycleInfo = data;
            });
        },
        [UPDATE_ACTIVITY_CYCLE_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;

                draft.activityCycleInfo = { ...state.activityCycleInfo, ...data };
            });
        },
    },
    initialState,
);
export default activitySettingReducer;
