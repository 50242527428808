import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { errorPath } from '../../ErrorPages';
import PageNotFound from '../../ErrorPages/Pages/PageNotFound';
import { checkExpire } from '../../../util/common/util';
import OauthLogin from '../Login';

export const AuthRoute = ({ component: Component, rest }) => {
    return (
        <Route
            {...rest}
            render={() => {
                if (checkExpire()) {
                    return <Component />;
                }
                return <Redirect to={'/login'} />;
            }}
        />
    );
};

export const TopSwitch = ({ MainComponent }) => {
    return (
        <Switch>
            {/*<Route exact path={'/login'} component={Login} />*/}
            <Route exact path={['/login', '/login/:oAuthInfo']} component={OauthLogin} />
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={'/login/:oAuthInfo'}*/}
            {/*    render={() => <Redirect to={{ pathname: '/login', state: { oAuthInfo: '' } }} />}*/}
            {/*/>*/}
            <Route exact path={errorPath.pageNotFound} component={PageNotFound} />
            <AuthRoute path="/" component={MainComponent} />
        </Switch>
    );
};

// Redirect Four zero Four
export const ErrorHandleSwitch = ({ children }) => {
    return (
        <Switch>
            {children}
            <Route render={() => <Redirect to={errorPath.pageNotFound} />} />
        </Switch>
    );
};
