import { createSlice } from '@reduxjs/toolkit';
import { idxGenerator } from '../../util/common/util';
import moment from 'moment';

const initialState = {
    selectedDashboard: null,
    dashboardListInfo: { page: 1, totalCount: 0, pageSize: 100, totalPage: 1, rows: [] },
};

const importWidgets = ({ action, state }) => {
    const dashboardInfo = action.payload;
    const importDashboardNum = dashboardInfo.importDashboardNum;
    if (importDashboardNum) {
        const idPrefix = moment().valueOf().toString(36);
        dashboardInfo.widgetInfo =
            state.dashboardListInfo.rows
                .find(v => v.menuNum === importDashboardNum)
                .widgetInfo.map((v, i) => ({
                    ...v,
                    id: idPrefix + i,
                    layout: { ...v.layout, i: idPrefix + i },
                })) || [];
        dashboardInfo.importDashboardNum = null;
        delete dashboardInfo.importDashboardNum;
    }
};

const menuNumGen = idxGenerator();

const Dashboards = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        selectDashboard: (state, action) => {
            state.selectedDashboard = action.payload;
        },
        setDashboardList: (state, action) => {
            state.dashboardListInfo = action.payload;
        },
        addDashboard: (state, action) => {
            const dashboardInfo = action.payload;
            const updateHome = dashboardInfo.home === 'Y';
            if (dashboardInfo.menuNum) {
                state.dashboardListInfo.rows = state.dashboardListInfo.rows
                    .map(v => {
                        if (v.menuNum === dashboardInfo.menuNum) {
                            if (!updateHome && v.home === 'Y') {
                                localStorage.removeItem('home');
                            }
                            importWidgets({ action, state });
                            return { ...dashboardInfo, widgetInfo: v.widgetInfo };
                        }
                        if (updateHome) {
                            v.home = 'N';
                        }
                        return v;
                    })
                    .sort((a, b) => b.chgDate - a.chgDate);
            } else {
                dashboardInfo.menuNum = menuNumGen.next().value;
                state.dashboardListInfo.totalCount += 1;
                dashboardInfo.widgetInfo = [];
                importWidgets({ action, state });
                if (updateHome) {
                    state.dashboardListInfo.rows = state.dashboardListInfo.rows.map(v => ({ ...v, home: 'N' }));
                }
                state.dashboardListInfo.rows.unshift(dashboardInfo);
            }

            if (updateHome) {
                localStorage.setItem('home', dashboardInfo.menuNum);
            }
        },
        deleteDashboard: (state, action) => {
            state.dashboardListInfo.rows = state.dashboardListInfo.rows.filter(v => v.menuNum !== action.payload);
        },
        setDashboardWidgets: (state, action) => {
            const dashboardInfo = action.payload;
            dashboardInfo.widgetInfo = dashboardInfo.widgetInfo.filter(widget => widget.config.type !== 'temp');
            state.dashboardListInfo.rows = state.dashboardListInfo.rows.map(dashboard => {
                if (dashboard.menuNum === dashboardInfo.menuNum) {
                    return dashboardInfo;
                }
                return dashboard;
            });
        },
    },
});

export const {
    selectDashboard,
    setDashboardList,
    addDashboard,
    deleteDashboard,
    setDashboardWidgets,
} = Dashboards.actions;
export default Dashboards.reducer;
