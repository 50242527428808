import React, { useEffect, useState } from 'react';

import Search from '../../Components/Search/Search';
import Filter from '../../Components/Filter/Filter';
import useAsync from '../../../../util/hooks/useAsync';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveScheduleList } from '../../../../api/activeSchedule';
import { resetPatientDetailInfo } from '../../../../reducers/Common/PatientInfo';
import WardTeam from '../../Components/Filter/Components/WardTeam';
import Activity from '../../Components/Filter/Components/Activity';
import Risk from '../../Components/Filter/Components/Risk';
import { SingleSelect } from '../../Components/Search/Components/SingleSelect';
import SearchInput from '../../Components/Search/Components/SearchInput';
import { setFilter, setSearchFilter } from '../../../../reducers/Common/SearchFilter';
import { useFilterParam } from '../../Components/Filter/hooks/useFilterParam';
import ActivityScheduleList from './Components/ActivityScheduleList';

const ScheduleByActivity = props => {
    const storeDispatch = useDispatch();
    const { profile } = useSelector(state => state.UserInfo);
    const [list, setList] = useState({ rows: [], pageSize: 10 });
    const { promise: getPrevFilterList, state: activeScheduleListState } = useAsync({
        promise: getActiveScheduleList,
        resolve: res => {
            setList(res);
        },
    });
    const reset = () => {
        storeDispatch(setFilter(null));
        storeDispatch(setSearchFilter(null));
        setOptionData({ keyword: '', opt: null });
        setSearchOption(null);
    };
    const filterParam = useFilterParam();
    const [optionData, setOptionData] = useState({ keyword: '', opt: null });
    const handleValueChange = e => {
        const { value } = e.target;
        setOptionData({ ...optionData, keyword: value });
    };

    const [searchOption, setSearchOption] = useState(null);
    const handleOptionChange = e => {
        const { value, label, name } = e;
        setOptionData({ ...optionData, opt: value });
        setSearchOption({ name, value, label });
    };

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSearchClick();
        }
    };

    useEffect(() => {
        if (filterParam) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...optionData,
                    ...filterParam,
                    page: 1,
                    pageSize: 10,
                });
            }

            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
        storeDispatch(resetPatientDetailInfo());
    }, [filterParam, profile]);

    const handlePageChange = pageIndex => {
        if (profile && profile.metaData.drst === 'nr') {
            getPrevFilterList({ ...filterParam, ...optionData, ...searchOption, page: pageIndex, pageSize: 10 });
        }
        if (profile && profile.metaData.drst === 'ts') {
            getPrevFilterList({
                ...filterParam,
                ...optionData,
                page: pageIndex,
                pageSize: 10,
                actTypeCodes: 'BS_REPORT,BS_EDU',
                actItemCodes: 'BS_REPORT_1,BS_IV_1',
                actTypeCodesName: '욕창치료관리,욕창예방활동',
                actItemCodesName: '욕창치료관리,전신피부상태확인',
            });
        }
    };

    const handleSearchClick = () => {
        if (searchOption) {
            if (profile && profile.metaData.drst === 'nr') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                });
            }
            if (profile && profile.metaData.drst === 'ts') {
                getPrevFilterList({
                    ...filterParam,
                    ...optionData,
                    page: 1,
                    pageSize: 10,
                    actTypeCodes: 'BS_REPORT,BS_EDU',
                    actItemCodes: 'BS_REPORT_1,BS_IV_1',
                    actTypeCodesName: '욕창치료관리,욕창예방활동',
                    actItemCodesName: '욕창치료관리,전신피부상태확인',
                });
            }
        }
    };

    return (
        <div className="grid-main-container">
            <Filter
                reset={reset}
                Search={
                    <Search handleSearchClick={handleSearchClick}>
                        <SingleSelect value={searchOption} handleOptionChange={handleOptionChange} />
                        <SearchInput
                            value={optionData}
                            handleKeyDown={handleKeyDown}
                            handleValueChange={handleValueChange}
                        />
                    </Search>
                }
            >
                <WardTeam />
                <Activity />
                <Risk />
            </Filter>
            <ActivityScheduleList
                handlePageChange={handlePageChange}
                activeScheduleListState={activeScheduleListState}
                list={list}
            />
        </div>
    );
};

export default ScheduleByActivity;
