import React from 'react';
import { TextInputProps } from '../type';

export const TextInput = ({
    type,
    size,
    name,
    value,
    handleChange,
    width,
    style,
    disabled = false,
    placeholder,
    buttonIcon,
    className,
    buttonClassName,
    minlength,
    maxlength,
    required,
    buttonHandleClick,
    handleKeyUp,
    handleKeyDown,
    onKeyPress,
}: TextInputProps): JSX.Element => {
    return (
        <div className="pnt-input--group">
            <input
                type={type}
                size={size}
                name={name}
                value={value}
                onChange={handleChange}
                className={`pnt-input ${className}`}
                placeholder={placeholder}
                disabled={disabled}
                minLength={minlength}
                maxLength={maxlength}
                required={required}
                style={style}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onKeyPress={onKeyPress}
            />
            {buttonIcon && (
                <button
                    className={`pnt-btn btn-icon-only btn-on-input ${buttonClassName}`}
                    tabIndex={-1}
                    onClick={buttonHandleClick}
                >
                    <span className={`${buttonIcon}`} />
                </button>
            )}
        </div>
    );
};

export default TextInput;
