import React, { useContext } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TextInput from '../../../Common/Input/TextInput';
import { updateGroupInfo } from '../groupSettingReducer';
import ReactSelect from '../../../Common/Select';
import { GroupSettingDispatchContext, GroupSettingStateContext } from '../PatientGroupSetting';
import RadioButton from '../../Components/RadioButton';
import useTranslation from '../../../../util/hooks/useTranslation';

const StyledModalTitle = styled.span`
    font-size: 0.8rem;
    font-weight: bold;
    color: ${props => props.color || 'black'};
`;

const StyledFooter = styled.div`
    height: 5rem;
    background-color: #f8f9fa;
    border-top: 1px solid #d1d1d1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
`;
const CustomGroupModal = ({
    headerTitle,
    handleClick,
    openModal,
    onModalClickYes,
    headerClassName = 'black',
    btnClassName = 'btn-orange',
    validationCheck,
    validation,
}) => {
    const t = useTranslation('Group');
    const dispatch = useContext(GroupSettingDispatchContext);
    const state = useContext(GroupSettingStateContext);

    const { groupInfo, wardInfoList } = state;

    return (
        <Modal isOpen={openModal}>
            <ModalHeader className={headerClassName} toggle={onModalClickYes}>
                <StyledModalTitle>{headerTitle}</StyledModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex mb-4">
                    <div className={'flx-col gap-2 pnt-label-8 p-3'}>
                        <div className={'pnt-label--group'}>
                            <span className="label-main label-dot">{t('Patient Group Name')}</span>
                            <span className="label-options">
                                <div className="pnt-input--group">
                                    <TextInput
                                        size={100}
                                        name={'patientGroupName'}
                                        className={'pnt-input'}
                                        value={(groupInfo && groupInfo.patientGroupName) || null}
                                        handleChange={e => {
                                            const { name, value } = e.target;

                                            if (value === '') {
                                                validationCheck({ ...validation, [name]: t('Patient Group Name') });
                                            } else {
                                                validationCheck({ ...validation, [name]: null });
                                            }
                                            dispatch(updateGroupInfo({ [name]: value }));
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={'pnt-label--group'}>
                            <span className="label-main label-dot">{t('Patient Group Id')}</span>
                            <span className="label-options">
                                <div className="pnt-input--group">
                                    <TextInput
                                        size={100}
                                        name={'patientGroupId'}
                                        value={(groupInfo && groupInfo.patientGroupId) || null}
                                        className={'pnt-input'}
                                        handleChange={e => {
                                            const { name, value } = e.target;

                                            if (value === '') {
                                                validationCheck({ ...validation, [name]: t('Patient Group Id') });
                                            } else {
                                                validationCheck({ ...validation, [name]: null });
                                            }
                                            dispatch(updateGroupInfo({ [name]: value }));
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={'pnt-label--group'}>
                            <span className="label-main label-dot">{t('Ward')}</span>
                            <span className="label-options">
                                <div className="pnt-input--group">
                                    <ReactSelect
                                        name={'wardNo'}
                                        className={'pnt-input'}
                                        options={wardInfoList.rows}
                                        onChange={e => {
                                            const { value } = e;
                                            dispatch(updateGroupInfo({ wardNo: value }));
                                            validationCheck({ ...validation, wardNo: null });
                                        }}
                                        value={
                                            (groupInfo &&
                                                groupInfo.wardNo && {
                                                    label: groupInfo.wardNo,
                                                    value: groupInfo.wardNo,
                                                }) || {
                                                label: t('Select'),
                                                value: 0,
                                            }
                                        }
                                        customControlStyles={{ width: '200px' }}
                                        customOptionStyles={{ width: '200px' }}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={'pnt-label--group'}>
                            <span className="label-main label-dot">{t('Ward Team')}</span>
                            <span className="label-options">
                                <div className="pnt-input--group">
                                    <TextInput
                                        size={100}
                                        name={'teamCd'}
                                        className={'pnt-input'}
                                        value={(groupInfo && groupInfo.teamCd) || null}
                                        handleChange={e => {
                                            const { name, value } = e.target;
                                            if (value === '') {
                                                validationCheck({ ...validation, [name]: t('Ward Team') });
                                            } else {
                                                validationCheck({ ...validation, [name]: null });
                                            }
                                            dispatch(updateGroupInfo({ [name]: value }));
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                        <div className={'pnt-label--group'}>
                            <span className="label-main label-dot">{t('Use Flag')}</span>
                            <span className="label-options">
                                <RadioButton
                                    name={'useFlag'}
                                    radioList={[
                                        { value: 'Y', label: 'Y' },
                                        { value: 'N', label: 'N' },
                                    ]}
                                    value={(groupInfo && groupInfo.useFlag) || null}
                                    onChange={selected => {
                                        dispatch(updateGroupInfo({ useFlag: selected }));
                                        validationCheck({ ...validation, useFlag: null });
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <StyledFooter>
                <button className="pnt-btn btn-back color-orange mr-1 " onClick={onModalClickYes}>
                    {t('Cancel')}
                </button>
                <button
                    className={`pnt-btn  border w-30 ${btnClassName}`}
                    onClick={() => {
                        if (typeof handleClick === 'function') {
                            handleClick();
                        }
                    }}
                >
                    <span />
                    {t('OK')}
                </button>
            </StyledFooter>
        </Modal>
    );
};

export default CustomGroupModal;
