import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_FLOOR_INFO = 'assetSummaryList/SET_FLOOR_INFO';
const SET_SELECTED_FLOOR = 'assetSummaryList/SET_SELECTED_FLOOR';
const SET_ASSET_LIST = 'assetSummaryList/SET_ASSET_LIST';
const SET_SELECTED_ASSET = 'assetSummaryList/SET_SELECTED_ASSET';

export const { setFloorInfo, setSelectedFloor, setAssetList, setSelectedAsset } = createActions(
    {
        SET_FLOOR_INFO: floorInfo => floorInfo,
        SET_SELECTED_FLOOR: selectedFloor => selectedFloor,
        SET_ASSET_LIST: assetList => assetList,
        SET_SELECTED_ASSET: selectedAsset => selectedAsset,
    },
    {
        prefix: 'assetSummaryList',
    },
);

export const initialState = {
    floorInfo: {},
    selectedFloor: {},
    selectedAsset: {},
    assetList: [],
};

const assetSummaryListReducer = handleActions(
    {
        [SET_FLOOR_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.floorInfo = { ...action.payload };
            });
        },
        [SET_SELECTED_FLOOR]: (state, action) => {
            return produce(state, draft => {
                draft.selectedFloor = action.payload;
            });
        },
        [SET_ASSET_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.assetList = action.payload;
            });
        },
        [SET_SELECTED_ASSET]: (state, action) => {
            return produce(state, draft => {
                draft.selectedAsset = action.payload;
            });
        },
    },
    initialState,
);

export default assetSummaryListReducer;
