import React from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';

export const getRiskCircleNamePlate = riskCode => {
    const style = { display: 'inline-block', textAlign: 'center' };
    return (
        <>
            {riskCode === 'BS01' && (
                <div className="bg-danger bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={'고위험군'} className="bg-danger p-1 color-white b-radius-100">
                            욕
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BS02' && (
                <div className="bg-secondary bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={'보통'} className="bg-secondary p-1 color-white b-radius-100">
                            욕
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BS03' && (
                <div className="bg-danger  p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-red p-0">
                        <span title={'관심'} className="bg-danger bg-light p-1 color-red b-radius-100">
                            욕
                        </span>
                    </div>
                </div>
            )}

            {riskCode === 'BF01' && ( // 저위험
                <div className="bg-secondary bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={'저위험군'} className="bg-secondary p-1 color-white b-radius-100">
                            낙
                        </span>
                    </div>
                </div>
            )}

            {riskCode === 'BF02' && ( // 중위험
                <div className="bg-warning bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={'중위험군'} className="bg-warning p-1 color-white b-radius-100">
                            낙
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BF03' && ( //고위험
                <div className="bg-danger bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={'고위험군'} className=" p-1 bg-danger color-white b-radius-100">
                            낙
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BF04' && ( //관심
                <div className="bg-danger  p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-red p-0">
                        <span title={'관심'} className="bg-danger bg-light p-1 color-red b-radius-100">
                            낙
                        </span>
                    </div>
                </div>
            )}

            {!riskCode && '-'}
        </>
    );
};

export const RiskCircleNamePlate = ({ riskCode }) => {
    const t = useTranslation('Risk');
    const style = { display: 'inline-block', textAlign: 'center' };
    return (
        <>
            {riskCode === 'BS01' && (
                <div className="bg-danger bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={t('High')} className="bg-danger p-1 color-white b-radius-100">
                            {t('PU')}
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BS02' && (
                <div className="bg-secondary bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={t('Normal')} className="bg-secondary p-1 color-white b-radius-100">
                            {t('PU')}
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BS03' && (
                <div className="bg-danger  p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-red p-0">
                        <span title={t('Attention')} className="bg-danger bg-light p-1 color-red b-radius-100">
                            {t('PU')}
                        </span>
                    </div>
                </div>
            )}

            {riskCode === 'BF01' && ( // 저위험
                <div className="bg-secondary bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={t('Low')} className="bg-secondary p-1 color-white b-radius-100">
                            {t('F')}
                        </span>
                    </div>
                </div>
            )}

            {riskCode === 'BF02' && ( // 중위험
                <div className="bg-warning bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={t('Medium')} className="bg-warning p-1 color-white b-radius-100">
                            {t('F')}
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BF03' && ( //고위험
                <div className="bg-danger bg-light p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-danger p-0">
                        <span title={t('High')} className=" p-1 bg-danger color-white b-radius-100">
                            {t('F')}
                        </span>
                    </div>
                </div>
            )}
            {riskCode === 'BF04' && ( //관심
                <div className="bg-danger  p-0 " style={style}>
                    <div className="custom-nameplate b-radius-100 color-red p-0">
                        <span title={t('Attention')} className="bg-danger bg-light p-1 color-red b-radius-100">
                            {t('F')}
                        </span>
                    </div>
                </div>
            )}

            {!riskCode && '-'}
        </>
    );
};
export const getRiskStatus = value => {
    switch (value) {
        case 'BS01':
            return (
                <div className="pnt-nameplate bg-danger bg-light b-radius-100 color-danger">
                    <span className="pnt-txt txt-border bg-danger p-1 color-white ">욕</span>
                    <b>고위험군</b>
                </div>
            );
        case 'BS02':
            return (
                <div className="pnt-nameplate bg-secondary bg-light b-radius-100 color-secondary">
                    <span className="pnt-txt txt-border bg-secondary p-1 color-white ">욕</span>
                    <b>보통</b>
                </div>
            );

        case 'BS03':
            return (
                <div className="pnt-nameplate bg-danger b-radius-100 color-white">
                    <span className="pnt-txt txt-border bg-danger bg-light p-1 color-danger">욕</span>
                    <b>관심</b>
                </div>
            );

        case 'BF01':
            return (
                <div className="pnt-nameplate bg-secondary bg-light b-radius-100 color-secondary">
                    <span className="pnt-txt txt-border bg-secondary p-1 color-white ">낙</span>
                    <b>저위험군</b>
                </div>
            );
        case 'BF02':
            return (
                <div className="pnt-nameplate bg-warning bg-light b-radius-100 color-warning">
                    <span className="pnt-txt txt-border bg-warning p-1 color-white">낙</span>
                    <b>중위험군</b>
                </div>
            );
        case 'BF03':
            return (
                <div className="pnt-nameplate bg-danger bg-light b-radius-100 color-danger">
                    <span className="pnt-txt txt-border bg-danger p-1 color-white ">낙</span>
                    <b>고위험군</b>
                </div>
            );
        case 'BF04':
            return (
                <div className="pnt-nameplate bg-danger b-radius-100 color-white">
                    <span className="pnt-txt txt-border bg-danger bg-light p-1 color-danger">낙</span>
                    <b>관심</b>
                </div>
            );

        default:
            return <></>;
    }
};

const Return = ({ status, risk }) => {
    const t = useTranslation('Risk');
    return (
        <div className="pnt-nameplate bg-danger bg-light b-radius-100 color-danger">
            <span className="pnt-txt txt-border bg-danger p-1 color-white ">{t(status)}</span>
            <b>{t(risk)}</b>
        </div>
    );
};
export const RiskStatus = ({ value }) => {
    switch (value) {
        case 'BS01':
            return <Return status={'PU'} risk={'High'} />;
        case 'BS02':
            return <Return status={'PU'} risk={'Normal'} />;
        case 'BS03':
            return <Return status={'PU'} risk={'Attention'} />;
        case 'BF01':
            return <Return status={'F'} risk={'Low'} />;
        case 'BF02':
            return <Return status={'F'} risk={'Medium'} />;
        case 'BF03':
            return <Return status={'F'} risk={'High'} />;
        case 'BF04':
            return <Return status={'F'} risk={'Attention'} />;
        default:
            return <span>-</span>;
    }
};
