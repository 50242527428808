import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    socket: null,
};

const SocketInfo = createSlice({
    name: 'socketInfo',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
        setEventHandler: (state, action) => {
            const { messageType, callback } = action.payload;
            if (state.socket) {
                if (typeof callback === 'function') {
                    state.socket.on(messageType, function (data) {
                        callback(data);
                    });
                } else {
                    state.socket.off(messageType);
                }
            }
        },
    },
});

export const { setSocket, setEventHandler } = SocketInfo.actions;
export default SocketInfo.reducer;
