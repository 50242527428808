import { useMemo } from 'react';
import useTranslation from './useTranslation';

const existT = (customT, t) => {
    return customT ? customT : t;
};

const makeColumns = (columns, t) =>
    columns.map(col => {
        const newCol = { ...col };
        if (newCol.Header && typeof newCol.Header === 'string') {
            newCol.t = existT(col.t, t);
            newCol.Header = newCol.t(newCol.Header);
        }
        return newCol;
    });

// 번역 우선 순위 : 컬럼 옵션 t > useColumn 파라미터 t > 기본 t
const useColumns = (cols, t, deps = []) => {
    const defaultT = useTranslation('commonColumn');
    return useMemo(() => {
        return makeColumns(cols, existT(t, defaultT));
    }, [defaultT, ...deps]);
};

export default useColumns;
