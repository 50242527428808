import React from 'react';
import { LabelProps } from '../type';

const Label = ({ name, value, labelGroupClassName, labelValueClassName }: LabelProps): JSX.Element => {

    return (
        <div className={`pnt-label--group ${labelGroupClassName}`}>
            <span className={`label-main ${labelValueClassName}`}>{(name)}</span>
            {value && <span className={`label-options`}>{(value)}</span>}
        </div>
    );
};

export default Label;
