import moment from 'moment';
import React from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';

export const dateChange = value => {
    return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm') : null;
};
export const timeChange = value => {
    return moment(value * 1000).format('HH:mm');
};
export const dayChange = value => {
    const beforeDay = moment(value).add(28, 'days');
    return beforeDay.isBefore(moment());
};

export const onHandleAge = value => {
    const month = moment().diff(moment(value * 1000), 'month');
    return `${Math.floor(month / 12)} age ${month % 12} months`;
};
export const onHandlePartAge = value => {
    const month = moment().diff(moment(value * 1000), 'month');
    return `${Math.floor(month / 12)}`;
};
export const monthChange = value => {
    let option = {};
    const month = moment().diff(moment(value * 1000), 'month');

    if (4 <= month && month < 12) {
        option = { BF_RATING_ITEM_AGE: [{ label: 'Less than 4 to 12 months', id: 0, value: '1' }] };
    }
    if (4 > month || month >= 36) {
        option = { BF_RATING_ITEM_AGE: [{ label: 'Under 4 months, 3 years and older', id: 0, value: '4' }] };
    }
    if (13 <= month && month < 24) {
        option = { BF_RATING_ITEM_AGE: [{ label: 'Less than 13 to 24 months', id: 0, value: '2' }] };
    }
    if (25 <= month && month < 36) {
        option = { BF_RATING_ITEM_AGE: [{ label: 'Less than 25 to 36 months', id: 0, value: '3' }] };
    }
    return option;
};
export const minToDhm = min => {
    let restMin = Math.abs(min);
    const day = Math.floor(restMin / (24 * 60));
    restMin = restMin % (24 * 60);
    const hours = Math.floor(restMin / 60);
    restMin = restMin % 60;
    return `${day ? (day <= 1 ? Math.abs(day) + 'day' : Math.abs(day) + 'days') : ''}${
        hours ? (hours <= 1 ? Math.abs(hours) + 'hour' : Math.abs(hours) + 'hours') : ''
    }${!isNaN(restMin) ? Math.abs(restMin) + 'min' : ''}`;
};

export const MinToDhm = ({ value, className }) => {
    const t = useTranslation('Date Picker');
    let restMin = Math.abs(value);
    const day = Math.floor(restMin / (24 * 60));
    restMin = restMin % (24 * 60);
    const hours = Math.floor(restMin / 60);
    restMin = restMin % 60;
    return (
        <span className={className} style={{ color: value && value.includes('-') ? 'red' : 'blue' }}>
            {day ? (day <= 1 ? Math.abs(day) + t('Day') : Math.abs(day) + t('Days')) : ''}
            {hours ? (hours <= 1 ? Math.abs(hours) + t('hours') : Math.abs(hours) + t('hours')) : ''}
            {!isNaN(restMin) ? Math.abs(restMin) + t('min') : ''}
        </span>
    );
};
