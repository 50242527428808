import React, { useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppHeader from '../Layout/AppHeader';
import AppSidebar from '../Layout/AppSidebar';
import { ErrorHandleSwitch } from '../MainPages/Components/Router';
import { getLsUserInfo } from '../../util/common/util';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAdditionalInfo,
    setOauthInfo,
    setOauthStatusModal,
    logOut,
    setLanguage,
    setProfile,
} from '../../reducers/UserInfo';
import { useUserTokenCheck } from '../../util/hooks/utilHooks';
import ConfirmModal from '../Common/ConfirmModal';
import Modules from '../Common/Modules';
import ActiveSchedule from '../MainPages/ActiveSchedule';
import ActivityHistory from '../MainPages/ActivityHistory';
import Setting from '../MainPages/Setting';
import GroupSetting from '../MainPages/GroupSetting';
import AppFooter from './AppFooter';
import { fetchCompany, fetchUserInfo } from '../../api/login';
import Home from '../MainPages/Home';
import CodeManagement from '../MainPages/CodeManagement';
import { getApiURL } from '../../api';
import { setUrl } from '../../reducers/Common/CommonInfo';
import useTranslation from '../../util/hooks/useTranslation';
import useSocket from '../../util/socket/hooks/useSocket';
import useAsync from '../../util/hooks/useAsync';
import { setMarkerConfig } from '../../reducers/Common/AppConfig';
import { setNurseInfo } from '../../reducers/Common/CommonInfo';
import moment from 'moment';

const apiUrl = getApiURL();

const getApiUrl = async () => {
    const url = await apiUrl;
    return url;
};
const AppMain = () => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const { userInfo, modal } = useSelector(state => state.UserInfo);

    const { promise: getUserInfo } = useAsync({
        immediate: true,
        promise: fetchUserInfo,
        fixedParam: { lookupItems: 'userName' },
        resolve: res => {
            dispatch(
                setNurseInfo({
                    exeUserName: res.userName,
                    exeUserNum: res.userNum,
                    exeDate: moment().unix(),
                }),
            );
            dispatch(setProfile(res));
        },
    });

    useEffect(() => {
        getUserInfo({});
        const guideUrl = getApiUrl();
        guideUrl.then(res => dispatch(setUrl(res)));
    }, []);

    const { socket, setSocketEvent, closeSocket } = useSocket();

    const { promise: getMoreUserInfo } = useAsync({
        promise: fetchCompany,
        resolve: companyInfo => {
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: companyInfo.markerTransitionActive,
                    markerPulseActive: companyInfo.markerPulseActive,
                    markerBorderRadius: companyInfo.markerBorderRadius,
                    markerPulseAnimation: companyInfo.markerPulseAnimation,
                    markerPulseRssi: companyInfo.markerPulseRssi,
                    markerPulseColor: companyInfo.markerPulseColor,
                }),
            );
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                dispatch(setLanguage(lang));
            }
            // dispatch(setAdditionalInfo(getLsUserInfo()));
        }
    }, [userInfo]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();

    useEffect(() => {
        getMoreUserInfo();
        return () => {
            closeSocket(socket);
        };
    }, []);

    useEffect(() => {
        if (socket) {
            setSocketEvent('notification', data => {
                // console.log('noti', { data });
            });
        }
        return () => {
            setSocketEvent('notification');
        };
    }, [socket]);

    return (
        <>
            {/*<ThemeOptions />*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <ErrorHandleSwitch>
                            {/*<Route exact path={'/dashboards/list'} component={DashboardsList} />*/}
                            {/*{!isMobile && (*/}
                            {/*    <Route exact path={'/dashboards/edit/:menuNum([0-9]+)'} component={DashboardEdit} />*/}
                            {/*)}*/}
                            {/*<Route exact path={'/dashboards/:menuNum([0-9]+)'} component={CustomDashboard} />*/}
                            {/*<Route*/}
                            {/*    exact*/}
                            {/*    path={['/', '/dashboards']}*/}
                            {/*    render={() => {*/}
                            {/*        const home = getHome();*/}
                            {/*        if (home) {*/}
                            {/*            return <Redirect to={`/dashboards/${home}`} />;*/}
                            {/*        }*/}
                            {/*        return <Redirect to={'/dashboards/list'} />;*/}
                            {/*    }}*/}
                            {/*/>*/}

                            {/*<Route path={'/dashboards'} component={Dashboards} />*/}
                            {/*<Route path={'/report'} component={Report} />*/}
                            <Route
                                exact
                                path={'/'}
                                render={() => {
                                    return <Redirect to={'/home'} />;
                                }}
                            />
                            <Route path={'/home'} component={Home} />
                            <Route path={'/activity-schedule'} component={ActiveSchedule} />
                            <Route path={'/activity-history'} component={ActivityHistory} />
                            <Route path={'/active'} component={Setting} />
                            <Route path={'/patient-group/setting'} component={GroupSetting} />
                            <Route path={'/common-code'} component={CodeManagement} />
                            <Route exact path={'/modules'} component={Modules} />
                        </ErrorHandleSwitch>

                        <ConfirmModal
                            initModal={modal.modalOauthStatusOpen}
                            toggleModal={toggleOauthStatus}
                            header={{ title: t('Login;Authentication Error') }}
                            confirmText={
                                <span>
                                    {t('Login;The authentication information is incorrect. Please log in again.')}
                                </span>
                            }
                            okCallback={toggleOauthStatus}
                            removeCancel={true}
                        />
                    </div>
                    <AppFooter />
                </div>
            </div>
            <ToastContainer />
            <div id={'selectContainer'} />
        </>
    );
};

export default withRouter(AppMain);
