import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const SET_ACTIVITY_DASHBOARD_NOT_ENFORCED = 'dashBoard/SET_ACTIVITY_DASHBOARD_NOT_ENFORCED';
const SET_ACTIVITY_DASHBOARD_NOT_ENFORCED_COUNT = 'dashBoard/SET_ACTIVITY_DASHBOARD_NOT_ENFORCED_COUNT';
const SET_ACTIVITY_DASHBOARD_ENFORCED = 'dashBoard/SET_ACTIVITY_DASHBOARD_ENFORCED';
const SET_ACTIVITY_DASHBOARD_ENFORCED_COUNT = 'dashBoard/SET_ACTIVITY_DASHBOARD_ENFORCED_COUNT';
const SET_ACTIVITY_DASHBOARD_PATIENT_RECORD = 'dashBoard/SET_ACTIVITY_DASHBOARD_PATIENT_RECORD';
const SET_ACTIVITY_DASHBOARD_PATIENT_RECORD_COUNT = 'dashBoard/SET_ACTIVITY_DASHBOARD_PATIENT_RECORD_COUNT';

const SET_SUMMARY_OF_FALL_PREVENTION_DASHBOARD = 'dashBoard/SET_SUMMARY_OF_FALL_PREVENTION_DASHBOARD';

const SET_FALL_PATIENT_LIST = 'dashBoard/SET_FALL_PATIENT_LIST';
const SET_FALL_PATIENT_DETAIL_INFO = 'dashBoard/SET_FALL_PATIENT_DETAIL_INFO';
const SET_FLOOR = 'dashBoard/SET_FLOOR';
const SET_GEOFENCE = 'dashBoard/SET_GEOFENCE';
const RESET_MAP_INFO = 'dashBoard/RESET_MAP_INFO';
export const {
    setActivityDashboardNotEnforced,
    setActivityDashboardNotEnforcedCount,
    setActivityDashboardEnforced,
    setActivityDashboardEnforcedCount,
    setActivityDashboardPatientRecord,
    setActivityDashboardPatientRecordCount,
    setSummaryOfFallPreventionDashboard,
    setFallPatientList,
    setFallPatientDetailInfo,
    setFloor,
    setGeofence,
    resetMapInfo,
} = createActions(
    {
        SET_ACTIVITY_DASHBOARD_NOT_ENFORCED: activityDashboardNotEnforced => activityDashboardNotEnforced,
        SET_ACTIVITY_DASHBOARD_NOT_ENFORCED_COUNT: activityDashboardNotEnforcedCount =>
            activityDashboardNotEnforcedCount,
        SET_ACTIVITY_DASHBOARD_ENFORCED: activityDashboardEnforced => activityDashboardEnforced,
        SET_ACTIVITY_DASHBOARD_ENFORCED_COUNT: activityDashboardEnforcedCount => activityDashboardEnforcedCount,
        SET_ACTIVITY_DASHBOARD_PATIENT_RECORD: activityDashboardPatientRecord => activityDashboardPatientRecord,
        SET_ACTIVITY_DASHBOARD_PATIENT_RECORD_COUNT: activityDashboardPatientRecordCount =>
            activityDashboardPatientRecordCount,
        SET_SUMMARY_OF_FALL_PREVENTION_DASHBOARD: summaryOfFallPreventionDashboard => summaryOfFallPreventionDashboard,
        SET_FALL_PATIENT_LIST: fallPatientList => fallPatientList,
        SET_FALL_PATIENT_DETAIL_INFO: fallPatientDetailInfo => fallPatientDetailInfo,
        SET_FLOOR: floor => floor,
        SET_GEOFENCE: geofence => geofence,
        RESET_MAP_INFO: mapInfo => mapInfo,
    },
    {
        prefix: 'dashBoard',
    },
);

export const initialState = {
    activityDashboardNotEnforced: null,
    activityDashboardNotEnforcedCount: null,
    activityDashboardEnforced: null,
    activityDashboardEnforcedCount: null,
    activityDashboardPatientRecord: null,
    activityDashboardPatientRecordCount: null,
    summaryOfFallPreventionDashboard: null,
    fallPatientList: { rows: [] },
    fallPatientDetailInfo: null,
    floor: null,
    geofence: null,
};

const dashBoardReducer = handleActions(
    {
        [SET_ACTIVITY_DASHBOARD_NOT_ENFORCED]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardNotEnforced = data;
            });
        },
        [SET_ACTIVITY_DASHBOARD_NOT_ENFORCED_COUNT]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardNotEnforcedCount = data;
            });
        },
        [SET_ACTIVITY_DASHBOARD_ENFORCED]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardEnforced = data;
            });
        },
        [SET_ACTIVITY_DASHBOARD_ENFORCED_COUNT]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardEnforcedCount = data;
            });
        },
        [SET_ACTIVITY_DASHBOARD_PATIENT_RECORD]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardPatientRecord = data;
            });
        },
        [SET_ACTIVITY_DASHBOARD_PATIENT_RECORD_COUNT]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.activityDashboardPatientRecordCount = data;
            });
        },

        [SET_SUMMARY_OF_FALL_PREVENTION_DASHBOARD]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                if (data) {
                    const unidentifiedPatientsCount = data.totalCount - data.patientSignalDetectionCount;
                    const bfCount = data.bf01Count + data.bf04Count;
                    draft.summaryOfFallPreventionDashboard = { ...data, bfCount, unidentifiedPatientsCount };
                }
            });
        },

        [SET_FALL_PATIENT_LIST]: (state, action) => {
            return produce(state, draft => {
                let patientList = null;
                const data = action.payload;
                patientList = {
                    ...data,
                    rows: data.rows.map(patientData => {
                        return { ...patientData, ...patientData.properties };
                    }),
                };
                draft.fallPatientList = patientList;
            });
        },
        [SET_FALL_PATIENT_DETAIL_INFO]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.fallPatientDetailInfo = data;
            });
        },
        [SET_FLOOR]: (state, action) => {
            return produce(state, draft => {
                const data = action.payload;
                draft.floor = data;
            });
        },
        [SET_GEOFENCE]: (state, action) => {
            return produce(state, draft => {
                let geofenceInfo = [];
                const data = action.payload;
                geofenceInfo = data.rows.reduce((acc, curr, idx) => {
                    if (state.floor.hasOwnProperty('floorId')) {
                        if (curr.floor === state.floor.floorId) {
                            acc.push({
                                ...curr,
                                bounds: curr.llList.map(latLng => [latLng.lat, latLng.lng]),
                            });
                        }
                    }
                    return acc;
                }, []);

                draft.geofence = geofenceInfo;
            });
        },
        [RESET_MAP_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.geofence = null;
                draft.floor = null;
                draft.fallPatientDetailInfo = null;
            });
        },
    },
    initialState,
);
export default dashBoardReducer;
