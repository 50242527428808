import React, { createContext, useReducer } from 'react';
import groupSettingReducer, { initialState } from '../groupSettingReducer';
import GroupList from '../Components/GroupList';
import PatientList from '../Components/PatientList';

export const GroupSettingDispatchContext = createContext();
export const GroupSettingStateContext = createContext();
const PatientGroupSetting = () => {
    const [state, dispatch] = useReducer(groupSettingReducer, initialState);
    return (
        <GroupSettingDispatchContext.Provider value={dispatch}>
            <GroupSettingStateContext.Provider value={state}>
                <div className="p-0 row grid-group-setting-container">
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <GroupList />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-2">
                        <PatientList />
                    </div>
                </div>
            </GroupSettingStateContext.Provider>
        </GroupSettingDispatchContext.Provider>
    );
};

export default PatientGroupSetting;
