import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useFilterParam = () => {
    const { searchFilter, search } = useSelector(state => state.SearchFilter);
    const [filterParam, setFilterParam] = useState(null);
    useEffect(() => {
        let temp = {};
        if (searchFilter) {
            for (let key in searchFilter) {
                if (searchFilter[key]) {
                    if (searchFilter[key].length > 0) {
                        let values = '';
                        values = searchFilter[key].join(',');
                        temp[key] = values;
                    }
                }
            }
            if (search.hasOwnProperty('keyword')) {
                let values = '';
                values = search['keyword'];
                temp['keyword'] = values;
            }
        }
        setFilterParam({ ...temp });
    }, [searchFilter]);

    return filterParam;
};
