import React, { createContext, useReducer } from 'react';
import { Container } from 'reactstrap';
import { ErrorHandleSwitch } from '../Components/Router';
import { Route } from 'react-router-dom';
import CodeList from './CodeList';
import CodeDetail from './CodeDetail';
import { initialState, reducer } from './reducer';

export const CodeManagementContext = createContext();
export const CodeManagementDispatchContext = createContext();

const CodeManagement = ({ match }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <CodeManagementDispatchContext.Provider value={dispatch}>
            <CodeManagementContext.Provider value={state}>
                <Container fluid>
                    <ErrorHandleSwitch>
                        <Route exact path={`${match.path}`} component={CodeList} />
                        <Route exact path={`${match.path}/create`} component={CodeDetail} />
                        <Route exact path={`${match.path}/:codeNum([0-9]+)`} component={CodeDetail} />
                    </ErrorHandleSwitch>
                </Container>
            </CodeManagementContext.Provider>
        </CodeManagementDispatchContext.Provider>
    );
};

export default CodeManagement;
